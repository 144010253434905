import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getSubscriptions, deleteSubscription, changeStatusSubscription } from '../../../../api/subscription';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';
import { getNonEmpty } from '../../../../utils/helpers';

function getData(subscriptions) {
	let data = [];
	for (let i = 0; i < subscriptions.length; ++i) {
		const elem = {};
		elem.title = getNonEmpty(subscriptions[i].title);
		elem.monthlyCost = subscriptions[i].monthlyCost + '€';
		elem.annualDiscount = subscriptions[i].annualDiscount + '%';
		elem.createdAt = subscriptions[i].createdAt;
		elem.id = subscriptions[i]._id;
		elem.active = subscriptions[i].active;
		data = data.concat(elem);
	}
	return data;
}

export default function SubscriptionsPage() {
	const [data, setData] = useState([]);
	const [subscriptionId, setSubscriptionId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item.id === cell)
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-subscription/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes('admin') && <>
					<Tooltip title={elem?.active ? "Disable" : "Enable"}>
						<Button
							style={buttonsStyle} size="small"
							onClick={() => {
								setSubscriptionId(elem)
								setOpenConfirmDialog(1)
							}}>
							{elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon style={{ color: 'red' }} />}
						</Button>
					</Tooltip>
					<Tooltip title='Delete'>
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setSubscriptionId(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
			</>
		);
	}

	const columns = [
		{ dataField: 'title', text: 'Title', sort: true },
		{ dataField: 'monthlyCost', text: 'Monthly cost', sort: true },
		{ dataField: 'annualDiscount', text: 'Annual discount', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getSubscriptions()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get subscriptions.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Subscriptions list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-subscription')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${subscriptionId?.active ? 'disable' : 'enable'} this subscription?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusSubscription(subscriptionId.id, !subscriptionId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({ title: `${subscriptionId?.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Subscription ${subscriptionId?.active ? 'disabled' : 'enabled'} successfully` })
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({ error: error, customMessage: `Could not ${subscriptionId?.active ? 'disable' : 'enable'} subscription.` })
								});
						}}
					/>
					<ConfirmDialog
						title={'Are you sure you want to remove this subscription?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteSubscription(subscriptionId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Subscription removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove subscription.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
