import { API, authClient, baseClient, OPENAI_API_KEY } from "../index";
import { uploadFilesGetLinks, uploadSingleFile } from "../files";

// Get all users
export const getUsers = () => {
	return authClient().get(`${API}/user?getDisabled=true`);
};

// Get user by id
export const getUserById = (id) => {
	return authClient().get(`${API}/user/${id}`);
};

// Delete user
export const deleteUser = (id) => {
	return authClient().delete(`${API}/user/${id}`);
};

// Create user
export const postUser = async (user, images) => {
	if (images) {
		for (const index in images) {
			const response = await uploadSingleFile(images[index], "users");
			user.imagesURLs[index] = response.data.fileURL;
		}
	}
	return authClient().post(`${API}/user`, user);
};

// Update user
export const updateUser = async (id, user, images) => {
	if (images) {
		const imagesURLs = await uploadFilesGetLinks(images, "users");
		user.imagesURLs = imagesURLs;
	}
	return authClient().put(`${API}/user/${id}`, user);
};

// Change status user
export const changeStatusUser = async (id, active) => {
	return authClient().put(`${API}/user/change-status/${id}`, { active });
};

// Count all professionals
export const countProfessionals = () => {
	return authClient().get(`${API}/user/count/professional`);
};

// Count all users
export const countUsers = () => {
	return authClient().get(`${API}/user/count/user`);
};

// Get all AI models
export const getAIModels = () => {
	const config = {
		headers: { Authorization: `Bearer ${OPENAI_API_KEY}` },
	};
	return baseClient().get("https://api.openai.com/v1/models", config);
};

// Get user statistics
export const getUserStatistics = (role) => {
	return authClient().get(`${API}/user/statistics/${role}`);
};

// Get user statistics by id
export const getUserStatisticsById = (id) => {
	return authClient().get(`${API}/user/statistics-by-id/${id}`);
};

export const verifyEmail = async (token) => {
	return baseClient().get(`${API}/user/verify-email?token=${token}`);
};
