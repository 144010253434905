import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import { SERVER_URL } from "../../../api";
import MultilanguageTabBlock from "../MultilanguageTabBlock";
import PreviewDialog from "./PreviewDialog";

const ViewAnswerDialog = (props) => {
	const { answer, open, setOpen } = props;

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<h5>Title</h5>
				{answer?.title[lang] || "---"}
				<br />
				<br />
				<h5>Explanation</h5>
				<div
					dangerouslySetInnerHTML={{
						__html: answer?.explanation[lang] || "---",
					}}
				></div>
			</>
		);
	};

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="md"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{"Answer"}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
				<br />
				<br />
				{answer?.selectedImage ||
					(answer?.imageURL && answer?.imageURL !== "" && (
						<>
							<img
								src={
									answer?.selectedImage
										? URL.createObjectURL(
												answer?.selectedImage
										  )
										: `${SERVER_URL}/${answer?.imageURL}`
								}
								alt={
									answer?.selectedImage
										? answer?.selectedImage?.name
										: answer?.imageURL &&
										  answer?.imageURL !== ""
										? answer?.imageURL?.split(/-(.*)/s)[1]
										: ""
								}
								style={{ maxWidth: "200px", cursor: "zoom-in" }}
								onClick={() => setOpenPreviewDialog(true)}
							/>
							<PreviewDialog
								open={openPreviewDialog}
								setOpen={setOpenPreviewDialog}
								src={
									answer?.selectedImage
										? URL.createObjectURL(
												answer?.selectedImage
										  )
										: `${SERVER_URL}/${answer?.imageURL}`
								}
							/>
							<br />
						</>
					))}
				<br />
				<FormControlLabel
					control={
						<Checkbox
							checked={answer?.correct}
							name="checkCorrect"
						/>
					}
					label="Correct"
				/>
				<br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					onClick={() => {
						setOpen(false);
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default ViewAnswerDialog;
