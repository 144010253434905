import { Button, Tooltip } from "@material-ui/core";
import { LocalConvenienceStoreOutlined, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getLanguages } from "../../../../api/language";
import { getNutritionalPlans } from "../../../../api/nutritionalPlan";
import {
  getPersonalizedNutritionalPlans,
  updatePersonalizedNutritionalPlan,
} from "../../../../api/personalizedNutritionalPlan";
import { getRoutinePlans } from "../../../../api/routinePlan";
import { getSubscriptions } from "../../../../api/subscription";
import { getUsers } from "../../../../api/user";
import { getUserInfoByUserId } from "../../../../api/userInfo";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import LineChartContainer from "../../../components/charts/LineChartContainer";
import { buttonsStyle, dateFormatter } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { EditPersonalizedNutritionalPlanDialog } from "../../../components/dialogs/EditPersonalizedNutritionalPlanDialog";

export default function ViewUsersInfoPage() {
  const [userInfo, setUserInfo] = useState(null);
  const userId = useParams().id;
  const history = useHistory();

  const [trainers, setTrainers] = useState([]);
  const [nutritionists, setNutritionists] = useState([]);
  const [nutritionalPlans, setNutritionalPlans] = useState(null);
  const [
    personalizedNutritionalPlans,
    setPersonalizedNutritionalPlans,
  ] = useState([]);
  const [routinePlans, setRoutinePlans] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [openPlansDialog, setOpenPlansDialog] = useState(false);
  const userLogged = useSelector(
    (store) => store.authentication?.user,
    shallowEqual
  );

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!userId) {
      disableLoadingData();
      return;
    }
    getUsers()
      .then((res) => {
        if (res.status === 200) {
          setTrainers(res.data.filter((user) => user.role.includes("trainer")));
          setNutritionists(
            res.data.filter((user) => user.role.includes("nutritionist"))
          );
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get professionals.",
        });
        history.push("/users");
      });
    getNutritionalPlans()
      .then((res) => {
        if (res.status === 200) {
          const plans = res.data.map((item) => ({
            ...item,
            standard: !item.nutritionist,
          }));
          setNutritionalPlans(plans);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get nutritional plans.",
        });
        history.push("/users");
      });
    getPersonalizedNutritionalPlans()
      .then((res) => {
        if (res.status === 200) {
          console.log("PERSONALIZED NUTRITIONAL PLANS: ", res.data);
          setPersonalizedNutritionalPlans(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get personalized nutritional plans.",
        });
        // history.push("/users");
      });
    getRoutinePlans()
      .then((res) => {
        if (res.status === 200) {
          const plans = res.data.map((item) => ({
            ...item,
            standard: !item.trainer,
          }));
          setRoutinePlans(plans);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get routine plans.",
        });
        history.push("/users");
      });
    getSubscriptions()
      .then((res) => {
        if (res.status === 200) {
          setSubscriptions(
            res.data.map((item) => ({
              ...item,
              title: getNonEmpty(item.title),
              description: getNonEmpty(item.description),
            }))
          );
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get subscriptions.",
        });
        history.push("/users");
      });
    getLanguages()
      .then((resp) => {
        if (resp.status === 200) {
          getUserInfoByUserId(userId)
            .then((res) => {
              if (res.status === 200) {
                let userInfo = res.data;
                let user = {
                  ...userInfo?.user,
                  defaultLanguage: resp.data.find(
                    (item) => item.isocode === userInfo?.user?.defaultLanguage
                  )?.fullName,
                };

                setUserInfo({
                  ...userInfo,
                  user: user,
                });
                disableLoadingData();
              }
            })
            .catch((error) => {
              alertError({
                error: error,
                customMessage: "Could not get user info.",
              });
              history.push("/users");
            });
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get languages.",
        });
      });
  }, [userId, disableLoadingData, history, userLogged]);

  const saveUserWithUpdatedInfo = (updatedInfo) => {
    console.log("UPDATED INFO: ", updatedInfo);
    function filterData(data) {
      function filterItem(item) {
        if (item && item._id) {
          return item._id;
        }
        return item;
      }
      function filterObject(obj) {
        Object.keys(obj).forEach((key) => {
          const value = obj[key];
          if (typeof value === "object" && value !== null) {
            if (key === "foodPlate" || key === "meal") {
              obj[key] = filterItem(value);
            } else {
              filterObject(value);
            }
          }
        });
      }
      const clonedData = JSON.parse(JSON.stringify(data));
      filterObject(clonedData);
      return clonedData;
    }

    const filteredInfo = filterData(updatedInfo);
    console.log("FILTERED INFO: ", filteredInfo);
    updatePersonalizedNutritionalPlan(updatedInfo._id, filteredInfo).then(
      (res) => {
        if (res.status === 200) {
          alertSuccess({ message: "Personalized nutritional plan updated." });
        } else {
          alertError({
            message: "Could not update personalized nutritional plan.",
          });
        }
      }
    );
    setOpenPlansDialog(false);
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="View user additional info"></CardHeader>
          <CardBody>
            <h5>Full name</h5>
            <p>{userInfo?.user?.fullName || "---"}</p>
            <h5>Birth date</h5>
            <p>
              {userInfo?.birthdate && userInfo?.birthdate.includes("T")
                ? dateFormatter(userInfo?.birthdate, true)
                : "--/--/--"}
            </p>
            <h5>Gender</h5>
            <p>{userInfo?.gender || "---"}</p>
            <h5>Default language</h5>
            <p>{userInfo?.user?.defaultLanguage || "---"}</p>
            <h5>Height (cm)</h5>
            <p>{userInfo?.height || "---"}</p>
            <h5>Initial weight (kg)</h5>
            <p>{userInfo?.initialWeight || "---"}</p>
            <h5>Target weight (kg)</h5>
            <p>{userInfo?.targetWeight || "---"}</p>
            <LineChartContainer
              data={
                userInfo?.weightHistory
                  ? userInfo?.weightHistory.map((item) => ({
                      key: dateFormatter(item.date, true),
                      value: item.weight,
                    }))
                  : []
              }
              title="Weight history (kg)"
              format="#.0"
              noDataMessage="This user has not entered his weight yet."
            />
            <br />
            <h5>Goal</h5>
            <p>{userInfo?.goal || "---"}</p>
            <h5>Objective</h5>
            <p>{userInfo?.bodyTypeObjective || "---"}</p>
            <h5>Current body type</h5>
            <p>{userInfo?.bodyType || "---"}</p>
            <h5>Body fat level</h5>
            <p>{userInfo?.bodyFatLevel || "---"}</p>
            <h5>Areas to improve</h5>
            <p>{userInfo?.areas?.join(" · ") || "---"}</p>
            <h5>Current fitness level</h5>
            <p>{userInfo?.fitnessLevel || "---"}</p>
            <h5>Training experience</h5>
            <p>{userInfo?.trainingExperience || "---"}</p>
            <h5>Training place</h5>
            <p>{userInfo?.trainingPlace || "---"}</p>
            <h5>Sports interested in</h5>
            <p>
              {Array.isArray(userInfo?.sports)
                ? userInfo.sports.length > 0
                  ? userInfo.sports.join(", ")
                  : "---"
                : "---"}
            </p>
            <h5>Problems in previous attempts at physical preparation</h5>
            <p>{userInfo?.previousProblems?.join(", ") || "---"}</p>
            <h5>Extra goals</h5>
            <p>{userInfo?.extraGoals?.join(" · ") || "---"}</p>
            <h5>Times per week trained before using the app</h5>
            <p>{userInfo?.timeDedicationBefore || "---"}</p>
            <h5>Time willing to dedicate to a training session</h5>
            <p>{userInfo?.timeDedicationNow || "---"}</p>
            <h5>Actual diet</h5>
            <p>{userInfo?.diet || "---"}</p>
            <h5>Amount of sugar per day</h5>
            <p>{userInfo?.sugar || "---"}</p>
            <h5>Amount of water per day</h5>
            <p>{userInfo?.water || "---"}</p>
            <h5>Meal preferences</h5>
            <p>{userInfo?.meals?.join(" · ") || "---"}</p>
            <h5>Other goals</h5>
            <p>{userInfo?.otherGoals?.join(" · ") || "---"}</p>
          </CardBody>
          <CardHeader title="Health Parameters"></CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-4">
                <h5>Body Mass Index (IMC)</h5>
                <p>{userInfo?.healthParameters?.IMC?.value || "---"}</p>
              </div>
              <div className="col-md-4">
                <h5>Fat Free Body Mass Index (FFMI)</h5>
                <p>{userInfo?.healthParameters?.FFMI?.value || "---"}</p>
              </div>
              <div className="col-md-4">
                <h5>Basal Metabolic Rate (TMB)</h5>
                <p>{userInfo?.healthParameters?.TMB?.value || "---"}</p>
              </div>
              <div className="col-md-4">
                <h5>Body Fat %</h5>
                <p>{userInfo?.healthParameters?.GC?.value || "---"}</p>
              </div>
              <div className="col-md-4">
                <h5>Lean Body Mass (LBM)</h5>
                <p>{userInfo?.healthParameters?.LBM?.value || "---"}</p>
              </div>
              <div className="col-md-4">
                <h5>Muscle Mass %</h5>
                <p>{userInfo?.healthParameters?.MM?.value || "---"}</p>
              </div>
            </div>
          </CardBody>
          <CardHeader title="Calories (Last 7 days)"></CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-4">
                <h5>Ingested Calories</h5>
                <p>
                  {userInfo?.healthParameters?.consumedCalories?.value} cal.
                </p>
              </div>
              <div className="col-md-4">
                <h5>Burned Calories</h5>
                <p>{userInfo?.healthParameters?.burnedCalories?.value} cal.</p>
              </div>
            </div>
          </CardBody>
          <CardHeader title="Trainer"></CardHeader>
          <CardBody>
            <p>
              {trainers?.find((item) => item._id === userInfo?.user?.trainer)
                ?.fullName || "---"}
            </p>
          </CardBody>
          <CardHeader title="Nutritionist"></CardHeader>
          <CardBody>
            <p>
              {nutritionists?.find(
                (item) => item._id === userInfo?.user?.nutritionist
              )?.fullName || "---"}
            </p>
          </CardBody>
          <CardHeader title="Nutritional plans"></CardHeader>
          <CardBody>
            {!userInfo?.user?.nutritionalPlans?.length ? (
              <p>This user has no previous nutritional plans assigned.</p>
            ) : (
              userInfo?.user?.nutritionalPlans.map((item, index) => {
                let plan;
                if (!item.personalized) {
                  plan = nutritionalPlans?.find(
                    (element) => element._id === item.plan
                  );
                } else {
                  plan = personalizedNutritionalPlans?.find(
                    (element) => element._id === item.plan
                  );
                }
                console.log("PLAN: ", plan);
                return (
                  <>
                    <span>
                      {plan?.title
                        ? plan.title
                        : "Personalized Nutritional Plan"}
                      <strong>{" → Start date: "}</strong>
                      {dateFormatter(item.startDate, true)}
                    </span>
                    {((userLogged.role.includes("nutritionist") &&
                      plan.nutritionist === userLogged._id) ||
                      userLogged.role.includes("admin")) &&
                      (!plan?.isPersonalized ? (
                        <Tooltip title={"View"}>
                          <Button
                            size="small"
                            onClick={() =>
                              window.open(
                                "/edit-nutritional-plan/" + plan._id,
                                "_blank"
                              )
                            }
                            style={buttonsStyle}
                          >
                            <Visibility />
                          </Button>
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title={"Edit personalized plan"}>
                            <Button
                              size="small"
                              onClick={() => setOpenPlansDialog(true)}
                              style={buttonsStyle}
                            >
                              <Visibility />
                            </Button>
                          </Tooltip>
                          <EditPersonalizedNutritionalPlanDialog
                            open={openPlansDialog}
                            setOpen={setOpenPlansDialog}
                            title="Personalized Nutritional Plan"
                            plan={plan}
                            setPlan={saveUserWithUpdatedInfo}
                          />
                        </>
                      ))}
                    <br />
                  </>
                );
              })
            )}
          </CardBody>
          <CardHeader title="Routine plans"></CardHeader>
          <CardBody>
            {!userInfo?.user?.routinePlans?.length ? (
              <p>This user has no previous routine plans assigned.</p>
            ) : (
              userInfo?.user?.routinePlans.map((item, index) => {
                const plan = routinePlans?.find(
                  (element) => element._id === item.plan
                );
                return (
                  <>
                    <span>
                      {plan.title}
                      <strong>{" → Start date: "}</strong>
                      {dateFormatter(item.startDate, true)}
                    </span>
                    {((userLogged.role.includes("trainer") &&
                      plan.trainer === userLogged._id) ||
                      userLogged.role.includes("admin")) && (
                      <Tooltip title={"View"}>
                        <Button
                          size="small"
                          onClick={() =>
                            window.open(
                              "/training/edit-plan-routine/" + plan._id,
                              "_blank"
                            )
                          }
                          style={buttonsStyle}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                    )}
                    <br />
                  </>
                );
              })
            )}
            <br />
          </CardBody>
          <CardHeader title="Special routine sessions"></CardHeader>
          <CardBody>
            {!userInfo?.user?.specialSessions?.length ? (
              <p>
                This user has no previous special routine sessions assigned.
              </p>
            ) : (
              userInfo?.user?.specialSessions.map((item, index) => {
                return (
                  <>
                    <span>
                      {item.title}
                      <strong>{" → Start date: "}</strong>
                      {dateFormatter(item.startDate, true)}
                      <strong>{" → Status: "}</strong>
                      {item.status}
                    </span>
                    <br />
                  </>
                );
              })
            )}
            <br />
          </CardBody>
          <CardHeader title="Subscriptions"></CardHeader>
          <CardBody>
            {!userInfo?.user?.subscriptions?.length ? (
              <p>This user has no previous subscriptions added.</p>
            ) : (
              userInfo?.user?.subscriptions.map((item, index) => {
                const subscription = subscriptions?.find(
                  (element) => element._id === item.subscription
                );
                if (!subscription) return <div></div>;
                return (
                  <>
                    <span>
                      {subscription.title}
                      <strong>{" → Start date: "}</strong>
                      {dateFormatter(item.startDate, true)}
                      <strong>{" → Payment status: "}</strong>
                      {item.paymentStatus}
                    </span>
                    {userLogged.role.includes("admin") && (
                      <>
                        <Tooltip title={"View"}>
                          <Button
                            size="small"
                            onClick={() =>
                              window.open(
                                "/edit-subscription/" + subscription._id,
                                "_blank"
                              )
                            }
                            style={buttonsStyle}
                          >
                            <Visibility />
                          </Button>
                        </Tooltip>
                        <br />
                      </>
                    )}
                    <br />
                  </>
                );
              })
            )}
          </CardBody>
        </Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => history.push("/users")}
            variant="outlined"
            style={{ marginRight: "20px" }}
          >
            Back
          </Button>
        </div>
      </>
    );
}
