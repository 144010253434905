import { API, authClient } from "../index";
import { uploadSingleFile } from "../files";

// Get all personalized nutritional plans
export const getPersonalizedNutritionalPlans = () => {
  console.log("ENTRO");
  return authClient().get(
    `${API}/personalized-nutritional-plan?getDisabled=true`
  );
};

// Update personalized nutritional plan
export const updatePersonalizedNutritionalPlan = async (
  id,
  nutritionalPlan,
  image
) => {
  if (image) {
    const response = await uploadSingleFile(
      image,
      "personalizedNutritionalPlans"
    );
    nutritionalPlan.bannerURL = response.data.fileURL;
  }
  return authClient().put(
    `${API}/personalized-nutritional-plan/${id}`,
    nutritionalPlan
  );
};
