import { API, authClient } from "../index";
import { uploadSingleFile } from "../files";

// Get all messages by chat id
export const getMessagesByChatId = (id) => {
	return authClient().get(`${API}/message/${id}`);
};

// Create message
export const postMessage = async (message, file) => {
	if (file) {
		const response = await uploadSingleFile(file, "messages");
		message.fileURL = response.data.fileURL;
		message.text = "";
	}
	return authClient().post(`${API}/message`, message);
};

// Get all unread messages by user id
export const getUnreadMessagesByUser = (userId) => {
	return authClient().get(`${API}/message/unread/${userId}`);
};

// Set all messages of chat as read
export const setAsRead = async (chatId, userId) => {
	return authClient().put(`${API}/message/${chatId}`, { userId: userId });
};
