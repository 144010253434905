import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import { getCredentials, refreshTokens } from "../api/auth/index";
import { getLanguages } from "../api/language";
import { logError } from "../utils/logger";
import BasePage from "./BasePage";
import { AuthPage, Logout } from "./modules/Auth";
import { authActions } from "./modules/Auth/_redux/authActions";
import { ActionsPage } from "./modules/Auth/pages/ActionsPage";
import ErrorsPage from "./modules/Errors/ErrorsPage";

export function Routes() {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const isLogged = useSelector(
		(store) => store.authentication?.loggedIn,
		shallowEqual
	);

	// Checking wether we have credentials in cookies before going to login
	useEffect(() => {
		function fetchMyCredentials() {
			setLoading(true);
			setTimeout(async () => {
				try {
					const response = await getCredentials();
					if (response && response.data) {
						dispatch(authActions.loginActionSuccess(response.data));
					}
					const languages = await getLanguages();
					if (languages?.data) {
						dispatch(
							authActions.getLanguagesSuccess(languages?.data)
						);
						setLoading(false);
					}
				} catch (error) {
					logError({
						error,
						customMessage: "Cannot get credentials, please login.",
					});
					// alertError({error})
					dispatch(authActions.logoutAction());
				}
				setLoading(false);
			}, 1000);
		}
		fetchMyCredentials();
	}, [dispatch]);

	// Check whether browser supports the promise version of requestPermission()
	// Safari only supports the old callback-based version
	function checkNotificationPromise() {
		try {
			Notification.requestPermission().then();
		} catch (e) {
			return false;
		}

		return true;
	}

	// Refresh interval every 15 minutes if logged
	useEffect(() => {
		function askNotificationPermission() {
			// Function to actually ask the permissions
			function handlePermission(permission) {
				console.log("Browser notifications permission: ", permission);
				if (permission !== "granted")
					alert(
						"Push notifications are blocked. Please enable them in your browser by clicking on the padlock that appears to the left of the URL of this site."
					);
			}

			// Check if the browser supports notifications
			if (!Reflect.has(window, "Notification")) {
				console.log("This browser does not support notifications.");
			} else {
				if (checkNotificationPromise()) {
					Notification.requestPermission().then(handlePermission);
				} else {
					Notification.requestPermission(handlePermission);
				}
			}
		}

		if (isLogged) {
			askNotificationPermission();
			const interval = setInterval(async () => {
				try {
					await refreshTokens();
				} catch (error) {
					logError({
						error,
						customMessage: "Cannot refresh tokens.",
					});
					// alertError({error})
					dispatch(authActions.logoutAction());
				}
			}, 900000);
			return () => clearInterval(interval);
		}
	}, [dispatch, isLogged]);

	return loading ? (
		<LayoutSplashScreen />
	) : (
		<Switch>
			{!isLogged &&
				["/verify-email", "/reset-password-user"].some((x) =>
					location?.pathname?.toString().includes(x)
				) && <ActionsPage />}

			{!isLogged ? (
				/*Render auth page when user at `/auth` and not authorized.*/
				<Route>
					<AuthPage />
				</Route>
			) : (
				/*Otherwise redirect to root page (`/`)*/
				<Redirect from="/auth" to="/" />
			)}

			<Route path="/error" component={ErrorsPage} />
			<Route path="/logout" component={Logout} />

			{!isLogged ? (
				/*Redirect to `/auth` when user is not authorized*/
				<Redirect to="/auth/login" />
			) : (
				<Layout>
					<BasePage />
				</Layout>
			)}
		</Switch>
	);
}
