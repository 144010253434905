import axios from 'axios';

export const baseClient = () => {
	return axios.create();
};

export const authClient = () => {
	return axios.create({
		withCredentials: true,
	});
};

export const OPENAI_API_KEY = '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';

const PRO =
	process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pro';

export const API = PRO
	? 'https://eatfity-api.owius.com:4003/api'
	: 'http://localhost:4001/api';

export const SERVER_URL = PRO
	? 'https://eatfity-api.owius.com:4003'
	: 'http://localhost:4001';

export const BACKOFFICE_URL = PRO
	? 'https://app.eatfity.com'
	: 'http://localhost:3000';
