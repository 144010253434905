import { API, authClient } from '../index';
import { uploadSingleFile } from '../files';

export const getScreens = () => authClient().get(`${API}/screen`);

export const getScreenById = (id) => authClient().get(`${API}/screen/${id}`);

export const deleteScreen = (id) => authClient().delete(`${API}/screen/${id}`);

export const postScreen = async (screen, asset) => {
	if (asset) {
		const response = await uploadSingleFile(asset, 'screen');
		screen.asset = response.data.fileURL;
	}
	return authClient().post(`${API}/screen`, screen);
};

export const updateScreen = async (id, screen, asset) => {
	if (asset) {
		const response = await uploadSingleFile(asset, 'screen');
		screen.asset = response.data.fileURL;
	}
	return authClient().put(`${API}/screen/${id}`, screen);
};

export const changeStatusScreen = async (id, active) =>
	authClient().put(`${API}/screen/change-status/${id}`, { active });
