import React, { useEffect, useState } from 'react';

import { getReviews } from '../../../../api/reviews';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import Table, { dateFormatter } from '../../../components/tables/table';
import { alertError } from '../../../../utils/logger';

const getData = (entries) =>
	entries.map((entry) => ({
		routine: entry.routinePlan?.title || '---',
		user: entry.user?.fullName || entry.guest?._id || '---',
		score: entry.score || '---',
		reason: entry.reason || '---',
	}));

export function ReviewsByRoutinePage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);

	const columns = [
		{
			dataField: 'timestamp',
			text: 'Timestamp',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'routine', text: 'Routine', sort: true },
		{ dataField: 'score', text: 'Score', sort: true },
		{ dataField: 'reason', text: 'Reason', sort: true },
		{ dataField: 'user', text: 'User', sort: true },
	];

	useEffect(() => {
		getReviews('?by-routine=true')
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) =>
				alertError({
					error: error,
					customMessage: 'Could not get entries.',
				})
			);
	}, [refresh]);

	return (
		<Card>
			<CardHeader title={'Routine Reviews'} />
			<CardBody>
				<Table data={data} columns={columns} />
			</CardBody>
		</Card>
	);
}
