import { API, authClient } from "../index";

export const getDietVarietyLevels = () => {
	return authClient().get(`${API}/diet-variety-level?getDisabled=true`);
};

export const getDietVarietyLevelById = (id) => {
	return authClient().get(`${API}/diet-variety-level/${id}`);
};

export const deleteDietVarietyLevel = (id) => {
	return authClient().delete(`${API}/diet-variety-level/${id}`);
};

export const postDietVarietyLevel = async (dietVarietyLevel) => {
	return authClient().post(`${API}/diet-variety-level`, dietVarietyLevel);
};

export const updateDietVarietyLevel = async (id, dietVarietyLevel) => {
	return authClient().put(
		`${API}/diet-variety-level/${id}`,
		dietVarietyLevel
	);
};

export const changeStatusDietVarietyLevel = async (id, active) => {
	return authClient().put(`${API}/diet-variety-level/change-status/${id}`, {
		active,
	});
};
