import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getUsers, deleteUser, getUserById, changeStatusUser } from '../../../../api/user';
import { Button, Tooltip, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel  } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from "react-redux";
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import FiltersCard from '../../../components/filters/Filter';
import { getTrainerCertifications } from '../../../../api/trainerCertification'
import { getTrainerSpecializations } from '../../../../api/trainerSpecialization'
import { getNonEmpty } from '../../../../utils/helpers';
import AssessmentIcon from '@material-ui/icons/Assessment'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getData(professionals) {
	let data = [];
	for (let i = 0; i < professionals.length; ++i) {
		if (professionals[i].role.includes('nutritionist') || professionals[i].role.includes('trainer')) {
			const elem = {};
			elem.name = professionals[i].fullName;
			elem.role = { role: professionals[i].role.join(', '), isAI: professionals[i].isAI };
			elem.email = professionals[i].email;
			elem.createdAt = professionals[i].createdAt;
			elem.id = professionals[i]._id;
			elem.active = professionals[i].active;
			data = data.concat(elem);
		}
	}
	return data;
}

const initialFilters = {
	active: false,
	AI: false,
	type: 'All',
	specialization: [],
	certification: []
}

export default function ProfessionalsPage() {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [collapsed, setCollapsed] = useState(true)
	const [certifications, setCertifications] = useState([])
	const [specializations, setSpecializations] = useState([])
	const [filterOptions, setFilterOptions] = useState(initialFilters)
	const [professional, setProfessional] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item._id === cell)
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-professional/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes('admin') && <>
					<Tooltip title={elem?.active ? "Disable" : "Enable"}>
						<Button
							style={buttonsStyle} size="small"
							onClick={() => {
								setProfessional(elem)
								setOpenConfirmDialog(1)
							}}>
							{elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon style={{ color: 'red' }} />}
						</Button>
					</Tooltip>
					<Tooltip title='Delete'>
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setProfessional(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
				<Tooltip title='View report'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/statistics-professional/' + cell)}>
						<AssessmentIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	function roleFormatter(cell) {
		return <>
			<span>
				{cell?.role} 
				{cell?.isAI && <span className='badge badge-primary ml-2'>
					AI
				</span>}
			</span>
		</>
	}

	const columns = [
		{ dataField: 'name', text: 'Full name', sort: true },
		{ dataField: 'email', text: 'Email', sort: true },
		{ dataField: 'role', text: 'Type', sort: true, formatter: roleFormatter },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		if (!user) return
		if (user.role.includes('admin')) {
			getTrainerCertifications()
				.then((res) => {
					if (res.status === 200) {
						setCertifications(res.data);
						setRefresh(false);
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not get trainer certifications.' });
				});
			getTrainerSpecializations()
				.then((res) => {
					if (res.status === 200) {
						setSpecializations(res.data);
						setRefresh(false);
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not get trainer specializations.' });
				});
			getUsers()
				.then((res) => {
					if (res.status === 200) {
						setData(res.data);
						setFilteredData(res.data)
						setRefresh(false);
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not get professionals.' });
				});
		} else {
			getUserById(user._id)
				.then((res) => {
					if (res.status === 200) {
						setData([res.data]);
						setFilteredData([res.data])
						setRefresh(false);
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not get professional.' });
				});
		}
	}, [user, refresh]);

	const handleSearch = async () => {
		if (!data.length) return
		setFilteredData(data.filter(item => {
		  	let filter = true
			if (filterOptions.AI) filter = filter && item.isAI
			if (filterOptions.active) filter = filter && item.active
			if (filter) return item
			return false
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions(initialFilters)
		setRefresh(true)
	}

	const handleChange = (element) => (event) => {
		setFilterOptions({ ...filterOptions, [element]: event.target.value });
	}; 

	const renderFiltersContent = () => {
		return <>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Trainer certification</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.certification || []}
					multiple
					onChange={handleChange('certification')}
					MenuProps={MenuProps}
				>
				{certifications?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.title)}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Trainer specialization</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.specialization || []}
					multiple
					onChange={handleChange('specialization')}
					MenuProps={MenuProps}
				>
				{specializations?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.title)}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.type || ''}
					onChange={handleChange('type')}
					MenuProps={MenuProps}
				>
				{['All', 'Trainer', 'Nutritionist'].map((option) => (
					<MenuItem key={option} value={option}>{option}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControlLabel
				control={<Switch checked={filterOptions.active} onChange={() => setFilterOptions({ ...filterOptions, active: !filterOptions.active })} name="checkActive" />}
				label={filterOptions.active ? "Only active" : "All active/inactive"}
			/>
		</>
	}


	return (
		<>
			<Card>
				<CardHeader title='Professionals list'>
					{user?.role.includes('admin') && <CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-professional')}>
							Add new
						</button>
					</CardHeaderToolbar>}
				</CardHeader>
				<CardBody>
					{user?.role.includes('admin') &&
						<FiltersCard 
							filtersContent={renderFiltersContent}
							collapsed={collapsed} 
							setCollapsed={setCollapsed}
							handleClearFilters={handleClearFilters}
							handleSearch={handleSearch}
						/>
					}
					
					<Table data={getData(filteredData)} columns={columns} />

					<ConfirmDialog
						title={`Are you sure you want to ${professional?.active ? 'disable' : 'enable'} this professional?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusUser(professional?._id, !professional?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({ title: `${professional?.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Professional ${professional?.active ? 'disabled' : 'enabled'} successfully` })
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({ error: error, customMessage: `Could not ${professional?.active ? 'disable' : 'enable'} professional.` })
								});
						}}
					/>
					<ConfirmDialog
						title={'Are you sure you want to remove this professional?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteUser(professional?._id)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Professional removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove professional.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
