import {
	Button,
	Checkbox,
	FormControlLabel,
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	getDietVarietyLevelById,
	updateDietVarietyLevel,
} from "../../../../api/dietVarietyLevel";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyDietVarietyLevel() {
	return {
		fullName: {},
		description: {},
		varietyLevel: "0",
		active: true,
	};
}

export default function EditDietVarietyLevelsPage() {
	const [dietVarietyLevel, setDietVarietyLevel] = useState(
		getEmptyDietVarietyLevel()
	);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const dietVarietyLevelId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!dietVarietyLevelId) {
			history.push("/diet-variety-levels");
			disableLoadingData();
			return;
		}
		getDietVarietyLevelById(dietVarietyLevelId)
			.then((res) => {
				if (res.status === 200) {
					setDietVarietyLevel(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get diet variety level.",
				});
				history.push("/diet-variety-levels");
			});
	}, [dietVarietyLevelId, disableLoadingData, history]);

	function saveDietVarietyLevel() {
		if (checkIsEmpty(dietVarietyLevel.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		updateDietVarietyLevel(dietVarietyLevelId, dietVarietyLevel)
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: "Saved!",
						customMessage: "Changes successfully saved.",
					});
					history.push("/diet-variety-levels");
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not save changes.",
				});
			});
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!dietVarietyLevel[element]) dietVarietyLevel[element] = {};
			let newText = dietVarietyLevel[element];
			newText[lang] = event.target.value;
			setDietVarietyLevel({ ...dietVarietyLevel, [element]: newText });
		} else
			setDietVarietyLevel({
				...dietVarietyLevel,
				[element]: event.target.value,
			});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={
						(dietVarietyLevel.fullName &&
							dietVarietyLevel.fullName[lang]) ||
						""
					}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<TextField
					id={`description`}
					label="Description"
					value={
						(dietVarietyLevel.description &&
							dietVarietyLevel.description[lang]) ||
						""
					}
					onChange={handleChange("description", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit diet variety level"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<TextField
							id={`varietyLevel`}
							label="Variety level"
							value={dietVarietyLevel.varietyLevel}
							onChange={handleChange("varietyLevel")}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								inputProps: {
									min: 0,
								},
							}}
							margin="normal"
							variant="outlined"
							type="number"
						/>
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={dietVarietyLevel.active}
									onChange={() =>
										setDietVarietyLevel({
											...dietVarietyLevel,
											active: !dietVarietyLevel.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/diet-variety-levels")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveDietVarietyLevel()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save diet variety level
					</Button>
				</div>
			</>
		);
}
