import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
	Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { checkIsEmpty } from "../../../utils/helpers";
import { alertError } from "../../../utils/logger";
import MultilanguageTabBlock from "../MultilanguageTabBlock";
import Editor from "../editor/Editor";
import { buttonsStyle } from "../tables/table";
import { Visibility } from "@material-ui/icons";
import { SERVER_URL } from "../../../api";
import PreviewDialog from "./PreviewDialog";

function getEmptySubscriptionBenefit() {
	return {
		title: {},
		description: {},
		basic: false,
		pro: false,
		imageURL: "",
		selectedImage: null,
		active: true,
	};
}

const EditSubscriptionBenefitDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly } = props;

	const [benefit, setBenefit] = useState(getEmptySubscriptionBenefit());

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

	useEffect(() => {
		if (!open || !data) setBenefit(getEmptySubscriptionBenefit());
		else setBenefit(data);
	}, [data, open]);

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!benefit[element]) benefit[element] = {};
			let newText = benefit[element];
			newText[lang] = event.target.value;
			setBenefit({ ...benefit, [element]: newText });
		} else setBenefit({ ...benefit, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!benefit[element]) benefit[element] = {};
				let newText = benefit[element];
				newText[lang] = value;
				setBenefit({ ...benefit, [element]: newText });
			} else setBenefit({ ...benefit, [element]: value });
		}
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Title"
					value={(benefit?.title && benefit?.title[lang]) || ""}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
					disabled={readOnly}
				/>
				<br />
				<br />
				<div>Description</div>
				<Editor
					disabled={readOnly}
					body={
						(benefit.description && benefit?.description[lang]) ||
						""
					}
					setBody={(new_body) => {
						handleChangeEditor("description", new_body, lang);
					}}
					className="max-height"
					placeholder={"Enter benefit description here..."}
				/>
			</>
		);
	};

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
				<br />
				{!readOnly && (
					<label htmlFor={"upload-answer-image"} className="ml-0">
						<input
							style={{ display: "none" }}
							id={"upload-answer-image"}
							name={"upload-answer-image"}
							type="file"
							accept={"image/*"}
							onChange={(e) => {
								setBenefit({
									...benefit,
									selectedImage: e.target.files[0],
								});
							}}
						/>
						<Button
							style={{ marginRight: "15px" }}
							color="secondary"
							component="span"
							variant="outlined"
						>
							{benefit?.selectedImage ||
							(benefit?.imageURL && benefit?.imageURL !== "")
								? "Change image"
								: "Upload image"}
						</Button>
					</label>
				)}
				{(benefit?.selectedImage ||
					(benefit?.imageURL && benefit?.imageURL !== "")) && (
					<>
						<Tooltip title={"Preview image"}>
							<Button
								className={readOnly ? "ml-0" : ""}
								size="small"
								onClick={() => setOpenPreviewDialog(true)}
								style={{
									...buttonsStyle,
									marginRight: "15px",
								}}
							>
								<Visibility />
							</Button>
						</Tooltip>
						<PreviewDialog
							title={"Preview image"}
							open={openPreviewDialog}
							setOpen={setOpenPreviewDialog}
							src={
								benefit?.selectedImage
									? URL.createObjectURL(
											benefit?.selectedImage
									  )
									: `${SERVER_URL}/${benefit?.imageURL}`
							}
						/>
						<span>
							{benefit?.selectedImage
								? benefit?.selectedImage?.name
								: benefit?.imageURL && benefit?.imageURL !== ""
								? benefit?.imageURL?.split(/-(.*)/s)[1]
								: ""}
						</span>
					</>
				)}
				<div className="row d-flex align-items-center">
					<div className="col-md-6">
						<FormControlLabel
							control={
								<Checkbox
									checked={benefit?.basic}
									onChange={() =>
										setBenefit({
											...benefit,
											basic: !benefit?.basic,
										})
									}
									name="checkBasic"
								/>
							}
							disabled={readOnly}
							label="Basic"
						/>
					</div>
					<div className="col-md-6">
						<FormControlLabel
							control={
								<Checkbox
									checked={benefit?.pro}
									onChange={() =>
										setBenefit({
											...benefit,
											pro: !benefit?.pro,
										})
									}
									name="checkPRO"
								/>
							}
							disabled={readOnly}
							label="PRO"
						/>
					</div>
				</div>
				<FormControlLabel
					control={
						<Checkbox
							checked={benefit?.active}
							onChange={() =>
								setBenefit({
									...benefit,
									active: !benefit?.active,
								})
							}
							name="checkActive"
						/>
					}
					disabled={readOnly}
					label="Active"
				/>
				<br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setBenefit(getEmptySubscriptionBenefit());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (checkIsEmpty(benefit.title)) {
							alertError({
								error: null,
								customMessage:
									"The title is required in at least one of the languages",
							});
						} else {
							setOpen(false);
							onSave(benefit);
							setBenefit(getEmptySubscriptionBenefit());
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditSubscriptionBenefitDialog;
