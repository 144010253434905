import React, { useEffect } from 'react'

import {logout} from "../../../../api/auth/index"

import {authActions} from '../../Auth/_redux/authActions'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import socket from '../../../components/socket/socket'


// import { LayoutSplashScreen } from '../../../../_metronic/layout'


function Logout() {
	//console.log('%c[Logout] rendered', '🧠;background: lightpink; color: #444; padding: 3px; border-radius: 5px;')
	// Needs to clean cookies so user doesn't "auto-get credentials"
	// Redirect to login page (automatically when dispatching logoutAction --> sets isLogged to false)

	const dispatch = useDispatch()
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	useEffect(() => {
		async function clearCookies() {
			await logout()
			socket.emit("user-disconnect", user._id);
		}
		clearCookies()
		dispatch(authActions.logoutAction())
	}, [user, dispatch])

	// return <LayoutSplashScreen />
	return <>Logout page</>
}

export default Logout
