import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle,
} from '../../../components/tables/table';
import { getSendings } from '../../../../api/sending';
import { Button, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { alertError } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import { getNonEmpty } from '../../../../utils/helpers';

function getData(sendings) {
	let data = [];
	for (let i = 0; i < sendings.length; ++i) {
		const elem = {};
		elem.receivers =
			sendings[i].receivers.length > 1
				? 'More than one'
				: sendings[i].receivers.map((user) => user.fullName);
		elem.title = getNonEmpty(sendings[i].title);
		elem.createdAt = sendings[i].createdAt;
		elem.id = sendings[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function SendingsPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title='View'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-sending/' + cell)}>
						<VisibilityIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'receivers', text: 'Receivers', sort: true },
		{ dataField: 'title', text: 'Title', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getSendings()
			.then((res) => {
				if (res.status === 200) {
					
					setData(getData(res.data));
					setRefresh(false);
			
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get sendings.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Sendings list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-sending')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
