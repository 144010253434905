import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle,
	booleanFormatter
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getEvents, deleteEvent } from '../../../../api/event';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { getNonEmpty } from '../../../../utils/helpers';
import VisibilityIcon from '@material-ui/icons/Visibility';

function getData(events) {
	let data = [];
	for (let i = 0; i < events.length; ++i) {
		const elem = {};
		elem.title = getNonEmpty(events[i].title);
		elem.date = events[i].date;
		elem.linkURL = events[i].linkURL;
		elem.address = events[i].address;
		elem.maxParticipants = events[i].maxParticipants || '----';
		elem.createdAt = events[i].createdAt;
		elem.inPerson = events[i].inPerson;
		elem.free = events[i].free;
		elem.sport = getNonEmpty(events[i]?.sport?.fullName);
		elem.id = events[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function EventsPage() {
	const [data, setData] = useState([]);
	const [eventId, setEventId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item.id === cell)
		const today = new Date()
		const active = today > elem.date
		return (
			<>
				<Tooltip title={active ? 'Edit' : 'View'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-event/' + cell)}>
						{active ? <EditIcon /> : <VisibilityIcon/>}
					</Button>
				</Tooltip>
				{user?.role.includes('admin') && <>
					<Tooltip title='Delete'>
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setEventId(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
			</>
		);
	}

	const columns = [
		{ dataField: 'title', text: 'Title', sort: true },
		{
			dataField: "sport",
			text: "Sport",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{ dataField: 'date', text: 'Date', sort: true, formatter: dateFormatter },
		{ dataField: 'inPerson', text: 'In person', sort: true, formatter: booleanFormatter, align: 'center', headerAlign: 'center' },
		{ dataField: 'free', text: 'Free', sort: true, formatter: booleanFormatter, align: 'center', headerAlign: 'center' },
		{ dataField: 'linkURL', text: 'Link URL', sort: true, formatter: cell => <a href={cell} target="_blank" rel="noopener noreferrer">{cell}</a> },
		{ dataField: 'address', text: 'Address', sort: true },
		{ dataField: 'maxParticipants', text: 'Max participants', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getEvents()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get events.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Events list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-event')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={'Are you sure you want to remove this event?'}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteEvent(eventId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Event removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove event.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
