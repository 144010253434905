import { API, authClient } from '../index';

// Get all purchases
export const getPurchases = () => {
	return authClient().get(`${API}/purchase`);
};

// Get purchase by id
export const getPurchaseById = (id) => {
	return authClient().get(`${API}/purchase/${id}`);
};
