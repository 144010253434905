import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const getRecipeTypes = () => {
	return authClient().get(`${API}/recipe-type?getDisabled=true`);
};

export const getRecipeTypeById = (id) => {
	return authClient().get(`${API}/recipe-type/${id}`);
};

export const deleteRecipeType = (id) => {
	return authClient().delete(`${API}/recipe-type/${id}`);
};

export const postRecipeType = async (recipeType, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "recipeTypes");
		recipeType.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/recipe-type`, recipeType);
};

export const updateRecipeType = async (id, recipeType, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "recipeTypes");
		recipeType.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/recipe-type/${id}`, recipeType);
};

export const changeStatusRecipeType = async (id, active) => {
	return authClient().put(`${API}/recipe-type/change-status/${id}`, {
		active,
	});
};
