import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	Tooltip
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteGiveaway,
	getGiveawayById,
	postGiveaway,
	updateGiveaway,
	raffleGiveaway
} from '../../../../api/giveaway';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { shallowEqual, useSelector } from 'react-redux'
import { Visibility } from '@material-ui/icons'
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import { SERVER_URL } from "../../../../api/index"
import { buttonsStyle, toIsoString } from '../../../components/tables/table'
import Editor from '../../../components/editor/Editor';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import { getUsers } from '../../../../api/user'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyGiveaway() {
	return {
		title: {},
		text: {},
		imageURL: '',
		endDate: null,
		maxParticipants: null, 
		minParticipants: null,
		legalText: {},
		numWinners: 1
	};
}

export default function EditGiveawaysPage() {
	const [giveaway, setGiveaway] = useState(getEmptyGiveaway());
	const [users, setUsers] = useState([])
	const [selectedImage, setSelectedImage] = useState(null)
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false)
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const giveawayId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!giveawayId) {
			disableLoadingData();
			return;
		}
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setUsers(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get users.' });
				history.push('/giveaways');
			});
		getGiveawayById(giveawayId)
			.then((res) => {
				if (res.status === 200) {
					setGiveaway(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get giveaway.' });
				history.push('/giveaways');
			});
	}, [giveawayId, disableLoadingData, history]);

	function saveGiveaway() {
		if (!giveawayId) {
			postGiveaway(giveaway, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Giveaway successfully created.',
						});
						history.push('/giveaways');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save giveaway.' });
				});
		} else {
			updateGiveaway(giveawayId, giveaway, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/giveaways');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	function raffle() {
		raffleGiveaway(giveawayId)
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: 'Done!',
						customMessage: 'Winners have been selected.',
					});
					setGiveaway(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not raffle giveaway.',
				});
			});
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return
		if (lang) {
			if (!giveaway[element]) giveaway[element] = {}
			let newText = giveaway[element]
			newText[lang] = event.target.value
			setGiveaway({ ...giveaway, [element]: newText })
		} else setGiveaway({ ...giveaway, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value === ' ') return
		if (lang) {
			if (!giveaway[element]) giveaway[element] = {}
			let newText = giveaway[element]
			newText[lang] = value
			setGiveaway({ ...giveaway, [element]: newText })
		} else setGiveaway({ ...giveaway, [element]: value });
	}

	const renderMultilanguageTabContent = (lang) => {
		return <>
			<br/>
			<TextField
				id={`title`}
				label='Title'
				value={(giveaway.title && giveaway.title[lang]) || ''}
				onChange={handleChange('title', lang)}
				InputLabelProps={{
					shrink: true,
				}}
				disabled={giveaway.status === 'closed'}
				margin='normal'
				variant='outlined'
				required
			/>
			<br/>
			<Editor
				body={(giveaway.text && giveaway.text[lang]) || ''}
				setBody={new_body => handleChangeEditor('text', new_body, lang)}
				className='max-height'
				placeholder={'Enter giveaway text here...'}
				label="Text *"
				disabled={giveaway.status === 'closed'}
			/>
			<br/>
			<Editor
				body={(giveaway.legalText && giveaway.legalText[lang]) || ''}
				setBody={new_body => handleChangeEditor('legalText', new_body, lang)}
				className='max-height'
				placeholder={'Enter giveaway legal text here...'}
				label="Legal text *"
				disabled={giveaway.status === 'closed'}
			/>
		</>
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit giveaway'></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent = {renderMultilanguageTabContent}
						/>
						<br/>
						<br/>
		            	<TextField
							id="endDate"
							label="End date"
							type="datetime-local"
							value={toIsoString(giveaway.endDate)}
							onChange={handleChange('endDate')}
							InputLabelProps={{
								shrink: true
							}}
							disabled={giveaway.status === 'closed'}
							margin="normal"
							variant="outlined"
							required
						/>
						<TextField
							id={`maxParticipants`}
							label='Max participants'
							value={giveaway.maxParticipants}
							onChange={handleChange('maxParticipants')}
							InputLabelProps={{
								shrink: true,
							}}
							disabled={giveaway.status === 'closed'}
							margin='normal'
							variant='outlined'
							type="number"
						/>
						<TextField
							id={`minParticipants`}
							label='Min participants'
							value={giveaway.minParticipants}
							onChange={handleChange('minParticipants')}
							InputLabelProps={{
								shrink: true,
							}}
							disabled={giveaway.status === 'closed'}
							margin='normal'
							variant='outlined'
							type="number"
						/>
						<TextField
							id={`numWinners`}
							label='Number of winners'
							value={giveaway.numWinners}
							onChange={handleChange('numWinners')}
							InputLabelProps={{
								shrink: true,
							}}
							disabled={giveaway.status === 'closed'}
							margin='normal'
							variant='outlined'
							type="number"
							required
						/>
						<br/>
						<br/>
						{giveaway.status !== 'closed' && <>
							<label htmlFor={"upload-image"}>
								<input
									style={{ display: 'none' }}
									id={"upload-image"}
									name={"upload-image"}
									type="file"
									accept={'image/*'}
									onChange={(e) => {
										setSelectedImage(e.target.files[0])
									}}
								/>
								<Button
									style={{ marginRight: '15px' }}
									color="secondary"
									component="span"
									variant="outlined">
									{(selectedImage || giveaway.imageURL !== '') ? 'Change image' : 'Upload image'}
								</Button>
							</label> 
						</>}
						{(selectedImage || giveaway.imageURL !== '') &&
							<>
								<Tooltip title={'Preview image'}>
									<Button
										size="small"
										onClick={() => setOpenPreviewDialog(true)}
										style={{...buttonsStyle, marginRight: '15px' }}>
										<Visibility/>
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview image'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={selectedImage ? URL.createObjectURL(selectedImage) : `${SERVER_URL}/${giveaway.imageURL}`}
								/>
								<span>
									{selectedImage ? selectedImage?.name : (giveaway.imageURL !== '' ? giveaway.imageURL.split(/-(.*)/s)[1] : '')}
								</span>
							</>
							}
						<br />
						<br />
						{giveawayId && giveaway.participants && <>
							<h5>Participants</h5>
							<ul>
								{giveaway.participants?.map(p => <li key={p}>{users.find(item => item._id === p)?.fullName || '---'}</li>)}
							</ul>
						</>}
						{giveawayId && giveaway.status === 'closed' && <>
							<h5>Winners</h5>
							<ul>
								{giveaway.winners?.map(w => <li key={w}>{users.find(item => item._id === w)?.fullName || '---'}</li>)}
							</ul>
						</>}
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/giveaways')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					{giveaway.status !== 'closed' && <Button
						onClick={() => saveGiveaway()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save giveaway
					</Button>}
					{giveawayId && giveaway.status !== 'closed' && <Button
						onClick={() => raffle()}
						variant='outlined'
						color='secondary'
						style={{ marginRight: '20px' }}>
						Raffle giveaway
					</Button>}
					{giveawayId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete giveaway
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this giveaway?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteGiveaway(giveawayId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Giveaway deleted successfully',
												});
												history.push('/giveaways');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete giveaway.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
