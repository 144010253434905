import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React from "react";
import QuestionContent from "../../modules/ContentManager/questions/QuestionContent";

const ViewQuestionDialog = (props) => {
	const { question, open, setOpen } = props;

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{"Question"}</DialogTitle>
			<DialogContent>
				<QuestionContent question={question}></QuestionContent>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					onClick={() => {
						setOpen(false);
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default ViewQuestionDialog;
