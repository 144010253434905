import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

// Get all subscriptions
export const getSubscriptions = () => {
	return authClient().get(`${API}/subscription?getDisabled=true`);
};

// Get subscription by id
export const getSubscriptionById = (id) => {
	return authClient().get(`${API}/subscription/${id}?getDisabled=true`);
};

// Delete subscription
export const deleteSubscription = (id) => {
	return authClient().delete(`${API}/subscription/${id}`);
};

// Create subscription
export const postSubscription = async (subscription) => {
	for (let benefit of subscription.benefitComparison) {
		if (benefit?.selectedImage) {
			const response = await uploadSingleFile(
				benefit?.selectedImage,
				"subscriptionBenefits"
			);
			benefit.imageURL = response.data.fileURL;
			delete benefit.selectedImage;
		}
	}
	return authClient().post(`${API}/subscription`, subscription);
};

// Update subscription
export const updateSubscription = async (id, subscription) => {
	for (let benefit of subscription.benefitComparison) {
		if (benefit?.selectedImage) {
			const response = await uploadSingleFile(
				benefit?.selectedImage,
				"subscriptionBenefits"
			);
			benefit.imageURL = response.data.fileURL;
			delete benefit.selectedImage;
		}
	}
	return authClient().put(`${API}/subscription/${id}`, subscription);
};

// Change status subscription
export const changeStatusSubscription = async (id, active) => {
	return authClient().put(`${API}/subscription/change-status/${id}`, {
		active,
	});
};

// Get subscriptions statistics
export const getSubscriptionsStatistics = () => {
	return authClient().get(`${API}/subscription/statistics`);
};
