import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { buttonsStyle, dateFormatter } from '../../../components/tables/table'
import { getTexts, deleteText } from '../../../../api/text'
import { Button, Tooltip } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { alertError, alertSuccess } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'
import { getNonEmpty } from '../../../../utils/helpers'
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { shallowEqual, useSelector } from 'react-redux';

function getData(texts) {
  let data = []
  for (let i = 0; i < texts.length; ++i) {
      const elem = {}
      elem.title = getNonEmpty(texts[i].title)
      elem.createdAt = texts[i].createdAt
      elem.id = texts[i]._id
      data = data.concat(elem)
  }
  return data
}

export default function TextsPage() {
  const [data, setData] = useState([])
  const [textId, setTextId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()
  const user = useSelector((store) => store.authentication?.user, shallowEqual)

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Edit">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/edit-text/' + cell)}>
          <EditIcon/>
        </Button>
      </Tooltip>
      {user?.role.includes('admin') && <>
					<Tooltip title='Delete'>
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setTextId(cell);
								setOpenConfirmDialog(true);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
    </>)
  }

  const columns = [
    { dataField: 'title', text: 'Title', sort: true },
    {
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getTexts().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get texts.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Texts list'>
          <CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-text')}>
							Add new
						</button>
					</CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
						title={'Are you sure you want to remove this text?'}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteText(textId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Text removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove text.',
									});
								});
						}}
					/>
        </CardBody>
      </Card>
    </>
  );
}
