import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import { Button, AppBar, Tab, Tabs, TextField } from '@material-ui/core';
import Editor from '../../../components/editor/Editor';
import { useHistory, useParams } from 'react-router-dom';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { shallowEqual, useSelector } from 'react-redux'
import { getTranslationsByEntityId, postTranslation, updateTranslation } from '../../../../api/translation'
import { getNutritionalPlanById } from '../../../../api/nutritionalPlan';
import { alertError, alertSuccess } from '../../../../utils/logger'
import { weekdays } from './EditNutritionalPlansPage';
import DaysTabBlock from '../../../components/DaysTabBlock';

function getEmptyTranslation() {
	let plan = {}
	weekdays.forEach(day => plan = {...plan, [day]: {
		breakfast: { description: '' },
		lunch: { description: '' },
		afternoon: { description: '' },
		dinner: { description: '' }
	}})
	return {
		type: 'NutritionalPlan',
		langIsoCode: null,
		entityId: null,
		content: {
			title: '',
			description: '',
			plan
		}
	};
}

export default function TranslateNutritionalPlansPage() {
	const [nutritionalPlan, setNutritionalPlan] = useState(null)
	const [translation, setTranslation] = useState(getEmptyTranslation())
	const [translationId, setTranslationId] = useState(null)
	const nutritionalPlanId = useParams().id;
	const currentLanguage = useParams().lang
	const history = useHistory();
	const languages = useSelector((store) => store.authentication?.languages, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!nutritionalPlanId || !currentLanguage) {
			disableLoadingData();
			alertError({ error: null, customMessage: 'Could not get nutritional plan translation.' });
			history.push('/nutritional-plans');
			return;
		}
		getTranslationsByEntityId(nutritionalPlanId, currentLanguage)
			.then((res) => {
				if (res.status === 200) {
					getNutritionalPlanById(nutritionalPlanId)
						.then(response => {
							if (response.status === 200) {
								const { title, description, plan } = response.data
								setNutritionalPlan({ title, description, plan })
								if (res.data?.length) {
									setTranslation(res.data[0]);
									setTranslationId(res.data[0]._id)
								}
								disableLoadingData();
							}
						})
						.catch((error) => {
							alertError({ error: error, customMessage: 'Could not get nutritional plan.' });
							history.push('/nutritional-plan-translations/' + nutritionalPlanId)
						});
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get nutritional plan translations.' });
				history.push('/nutritional-plan-translations/' + nutritionalPlanId)
			});		
		// eslint-disable-next-line
	}, [nutritionalPlanId, disableLoadingData, history]);

	const handleChange = (element, day, subelement) => (event) => {
		if (day) {
			if (event.target.value === ' ') return
			const newContent = translation.content
			let newText = newContent.plan
			if (subelement) newText[day][element][subelement] = event.target.value
			else newText[day][element] = event.target.value
			setTranslation({ ...translation, content: newContent })
		} else {
			const newContent = translation.content
			newContent[element] = event.target.value
			setTranslation({ ...translation, content: newContent });
		}
		
	};

	const handleChangeEditor = (element, value) => {
		const newContent = translation.content
		newContent[element] = value
		setTranslation({ ...translation, content: newContent });
	}

	function saveTranslation() {
		if (!translationId) {
			postTranslation(({ ...translation, entityId: nutritionalPlanId, langIsoCode: currentLanguage }))
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Translation successfully created.',
						});
						history.push('/nutritional-plan-translations/' + nutritionalPlanId)
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save translation.' });
				});
		} else {
			updateTranslation(translationId, translation)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/nutritional-plan-translations/' + nutritionalPlanId)
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}
	const renderDaysTabContent = day => {
		const dayLabel = day.charAt(0).toUpperCase() + day.slice(1)
		return (
		  <>
			<br/>
			<h5>{`${dayLabel} breakfast description`}</h5>
			<div dangerouslySetInnerHTML = {{ __html: (nutritionalPlan.plan && nutritionalPlan.plan[day] && nutritionalPlan.plan[day].breakfast && nutritionalPlan.plan[day].breakfast.description) }}></div>
			<br/>
			<TextField
				id={`${dayLabel} breakfast description`}
				label={`${dayLabel} breakfast description translation`}
				placeholder={`Enter ${day} breakfast translation description here...`}
				value={(translation.content && translation.content.plan && translation.content.plan[day] && translation.content.plan[day].breakfast && translation.content.plan[day].breakfast.description) || ''}
				onChange={handleChange('breakfast', day, 'description')}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				multiline
				variant="outlined"
				rows={6}
				required
			/>
			<br/>
			<br/>
			<h5>{`${dayLabel} lunch description`}</h5>
			<div dangerouslySetInnerHTML = {{ __html: (nutritionalPlan.plan && nutritionalPlan.plan[day] && nutritionalPlan.plan[day].lunch && nutritionalPlan.plan[day].lunch.description) }}></div>
			<br/>
			<TextField
				id={`${dayLabel} lunch description`}
				label={`${dayLabel} lunch description translation`}
				placeholder={`Enter ${day} lunch translation description here...`}
				value={(translation.content && translation.content.plan && translation.content.plan[day] && translation.content.plan[day].lunch && translation.content.plan[day].lunch.description) || ''}
				onChange={handleChange('lunch', day, 'description')}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				multiline
				variant="outlined"
				rows={6}
				required
			/>
			<br/>
			<br/>
			<h5>{`${dayLabel} afternoon snack description`}</h5>
			<div dangerouslySetInnerHTML = {{ __html: (nutritionalPlan.plan && nutritionalPlan.plan[day] && nutritionalPlan.plan[day].afternoon && nutritionalPlan.plan[day].afternoon.description) }}></div>
			<br/>
			<TextField
				id={`${dayLabel} afternoon snack description`}
				label={`${dayLabel} afternoon snack description translation`}
				placeholder={`Enter ${day} afternoon snack translation description here...`}
				value={(translation.content && translation.content.plan && translation.content.plan[day] && translation.content.plan[day].afternoon && translation.content.plan[day].afternoon.description) || ''}
				onChange={handleChange('afternoon', day, 'description')}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				multiline
				variant="outlined"
				rows={6}
				required
			/>
			<br/>
			<br/>
			<h5>{`${dayLabel} dinner description`}</h5>
			<div dangerouslySetInnerHTML = {{ __html: (nutritionalPlan.plan && nutritionalPlan.plan[day] && nutritionalPlan.plan[day].dinner && nutritionalPlan.plan[day].dinner.description) }}></div>
			<br/>
			<TextField
				id={`${dayLabel} dinner description`}
				label={`${dayLabel} dinner description translation`}
				placeholder={`Enter ${day} dinner translation description here...`}
				value={(translation.content && translation.content.plan && translation.content.plan[day] && translation.content.plan[day].dinner && translation.content.plan[day].dinner.description) || ''}
				onChange={handleChange('dinner', day, 'description')}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				multiline
				variant="outlined"
				rows={6}
				required
			/>
		  </>
		)
	  }
	

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title={'Translate nutritional plan ' + nutritionalPlan?.title}/>
					<CardBody>
						<AppBar position="static" color="default" key="appbar">
							<Tabs
								scrollButtons="auto"
								key="tabs"
							>
								<Tab key={currentLanguage} label={languages.find(item => item.isocode === currentLanguage)?.fullName} />
							</Tabs>
						</AppBar>
						<br/>
						<h5>Title</h5>
          				<p>{nutritionalPlan?.title}</p>
						<TextField
							id={`title`}
							label='Title translation'
							value={translation.content?.title || ''}
							onChange={handleChange('title')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<br/>
						<br/>
						<h5>Description</h5>
        				<div dangerouslySetInnerHTML = {{ __html: nutritionalPlan?.description }}></div>
						<br/>
						<Editor
							body={translation.content?.description || ''}
							setBody={new_body => handleChangeEditor('description', new_body)}
							className='max-height'
							placeholder={'Enter nutritional plan description translation here...'}
							label="Description translation"
						/>
						<br/>
						<br/>
						<DaysTabBlock
							daysTabContent = {renderDaysTabContent}
							days = {weekdays}
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/nutritional-plan-translations/' + nutritionalPlanId)}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveTranslation()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save translation
					</Button>
				</div>
			</>
		);
}
