import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import Table, {
	dateFormatter,
	buttonsStyle,
} from "../../../components/tables/table";
import { getPurchases } from "../../../../api/purchases";
import { Button, Tooltip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

function getData(purchases) {
	let data = [];
	for (let i = 0; i < purchases.length; ++i) {
		const elem = {};
		elem.user = purchases[i].user?.fullName;
		elem.status = purchases[i].status;
		elem.product =
			purchases[i].metadata?.purchaseType || purchases[i].productId;
		elem.purchase_date =
			purchases[i].metadata?.purchaseTime || purchases[i].createdAt;
		if (purchases[i].isSubscription)
			elem.expiration_date = purchases[i].metadata?.expirationTime;

		elem.platform = purchases[i].platform;
		elem.price =
			purchases[i].price && !purchases[i].price?.includes("NaN")
				? purchases[i].price
				: purchases[i].metadata?.priceAmount;
		elem.id = purchases[i]._id;
		data = data.concat(elem);
	}
	
	data.sort((a, b) => new Date(b.purchase_date) - new Date(a.purchase_date));

	return data;
}

export default function PurchasesPage() {
	const [data, setData] = useState([]);
	const [rawData, setRawData] = useState([]);

	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const userId = useParams().id;

	function buttonFormatter(cell) {
		const elem = rawData.find((item) => item._id === cell);
		return (
			<>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title="see-details">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() =>
									history.push({
										pathname: "/view-purchase/" + cell,
										state: elem,
									})
								}
							>
								<VisibilityIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: "product", text: "Product", sort: true },
		{ dataField: "user", text: "User", sort: true },
		{ dataField: "status", text: "Status", sort: true },
		{ dataField: "price", text: "Price", sort: true },
		{ dataField: "platform", text: "Platform", sort: true },
		{
			dataField: "purchase_date",
			text: "Purchase date",
			formatter: dateFormatter,
			sort: true,
		},
		{
			dataField: "expiration_date",
			text: "Expiration date",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	const byUser = (item) => item.user._id === userId;

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			const res = await getPurchases();
			if (res.status === 200) {
				if (userId) {
					setRawData(res.data.filter(byUser));
					if (isMounted) setData(getData(res.data.filter(byUser)));
				} else {
					setRawData(res.data);
					if (isMounted) setData(getData(res.data));
				}
			}
		};
		fetchData();
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<>
			<Card>
				<CardHeader title="Purchases list" />
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
