import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getTrainerSpecializations, deleteTrainerSpecialization, changeStatusTrainerSpecialization } from '../../../../api/trainerSpecialization';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';
import { getNonEmpty } from '../../../../utils/helpers';

function getData(trainerSpecializations) {
	let data = [];
	for (let i = 0; i < trainerSpecializations.length; ++i) {
		const elem = {};
		elem.name = getNonEmpty(trainerSpecializations[i].fullName);
		elem.createdAt = trainerSpecializations[i].createdAt;
		elem.id = trainerSpecializations[i]._id;
		elem.active = trainerSpecializations[i].active;
		data = data.concat(elem);
	}
	return data;
}

export default function TrainerSpecializationsPage() {
	const [data, setData] = useState([]);
	const [trainerSpecializationId, setTrainerSpecializationId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item.id === cell)
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-trainer-specialization/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes('admin') && <>
					<Tooltip title={elem?.active ? "Disable" : "Enable"}>
						<Button
							style={buttonsStyle} size="small"
							onClick={() => {
								setTrainerSpecializationId(elem)
								setOpenConfirmDialog(1)
							}}>
							{elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon style={{ color: 'red' }} />}
						</Button>
					</Tooltip>
					<Tooltip title='Delete'>
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setTrainerSpecializationId(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Full name', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getTrainerSpecializations()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get trainer specializations.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Trainer specializations list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-trainer-specialization')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${trainerSpecializationId?.active ? 'disable' : 'enable'} this trainer specialization?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusTrainerSpecialization(trainerSpecializationId.id, !trainerSpecializationId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({ title: `${trainerSpecializationId?.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Trainer specialization ${trainerSpecializationId?.active ? 'disabled' : 'enabled'} successfully` })
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({ error: error, customMessage: `Could not ${trainerSpecializationId?.active ? 'disable' : 'enable'} trainer specialization.` })
								});
						}}
					/>
					<ConfirmDialog
						title={'Are you sure you want to remove this trainer specialization?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteTrainerSpecialization(trainerSpecializationId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Trainer specialization removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove trainer specialization.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
