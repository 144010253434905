import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	FormHelperText,
	Tooltip,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Checkbox,
	AppBar,
	Tab,
	Tabs,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteRoutine,
	getRoutineById,
	postRoutine,
	updateRoutine,
} from '../../../../api/routine';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getMuscleGroups } from '../../../../api/muscleGroup';
import Editor from '../../../components/editor/Editor';
import { shallowEqual, useSelector } from 'react-redux';
import { ArrowUpward, ArrowDownward, Visibility } from '@material-ui/icons';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import { SERVER_URL } from '../../../../api/index';
import DeleteIcon from '@material-ui/icons/Delete';
import { buttonsStyle } from '../../../components/tables/table';
import RoutineContent from './RoutineContent';
import { getNonEmpty } from '../../../../utils/helpers';
import { getEquipments } from '../../../../api/equipment';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

function getEmptyRoutine() {
	return {
		title: '',
		description: '',
		commonerrors: '',
		muscleGroup: null,
		otherMuscleGroups: [],
		equipments: [],
		videoURL: '',
		imageURL: '',
		otherImagesURLs: [],
		audioTipURL: '',
		trainer: null,
		model: 'series',
		restTime: 60,
		active: true,
	};
}

export default function EditRoutinesPage() {
	const [routine, setRoutine] = useState(getEmptyRoutine());
	const [muscleGroups, setMuscleGroups] = useState(null);
	const [equipments, setEquipments] = useState(null);
	const [selectedMainImage, setSelectedMainImage] = useState(null);
	const [selectedAudio, setSelectedAudio] = useState(null);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [selectedImages, setSelectedImages] = useState(null);
	const [deletedImages, setDeletedImages] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const routineId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);
	const languages = useSelector(
		(store) => store.authentication?.languages,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getMuscleGroups()
			.then((res) => {
				if (res.status === 200) {
					setMuscleGroups(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get muscle groups.',
				});
				history.push('/training/routines');
			});
		getEquipments()
			.then((res) => {
				if (res.status === 200) {
					setEquipments(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get equipments.',
				});
				history.push('/training/routines');
			});
		if (!routineId) {
			disableLoadingData();
			return;
		}
		getRoutineById(routineId)
			.then((res) => {
				if (res.status === 200) {
					setRoutine({
						...res.data,
						muscleGroup: res.data.muscleGroup?._id,
						otherMuscleGroups: res.data.otherMuscleGroups?.map(
							(item) => item._id
						),
					});
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get routine.',
				});
				history.push('/training/routines');
			});
	}, [routineId, disableLoadingData, history]);

	function saveRoutine() {
		if (!routine.muscleGroup)
			return alertError({
				error: null,
				customMessage: 'Main muscle group is required.',
			});
		if (!routineId) {
			postRoutine(
				{
					...routine,
					trainer: user.role.includes('trainer') ? user._id : null,
				},
				selectedAudio,
				selectedVideo,
				selectedMainImage,
				selectedImages
			)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Routine successfully created.',
						});
						history.push('/training/routines');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save routine.',
					});
				});
		} else {
			updateRoutine(
				routineId,
				routine,
				selectedAudio,
				selectedVideo,
				selectedMainImage,
				selectedImages
			)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/training/routines');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setRoutine({ ...routine, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value) => {
		setRoutine({ ...routine, [element]: value });
	};

	const handleDeleteImage = (index) => {
		// TODO: DELETE IMAGES FROM SERVER
		let newImages = [...routine.otherImagesURLs];
		const deletedImage = newImages.splice(index, 1);
		setRoutine({ ...routine, otherImagesURLs: newImages });
		if (selectedImages && selectedImages[index]) {
			let _selectedImages = {};
			for (const [key, value] of Object.entries(selectedImages)) {
				if (key !== index) {
					if (key > index) {
						_selectedImages[key - 1] = value;
					} else _selectedImages[key] = value;
				}
			}
			if (!Object.keys(_selectedImages).length) _selectedImages = null;
			setSelectedImages(_selectedImages);
		} else
			setDeletedImages(
				deletedImages ? deletedImages.concat(deletedImage) : [deletedImage]
			);
	};

	const handleMoveImage = (index, newIndex) => {
		const aux = routine.otherImagesURLs[index];
		let images = [...routine.otherImagesURLs];
		images.splice(index, 1, images[newIndex]);
		images.splice(newIndex, 1, aux);
		setRoutine({ ...routine, otherImagesURLs: images });
		if (selectedImages && (selectedImages[index] || selectedImages[newIndex])) {
			let _selectedImages = {};
			for (const [key, value] of Object.entries(selectedImages)) {
				if (key === index.toString()) _selectedImages[newIndex] = value;
				else if (key === newIndex.toString()) _selectedImages[index] = value;
				else _selectedImages[key] = value;
			}
			setSelectedImages(_selectedImages);
		}
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					{!routineId ||
					user?.role.includes('admin') ||
					(user?.role.includes('trainer') &&
						routine.trainer === user?._id) ? (
						<>
							<CardHeader title='Edit routine'></CardHeader>
							<CardBody>
								<AppBar
									position='static'
									color='default'
									key='appbar'>
									<Tabs scrollButtons='auto' key='tabs'>
										<Tab
											key={'es'}
											label={
												languages.find(
													(item) => item.isocode === 'es'
												)?.fullName
											}
										/>
									</Tabs>
								</AppBar>
								<TextField
									id={`title`}
									label='Title'
									value={routine.title}
									onChange={handleChange('title')}
									InputLabelProps={{
										shrink: true,
									}}
									margin='normal'
									variant='outlined'
									required
								/>
								<br />
								<Editor
									body={routine.description || ''}
									setBody={(new_body) =>
										handleChangeEditor('description', new_body)
									}
									className='max-height'
									placeholder={'Enter routine description here...'}
									label='Description'
								/>
								<br />
								<Editor
									body={routine.commonerrors || ''}
									setBody={(new_body) =>
										handleChangeEditor('commonerrors', new_body)
									}
									className='max-height'
									placeholder={
										'Enter routine common errors here...'
									}
									label='Common errors'
								/>
								<br />
								<FormControl>
									<FormLabel id='demo-radio-buttons-group-label'>
										Model
									</FormLabel>
									<RadioGroup
										row
										aria-label='model'
										name='model'
										value={routine.model}
										onChange={handleChange('model')}>
										<FormControlLabel
											value='series'
											control={<Radio />}
											label='Series with repetitions'
										/>
										<FormControlLabel
											value='time'
											control={<Radio />}
											label='Time'
										/>
										<FormControlLabel
											value='nTimes'
											control={<Radio />}
											label='Number of times'
										/>
									</RadioGroup>
								</FormControl>
								<br />
								<div className='col-md-4'>
									<TextField
										id={`restTime`}
										label={`Rest time (seconds)`}
										value={routine.restTime || 60}
										onChange={handleChange('restTime')}
										InputLabelProps={{ shrink: true }}
										margin='normal'
										variant='outlined'
										type='number'
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</div>
								<br />
								<FormControl style={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-standard-label'>
										Main muscle group*
									</InputLabel>
									<Select
										labelId='demo-simple-select-standard-label'
										id='demo-simple-select-standard'
										value={routine.muscleGroup || ''}
										onChange={handleChange('muscleGroup')}
										MenuProps={MenuProps}>
										{muscleGroups?.map((option) => (
											<MenuItem
												key={option._id}
												value={option._id}>
												{getNonEmpty(option.fullName)}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										Select a muscle group
									</FormHelperText>
								</FormControl>
								<br />
								<br />
								<FormControl style={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-standard-label'>
										Other muscle groups
									</InputLabel>
									<Select
										labelId='demo-simple-select-standard-label'
										id='demo-simple-select-standard'
										multiple
										value={routine.otherMuscleGroups || []}
										onChange={handleChange('otherMuscleGroups')}
										MenuProps={MenuProps}>
										{muscleGroups?.map((option) => (
											<MenuItem
												key={option._id}
												value={option._id}>
												{getNonEmpty(option.fullName)}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										Select other muscle groups
									</FormHelperText>
								</FormControl>
								<br />
								<br />
								<FormControl style={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-standard-label'>
										Equipments
									</InputLabel>
									<Select
										labelId='demo-simple-select-standard-label'
										id='demo-simple-select-standard'
										multiple
										value={routine.equipments || []}
										onChange={handleChange('equipments')}
										MenuProps={MenuProps}>
										{equipments?.map((option) => (
											<MenuItem
												key={option._id}
												value={option._id}>
												{getNonEmpty(option.fullName)}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										Select equipments
									</FormHelperText>
								</FormControl>
								<br />
								<br />
								<FormControlLabel
									control={
										<Checkbox
											checked={routine.active}
											onChange={() =>
												setRoutine({
													...routine,
													active: !routine.active,
												})
											}
											name='checkActive'
										/>
									}
									label='Active'
								/>
								<br />
								<br />
								{/* AUDIO TIP */}
								<label htmlFor={'upload-audio'}>
									<input
										style={{ display: 'none' }}
										id={'upload-audio'}
										name={'upload-audio'}
										type='file'
										accept={'audio/*'}
										onChange={(e) => {
											setSelectedAudio(e.target.files[0]);
										}}
									/>
									<Button
										style={{ marginRight: '15px' }}
										color='secondary'
										component='span'
										variant='outlined'>
										{selectedAudio || routine.audioTipURL !== ''
											? 'Change audio tip'
											: 'Upload audio tip'}
									</Button>
								</label>
								{(selectedAudio || routine.audioTipURL !== '') && (
									<>
										<Tooltip title={'Preview audio tip'}>
											<Button
												size='small'
												onClick={() =>
													setOpenPreviewDialog('audio')
												}
												style={buttonsStyle}>
												<Visibility />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={'Preview audio tip'}
											open={openPreviewDialog === 'audio'}
											setOpen={setOpenPreviewDialog}
											src={
												selectedAudio
													? URL.createObjectURL(
															selectedAudio
													  )
													: `${SERVER_URL}/${routine.audioTipURL}`
											}
										/>
										<Tooltip title='Delete'>
											<Button
												size='small'
												style={buttonsStyle}
												onClick={() => {
													setSelectedAudio(null);
													setRoutine({
														...routine,
														audioTipURL: '',
													});
												}}>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedAudio
												? selectedAudio?.name
												: routine.audioTipURL !== ''
												? routine.audioTipURL.split(
														/-(.*)/s
												  )[1]
												: ''}
										</span>
									</>
								)}
								<br />
								{/* VIDEO */}
								<label htmlFor={'upload-video'}>
									<input
										style={{ display: 'none' }}
										id={'upload-video'}
										name={'upload-video'}
										type='file'
										accept={'video/*'}
										onChange={(e) => {
											setSelectedVideo(e.target.files[0]);
										}}
									/>
									<Button
										style={{ marginRight: '15px' }}
										color='secondary'
										component='span'
										variant='outlined'>
										{selectedVideo || routine.videoURL !== ''
											? 'Change video'
											: 'Upload video'}
									</Button>
								</label>
								{(selectedVideo || routine.videoURL !== '') && (
									<>
										<Tooltip title={'Preview video'}>
											<Button
												size='small'
												onClick={() =>
													setOpenPreviewDialog('video')
												}
												style={buttonsStyle}>
												<Visibility />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={'Preview video'}
											open={openPreviewDialog === 'video'}
											setOpen={setOpenPreviewDialog}
											type='video'
											src={
												selectedVideo
													? URL.createObjectURL(
															selectedVideo
													  )
													: `${SERVER_URL}/${routine.videoURL}`
											}
										/>
										<Tooltip title='Delete'>
											<Button
												size='small'
												style={buttonsStyle}
												onClick={() => {
													setSelectedVideo(null);
													setRoutine({
														...routine,
														videoURL: '',
													});
												}}>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedVideo
												? selectedVideo?.name
												: routine.videoURL !== ''
												? routine.videoURL.split(/-(.*)/s)[1]
												: ''}
										</span>
									</>
								)}
								<br />
								{/* MAIN IMAGE */}
								<label htmlFor={'upload-image'}>
									<input
										style={{ display: 'none' }}
										id={'upload-image'}
										name={'upload-image'}
										type='file'
										accept={'image/*'}
										onChange={(e) => {
											setSelectedMainImage(e.target.files[0]);
										}}
									/>
									<Button
										style={{ marginRight: '15px' }}
										color='secondary'
										component='span'
										variant='outlined'>
										{selectedMainImage || routine.imageURL !== ''
											? 'Change main image'
											: 'Upload main image'}
									</Button>
								</label>
								{(selectedMainImage || routine.imageURL !== '') && (
									<>
										<Tooltip title={'Preview main image'}>
											<Button
												size='small'
												onClick={() =>
													setOpenPreviewDialog(
														'main-image'
													)
												}
												style={buttonsStyle}>
												<Visibility />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={'Preview main image'}
											open={openPreviewDialog === 'main-image'}
											setOpen={setOpenPreviewDialog}
											src={
												selectedMainImage
													? URL.createObjectURL(
															selectedMainImage
													  )
													: `${SERVER_URL}/${routine.imageURL}`
											}
										/>
										<Tooltip title='Delete'>
											<Button
												size='small'
												style={buttonsStyle}
												onClick={() => {
													setSelectedMainImage(null);
													setRoutine({
														...routine,
														imageURL: '',
													});
												}}>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedMainImage
												? selectedMainImage?.name
												: routine.imageURL !== ''
												? routine.imageURL.split(/-(.*)/s)[1]
												: ''}
										</span>
									</>
								)}
								<br />
								{/* OTHER IMAGES */}
								{routine.otherImagesURLs?.map((_, index) => {
									return (
										<>
											<label htmlFor={'upload-image' + index}>
												<input
													style={{ display: 'none' }}
													id={'upload-image' + index}
													name={'upload-image' + index}
													type='file'
													accept={'image/*'}
													onChange={(e) => {
														setSelectedImages({
															...selectedImages,
															[index]:
																e.target.files[0],
														});
													}}
												/>
												<Button
													style={{ marginRight: '15px' }}
													color='secondary'
													component='span'
													variant='outlined'>
													{(selectedImages &&
														selectedImages[index]) ||
													routine.otherImagesURLs[
														index
													] !== ''
														? 'Change image ' +
														  (index + 1)
														: 'Upload image ' +
														  (index + 1)}
												</Button>
											</label>
											{((selectedImages &&
												selectedImages[index]) ||
												routine.otherImagesURLs[index] !==
													'') && (
												<>
													<Tooltip
														title={
															'Preview image ' +
															(index + 1)
														}>
														<Button
															size='small'
															onClick={() =>
																setOpenPreviewDialog(
																	index
																)
															}
															style={buttonsStyle}>
															<Visibility />
														</Button>
													</Tooltip>
													<PreviewDialog
														title={
															'Preview image ' +
															(index + 1)
														}
														open={
															openPreviewDialog ===
															index
														}
														setOpen={
															setOpenPreviewDialog
														}
														src={
															selectedImages &&
															selectedImages[index]
																? URL.createObjectURL(
																		selectedImages[
																			index
																		]
																  )
																: `${SERVER_URL}/${routine.otherImagesURLs[index]}`
														}
													/>
													<Tooltip title='Delete'>
														<Button
															size='small'
															style={buttonsStyle}
															onClick={() =>
																handleDeleteImage(
																	index
																)
															}>
															<DeleteIcon />
														</Button>
													</Tooltip>
													<Tooltip title='Move up'>
														<Button
															size='small'
															style={buttonsStyle}
															disabled={index === 0}
															onClick={() =>
																handleMoveImage(
																	index,
																	index - 1
																)
															}>
															<ArrowUpward />
														</Button>
													</Tooltip>
													<Tooltip title='Move down'>
														<Button
															size='small'
															disabled={
																!(
																	index <
																		routine
																			.otherImagesURLs
																			.length -
																			1 &&
																	((selectedImages &&
																		selectedImages[
																			index + 1
																		]) ||
																		routine
																			.otherImagesURLs[
																			index + 1
																		] !== '')
																)
															}
															style={{
																...buttonsStyle,
																marginRight: '1em',
															}}
															onClick={() =>
																handleMoveImage(
																	index,
																	index + 1
																)
															}>
															<ArrowDownward />
														</Button>
													</Tooltip>
													<span>
														{selectedImages &&
														selectedImages[index]
															? selectedImages[index]
																	?.name
															: routine
																	.otherImagesURLs[
																	index
															  ] !== ''
															? routine.otherImagesURLs[
																	index
															  ].split(/-(.*)/s)[1]
															: ''}
													</span>
													<br />
												</>
											)}
										</>
									);
								})}
								<br />
								<br />
								<Button
									variant='outlined'
									color='primary'
									onClick={() =>
										setRoutine({
											...routine,
											otherImagesURLs: routine.otherImagesURLs.concat(
												''
											),
										})
									}>
									Add image
								</Button>
							</CardBody>
						</>
					) : (
						<>
							<CardHeader title={'View routine ' + routine.title} />
							<CardBody>
								<RoutineContent
									routine={{
										...routine,
										standard: !routine.trainer,
										muscleGroups: [
											getNonEmpty(
												muscleGroups.find(
													(m) =>
														m._id === routine.muscleGroup
												)?.fullName
											),
										]
											.concat(
												routine.otherMuscleGroups.map((mg) =>
													getNonEmpty(
														muscleGroups.find(
															(m) => m._id === mg
														)?.fullName
													)
												)
											)
											.join(', '),
									}}
								/>
							</CardBody>
						</>
					)}
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/training/routines')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					{(!routineId ||
						user?.role.includes('admin') ||
						(user?.role.includes('trainer') &&
							routine.trainer === user?._id)) && (
						<>
							<Button
								onClick={() => {
									if (routine.used) setOpenConfirmDialog(1);
									else saveRoutine();
								}}
								variant='outlined'
								color='primary'
								style={{ marginRight: '20px' }}>
								Save routine
							</Button>
							<ConfirmDialog
								title={
									'This routine is already associated to some plans and/or users, are you sure you want to modify it?'
								}
								open={openConfirmDialog === 1}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => saveRoutine()}
							/>
						</>
					)}
					{routineId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(2)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete routine
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									'Are you sure you want to delete this routine?'
								}
								open={openConfirmDialog === 2}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteRoutine(routineId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: 'Deleted!',
													customMessage:
														'Routine deleted successfully',
												});
												history.push('/training/routines');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													'Could not delete routine.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
