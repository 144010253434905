import { API, authClient } from '../index';
import { uploadSingleFile } from "../files"

// Get all nutritional plans
export const getNutritionalPlans = () => {
	return authClient().get(`${API}/nutritional-plan?getDisabled=true`);
};

// Get nutritional plan by id
export const getNutritionalPlanById = (id) => {
	return authClient().get(`${API}/nutritional-plan/${id}`);
};

// Delete nutritional plan
export const deleteNutritionalPlan = (id) => {
	return authClient().delete(`${API}/nutritional-plan/${id}`);
};

// Create nutritional plan 
export const postNutritionalPlan = async (nutritionalPlan, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'nutritionalPlans')
		nutritionalPlan.bannerURL = response.data.fileURL
	}
	return authClient().post(`${API}/nutritional-plan`, nutritionalPlan);
};

// Update nutritional plan
export const updateNutritionalPlan = async (id, nutritionalPlan, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'nutritionalPlans')
		nutritionalPlan.bannerURL = response.data.fileURL
	}
	return authClient().put(`${API}/nutritional-plan/${id}`, nutritionalPlan);
};

// Change status nutritional plan
export const changeStatusNutritionalPlan = async (id, active) => {
	return authClient().put(`${API}/nutritional-plan/change-status/${id}`, { active });
};

// Get nutrition statistics
export const getNutritionalStatistics = () => {
	return authClient().get(`${API}/nutritional-plan/statistics`);
};