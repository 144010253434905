import React, { useState, useEffect } from "react";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	Button,
	MuiThemeProvider,
	createMuiTheme,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	FormHelperText,
	Tooltip,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
	deleteUser,
	getUserById,
	postUser,
	getUsers,
	updateUser,
} from "../../../../api/user";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { dateFormatter } from "../../../components/tables/table";
import { getNutritionalPlans } from "../../../../api/nutritionalPlan";
import { getRoutinePlans } from "../../../../api/routinePlan";
import { getSubscriptions } from "../../../../api/subscription";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { buttonsStyle } from "../../../components/tables/table";
import TableDialog from "../../../components/dialogs/TableDialog";
import { shallowEqual, useSelector } from "react-redux";
import { getNonEmpty } from "../../../../utils/helpers";
import PaymentIcon from "@material-ui/icons/Payment";
import SelectDialog from "../../../components/dialogs/SelectDialog";
import { getRoutines } from "../../../../api/routine";
import NewSessionDialog from "../../../components/dialogs/NewSessionDialog";
import EditIcon from "@material-ui/icons/Edit";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

function getEmptyUser() {
	return {
		fullName: "",
		active: true,
		email: "",
		username: null,
		code: null,
		role: ["user"],
		phone: "",
		imagesURLs: [],
		routinePlans: [],
		nutritionalPlans: [],
		subscriptions: [],
		trainer: null,
		nutritionist: null,
		specialSessions: [],
	};
}

const paymentStatus = ["pending", "accepted", "rejected"];

const sessionStatus = ["pending", "completed"];

export default function EditUsersPage() {
	const [user, setUser] = useState(getEmptyUser());
	const [trainers, setTrainers] = useState(null);
	const [nutritionists, setNutritionists] = useState(null);
	const [routinePlans, setRoutinePlans] = useState(null);
	const [nutritionalPlans, setNutritionalPlans] = useState(null);
	const [routines, setRoutines] = useState(null);
	const [subscriptions, setSubscriptions] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(null);
	const [openTableDialog, setOpenTableDialog] = useState(null);
	const [openSelectDialog, setOpenSelectDialog] = useState(null);
	const [openNewSessionDialog, setOpenNewSessionDialog] = useState(false);
	const [selectedSession, setSelectedSession] = useState(null);
	const userId = useParams().id;
	const history = useHistory();
	const userLogged = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setTrainers(
						res.data.filter((user) => user.role.includes("trainer"))
					);
					setNutritionists(
						res.data.filter((user) =>
							user.role.includes("nutritionist")
						)
					);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get professionals.",
				});
				history.push("/users");
			});
		getRoutines()
			.then((res) => {
				if (res.status === 200) {
					let _routines = res.data.map((item) => ({
						...item,
						standard: !item.trainer,
					}));
					setRoutines(
						_routines.filter(
							(item) =>
								item.active === true &&
								(item.trainer === userId ||
									item.trainer === null)
						)
					);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get routines.",
				});
				history.push("/users");
			});
		getNutritionalPlans()
			.then((res) => {
				if (res.status === 200) {
					const plans = res.data.map((item) => ({
						...item,
						standard: !item.nutritionist,
					}));
					setNutritionalPlans(plans);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get nutritional plans.",
				});
				history.push("/users");
			});
		getRoutinePlans()
			.then((res) => {
				if (res.status === 200) {
					const plans = res.data.map((item) => ({
						...item,
						standard: !item.trainer,
					}));
					setRoutinePlans(plans);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get routine plans.",
				});
				history.push("/users");
			});
		getSubscriptions()
			.then((res) => {
				if (res.status === 200) {
					setSubscriptions(
						res.data.map((item) => ({
							...item,
							title: getNonEmpty(item.title),
							description: getNonEmpty(item.description),
						}))
					);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get subscriptions.",
				});
				history.push("/users");
			});
		if (!userId) {
			disableLoadingData();
			return;
		}
		getUserById(userId)
			.then((res) => {
				if (res.status === 200) {
					const user = res.data;
					delete user.password;
					setUser(user);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get user.",
				});
				history.push("/users");
			});
	}, [userId, disableLoadingData, history, userLogged]);

	function saveUser() {
		if (!userId) {
			postUser(user)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "User successfully created.",
						});
						history.push("/users");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save user.",
					});
				});
		} else {
			updateUser(userId, user)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/users");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setUser({ ...user, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit user"></CardHeader>
					<CardBody>
						<h5>Full name</h5>
						<p>{user.fullName || "---"}</p>
						<div className="row d-flex align-items-center">
							<div className="col-md-4">
								<h5>Email</h5>
								<p>{user.email || "---"}</p>
							</div>
							<div className="col-md-4">
								<h5>Username</h5>
								<p>{user.username || "---"}</p>
							</div>
							<div className="col-md-4">
								<h5>Code</h5>
								<p>{user.code || "---"}</p>
							</div>
						</div>
						<h5>Phone</h5>
						<p>{user.phone || "---"}</p>
						<FormControlLabel
							control={
								<Checkbox
									checked={user.active}
									onChange={() =>
										setUser({
											...user,
											active: !user.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
						<br />
						{user.imagesURLs?.map((image) => (
							<>
								<img
									key={image}
									src={image}
									alt={image}
									style={{
										maxWidth: "200px",
										cursor: "zoom-in",
									}}
									onClick={() => setOpenPreviewDialog(true)}
								/>
								<PreviewDialog
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={image}
								/>
							</>
						))}
						{userLogged.role.includes("admin") ? (
							<>
								<FormControl style={{ width: "100%" }}>
									<InputLabel id="demo-simple-select-standard-label">
										Trainer
									</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={user.trainer || ""}
										onChange={handleChange("trainer")}
										MenuProps={MenuProps}
									>
										{trainers?.map((option) => (
											<MenuItem
												key={option._id}
												value={option._id}
											>
												{option.fullName}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										Select a trainer
									</FormHelperText>
								</FormControl>
								<br />
								<br />
								<FormControl style={{ width: "100%" }}>
									<InputLabel id="demo-simple-select-standard-label">
										Nutritionist
									</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={user.nutritionist || ""}
										onChange={handleChange("nutritionist")}
										MenuProps={MenuProps}
									>
										{nutritionists?.map((option) => (
											<MenuItem
												key={option._id}
												value={option._id}
											>
												{option.fullName}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										Select a nutritionist
									</FormHelperText>
								</FormControl>
							</>
						) : (
							<>
								<h5>Trainer</h5>
								<p>
									{trainers?.find(
										(item) => item._id === user.trainer
									)?.fullName || "---"}
								</p>
								<h5>Nutritionist</h5>
								<p>
									{nutritionists?.find(
										(item) => item._id === user.nutritionist
									)?.fullName || "---"}
								</p>
							</>
						)}
					</CardBody>
					<CardHeader title="Nutritional plans"></CardHeader>
					<CardBody>
						{!user.nutritionalPlans?.length ? (
							<p>
								This user has no previous nutritional plans
								assigned.
							</p>
						) : (
							user.nutritionalPlans.map((item, index) => {
								const plan = nutritionalPlans?.find(
									(element) => element._id === item.plan
								);
								return (
									<>
										<span>
											{plan?.title}
											<strong>{" → Start date: "}</strong>
											{dateFormatter(
												item.startDate,
												true
											)}
										</span>
										{((userLogged.role.includes(
											"nutritionist"
										) &&
											plan.nutritionist ===
												userLogged._id) ||
											userLogged.role.includes(
												"admin"
											)) && (
											<Tooltip title={"View"}>
												<Button
													size="small"
													onClick={() =>
														window.open(
															"/edit-nutritional-plan/" +
																plan._id,
															"_blank"
														)
													}
													style={buttonsStyle}
												>
													<VisibilityIcon />
												</Button>
											</Tooltip>
										)}
										{(userLogged.role.includes(
											"nutritionist"
										) ||
											userLogged.role.includes(
												"admin"
											)) &&
											index ===
												user.nutritionalPlans.length -
													1 && (
												<>
													<Tooltip title="Delete">
														<Button
															size="small"
															style={buttonsStyle}
															onClick={() => {
																let _plans = [
																	...user.nutritionalPlans,
																];
																_plans.pop();
																setUser({
																	...user,
																	nutritionalPlans: _plans,
																});
															}}
														>
															<DeleteIcon />
														</Button>
													</Tooltip>
													<br />
												</>
											)}
										<br />
									</>
								);
							})
						)}
						<br />
						{(userLogged.role.includes("nutritionist") ||
							userLogged.role.includes("admin")) && (
							<Button
								onClick={() => setOpenTableDialog(2)}
								variant="outlined"
								color="primary"
							>
								Assign nutritional plan
							</Button>
						)}
						<TableDialog
							open={openTableDialog === 2}
							setOpen={setOpenTableDialog}
							data={(userLogged.role.includes("nutritionist")
								? nutritionalPlans?.filter(
										(item) =>
											item.nutritionist ===
												userLogged._id ||
											item.nutritionist === null
								  )
								: nutritionalPlans
							)?.filter((item) => item.active)}
							onSelectRow={(id) => {
								setUser({
									...user,
									nutritionalPlans: user.nutritionalPlans.concat(
										{
											plan: id,
											startDate: new Date(),
										}
									),
								});
								setOpenTableDialog(null);
							}}
						/>
					</CardBody>
					<CardHeader title="Routine plans"></CardHeader>
					<CardBody>
						{!user.routinePlans?.length ? (
							<p>
								This user has no previous routine plans
								assigned.
							</p>
						) : (
							user.routinePlans.map((item, index) => {
								const plan = routinePlans?.find(
									(element) => element._id === item.plan
								);
								return (
									<>
										<span>
											{plan?.title}
											<strong>{" → Start date: "}</strong>
											{dateFormatter(
												item.startDate,
												true
											)}
										</span>
										{((userLogged.role.includes(
											"trainer"
										) &&
											plan.trainer === userLogged._id) ||
											userLogged.role.includes(
												"admin"
											)) && (
											<Tooltip title={"View"}>
												<Button
													size="small"
													onClick={() =>
														window.open(
															"/training/edit-plan-routine/" +
																plan._id,
															"_blank"
														)
													}
													style={buttonsStyle}
												>
													<VisibilityIcon />
												</Button>
											</Tooltip>
										)}
										{(userLogged.role.includes("trainer") ||
											userLogged.role.includes(
												"admin"
											)) &&
											index ===
												user.routinePlans.length -
													1 && (
												<>
													<Tooltip title="Delete">
														<Button
															size="small"
															style={buttonsStyle}
															onClick={() => {
																let _routines = [
																	...user.routinePlans,
																];
																_routines.pop();
																setUser({
																	...user,
																	routinePlans: _routines,
																});
															}}
														>
															<DeleteIcon />
														</Button>
													</Tooltip>
													<br />
												</>
											)}
										<br />
									</>
								);
							})
						)}
						<br />
						{(userLogged.role.includes("trainer") ||
							userLogged.role.includes("admin")) && (
							<Button
								onClick={() => setOpenTableDialog(1)}
								variant="outlined"
								color="primary"
							>
								Assign routine plan
							</Button>
						)}
						<TableDialog
							open={openTableDialog === 1}
							setOpen={setOpenTableDialog}
							routinePlan={true}
							data={(userLogged.role.includes("trainer")
								? routinePlans?.filter(
										(item) =>
											item.trainer === userLogged._id ||
											item.trainer === null
								  )
								: routinePlans
							)?.filter((item) => item.active)}
							onSelectRow={(id) => {
								setUser({
									...user,
									routinePlans: user.routinePlans.concat({
										plan: id,
										startDate: new Date(),
									}),
								});
								setOpenTableDialog(null);
							}}
						/>
					</CardBody>
					<CardHeader title="Special routine sessions"></CardHeader>
					<CardBody>
						{!user.specialSessions?.length ? (
							<p>
								This user has no previous special routine
								sessions assigned.
							</p>
						) : (
							user.specialSessions.map((item, index) => {
								return (
									<>
										<span>
											{item.title}
											<strong>{" → Start date: "}</strong>
											{dateFormatter(
												item.startDate,
												true
											)}
											<strong>{" → Status: "}</strong>
											{item.status}
										</span>
										{(userLogged.role.includes("trainer") ||
											userLogged.role.includes(
												"admin"
											)) && (
											<>
												<Tooltip title={"Edit"}>
													<Button
														size="small"
														onClick={() => {
															setSelectedSession({
																data: item,
																index,
															});
															setOpenNewSessionDialog(
																true
															);
														}}
														style={buttonsStyle}
													>
														<EditIcon />
													</Button>
												</Tooltip>
											</>
										)}
										{(userLogged.role.includes("trainer") ||
											userLogged.role.includes(
												"admin"
											)) &&
											index ===
												user.specialSessions.length -
													1 && (
												<>
													<Tooltip
														title={"Change status"}
													>
														<Button
															size="small"
															onClick={() =>
																setOpenSelectDialog(
																	1
																)
															}
															style={buttonsStyle}
														>
															<AssignmentTurnedInIcon />
														</Button>
													</Tooltip>
													<Tooltip title="Delete">
														<Button
															size="small"
															style={buttonsStyle}
															onClick={() => {
																let _routines = [
																	...user.specialSessions,
																];
																_routines.pop();
																setUser({
																	...user,
																	specialSessions: _routines,
																});
															}}
														>
															<DeleteIcon />
														</Button>
													</Tooltip>
													<br />
												</>
											)}
										<br />
									</>
								);
							})
						)}
						<br />
						{(userLogged.role.includes("trainer") ||
							userLogged.role.includes("admin")) && (
							<Button
								onClick={() => setOpenNewSessionDialog(true)}
								variant="outlined"
								color="primary"
							>
								Create new special session
							</Button>
						)}
						<NewSessionDialog
							open={openNewSessionDialog}
							setOpen={setOpenNewSessionDialog}
							data={routines}
							initialData={selectedSession}
							onConfirm={(sessionData) => {
								setSelectedSession(null);
								let sessions = user.specialSessions;
								if (!sessions) sessions = [];
								if (
									sessionData.index ||
									sessionData.index === 0
								) {
									sessions.splice(
										sessionData.index,
										1,
										sessionData.session
									);
								} else sessions.push(sessionData.session);
								setUser({ ...user, specialSessions: sessions });
								setOpenNewSessionDialog(null);
							}}
						/>
						<SelectDialog
							open={openSelectDialog === 1}
							onConfirm={(status) => {
								const _sessions = user.specialSessions;
								_sessions[_sessions.length - 1].status = status;
								setUser({
									...user,
									specialSessions: _sessions,
								});
								setOpenSelectDialog(null);
							}}
							onClose={() => setOpenSelectDialog(null)}
							options={sessionStatus}
							item="session status"
							title="Update special session status"
						/>
					</CardBody>
					<CardHeader title="Subscriptions"></CardHeader>
					<CardBody>
						{!user.subscriptions?.length ? (
							<p>
								This user has no previous subscriptions added.
							</p>
						) : (
							user.subscriptions.map((item, index) => {
								const subscription = subscriptions?.find(
									(element) =>
										element._id === item.subscription
								);
								if (!subscription) return <div></div>;
								return (
									<>
										<span>
											{subscription.title}
											<strong>{" → Start date: "}</strong>
											{dateFormatter(
												item.startDate,
												true
											)}
											<strong>
												{" → Payment status: "}
											</strong>
											{item.paymentStatus}
										</span>
										{userLogged.role.includes("admin") && (
											<>
												<Tooltip title={"View"}>
													<Button
														size="small"
														onClick={() =>
															window.open(
																"/edit-subscription/" +
																	subscription._id,
																"_blank"
															)
														}
														style={buttonsStyle}
													>
														<VisibilityIcon />
													</Button>
												</Tooltip>
												<Tooltip
													title={
														"Change payment status"
													}
												>
													<Button
														size="small"
														onClick={() =>
															setOpenSelectDialog(
																2
															)
														}
														style={buttonsStyle}
													>
														<PaymentIcon />
													</Button>
												</Tooltip>
												<Tooltip title="Delete">
													<Button
														size="small"
														style={buttonsStyle}
														onClick={() => {
															let subscriptions = [
																...user.subscriptions,
															];
															subscriptions.pop();
															setUser({
																...user,
																subscriptions: subscriptions,
															});
														}}
													>
														<DeleteIcon />
													</Button>
												</Tooltip>
												<br />
											</>
										)}
										<br />
									</>
								);
							})
						)}
						<br />
						{userLogged.role.includes("admin") && (
							<Button
								onClick={() => setOpenTableDialog(3)}
								variant="outlined"
								color="primary"
							>
								Add subscription
							</Button>
						)}
						<TableDialog
							open={openTableDialog === 3}
							setOpen={setOpenTableDialog}
							subscriptionsTable={true}
							data={subscriptions?.filter((item) => item.active)}
							onSelectRow={(id) => {
								setUser({
									...user,
									subscriptions: user.subscriptions.concat({
										subscription: id,
										startDate: new Date(),
										paymentStatus: "pending",
									}),
								});
								setOpenTableDialog(null);
							}}
						/>
						<SelectDialog
							open={openSelectDialog === 2}
							onConfirm={(status) => {
								const _subscriptions = user.subscriptions;
								_subscriptions[
									_subscriptions.length - 1
								].paymentStatus = status;
								setUser({
									...user,
									subscriptions: _subscriptions,
								});
								setOpenSelectDialog(null);
							}}
							onClose={() => setOpenSelectDialog(null)}
							options={paymentStatus}
							item="payment status"
							title="Update subscription payment status"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/users")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveUser()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save user
					</Button>
					{userId && userLogged?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete user
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this user?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteUser(userId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"User deleted successfully",
												});
												history.push("/users");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete user.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
