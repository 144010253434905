import { authClient, API } from '../index'
import { uploadSingleFile } from "../files"

// Get all advertisements
export const getAdvertisements = () => {
  return authClient().get(`${API}/advertisement?getDisabled=true`)
}

// Get advertisement by id
export const getAdvertisementById = (id) => {
  return authClient().get(`${API}/advertisement/${id}`)
}

// Delete advertisement
export const deleteAdvertisement = (id) => {
  return authClient().delete(`${API}/advertisement/${id}`)
}

// Create advertisement
export const postAdvertisement = async (advertisement, image) => {
  if (image) {
		const response = await uploadSingleFile(image, 'advertisements')
		advertisement.imageURL = response.data.fileURL
	}
  return authClient().post(`${API}/advertisement`, advertisement)
}

// Update advertisement
export const updateAdvertisement = async (id, advertisement, image) => {
  if (image) {
		const response = await uploadSingleFile(image, 'advertisements')
		advertisement.imageURL = response.data.fileURL
	}
  return authClient().put(`${API}/advertisement/${id}`, advertisement)
}

// Change status advertisement
export const changeStatusAdvertisement = async (id, active) => {
	return authClient().put(`${API}/advertisement/change-status/${id}`, { active });
};