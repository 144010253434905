import { API, authClient } from '../index';
import { uploadSingleFile } from "../files"

// Get all courses
export const getCourses = () => {
	return authClient().get(`${API}/course?getDisabled=true`);
};

// Get course by id
export const getCourseById = (id) => {
	return authClient().get(`${API}/course/${id}`);
};

// Delete course
export const deleteCourse = (id) => {
	return authClient().delete(`${API}/course/${id}`);
};

// Create course 
export const postCourse = async (course, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'courses')
		course.bannerURL = response.data.fileURL
	}
	return authClient().post(`${API}/course`, course);
};

// Update course
export const updateCourse = async (id, course, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'courses')
		course.bannerURL = response.data.fileURL
	}
	return authClient().put(`${API}/course/${id}`, course);
};

// Change status course
export const changeStatusCourse= async (id, active) => {
	return authClient().put(`${API}/course/change-status/${id}`, { active });
};

// Get training statistics
export const getTrainingStatistics = () => {
	return authClient().get(`${API}/course/statistics`);
};