import React, { useEffect, useState } from 'react'
import PreviewDialog from '../../../components/dialogs/PreviewDialog'
import { SERVER_URL } from '../../../../api'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { getUserById } from '../../../../api/user'
import { alertError } from '../../../../utils/logger'
import { dateFormatter } from '../../../components/tables/table'

const RoutineContent = (props) => {
    const { routine, showTitle } = props
    const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
    const [createdBy, setCreatedBy] = useState(null)

    useEffect(() => {
      if (!routine) return
      if (routine.trainer) {
        getUserById(routine.trainer).then(resp => {
          if (resp.status === 200) {
            setCreatedBy(resp.data.fullName)
          }
        })
        .catch((error) => {
          setCreatedBy('----')
          alertError({ error: error, customMessage: 'Could not get trainer.' });
        });
      } else setCreatedBy(null)
    }, [routine])

    if (!routine) return <></>
    return <>
        {showTitle && <>
          <h5>Title</h5>
          <p>{routine.title}</p>
        </>}
        <h5>Description</h5>
        <div dangerouslySetInnerHTML = {{ __html: routine.description }}></div>
        <h5>Muscle groups</h5>
        <p>{routine.muscleGroups}</p>
        <FormControlLabel
            control={<Checkbox checked={routine.standard} name="checkStandard" />}
            label="Standard"
        />
        <FormControlLabel
          control={<Checkbox checked={routine.active} name="checkActive" />}
          label="Active"
        />
        <br/>
        <br/>
        {routine.imageURL && routine.imageURL !== '' && <>
          <h5>Main image</h5>
          <img key={routine.imageURL} src={SERVER_URL + '/' + routine.imageURL} alt={routine.imageURL.split(/-(.*)/s)[1]} style={{ maxWidth: '200px', cursor: 'zoom-in' }} onClick={() => setOpenPreviewDialog('main')}/>
          <PreviewDialog
            open={openPreviewDialog === 'main'}
            setOpen={setOpenPreviewDialog}
            src={SERVER_URL + '/' + routine.imageURL}
          />
          <br/>
        </>}
        {routine.otherImagesURLs?.length > 0 && <h5>Other images</h5>}
        {routine.otherImagesURLs?.map((image, index) => (<>
            <img key={image} src={SERVER_URL + '/' + image} alt={image.split(/-(.*)/s)[1]} style={{ maxWidth: '200px', cursor: 'zoom-in' }} onClick={() => setOpenPreviewDialog(index)}/>
            <PreviewDialog
              open={openPreviewDialog === index}
              setOpen={setOpenPreviewDialog}
              src={SERVER_URL + '/' + image}
            />
          </>))}
        <br/>
        {routine.videoURL && routine.videoURL !== '' && <>
          <h5>Video</h5>
          <embed
            src={SERVER_URL + '/' + routine.videoURL}
            height="400px"
            width="500px" />
          <br/>
        </>}
        <br/>
        {routine.audioTipURL && routine.audioTipURL !== '' && <>
          <h5>Audio tip</h5>
          <embed src={SERVER_URL + '/' + routine.audioTipURL}
            height="140px"
            width="250px" />
        </>}
        <div className='mt-3'>
          <p style={{ textAlign: 'end'}}><strong>Created by: </strong>{createdBy || '-----'}{routine.createdAt ? ` (${dateFormatter(routine.createdAt)})` : ''}</p>
        </div>
    </>
}

export default RoutineContent