import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox,
	Tooltip
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteMuscleGroup,
	getMuscleGroupById,
	postMuscleGroup,
	updateMuscleGroup,
} from '../../../../api/muscleGroup';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import { checkIsEmpty } from '../../../../utils/helpers';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import { SERVER_URL } from '../../../../api/index';
import { Visibility } from '@material-ui/icons';
import { buttonsStyle } from '../../../components/tables/table';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyMuscleGroup() {
	return {
		fullName: {},
		active: true,
		iconURL: ''
	};
}

export default function EditMuscleGroupsPage() {
	const [muscleGroup, setMuscleGroup] = useState(getEmptyMuscleGroup());
	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const muscleGroupId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!muscleGroupId) {
			disableLoadingData();
			return;
		}
		getMuscleGroupById(muscleGroupId)
			.then((res) => {
				if (res.status === 200) {
					setMuscleGroup(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get muscle group.' });
				history.push('/muscle-groups');
			});
	}, [muscleGroupId, disableLoadingData, history]);

	function saveMuscleGroup() {
		if (checkIsEmpty(muscleGroup.fullName)) return alertError({ error: null, customMessage: 'The name is required in at least one of the languages.' })
		if (!muscleGroupId) {
			postMuscleGroup(muscleGroup, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Muscle group successfully created.',
						});
						history.push('/muscle-groups');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save muscle group.' });
				});
		} else {
			updateMuscleGroup(muscleGroupId, muscleGroup, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/muscle-groups');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return
		if (lang) {
			if (!muscleGroup[element]) muscleGroup[element] = {}
			let newText = muscleGroup[element]
			newText[lang] = event.target.value
			setMuscleGroup({ ...muscleGroup, [element]: newText })
		} else setMuscleGroup({ ...muscleGroup, [element]: event.target.value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return <>
			<br/>
			<TextField
				id={`fullName`}
				label='Full name'
				value={(muscleGroup.fullName && muscleGroup.fullName[lang]) || ''}
				onChange={handleChange('fullName', lang)}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				variant='outlined'
				required
			/>
		</>
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit muscle group'></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent = {renderMultilanguageTabContent}
						/>
						<br />
						<br />
						<label htmlFor={'upload-image'}>
							<input
								style={{ display: 'none' }}
								id={'upload-image'}
								name={'upload-image'}
								type='file'
								accept={'image/*'}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedImage || (muscleGroup.iconURL && muscleGroup.iconURL !== '')
									? 'Change icon'
									: 'Upload icon'}
							</Button>
						</label>
						{(selectedImage || (muscleGroup.iconURL && muscleGroup.iconURL !== '')) && (
							<>
								<Tooltip title={'Preview icon'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewDialog(true)}
										style={{ ...buttonsStyle, marginRight: '15px' }}>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview icon'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${muscleGroup.iconURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: (muscleGroup.iconURL && muscleGroup.iconURL !== '')
										? muscleGroup.iconURL?.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br/>
						<br/>
						<FormControlLabel
							control={<Checkbox checked={muscleGroup.active} onChange={() => setMuscleGroup({ ...muscleGroup, active: !muscleGroup.active })} name="checkActive" />}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/muscle-groups')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveMuscleGroup()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save muscle group
					</Button>
					{muscleGroupId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete muscle group
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this muscle group?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteMuscleGroup(muscleGroupId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Muscle group deleted successfully',
												});
												history.push('/muscle-groups');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete muscle group.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
