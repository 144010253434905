import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	Tooltip,
	FormControlLabel, 
	Checkbox
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteEvent,
	getEventById,
	postEvent,
	updateEvent,
} from '../../../../api/event';
import { Autocomplete } from "@material-ui/lab";
import { getSports } from "../../../../api/sport";
import { getNonEmpty } from "../../../../utils/helpers";
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { shallowEqual, useSelector } from 'react-redux'
import { Visibility } from '@material-ui/icons'
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import { SERVER_URL } from "../../../../api/index"
import { buttonsStyle, toIsoString } from '../../../components/tables/table'
import Editor from '../../../components/editor/Editor';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import LinkIcon from '@material-ui/icons/Link';
import MapIcon from '@material-ui/icons/Map';
import { getUsers } from '../../../../api/user'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyEvent() {
	return {
		title: {},
		description: {},
		sport: null,
		imageURL: '',
		date: null,
		maxParticipants: null, 
		linkURL: '',
		address: '',
		free: true,
		inPerson: false,
		price: 0
	};
}

export default function EditEventsPage() {
	const [liveEvent, setLiveEvent] = useState(getEmptyEvent());
	const [sports, setSports] = useState([]);
	const [users, setUsers] = useState([])
	const [selectedImage, setSelectedImage] = useState(null)
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false)
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [editBlocked, setEditBlocked] = useState(false)
	const eventId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getSports()
			.then((res) => {
				if (res.status === 200) {
					setSports(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get sports.",
				});
				history.push("/events");
			});
		if (!eventId) {
			disableLoadingData();
			return;
		}
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setUsers(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get users.' });
				history.push('/events');
			});
		getEventById(eventId)
			.then((res) => {
				if (res.status === 200) {
					const today = new Date()
					if ((new Date(res.data.date)) <= today) setEditBlocked(true)
					setLiveEvent(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get event.' });
				history.push('/events');
			});
	}, [eventId, disableLoadingData, history]);

	function saveEvent() {
		if (!eventId) {
			postEvent(liveEvent, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Event successfully created.',
						});
						history.push('/events');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save event.' });
				});
		} else {
			updateEvent(eventId, liveEvent, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/events');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return
		if (lang) {
			if (!liveEvent[element]) liveEvent[element] = {}
			let newText = liveEvent[element]
			newText[lang] = event.target.value
			setLiveEvent({ ...liveEvent, [element]: newText })
		} else setLiveEvent({ ...liveEvent, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value === ' ') return
		if (lang) {
			if (!liveEvent[element]) liveEvent[element] = {}
			let newText = liveEvent[element]
			newText[lang] = value
			setLiveEvent({ ...liveEvent, [element]: newText })
		} else setLiveEvent({ ...liveEvent, [element]: value });
	}

	const renderMultilanguageTabContent = (lang) => {
		return <>
			<br/>
			<TextField
				id={`title`}
				label='Title'
				value={(liveEvent.title && liveEvent.title[lang]) || ''}
				onChange={handleChange('title', lang)}
				InputLabelProps={{
					shrink: true,
				}}
				disabled={editBlocked}
				margin='normal'
				variant='outlined'
				required
			/>
			<br/>
			<Editor
				body={(liveEvent.description && liveEvent.description[lang]) || ''}
				setBody={new_body => handleChangeEditor('description', new_body, lang)}
				className='max-height'
				placeholder={'Enter event description here...'}
				label="Text *"
				disabled={editBlocked}
			/>
		</>
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit event'></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent = {renderMultilanguageTabContent}
						/>
						<br />
						<Autocomplete
							id="sports"
							disablePortal
							options={sports}
							getOptionLabel={(option) =>
								getNonEmpty(option?.fullName)
							}
							value={sports?.find(
								(x) => x._id === liveEvent?.sport
							)}
							onChange={(event, selected) => {
								setLiveEvent({
									...liveEvent,
									sport: selected?._id || null,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									placeholder="Select a sport"
									label="Sport"
									required
								/>
							)}
						/>
						<br/>
						<FormControlLabel
							control={<Checkbox checked={liveEvent.inPerson} onChange={() => setLiveEvent({ ...liveEvent, inPerson: !liveEvent.inPerson })} disabled={editBlocked} name="checkInPerson" />}
							label="In person (uncheck for live event)"
						/>
						{!liveEvent.inPerson && <div className='row mt-5' style={{ margin: "auto" }}>
							<TextField
								id={`linkURL`}
								label='Online event link'
								className='col-10 col-lg-11'
								value={liveEvent.linkURL}
								onChange={handleChange('linkURL')}
								InputLabelProps={{
									shrink: true,
								}}
								disabled={editBlocked}
								margin='normal'
								variant='outlined'
								required
							/>
							<Tooltip title="Open link in new tab">
								<Button onClick={() => {
									if (!liveEvent.linkURL?.length) return alertError({ error: null, customMessage: 'Please enter a valid link first.' })
									window.open(liveEvent.linkURL, '_blank').focus();
								}}>
									<LinkIcon/>
								</Button>
							</Tooltip>
						</div>}
						{liveEvent.inPerson && <div className='row mt-5' style={{ margin: "auto" }}>
							<TextField
								id={`address`}
								label='In person event address'
								className='col-10 col-lg-11'
								value={liveEvent.address}
								onChange={handleChange('address')}
								InputLabelProps={{
									shrink: true,
								}}
								disabled={editBlocked}
								margin='normal'
								variant='outlined'
								required
							/>
							<Tooltip title="Open adddress in new tab for check in maps">
								<Button onClick={() => {
									if (!liveEvent.address?.length) return alertError({ error: null, customMessage: 'Please enter a valid address first.' })
									window.open('https://www.google.com/maps/place/'+liveEvent.address.replace('/','%2F'), '_blank').focus();
								}}>
									<MapIcon/>
								</Button>
							</Tooltip>
						</div>}
						<br/>
		            	<TextField
							id="date"
							label="Date"
							type="datetime-local"
							value={toIsoString(liveEvent.date)}
							onChange={handleChange('date')}
							InputLabelProps={{
								shrink: true
							}}
							disabled={editBlocked}
							margin="normal"
							variant="outlined"
							required
						/>
						<TextField
							id={`maxParticipants`}
							label='Max participants'
							value={liveEvent.maxParticipants}
							onChange={handleChange('maxParticipants')}
							InputLabelProps={{
								shrink: true,
							}}
							disabled={editBlocked}
							margin='normal'
							variant='outlined'
							type="number"
						/>
						<br/>
						<FormControlLabel
							control={<Checkbox checked={liveEvent.free} onChange={() => setLiveEvent({ ...liveEvent, free: !liveEvent.free })} disabled={editBlocked} name="checkFree" />}
							label="Free"
						/>
						<br/>
						{!liveEvent.free && <TextField
							id={`price`}
							label='Price (€)'
							value={liveEvent.price}
							onChange={handleChange('price')}
							InputLabelProps={{
								shrink: true,
							}}
							disabled={editBlocked}
							margin='normal'
							variant='outlined'
							type="number"
						/>}
						<br/>
						<br/>
						{!editBlocked && <label htmlFor={"upload-image"}>
							<input
								style={{ display: 'none' }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={'image/*'}
								onChange={(e) => {
									setSelectedImage(e.target.files[0])
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color="secondary"
								component="span"
								variant="outlined">
								{(selectedImage || liveEvent.imageURL !== '') ? 'Change image' : 'Upload image'}
							</Button>
						</label>}
						{(selectedImage || liveEvent.imageURL !== '') &&
							<>
								<Tooltip title={'Preview image'}>
									<Button
										size="small"
										onClick={() => setOpenPreviewDialog(true)}
										style={{...buttonsStyle, marginRight: '15px' }}>
										<Visibility/>
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview image'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={selectedImage ? URL.createObjectURL(selectedImage) : `${SERVER_URL}/${liveEvent.imageURL}`}
								/>
								<span>
									{selectedImage ? selectedImage?.name : (liveEvent.imageURL !== '' ? liveEvent.imageURL.split(/-(.*)/s)[1] : '')}
								</span>
							</>
							}
						<br />
						<br />
						{eventId && liveEvent.participants && <>
							<h5>Participants</h5>
							<ul>
								{liveEvent.participants?.map(p => <li key={p}>{users.find(item => item._id === p)?.fullName || '---'}</li>)}
							</ul>
						</>}
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/events')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					{!editBlocked && <Button
						onClick={() => saveEvent()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save event
					</Button>}
					{eventId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete event
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this event?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteEvent(eventId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Event deleted successfully',
												});
												history.push('/events');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete event.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
