/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {
	AllInbox,
	EmojiEvents,
	Equalizer,
	Grade,
	Kitchen,
	LocalDining,
	Receipt,
	Restaurant,
	Schedule,
	SportsSoccer,
	Phonelink,
	FeaturedVideo,
	Extension,
} from '@material-ui/icons';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import KeyboardArrowRightIcon from '@material-ui/icons/ArrowRight';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import CategoryIcon from '@material-ui/icons/Category';
import {
	default as ContactSupportIcon,
	default as GuestIcon,
} from '@material-ui/icons/ContactSupport';
import DescriptionIcon from '@material-ui/icons/Description';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import LanguageIcon from '@material-ui/icons/Language';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RedeemIcon from '@material-ui/icons/Redeem';
import SchoolIcon from '@material-ui/icons/School';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SportsIcon from '@material-ui/icons/Sports';
import SportsMmaIcon from '@material-ui/icons/SportsMma';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AccessEntryIcon from '@material-ui/icons/VpnKey';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import PermMediaIcon from '@material-ui/icons/PermMedia';

import React from 'react';
import SVG from 'react-inlinesvg';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { checkIsActive, toAbsoluteUrl } from '../../../../_helpers';

export function AsideMenuList({ layoutProps }) {
	const location = useLocation();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: '';
	};

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/dashboard'>
						<span className='svg-icon menu-icon'>
							<SVG
								src={toAbsoluteUrl(
									'/media/svg/icons/Design/Layers.svg'
								)}
							/>
						</span>
						<span className='menu-text'>Dashboard</span>
					</NavLink>
				</li>
				{/*end::1 Level*/}
				<li className='menu-section'>
					<h4 className='menu-text'>PROFILES</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				{user?.role.includes('admin') && (
					<li
						className={`menu-item ${getMenuItemActive(
							'/admins',
							false
						)} ${getMenuItemActive('/edit-admin', false)}`}
						aria-haspopup='true'>
						<NavLink className='menu-link' to='/admins'>
							<span className='menu-icon'>
								<SettingsIcon />
							</span>
							<span className='menu-text'>Administrators</span>
						</NavLink>
					</li>
				)}
				<li
					className={`menu-item ${getMenuItemActive(
						'/professionals',
						false
					)} ${getMenuItemActive('/edit-professional', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/professionals'>
						<span className='menu-icon'>
							<AssignmentIndIcon />
						</span>
						<span className='menu-text'>Professionals</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/teams',
						false
					)} ${getMenuItemActive('/edit-team', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/teams'>
						<span className='menu-icon'>
							<SupervisedUserCircleIcon />
						</span>
						<span className='menu-text'>Teams</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/users',
						false
					)} ${getMenuItemActive('/edit-user', false)} 
          ${getMenuItemActive('/view-user-info', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/users'>
						<span className='menu-icon'>
							<PeopleIcon />
						</span>
						<span className='menu-text'>Users</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/guests', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/guests'>
						<span className='menu-icon'>
							<GuestIcon />
						</span>
						<span className='menu-text'>Guests</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/access-entries',
						false
					)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/access-entries'>
						<span className='menu-icon'>
							<AccessEntryIcon />
						</span>
						<span className='menu-text'>Access Logs</span>
					</NavLink>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/reviews'
					)} ${getMenuItemActive('/reviews')}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/reviews/by-exercise'>
						<span className='svg-icon menu-icon'>
							<RateReviewIcon />
						</span>
						<span className='menu-text'>
							Reviews <KeyboardArrowRightIcon />
						</span>
					</NavLink>
					<div className='menu-submenu '>
						<ul className='menu-subnav'>
							<ul className='menu-subnav'>
								<li
									className='menu-item menu-item-parent'
									aria-haspopup='true'>
									<span className='menu-link'>
										<span className='menu-text'>Reviews</span>
									</span>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/reviews/by-exercise',
										false
									)}`}
									aria-haspopup='true'>
									<NavLink
										className='menu-link'
										to='/reviews/by-exercise'>
										<span className='menu-icon'>
											<FitnessCenterIcon />
										</span>
										<span className='menu-text'>
											Reviews by Exercise
										</span>
									</NavLink>
								</li>
								<li
									className={`menu-item ${getMenuItemActive(
										'/reviews/by-routine',
										false
									)}`}
									aria-haspopup='true'>
									<NavLink
										className='menu-link'
										to='/reviews/by-routine'>
										<span className='menu-icon'>
											<CategoryIcon />
										</span>
										<span className='menu-text'>
											Reviews by Routine
										</span>
									</NavLink>
								</li>
							</ul>
						</ul>
					</div>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/ratings', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/ratings'>
						<span className='menu-icon'>
							<Grade />
						</span>
						<span className='menu-text'>Ratings</span>
					</NavLink>
				</li>
				<li className='menu-section'>
					<h4 className='menu-text'>INFO</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				{(user?.role.includes('trainer') ||
					user?.role.includes('admin')) && (
					<li
						className={`menu-item ${getMenuItemActive(
							'/training'
						)} ${getMenuItemActive('/training')}`}
						aria-haspopup='true'>
						<NavLink className='menu-link' to='/training/routine-plans'>
							<span className='svg-icon menu-icon'>
								<DirectionsRunIcon />
							</span>
							<span className='menu-text'>
								Training <KeyboardArrowRightIcon />
							</span>
						</NavLink>
						<div className='menu-submenu '>
							<ul className='menu-subnav'>
								<ul className='menu-subnav'>
									<li
										className='menu-item menu-item-parent'
										aria-haspopup='true'>
										<span className='menu-link'>
											<span className='menu-text'>
												Training
											</span>
										</span>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/training/routine-plans',
											false
										)} ${getMenuItemActive(
											'/training/edit-plan-routine',
											false
										)}
									${getMenuItemActive('/training/translate-plan-routine', false)} ${getMenuItemActive(
											'/training/plan-routine-translations',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/training/routine-plans'>
											<span className='menu-icon'>
												<ListAltIcon />
											</span>
											<span className='menu-text'>
												Routine plans
											</span>
										</NavLink>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/training/routines',
											false
										)} ${getMenuItemActive(
											'/training/edit-routine',
											false
										)}
									${getMenuItemActive('/training/translate-routine', false)} ${getMenuItemActive(
											'/training/routine-translations',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/training/routines'>
											<span className='menu-icon'>
												<FitnessCenterIcon />
											</span>
											<span className='menu-text'>
												Routines
											</span>
										</NavLink>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/training/standard-challenges',
											false
										)} ${getMenuItemActive(
											'/training/edit-challenge',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/training/standard-challenges'>
											<span className='menu-icon'>
												<EmojiEvents />
											</span>
											<span className='menu-text'>
												Standard challenges
											</span>
										</NavLink>
									</li>
								</ul>
							</ul>
						</div>
					</li>
				)}
				{(user?.role.includes('nutritionist') ||
					user?.role.includes('admin')) && (
					<li
						className={`menu-item ${getMenuItemActive(
							'/nutritional-plans',
							false
						)} ${getMenuItemActive('/edit-nutritional-plan', false)} 
							${getMenuItemActive('/translate-nutritional-plan', false)} ${getMenuItemActive(
							'nutritional-plan-translations',
							false
						)}`}
						aria-haspopup='true'>
						<NavLink className='menu-link' to='/nutritional-plans'>
							<span className='menu-icon'>
								<FastfoodIcon />
							</span>
							<span className='menu-text'>Nutritional plans</span>
						</NavLink>
					</li>
				)}
				{(user?.role.includes('sportsman') ||
					user?.role.includes('admin')) && (
					<li
						className={`menu-item ${getMenuItemActive(
							'/sports'
						)} ${getMenuItemActive('/sports')}`}
						aria-haspopup='true'>
						<NavLink className='menu-link' to='/sports/courses'>
							<span className='svg-icon menu-icon'>
								<SportsTennisIcon />
							</span>
							<span className='menu-text'>
								Sports <KeyboardArrowRightIcon />
							</span>
						</NavLink>
						<div className='menu-submenu '>
							<ul className='menu-subnav'>
								<ul className='menu-subnav'>
									<li
										className='menu-item menu-item-parent'
										aria-haspopup='true'>
										<span className='menu-link'>
											<span className='menu-text'>Sports</span>
										</span>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/sports/courses',
											false
										)} ${getMenuItemActive(
											'/sports/edit-course',
											false
										)}
									${getMenuItemActive('/sports/translate-course', false)} ${getMenuItemActive(
											'/sports/course-translations',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/sports/courses'>
											<span className='menu-icon'>
												<SchoolIcon />
											</span>
											<span className='menu-text'>
												Courses
											</span>
										</NavLink>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/sports/lessons',
											false
										)} ${getMenuItemActive(
											'/sports/edit-lesson',
											false
										)}
									${getMenuItemActive('/sports/translate-lesson', false)} ${getMenuItemActive(
											'/sports/lesson-translations',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/sports/lessons'>
											<span className='menu-icon'>
												<CastForEducationIcon />
											</span>
											<span className='menu-text'>
												Lessons
											</span>
										</NavLink>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/sports/tests',
											false
										)} ${getMenuItemActive(
											'/sports/edit-test',
											false
										)}
									${getMenuItemActive('/sports/translate-test', false)} ${getMenuItemActive(
											'/sports/test-translations',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/sports/tests'>
											<span className='menu-icon'>
												<DeviceUnknownIcon />
											</span>
											<span className='menu-text'>Tests</span>
										</NavLink>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/sports/questions',
											false
										)} ${getMenuItemActive(
											'/sports/edit-question',
											false
										)}
									${getMenuItemActive('/sports/translate-question', false)} ${getMenuItemActive(
											'/sports/question-translations',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/sports/questions'>
											<span className='menu-icon'>
												<ContactSupportIcon />
											</span>
											<span className='menu-text'>
												Questions
											</span>
										</NavLink>
									</li>
								</ul>
							</ul>
						</div>
					</li>
				)}
				{user?.role.includes('admin') && (
					<>
						<li
							className={`menu-item ${getMenuItemActive(
								'/food-plates',
								false
							)} ${getMenuItemActive('/edit-plate', false)} 
						`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/food-plates'>
								<span className='menu-icon'>
									<LocalDining />
								</span>
								<span className='menu-text'>Food plates</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/tips',
								false
							)} ${getMenuItemActive('/edit-tip', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/tips'>
								<span className='menu-icon'>
									<WbIncandescentIcon
										style={{ transform: 'rotate(180deg)' }}
									/>
								</span>
								<span className='menu-text'>Tips</span>
							</NavLink>
						</li>
					</>
				)}

				<li className='menu-section'>
					<h4 className='menu-text'>STATISTICS</h4>
					<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive(
						'/sessions',
						false
					)} ${getMenuItemActive('/view-sesion', false)}`}
					aria-haspopup='true'>
					<NavLink className='menu-link' to='/sessions'>
						<span className='menu-icon'>
							<RateReviewIcon />
						</span>
						<span className='menu-text'>Sessions feedback</span>
					</NavLink>
				</li>
				{user?.role.includes('admin') && (
					<li
						className={`menu-item ${getMenuItemActive(
							'/reports'
						)} ${getMenuItemActive('/reports')}`}
						aria-haspopup='true'>
						<NavLink className='menu-link' to='/reports/plans'>
							<span className='svg-icon menu-icon'>
								<AssessmentIcon />
							</span>
							<span className='menu-text'>Reports</span>
						</NavLink>
						<div className='menu-submenu '>
							<ul className='menu-subnav'>
								<ul className='menu-subnav'>
									<li
										className='menu-item menu-item-parent'
										aria-haspopup='true'>
										<span className='menu-link'>
											<span className='menu-text'>
												Reports
											</span>
										</span>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/reports/plans',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/reports/plans'>
											<span className='menu-icon'>
												<AssessmentIcon />
											</span>
											<span className='menu-text'>
												Plans report
											</span>
										</NavLink>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/reports/trainers',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/reports/trainers'>
											<span className='menu-icon'>
												<AssessmentIcon />
											</span>
											<span className='menu-text'>
												Trainers report
											</span>
										</NavLink>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/reports/nutritionists',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/reports/nutritionists'>
											<span className='menu-icon'>
												<AssessmentIcon />
											</span>
											<span className='menu-text'>
												Nutritionists report
											</span>
										</NavLink>
									</li>
								</ul>
							</ul>
						</div>
					</li>
				)}
				{user?.role.includes('admin') && (
					<li
						className={`menu-item ${getMenuItemActive(
							'/reviews'
						)} ${getMenuItemActive('/reviews')}`}
						aria-haspopup='true'>
						<NavLink className='menu-link' to='/reviews/by-exercise'>
							<span className='svg-icon menu-icon'>
								<RateReviewIcon />
							</span>
							<span className='menu-text'>
								Reviews <KeyboardArrowRightIcon />
							</span>
						</NavLink>
						<div className='menu-submenu '>
							<ul className='menu-subnav'>
								<ul className='menu-subnav'>
									<li
										className='menu-item menu-item-parent'
										aria-haspopup='true'>
										<span className='menu-link'>
											<span className='menu-text'>
												Reviews
											</span>
										</span>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/reviews/by-exercise',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/reviews/by-exercise'>
											<span className='menu-icon'>
												<FitnessCenterIcon />
											</span>
											<span className='menu-text'>
												Reviews by Exercise
											</span>
										</NavLink>
									</li>
									<li
										className={`menu-item ${getMenuItemActive(
											'/reviews/by-routine',
											false
										)}`}
										aria-haspopup='true'>
										<NavLink
											className='menu-link'
											to='/reviews/by-routine'>
											<span className='menu-icon'>
												<CategoryIcon />
											</span>
											<span className='menu-text'>
												Reviews by Routine
											</span>
										</NavLink>
									</li>
								</ul>
							</ul>
						</div>
					</li>
				)}
				{user?.role.includes('admin') && (
					<>
						<li className='menu-section'>
							<h4 className='menu-text'>ACTIONS</h4>
							<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/sendings',
								false
							)} ${getMenuItemActive('/edit-sending', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/sendings'>
								<span className='menu-icon'>
									<SendIcon />
								</span>
								<span className='menu-text'>Segmented sendings</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/giveaways',
								false
							)} ${getMenuItemActive('/edit-giveaway', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/giveaways'>
								<span className='menu-icon'>
									<RedeemIcon />
								</span>
								<span className='menu-text'>Giveaways</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/events',
								false
							)} ${getMenuItemActive('/edit-event', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/events'>
								<span className='menu-icon'>
									<EventIcon />
								</span>
								<span className='menu-text'>Live events</span>
							</NavLink>
						</li>

						<li className='menu-section'>
							<h4 className='menu-text'>MONETIZATION</h4>
							<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/advertisements',
								false
							)} ${getMenuItemActive('/edit-advertisement', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/advertisements'>
								<span className='menu-icon'>
									<LiveTvIcon />
								</span>
								<span className='menu-text'>Advertisements</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/subscriptions',
								false
							)} ${getMenuItemActive('/edit-subscription', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/subscriptions'>
								<span className='menu-icon'>
									<PaymentIcon />
								</span>
								<span className='menu-text'>Subscriptions</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/purchases',
								false
							)} ${getMenuItemActive('/view-purchase', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/purchases'>
								<span className='menu-icon'>
									<ShoppingCartIcon />
								</span>
								<span className='menu-text'>Purchases</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/ads',
								false
							)} ${getMenuItemActive('/view-ad', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/ads'>
								<span className='menu-icon'>
									<FeaturedVideo />
								</span>
								<span className='menu-text'>Ads</span>
							</NavLink>
						</li>
						<li className='menu-section'>
							<h4 className='menu-text'>MISCELLANY</h4>
							<i className='menu-icon ki ki-bold-more-hor icon-md'></i>
						</li>
						<li
							className={`menu-item
                ${getMenuItemActive('/recipe-types', false)} ${getMenuItemActive(
								'/edit-recipe-type',
								false
							)}
                ${getMenuItemActive('/diet-types', false)} ${getMenuItemActive(
								'/edit-diet-type',
								false
							)}
                ${getMenuItemActive('/meals', false)} ${getMenuItemActive(
								'/edit-meal',
								false
							)}
                ${getMenuItemActive(
					'/diet-variety-levels',
					false
				)} ${getMenuItemActive('/edit-diet-variety-level', false)}
                ${getMenuItemActive('/foods', false)} ${getMenuItemActive(
								'/edit-food',
								false
							)} ${getMenuItemActive(
								'/food-families',
								false
							)} ${getMenuItemActive('/edit-food-family', false)}
                ${getMenuItemActive(
					'/nutritionist-certifications',
					false
				)} ${getMenuItemActive('/edit-nutritionist-certification', false)}
                ${getMenuItemActive(
					'/nutritionist-specializations',
					false
				)} ${getMenuItemActive('/edit-nutritionist-specialization', false)}  
              `}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/recipe-types'>
								<span className='svg-icon menu-icon'>
									<MenuBookIcon />
								</span>
								<span className='menu-text'>
									Nutrition entities <KeyboardArrowRightIcon />
								</span>
							</NavLink>
							<div className='menu-submenu'>
								<ul className='menu-subnav'>
									<ul className='menu-subnav'>
										<li
											className={`menu-item ${getMenuItemActive(
												'/recipe-types',
												false
											)} ${getMenuItemActive(
												'/edit-recipe-type',
												false
											)} 
                    `}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/recipe-types'>
												<span className='menu-icon'>
													<Receipt />
												</span>
												<span className='menu-text'>
													Recipe types
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/diet-types',
												false
											)} ${getMenuItemActive(
												'/edit-diet-type',
												false
											)} 
                    `}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/diet-types'>
												<span className='menu-icon'>
													<Restaurant />
												</span>
												<span className='menu-text'>
													Diet types
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/meals',
												false
											)} ${getMenuItemActive(
												'/edit-meal',
												false
											)} 
                    `}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/meals'>
												<span className='menu-icon'>
													<Schedule />
												</span>
												<span className='menu-text'>
													Meals
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/diet-variety-levels',
												false
											)} ${getMenuItemActive(
												'/edit-diet-variety-level',
												false
											)} 
                    `}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/diet-variety-levels'>
												<span className='menu-icon'>
													<Equalizer />
												</span>
												<span className='menu-text'>
													Diet variety levels
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/foods',
												false
											)} ${getMenuItemActive(
												'/edit-food',
												false
											)} 
                    `}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/foods'>
												<span className='menu-icon'>
													<Kitchen />
												</span>
												<span className='menu-text'>
													Foods
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/food-families',
												false
											)} ${getMenuItemActive(
												'/edit-food-family',
												false
											)} 
                    `}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/food-families'>
												<span className='menu-icon'>
													<AllInbox />
												</span>
												<span className='menu-text'>
													Food families
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/nutritionist-certifications',
												false
											)} ${getMenuItemActive(
												'/edit-nutritionist-certifications',
												false
											)}`}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/nutritionist-certifications'>
												<span className='menu-icon'>
													<VerifiedUserIcon />
												</span>
												<span className='menu-text'>
													Nutritionist certifications
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/nutritionist-specializations',
												false
											)} ${getMenuItemActive(
												'/edit-nutritionist-specialization',
												false
											)}`}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/nutritionist-specializations'>
												<span className='menu-icon'>
													<AccountTreeIcon />
												</span>
												<span className='menu-text'>
													Nutritionist specializations
												</span>
											</NavLink>
										</li>
									</ul>
								</ul>
							</div>
						</li>
						<li
							className={`menu-item
                ${getMenuItemActive('/muscle-groups', false)} ${getMenuItemActive(
								'/edit-muscle-group',
								false
							)}
                ${getMenuItemActive('/equipments', false)} ${getMenuItemActive(
								'/edit-equipment',
								false
							)}
                ${getMenuItemActive(
					'/routine-plan-types',
					false
				)} ${getMenuItemActive('/edit-routine-plan-type', false)}
                ${getMenuItemActive(
					'/trainer-certifications',
					false
				)} ${getMenuItemActive('/edit-trainer-certification', false)}
                ${getMenuItemActive(
					'/trainer-specializations',
					false
				)} ${getMenuItemActive('/edit-trainer-specialization', false)}  
              `}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/muscle-groups'>
								<span className='svg-icon menu-icon'>
									<SportsIcon />
								</span>
								<span className='menu-text'>
									Training entities <KeyboardArrowRightIcon />
								</span>
							</NavLink>
							<div className='menu-submenu '>
								<ul className='menu-subnav'>
									<ul className='menu-subnav'>
										<li
											className={`menu-item ${getMenuItemActive(
												'/muscle-groups',
												false
											)} ${getMenuItemActive(
												'/edit-muscle-group',
												false
											)}`}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/muscle-groups'>
												<span className='menu-icon'>
													<AccessibilityNewIcon />
												</span>
												<span className='menu-text'>
													Muscle groups
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/equipments',
												false
											)} ${getMenuItemActive(
												'/edit-equipment',
												false
											)}`}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/equipments'>
												<span className='menu-icon'>
													<SportsMmaIcon />
												</span>
												<span className='menu-text'>
													Equipments
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/routine-plan-types',
												false
											)} ${getMenuItemActive(
												'/edit-routine-plan-type',
												false
											)}`}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/routine-plan-types'>
												<span className='menu-icon'>
													<CategoryIcon />
												</span>
												<span className='menu-text'>
													Routine plan types
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/trainer-certifications',
												false
											)} ${getMenuItemActive(
												'/edit-trainer-certification',
												false
											)}`}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/trainer-certifications'>
												<span className='menu-icon'>
													<VerifiedUserIcon />
												</span>
												<span className='menu-text'>
													Trainer certifications
												</span>
											</NavLink>
										</li>
										<li
											className={`menu-item ${getMenuItemActive(
												'/trainer-specializations',
												false
											)} ${getMenuItemActive(
												'/edit-trainer-specialization',
												false
											)}`}
											aria-haspopup='true'>
											<NavLink
												className='menu-link'
												to='/trainer-specializations'>
												<span className='menu-icon'>
													<AccountTreeIcon />
												</span>
												<span className='menu-text'>
													Trainer specializations
												</span>
											</NavLink>
										</li>
									</ul>
								</ul>
							</div>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/team-categories',
								false
							)} ${getMenuItemActive('/edit-team-category', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/team-categories'>
								<span className='menu-icon'>
									<Extension />
								</span>
								<span className='menu-text'>Team Categories</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/screens',
								false
							)} ${getMenuItemActive('/edit-screen', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/screens'>
								<span className='menu-icon'>
									<Phonelink />
								</span>
								<span className='menu-text'>Screens</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/sports',
								false
							)} ${getMenuItemActive('/edit-sport', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/sports'>
								<span className='menu-icon'>
									<SportsSoccer />
								</span>
								<span className='menu-text'>Sports</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/specialDays',
								false
							)} ${getMenuItemActive('/edit-specialDay', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/specialDays'>
								<span className='menu-icon'>
									<EventAvailableIcon />
								</span>
								<span className='menu-text'>Special Days</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/notification-texts',
								false
							)} ${getMenuItemActive(
								'/edit-notification-text',
								false
							)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/notification-texts'>
								<span className='menu-icon'>
									<DescriptionIcon />
								</span>
								<span className='menu-text'>Notification texts</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/multimedias',
								false
							)} ${getMenuItemActive('/edit-multimedia', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/multimedias'>
								<span className='menu-icon'>
									<PermMediaIcon />
								</span>
								<span className='menu-text'>Multimedia</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/texts',
								false
							)} ${getMenuItemActive('/edit-text', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/texts'>
								<span className='menu-icon'>
									<DescriptionIcon />
								</span>
								<span className='menu-text'>Texts</span>
							</NavLink>
						</li>
						<li
							className={`menu-item ${getMenuItemActive(
								'/languages',
								false
							)} ${getMenuItemActive('/edit-language', false)}`}
							aria-haspopup='true'>
							<NavLink className='menu-link' to='/languages'>
								<span className='menu-icon'>
									<LanguageIcon />
								</span>
								<span className='menu-text'>Languages</span>
							</NavLink>
						</li>
					</>
				)}
			</ul>

			{/* end::Menu Nav */}
		</>
	);
}
