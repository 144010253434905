import React, { useEffect, useState } from "react";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { getUserById } from "../../../../api/user";
import { alertError } from "../../../../utils/logger";
import { dateFormatter } from "../../../components/tables/table";
import { getNonEmpty } from "../../../../utils/helpers";

const LessonContent = (props) => {
	const { lesson, showTitle } = props;
	const [openPreviewDialog, setOpenPreviewDialog] = useState(null);
	const [createdBy, setCreatedBy] = useState(null);

	useEffect(() => {
		if (!lesson) return;
		if (lesson.trainer) {
			getUserById(lesson.trainer)
				.then((resp) => {
					if (resp.status === 200) {
						setCreatedBy(resp.data.fullName);
					}
				})
				.catch((error) => {
					setCreatedBy("----");
					alertError({
						error: error,
						customMessage: "Could not get trainer.",
					});
				});
		} else setCreatedBy(null);
	}, [lesson]);

	if (!lesson) return <></>;
	return (
		<>
			{showTitle && (
				<>
					<h5>Title</h5>
					<p>{getNonEmpty(lesson?.title) || "---"}</p>
				</>
			)}
			<h5>Description</h5>
			<div
				dangerouslySetInnerHTML={{
					__html: getNonEmpty(lesson?.description) || "---",
				}}
			></div>
			<h5>Common Errors</h5>
			<div
				dangerouslySetInnerHTML={{
					__html: getNonEmpty(lesson?.commonErrors) || "---",
				}}
			></div>
			<FormControlLabel
				control={
					<Checkbox checked={lesson.standard} name="checkStandard" />
				}
				label="Standard"
			/>
			<FormControlLabel
				control={
					<Checkbox checked={lesson.active} name="checkActive" />
				}
				label="Active"
			/>
			<br />
			<br />
			{lesson.imageURL && lesson.imageURL !== "" && (
				<>
					<h5>Main image</h5>
					<img
						key={lesson.imageURL}
						src={SERVER_URL + "/" + lesson.imageURL}
						alt={lesson.imageURL.split(/-(.*)/s)[1]}
						style={{ maxWidth: "200px", cursor: "zoom-in" }}
						onClick={() => setOpenPreviewDialog("main")}
					/>
					<PreviewDialog
						open={openPreviewDialog === "main"}
						setOpen={setOpenPreviewDialog}
						src={SERVER_URL + "/" + lesson.imageURL}
					/>
					<br />
				</>
			)}
			{lesson.otherImagesURLs?.length > 0 && <h5>Other images</h5>}
			{lesson.otherImagesURLs?.map((image, index) => (
				<>
					<img
						key={image}
						src={SERVER_URL + "/" + image}
						alt={image.split(/-(.*)/s)[1]}
						style={{ maxWidth: "200px", cursor: "zoom-in" }}
						onClick={() => setOpenPreviewDialog(index)}
					/>
					<PreviewDialog
						open={openPreviewDialog === index}
						setOpen={setOpenPreviewDialog}
						src={SERVER_URL + "/" + image}
					/>
				</>
			))}
			<br />
			{lesson.videoURL && lesson.videoURL !== "" && (
				<>
					<h5>Video</h5>
					<embed
						src={SERVER_URL + "/" + lesson.videoURL}
						height="400px"
						width="500px"
					/>
					<br />
				</>
			)}
			<br />
			{lesson.audioTipURL && lesson.audioTipURL !== "" && (
				<>
					<h5>Audio tip</h5>
					<embed
						src={SERVER_URL + "/" + lesson.audioTipURL}
						height="140px"
						width="250px"
					/>
				</>
			)}
			<div className="mt-3">
				<p style={{ textAlign: "end" }}>
					<strong>Created by: </strong>
					{createdBy || "-----"}
					{lesson.createdAt
						? ` (${dateFormatter(lesson.createdAt)})`
						: ""}
				</p>
			</div>
		</>
	);
};

export default LessonContent;
