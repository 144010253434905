import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteNutritionistCertification,
	getNutritionistCertificationById,
	postNutritionistCertification,
	updateNutritionistCertification,
} from '../../../../api/nutritionistCertification';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { shallowEqual, useSelector } from 'react-redux';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import { checkIsEmpty } from '../../../../utils/helpers';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyNutritionistCertification() {
	return {
		fullName: {},
		active: true
	};
}

export default function EditNutritionistCertificationsPage() {
	const [nutritionistCertification, setNutritionistCertification] = useState(getEmptyNutritionistCertification());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const nutritionistCertificationId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!nutritionistCertificationId) {
			disableLoadingData();
			return;
		}
		getNutritionistCertificationById(nutritionistCertificationId)
			.then((res) => {
				if (res.status === 200) {
					setNutritionistCertification(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get nutritionist certification.' });
				history.push('/nutritionist-certifications');
			});
	}, [nutritionistCertificationId, disableLoadingData, history]);

	function saveNutritionistCertification() {
		if (checkIsEmpty(nutritionistCertification.fullName)) return alertError({ error: null, customMessage: 'The name is required in at least one of the languages.' })
		if (!nutritionistCertificationId) {
			postNutritionistCertification(nutritionistCertification)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Nutritionist certification successfully created.',
						});
						history.push('/nutritionist-certifications');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save nutritionist certification.' });
				});
		} else {
			updateNutritionistCertification(nutritionistCertificationId, nutritionistCertification)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/nutritionist-certifications');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return
		if (lang) {
			if (!nutritionistCertification[element]) nutritionistCertification[element] = {}
			let newText = nutritionistCertification[element]
			newText[lang] = event.target.value
			setNutritionistCertification({ ...nutritionistCertification, [element]: newText })
		} else setNutritionistCertification({ ...nutritionistCertification, [element]: event.target.value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return <>
			<br/>
			<TextField
				id={`fullName`}
				label='Full name'
				value={(nutritionistCertification.fullName && nutritionistCertification.fullName[lang]) || ''}
				onChange={handleChange('fullName', lang)}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				variant='outlined'
				required
			/>
		</>
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit nutritionist certification'></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent = {renderMultilanguageTabContent}
						/>
						<br/>
						<FormControlLabel
							control={<Checkbox checked={nutritionistCertification.active} onChange={() => setNutritionistCertification({ ...nutritionistCertification, active: !nutritionistCertification.active })} name="checkActive" />}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/nutritionist-certifications')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveNutritionistCertification()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save nutritionist certification
					</Button>
					{nutritionistCertificationId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete nutritionist certification
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this nutritionist certification?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteNutritionistCertification(nutritionistCertificationId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Nutritionist certification deleted successfully',
												});
												history.push('/nutritionist-certifications');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete nutritionist certification.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
