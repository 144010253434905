import React from 'react';
import 'hammerjs';
import { 
    Chart, 
    ChartSeries, 
    ChartSeriesItem, 
    ChartValueAxis, 
    ChartValueAxisItem, 
    ChartCategoryAxis, 
    ChartCategoryAxisItem,
    ChartTitle,
    ChartSubtitle
} from '@progress/kendo-react-charts';
import './charts.css';

const categoryAxisMax = new Date();
const categoryAxisMaxDivisions = 10;

const LineChartContainer = ({ data, title, format, noDataMessage }) => {
    return <>
      <Chart 
          pannable={{
            lock: 'y'
          }} 
          zoomable={{
            mousewheel: {
              lock: 'y'
            },
            selection: {
              lock: 'y'
            }
          }}
        >
            <ChartTitle text={title}/>
            {(!data || data.length === 0) ? <ChartSubtitle text={noDataMessage || 'No data found'}/> : <ChartSubtitle text=""/>}
            <ChartCategoryAxis>
                <ChartCategoryAxisItem 
                  max={categoryAxisMax} 
                  maxDivisions={categoryAxisMaxDivisions} 
                />
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem 
                  labels={{
                      visible: true,
                      rotation: "auto",
                      format
                  }}
                />
            </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem 
                  type="line" 
                  data={data} 
                  field="value" 
                  labels={{
                    visible: true,
                    padding: 3,
                    font: 'bold'
                  }}
                  categoryField="key" 
                />
              </ChartSeries>
      </Chart>
    </>
};

export default LineChartContainer