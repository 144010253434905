import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { verifyEmail } from "../../../../api/user";

function EmailVerify() {
	const [verificationStatus, setVerificationStatus] = useState(
		"Verificando..."
	);
	const [statusType, setStatusType] = useState("info");
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const token = queryParams.get("token");

		if (token) {
			verifyEmail(token)
				.then((res) => {
					if (res.status === 200) {
						setVerificationStatus(
							"¡Tu email ha sido verificado correctamente!"
						);
						setStatusType("success");
					} else {
						setVerificationStatus(
							"Error al verificar email. Por favor, inténtelo de nuevo."
						);
						setStatusType("danger");
					}
				})
				.catch(() => {
					setVerificationStatus(
						"Ha ocurrido un error. Por favor, inténtelo más tarde.."
					);
					setStatusType("danger");
				});
		} else {
			history.push("/");
		}
	}, [location]);

	return (
		<div>
			<div className="container mt-5">
				<div
					className={`alert alert-${statusType}`}
					style={{ fontSize: "16px" }}
					role="alert"
				>
					<h4
						className="p-5 alert-heading"
						style={{ fontSize: "24px" }}
					>
						{verificationStatus}
					</h4>
					{statusType === "success" && (
						<div className="px-5 my-3">
							<p>
								¡Bienvenido a nuestra comunidad! Ahora puedes
								utilizar todas las funcionalidades de nuestra
								aplicación.
							</p>
							<div className="my-3">
								<div className="title3 text-center">
									¡Ya tienes tu cuenta disponible!
								</div>
								<div className="my-3 text-center">
									<p>
										Ya puedes descargar tu aplicación e
										iniciar sesión con tu nuevo usuario que
										acabas de crear.
									</p>
									<p>
										Accede a eatfity y te ayudaremos a
										conseguir tus objetivos.
									</p>
								</div>
								<div className="d-flex align-items-center justify-content-around my-5 py-5">
									<a
										href="https://apps.apple.com/ua/app/eatfity/id6450825366"
										target="_blank"
									>
										<img
											src={toAbsoluteUrl(
												"/media/app-store-download-eatfity.webp"
											)}
											style={{ maxHeight: "80px" }}
											className="img-fluid"
										/>
									</a>
									<a
										href="https://play.google.com/store/apps/details?id=com.eatfity&hl=es&gl=US"
										target="_blank"
									>
										<img
											src={toAbsoluteUrl(
												"/media/google-play-download-eatfity.webp"
											)}
											style={{ maxHeight: "80px" }}
											className="img-fluid"
										/>
									</a>
								</div>
							</div>
						</div>
					)}
					{statusType === "danger" && (
						<p className="px-5">
							Por favor, asegúrate de que el enlace es correcto o
							contacta con soporte para obtener ayuda.
						</p>
					)}
				</div>
			</div>
		</div>
	);
}

export default EmailVerify;
