import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import ForumIcon from '@material-ui/icons/Forum';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleIcon from '@material-ui/icons/People';
import GuestIcon from '@material-ui/icons/ContactSupport';
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

import { countProfessionals, countUsers } from '../../api/user';
import { countGuests } from '../../api/guest';
import { getSubscriptionsStatistics } from '../../api/subscription';
import { getAdvertisements } from '../../api/advertisement';
import { getTeams } from '../../api/team';
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls';
import CircularChartContainer from '../components/charts/CircularChartContainer';
import BarChartContainer from '../components/charts/BarChartContainer';
import { getNonEmpty } from '../../utils/helpers';
import { alertError } from '../../utils/logger';

export function DashboardPage() {
	const [data, setData] = useState([]);
	const notifications = useSelector(
		(store) => store.chat?.notifications,
		shallowEqual
	);
	const user = useSelector((store) => store.authentication?.user, shallowEqual);
	const [totalNotifications, setTotalNotifications] = useState(0);
	const history = useHistory();

	useEffect(() => {
		if (!notifications) return;
		let total = 0;
		for (const n of Object.values(notifications)) {
			if (!isNaN(n)) total += n;
		}
		setTotalNotifications(total);
	}, [notifications]);

	useEffect(() => {
		async function fetchData() {
			try {
				const users = await countUsers();
				const professionals = await countProfessionals();
				const guests = await countGuests();
				const subscriptions = await getSubscriptionsStatistics();
				const ads = await getAdvertisements();
				const teams = await getTeams();
				console.log(teams);

				setData({
					numUsers: users.data,
					numProfessionals: professionals.data,
					numGuests: guests.data,
					subscriptions: subscriptions.data,
					ads: ads.data,
					numTeams: teams.data.length
				});
			} catch (err) {
				alertError({
					error: err,
					customMessage: 'Could not get statistics.',
				});
			}
		}
		fetchData();
	}, []);

	return (
		<>
			<div className='row justify-content-center'>
				<div
					className='col-12 col-md-4 text-center my-2'
					role='button'
					onClick={() => history.push('/users')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'>
								<PeopleIcon /> Users
							</h5>
							<div className='contentDash'>{data.numUsers}</div>
						</div>
					</div>
				</div>				
				<div
					className='col-12 col-md-4 text-center my-2'
					role='button'
					onClick={() => history.push('/teams')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'>
								<SupervisedUserCircleIcon /> Teams
							</h5>
							<div className='contentDash'>{data.numTeams}</div>
						</div>
					</div>
				</div>
				<div
					className='col-12 col-md-4 text-center my-2'
					role='button'
					onClick={() => history.push('/guests')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'>
								<GuestIcon /> {`Guests (unlinked)`}
							</h5>
							<div className='contentDash'>{data.numGuests}</div>
						</div>
					</div>
				</div>
				{user?.role.includes('admin') && (
					<div
						className='col-12 col-md-4 text-center my-2'
						role='button'
						onClick={() => history.push('/professionals')}>
						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title'>
									<AssignmentIndIcon /> Professionals
								</h5>
								<div className='contentDash'>
									{data.numProfessionals}
								</div>
							</div>
						</div>
					</div>
				)}
				<div
					className='col-12 col-md-4 text-center my-2'
					role='button'
					onClick={() => history.push('/chat')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'>
								<ForumIcon /> Unread messages
							</h5>
							<div className='contentDash'>{totalNotifications}</div>
						</div>
					</div>
				</div>
			</div>
			<br />
			<Card>
				<CardHeader title='Statistics'></CardHeader>
				<CardBody>
					<CircularChartContainer
						data={data?.subscriptions?.map((item) => ({
							key: getNonEmpty(item.title),
							value: item.count,
							id: item._id,
							tooltip: [],
						}))}
						title={'Active subscriptions'}
						units='users'
						noDataMessage='There are no active subscriptions yet.'
					/>
				</CardBody>
				<CardBody>
					<BarChartContainer
						data={data?.ads?.map((item) => ({
							key: item.title,
							value: item.views,
							value2: item.clicks,
							id: item._id,
							tooltip: [],
						}))}
						title={'Advertisements'}
						units='views'
						units2='clicks'
						twoColumns={true}
						noDataMessage='There are no advertisements created yet.'
					/>
				</CardBody>
			</Card>
		</>
	);
}
