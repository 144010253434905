import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Button, Tooltip } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { getUserById } from '../../api/user';
import { SERVER_URL } from '../../api';
import { alertError } from '../../utils/logger';

import { weekdays } from '../modules/ContentManager/nutritionalPlans/EditNutritionalPlansPage';
import ViewRoutineDialog from '../modules/ContentManager/routines/ViewRoutineDialog';
import DaysTabBlock from './DaysTabBlock';
import { buttonsStyle, dateFormatter } from './tables/table';
import PreviewDialog from './dialogs/PreviewDialog';

const PlansContent = (props) => {
	const { plan, type, routines, user, showTitle } = props;
	const [openViewRoutineDialog, setOpenViewRoutineDialog] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [createdBy, setCreatedBy] = useState(null);

	useEffect(() => {
		if (!plan) return;
		const specialist = type === 'nutritional' ? plan.nutritionist : plan.trainer;
		if (specialist?._id)
			getUserById(specialist._id)
				.then((resp) => {
					if (resp.status === 200) setCreatedBy(resp.data.fullName);
				})
				.catch((error) => {
					setCreatedBy('----');
					alertError({
						error: error,
						customMessage: 'Could not get specialist.',
					});
				});
		else setCreatedBy(null);
		// eslint-disable-next-line
	}, [plan]);

	const renderDaysTabContentRoutinePlan = (day) => {
		const indexDay = parseInt(day.split('Day ')[1]) - 1;
		return (
			<>
				<br />
				<h5>Title</h5>
				<p>{plan.routines[indexDay]?.title || '----'}</p>
				{plan.routines[indexDay]?.duration && <h5>Duration (minutes)</h5>}
				{plan.routines[indexDay]?.duration && (
					<p>{plan.routines[indexDay]?.duration}</p>
				)}
				{plan.routines[indexDay]?.plans && <h5>Routines</h5>}
				{plan.routines[indexDay]?.plans?.map((item, index) => {
					const routine = routines?.find(
						(element) => element._id === item.id
					);
					return (
						<>
							<span>
								{routine?.title || '----'}
								{(item.series || item.time || item.nTimes) && (
									<strong>{` → ${
										item.series
											? 'Series repetitions'
											: item.nTimes
											? 'Number of times'
											: 'Minutes'
									}: `}</strong>
								)}
								{item.series
									? item.series.join(', ')
									: item.nTimes
									? item.nTimes
									: item.time}
							</span>
							{((user.role.includes('trainer') &&
								(routine.trainer === user._id ||
									!routine.trainer)) ||
								user.role.includes('admin')) && (
								<Tooltip title={'View'}>
									<Button
										size='small'
										onClick={() =>
											setOpenViewRoutineDialog(index)
										}
										style={buttonsStyle}>
										<VisibilityIcon />
									</Button>
								</Tooltip>
							)}
							<br />
							<ViewRoutineDialog
								open={openViewRoutineDialog === index}
								setOpen={setOpenViewRoutineDialog}
								routine={routine}
							/>
						</>
					);
				})}
			</>
		);
	};

	const renderDaysTabContentNutritionalPlan = (day) => {
		const dayLabel = day.charAt(0).toUpperCase() + day.slice(1);
		return (
			<>
				<br />
				<h5>{`${dayLabel} breakfast description`}</h5>
				<p>
					{(plan.plan &&
						plan.plan[day] &&
						plan.plan[day].breakfast &&
						plan.plan[day].breakfast.description) ||
						'----'}
				</p>
				<h5>{`${dayLabel} breakfast calories`}</h5>
				<p>
					{(plan.plan &&
						plan.plan[day] &&
						plan.plan[day].breakfast &&
						plan.plan[day].breakfast.calories) ||
						'----'}
				</p>
				<br />
				<h5>{`${dayLabel} lunch description`}</h5>
				<p>
					{(plan.plan &&
						plan.plan[day] &&
						plan.plan[day].lunch &&
						plan.plan[day].lunch.description) ||
						'----'}
				</p>
				<h5>{`${dayLabel} lunch calories`}</h5>
				<p>
					{(plan.plan &&
						plan.plan[day] &&
						plan.plan[day].lunch &&
						plan.plan[day].lunch.calories) ||
						'----'}
				</p>
				<br />
				<h5>{`${dayLabel} afternoon snack description`}</h5>
				<p>
					{(plan.plan &&
						plan.plan[day] &&
						plan.plan[day].afternoon &&
						plan.plan[day].afternoon.description) ||
						'----'}
				</p>
				<h5>{`${dayLabel} afternoon snack calories`}</h5>
				<p>
					{(plan.plan &&
						plan.plan[day] &&
						plan.plan[day].afternoon &&
						plan.plan[day].afternoon.calories) ||
						'----'}
				</p>
				<br />
				<h5>{`${dayLabel} dinner description`}</h5>
				<p>
					{(plan.plan &&
						plan.plan[day] &&
						plan.plan[day].dinner &&
						plan.plan[day].dinner.description) ||
						'----'}
				</p>
				<h5>{`${dayLabel} dinner calories`}</h5>
				<p>
					{(plan.plan &&
						plan.plan[day] &&
						plan.plan[day].dinner &&
						plan.plan[day].dinner.calories) ||
						'----'}
				</p>
				<br />
				<h5>{`${dayLabel} total calories`}</h5>
				<p>
					{(plan.plan && plan.plan[day] && plan.plan[day].totalCalories) ||
						'----'}
				</p>
			</>
		);
	};

	if (!plan) return <></>;
	return (
		<>
			{showTitle && (
				<>
					<h5>Title</h5>
					<p>{plan.title}</p>
				</>
			)}
			<h5>Description</h5>
			<div dangerouslySetInnerHTML={{ __html: plan.description }}></div>
			{type === 'nutritional' ? (
				<>
					<h5>Total calories plan</h5>
					<p>{plan.totalCaloriesPlan}</p>
					<br />
					<DaysTabBlock
						daysTabContent={renderDaysTabContentNutritionalPlan}
						days={weekdays}
					/>
				</>
			) : (
				<>
					<h5>Level</h5>
					<p>{plan.level}</p>
					<h5>Type</h5>
					<p>{plan.type}</p>
					<h5>Days per week</h5>
					<p>{plan.daysPerWeek}</p>
					<br />
					<DaysTabBlock
						daysTabContent={renderDaysTabContentRoutinePlan}
						days={Array(plan.daysPerWeek)
							.fill()
							.map((_, i) => 'Day ' + (i + 1))}
						subtitles={plan.routines?.map((item) => item.title)}
					/>
				</>
			)}
			<br />
			<FormControlLabel
				control={<Checkbox checked={plan.active} name='checkActive' />}
				label='Active'
			/>
			<FormControlLabel
				control={<Checkbox checked={plan.standard} name='checkStandard' />}
				label='Standard'
			/>
			<br />
			<FormControlLabel
				control={<Checkbox checked={plan.premium} name='checkPremium' />}
				label='Premium'
			/>
			{plan.premium && (
				<>
					<h5>Price</h5>
					<p>{plan.price}</p>
				</>
			)}
			{plan.bannerURL && plan.bannerURL !== '' && (
				<>
					<br />
					<br />
					<h5>Banner</h5>
					<img
						key={plan.bannerURL}
						src={SERVER_URL + '/' + plan.bannerURL}
						alt={plan.bannerURL.split(/-(.*)/s)[1]}
						style={{ maxWidth: '200px', cursor: 'zoom-in' }}
						onClick={() => setOpenPreviewDialog(true)}
					/>
					<PreviewDialog
						open={openPreviewDialog}
						setOpen={setOpenPreviewDialog}
						src={SERVER_URL + '/' + plan.bannerURL}
					/>
					<br />
				</>
			)}
			<br />
			<div className='mt-3'>
				<p style={{ textAlign: 'end' }}>
					<strong>Created by: </strong>
					{createdBy || '-----'}
					{plan.createdAt ? ` (${dateFormatter(plan.createdAt)})` : ''}
				</p>
			</div>
		</>
	);
};

export default PlansContent;
