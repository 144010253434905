import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import { Button, AppBar, Tab, Tabs, TextField } from '@material-ui/core';
import Editor from '../../../components/editor/Editor';
import { useHistory, useParams } from 'react-router-dom';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { shallowEqual, useSelector } from 'react-redux'
import { getTranslationsByEntityId, postTranslation, updateTranslation } from '../../../../api/translation'
import { getRoutinePlanById } from '../../../../api/routinePlan';
import { alertError, alertSuccess } from '../../../../utils/logger'
import DaysTabBlock from '../../../components/DaysTabBlock';

function getEmptyTranslation() {
	return {
		type: 'RoutinePlan',
		langIsoCode: null,
		entityId: null,
		content: {
			title: '',
			description: '',
			routines: [{}]
		}
	};
}

export default function TranslateRoutinePlansPage() {
	const [routinePlan, setRoutinePlan] = useState(null)
	const [translation, setTranslation] = useState(getEmptyTranslation())
	const [translationId, setTranslationId] = useState(null)
	const routinePlanId = useParams().id;
	const currentLanguage = useParams().lang
	const history = useHistory();
	const languages = useSelector((store) => store.authentication?.languages, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!routinePlanId || !currentLanguage) {
			disableLoadingData();
			alertError({ error: null, customMessage: 'Could not get routine plan translation.' });
			history.push('/training/routine-plans');
			return;
		}
		getTranslationsByEntityId(routinePlanId, currentLanguage)
			.then((res) => {
				if (res.status === 200) {
					getRoutinePlanById(routinePlanId)
						.then(response => {
							if (response.status === 200) {
								const { title, description, routines, daysPerWeek } = response.data
								setRoutinePlan({ title, description, routines, daysPerWeek })
								if (res.data?.length) {
									setTranslation(res.data[0]);
									setTranslationId(res.data[0]._id)
								}
								disableLoadingData();
							}
						})
						.catch((error) => {
							alertError({ error: error, customMessage: 'Could not get routine plan.' });
							history.push('/training/plan-routine-translations/' + routinePlanId)
						});
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get routine plan translations.' });
				history.push('/training/plan-routine-translations/' + routinePlanId)
			});		
		// eslint-disable-next-line
	}, [routinePlanId, disableLoadingData, history]);

	const handleChange = (element, day, subelement) => (event) => {
		if (day || day === 0) {
			if (event.target.value === ' ') return
			const newContent = translation.content
			if (!newContent[element]) newContent[element] = []
			let newText = newContent[element]
			if (subelement) {
				newText[day] = { ...newText[day], [subelement]: event.target.value }
			} else newText[day] = event.target.value
			setTranslation({ ...translation, content: newContent })
		} else {
			const newContent = translation.content
			newContent[element] = event.target.value
			setTranslation({ ...translation, content: newContent });
		}
		
	};

	const handleChangeEditor = (element, value) => {
		const newContent = translation.content
		newContent[element] = value
		setTranslation({ ...translation, content: newContent });
	}

	function saveTranslation() {
		if (!translationId) {
			postTranslation(({ ...translation, entityId: routinePlanId, langIsoCode: currentLanguage }))
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Translation successfully created.',
						});
						history.push('/training/plan-routine-translations/' + routinePlanId)
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save translation.' });
				});
		} else {
			updateTranslation(translationId, translation)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/training/plan-routine-translations/' + routinePlanId)
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}
	const renderDaysTabContent = day => {
		const indexDay = parseInt(day.split('Day ')[1]) - 1
		return (
		  <>
			<br/>
			<h5>{`${day} title`}</h5>
			<p>{routinePlan?.routines[indexDay]?.title || ''}</p>
			<TextField
				id={`${day} title translation`}
				label={`${day} title translation`}
				value={translation?.content?.routines[indexDay]?.title || ''}
				onChange={handleChange('routines', indexDay, 'title')}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				variant='outlined'
				required
			/>
		  </>
		)
	  }
	

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title={'Translate routine plan ' + routinePlan?.title}/>
					<CardBody>
						<AppBar position="static" color="default" key="appbar">
							<Tabs
								scrollButtons="auto"
								key="tabs"
							>
								<Tab key={currentLanguage} label={languages.find(item => item.isocode === currentLanguage)?.fullName} />
							</Tabs>
						</AppBar>
						<br/>
						<h5>Title</h5>
          				<p>{routinePlan?.title}</p>
						<TextField
							id={`title`}
							label='Title translation'
							value={translation.content?.title || ''}
							onChange={handleChange('title')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<br/>
						<br/>
						<h5>Description</h5>
        				<div dangerouslySetInnerHTML = {{ __html: routinePlan?.description }}></div>
						<br/>
						<Editor
							body={translation.content?.description || ''}
							setBody={new_body => handleChangeEditor('description', new_body)}
							className='max-height'
							placeholder={'Enter routine plan description translation here...'}
							label="Description translation"
						/>
						<br/>
						<br/>
						<DaysTabBlock
							daysTabContent = {renderDaysTabContent}
							days = {Array(routinePlan?.daysPerWeek)?.fill().map((_, i) => 'Day ' + (i + 1))}
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/training/plan-routine-translations/' + routinePlanId)}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveTranslation()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save translation
					</Button>
				</div>
			</>
		);
}
