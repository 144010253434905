import { API, authClient } from '../index';
import { uploadSingleFile } from '../files';

// Get all specialDays
export const getSpecialDays = () => {
	return authClient().get(`${API}/specialDay?getDisabled=true`);
};

// Get specialDay by id
export const getSpecialDayById = (id) => {
	return authClient().get(`${API}/specialDay/${id}`);
};

// Delete specialDay
export const deleteSpecialDay = (id) => {
	return authClient().delete(`${API}/specialDay/${id}`);
};

// Create specialDay 
export const postSpecialDay = async (specialDay, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'specialDays')
		specialDay.imageURL = response.data.fileURL
	}
	return authClient().post(`${API}/specialDay`, specialDay);
};

// Update specialDay
export const updateSpecialDay = async (id, specialDay, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'specialDays')
		specialDay.imageURL = response.data.fileURL
	}
	return authClient().put(`${API}/specialDay/${id}`, specialDay);
};

// Change status specialDay
export const changeStatusSpecialDay = async (id, active) => {
	return authClient().put(`${API}/specialDay/change-status/${id}`, { active });
};