import {
	AppBar,
	Button,
	Checkbox,
	createMuiTheme,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	MuiThemeProvider,
	Select,
	Tab,
	Tabs,
	TextField,
	Tooltip,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api/index";
import { getRoutines } from "../../../../api/routine";
import {
	deleteRoutinePlan,
	getRoutinePlanById,
	postRoutinePlan,
	updateRoutinePlan,
} from "../../../../api/routinePlan";
import { getRoutinePlanTypes } from "../../../../api/routinePlanType";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import DaysTabBlock from "../../../components/DaysTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import TableDialog from "../../../components/dialogs/TableDialog";
import Editor from "../../../components/editor/Editor";
import PlansContent from "../../../components/plansContent";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import EditRoutineDialog from "./EditRoutineDialog";
import ViewRoutineDialog from "./ViewRoutineDialog";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

function getEmptyRoutinePlan() {
	return {
		title: "",
		description: "",
		references: "",
		level: "",
		type: "",
		daysPerWeek: 1,
		routines: [[]],
		trainer: null,
		active: true,
		bannerURL: "",
		premium: false,
		price: 0,
		weight: {
			forLosing: null,
			forMantain: null,
			forGaining: null
		},
		velocity: {
			slow: null,
			recomended: null,
			accelerated: null
		},
		age: {
			less29: null,
			less39: null,
			less49: null,
			more50: null
		},
		gender: {
			femenine: null,
			masculine: null,
			other: null
		},
		imc: {
			low: null,
			normal: null,
			high: null,
			veryHigh: null
		}
	};
}

export default function EditRoutinePlansPage() {
	const [routinePlan, setRoutinePlan] = useState(getEmptyRoutinePlan());
	const [days, setDays] = useState(["Day 1"]);
	const [types, setTypes] = useState(null);
	const [routines, setRoutines] = useState(null);
	const [openTableDialog, setOpenTableDialog] = useState(false);
	const [openEditRoutineDialog, setOpenEditRoutineDialog] = useState(null);
	const [openViewRoutineDialog, setOpenViewRoutineDialog] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [selectedBanner, setSelectedBanner] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const routinePlanId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const languages = useSelector(
		(store) => store.authentication?.languages,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getRoutines()
			.then((res) => {
				if (res.status === 200) {
					const _routines = res.data.map((item) => ({
						...item,
						standard: !item.trainer,
						muscleGroups: [getNonEmpty(item.muscleGroup?.fullName)]
							.concat(
								item.otherMuscleGroups.map((mg) =>
									getNonEmpty(mg?.fullName)
								)
							)
							.join(", "),
					}));
					setRoutines(
						_routines.filter(
							(item) =>
								item.active === true &&
								(item.trainer === user || item.trainer === null)
						)
					);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get routines.",
				});
				history.push("/training/routine-plans");
			});
		getRoutinePlanTypes()
			.then((res) => {
				if (res.status === 200) {
					setTypes(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get routine plan types.",
				});
				history.push("/training/routine-plans");
			});
		if (!routinePlanId) {
			disableLoadingData();
			return;
		}
		getRoutinePlanById(routinePlanId)
			.then((res) => {
				if (res.status === 200) {
					setRoutinePlan({ ...res.data, type: res.data.type?._id });
					setDays(
						Array(res.data.daysPerWeek)
							.fill()
							.map((_, i) => "Day " + (i + 1))
					);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get routine plan.",
				});
				history.push("/training/routine-plans");
			});
	}, [routinePlanId, disableLoadingData, history, user]);

	function saveRoutinePlan() {
		if (!routinePlan.routines)
			return alertError({
				error: null,
				customMessage:
					"At least one routine and a title for each day are required, please check that it is all filled out.",
			});
		for (const item of routinePlan.routines) {
			if (!item.plans || !item.plans?.length || !item.title?.length)
				return alertError({
					error: null,
					customMessage:
						"At least one routine and a title for each day are required, please check that it is all filled out.",
				});
		}

		for (const routine of routinePlan.routines) {
			for (const plan of routine?.plans) {
				if (plan.timeSeries && plan.timeSeries?.length > 0)
					plan.time = plan.timeSeries[0];

				if (plan.nTimesSeries && plan.nTimesSeries?.length > 0)
					plan.nTimes = plan.nTimesSeries[0];
			}
		}

		if (!routinePlanId) {
			postRoutinePlan(
				{
					...routinePlan,
					trainer: user.role.includes("trainer") ? user._id : null,
					admin: user.role.includes("admin") ? user._id : null,
				},
				selectedBanner
			)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Routine plan successfully created.",
						});
						history.push("/training/routine-plans");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save routine plan.",
					});
				});
		} else {
			updateRoutinePlan(routinePlanId, routinePlan, selectedBanner)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/training/routine-plans");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, index, subelement) => (event) => {
		if (element === "daysPerWeek") {
			let newRoutines = [...routinePlan.routines];
			let _days = [...days];
			if (routinePlan.daysPerWeek < event.target.value) {
				newRoutines.push({ title: "", plans: [], duration: "" });
				_days.push("Day " + (parseInt(_days.length) + 1));
			} else if (routinePlan.daysPerWeek > event.target.value) {
				newRoutines.pop();
				_days.pop();
			}
			setDays(_days);
			setRoutinePlan({
				...routinePlan,
				[element]: event.target.value,
				routines: newRoutines,
			});
		}
		if (index || index === 0) {
			if (event.target.value === " ") return;
			if (!routinePlan[element]) routinePlan[element] = [];
			let newText = routinePlan[element];
			if (subelement) {
				newText[index] = {
					...newText[index],
					[subelement]: event.target.value,
				};
			} else newText[index] = event.target.value;
			setRoutinePlan({ ...routinePlan, [element]: newText });
		} else
			setRoutinePlan({ ...routinePlan, [element]: event.target.value });
	};

	const handleChangeWeight = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		 setRoutinePlan((prevRutinePlan) => ({
			...prevRutinePlan,
			weight: {
				...prevRutinePlan.weight,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeVelocity = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		 setRoutinePlan((prevRutinePlan) => ({
			...prevRutinePlan,
			velocity: {
				...prevRutinePlan.velocity,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeAge = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		 setRoutinePlan((prevRutinePlan) => ({
			...prevRutinePlan,
			age: {
				...prevRutinePlan.age,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeGender = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		 setRoutinePlan((prevRutinePlan) => ({
			...prevRutinePlan,
			gender: {
				...prevRutinePlan.gender,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeImc = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		 setRoutinePlan((prevRutinePlan) => ({
			...prevRutinePlan,
			imc: {
				...prevRutinePlan.imc,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeEditor = (element, value) => {
		setRoutinePlan({ ...routinePlan, [element]: value });
	};

	const handleMoveRoutine = (indexDay, index, newIndex) => {
		const aux = routinePlan.routines[indexDay]?.plans[index];
		if (!aux)
			return alertError({
				error: null,
				customMessage:
					"An unexpected error has ocurred trying to move the routines, please try again.",
			});
		let _routines = routinePlan.routines;
		_routines[indexDay].plans.splice(
			index,
			1,
			_routines[indexDay].plans[newIndex]
		);
		_routines[indexDay].plans.splice(newIndex, 1, aux);
		setRoutinePlan({ ...routinePlan, routines: _routines });
	};

	const renderDaysTabContent = (day) => {
		const indexDay = parseInt(day.split("Day ")[1]) - 1;
		return (
			<>
				<br />
				<TextField
					id={`${day} title`}
					label="Title"
					value={routinePlan.routines[indexDay]?.title || ""}
					onChange={handleChange("routines", indexDay, "title")}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<TextField
					id={`${day} duration`}
					label="Duration (minutes)"
					value={routinePlan.routines[indexDay]?.duration || ""}
					onChange={handleChange("routines", indexDay, "duration")}
					InputLabelProps={{
						shrink: true,
						min: 0,
					}}
					type="number"
					margin="normal"
					variant="outlined"
				/>
				<br />
				<TextField
					id={`${day} calories`}
					label="Calories"
					value={routinePlan.routines[indexDay]?.calories || ""}
					onChange={handleChange("routines", indexDay, "calories")}
					InputLabelProps={{
						shrink: true,
						min: 0,
					}}
					type="number"
					margin="normal"
					variant="outlined"
				/>
				<br />
				<br />
				<h5>Routines</h5>
				<br />
				{routinePlan.routines[indexDay]?.plans?.map((item, index) => {
					const routine = routines?.find(
						(element) => element._id === item.id
					);
					return (
						<>
							<span>
								{routine?.title}
								{(item.series ||
									item.time ||
									item.nTimes ||
									item.timeSeries ||
									item.nTimesSeries) && (
									<strong>{` → ${
										item.series
											? "Series repetitions"
											: item.nTimes || item?.nTimesSeries
											? "Number of times"
											: "Minutes"
									}: `}</strong>
								)}
								{item.series
									? item.series.join(", ")
									: item.nTimes
									? (Array.isArray(item.nTimes) &&
											item.nTimes?.join(", ")) ||
									  (Array.isArray(item.nTimesSeries) &&
											item.nTimesSeries?.join(", ")) ||
									  item.nTimes
									: (Array.isArray(item.time) &&
											item.time?.join(", ")) ||
									  (Array.isArray(item.timeSeries) &&
											item.timeSeries?.join(", ")) ||
									  item.time}
							</span>
							{((user.role.includes("trainer") &&
								(routinePlan.trainer === user._id ||
									!routinePlan.trainer)) ||
								user.role.includes("admin")) && (
								<Tooltip title={"View"}>
									<Button
										size="small"
										onClick={() =>
											setOpenViewRoutineDialog(index)
										}
										style={buttonsStyle}
									>
										<VisibilityIcon />
									</Button>
								</Tooltip>
							)}
							{(user.role.includes("trainer") ||
								user.role.includes("admin")) && (
								<>
									<Tooltip
										title={`Edit ${
											routinePlan.model === "series"
												? "series repetitions"
												: routinePlan.model === "nTimes"
												? "number of times"
												: "minutes"
										}`}
									>
										<Button
											size="small"
											style={buttonsStyle}
											onClick={() => {
												setOpenEditRoutineDialog(index);
											}}
										>
											<EditIcon />
										</Button>
									</Tooltip>
									<Tooltip title="Delete">
										<Button
											size="small"
											style={buttonsStyle}
											onClick={() => {
												let _routines = [
													...routinePlan.routines,
												];
												_routines[
													indexDay
												].plans.splice(index, 1);
												setRoutinePlan({
													...routinePlan,
													routines: _routines,
												});
											}}
										>
											<DeleteIcon />
										</Button>
									</Tooltip>
									<Tooltip title="Move up">
										<Button
											size="small"
											style={buttonsStyle}
											disabled={index === 0}
											onClick={() =>
												handleMoveRoutine(
													indexDay,
													index,
													index - 1
												)
											}
										>
											<ArrowUpward />
										</Button>
									</Tooltip>
									<Tooltip title="Move down">
										<Button
											size="small"
											disabled={
												index >=
												routinePlan.routines[indexDay]
													.plans.length -
													1
											}
											style={buttonsStyle}
											onClick={() =>
												handleMoveRoutine(
													indexDay,
													index,
													index + 1
												)
											}
										>
											<ArrowDownward />
										</Button>
									</Tooltip>
								</>
							)}
							<br />
							<EditRoutineDialog
								title={routine?.title}
								open={openEditRoutineDialog === index}
								setOpen={setOpenEditRoutineDialog}
								routine={routine}
								initialData={{
									series: item.series,
									timeSeries: item.timeSeries || item.time,
									nTimesSeries:
										item.nTimesSeries || item.nTimes,
								}}
								onConfirm={(data) => {
									setOpenEditRoutineDialog(null);
									let _routines = [...routinePlan.routines];
									_routines[indexDay].plans[index] = {
										..._routines[indexDay].plans[index],
										series: data.series,
										timeSeries: data.timeSeries,
										nTimesSeries: data.nTimesSeries,
									};
									setRoutinePlan({
										...routinePlan,
										routines: _routines,
									});
								}}
							/>
							<ViewRoutineDialog
								open={openViewRoutineDialog === index}
								setOpen={setOpenViewRoutineDialog}
								routine={routine}
							/>
						</>
					);
				})}
				<br />
				{(user.role.includes("trainer") ||
					user.role.includes("admin")) && (
					<Button
						onClick={() => setOpenTableDialog(true)}
						variant="outlined"
						color="primary"
					>
						Add routine
					</Button>
				)}
				<TableDialog
					open={openTableDialog}
					setOpen={setOpenTableDialog}
					routinesTable={true}
					data={
						user.role.includes("trainer")
							? routines?.filter(
									(item) =>
										item.trainer === user._id ||
										item.trainer === null
							  )
							: routines
					}
					onSelectRow={(id) => {
						let _routines = [...routinePlan.routines];
						if (_routines[indexDay] && _routines[indexDay].plans)
							_routines[indexDay].plans.push({
								id: id,
								series: null,
								timeSeries: null,
								nTimesSeries: null,
							});
						else
							_routines[indexDay].plans = [
								{
									id: id,
									series: null,
									timeSeries: null,
									nTimesSeries: null,
								},
							];
						setRoutinePlan({ ...routinePlan, routines: _routines });
						setOpenTableDialog(null);
						// TODO: open modal to edit series/time ???
					}}
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					{!routinePlan.validated && routinePlanId ? (
						<div class="alert alert-danger">
							{routinePlan.validationMessage}
						</div>
					) : (
						<></>
					)}
					{!routinePlanId ||
					user?.role.includes("admin") ||
					(user?.role.includes("trainer") &&
						routinePlan.trainer === user?._id) ? (
						<>
							<CardHeader title="Edit routine plan"></CardHeader>
							<CardBody>
								<AppBar
									position="static"
									color="default"
									key="appbar"
								>
									<Tabs scrollButtons="auto" key="tabs">
										<Tab
											key={"es"}
											label={
												languages.find(
													(item) =>
														item.isocode === "es"
												)?.fullName
											}
										/>
									</Tabs>
								</AppBar>
								<TextField
									id={`title`}
									label="Title"
									value={routinePlan.title}
									onChange={handleChange("title")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									required
								/>
								<br />
								<Editor
									body={routinePlan.description || ""}
									setBody={(new_body) =>
										handleChangeEditor(
											"description",
											new_body
										)
									}
									className="max-height"
									placeholder={
										"Enter plan description here..."
									}
									label="Description*"
								/>
								<br />
								<br />
								<TextField
									id={`References`}
									label="References"
									value={routinePlan.references || ""}
									margin="normal"
									variant="outlined"
									placeholder={"Enter References here ..."}
									onChange={handleChange("references")}
								/>
								<br />
								<br />
								<FormControl style={{ width: "100%" }}>
									<InputLabel id="demo-simple-select-standard-label">
										Level
									</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={routinePlan.level || ""}
										onChange={handleChange("level")}
										MenuProps={MenuProps}
									>
										{[
											"beginner",
											"intermediate",
											"advanced",
										].map((option) => (
											<MenuItem
												key={option}
												value={option}
											>
												{option}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										Select a level
									</FormHelperText>
								</FormControl>
								<br />
								<br />
								<FormControl style={{ width: "100%" }}>
									<InputLabel id="demo-simple-select-standard-label">
										Type
									</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={routinePlan.type || ""}
										onChange={handleChange("type")}
										MenuProps={MenuProps}
									>
										{types?.map((option) => (
											<MenuItem
												key={option._id}
												value={option._id}
											>
												{getNonEmpty(option.fullName)}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										Select a type
									</FormHelperText>
								</FormControl>
								<br />
								<br />
								<label htmlFor={"upload-image"}>
									<input
										style={{ display: "none" }}
										id={"upload-image"}
										name={"upload-image"}
										type="file"
										accept={"image/*"}
										onChange={(e) => {
											setSelectedBanner(
												e.target.files[0]
											);
										}}
									/>
									<Button
										style={{ marginRight: "15px" }}
										color="secondary"
										component="span"
										variant="outlined"
									>
										{selectedBanner ||
										(routinePlan.bannerURL &&
											routinePlan.bannerURL !== "")
											? "Change banner"
											: "Upload banner"}
									</Button>
								</label>
								{(selectedBanner ||
									(routinePlan.bannerURL &&
										routinePlan.bannerURL !== "")) && (
									<>
										<Tooltip title={"Preview banner"}>
											<Button
												size="small"
												onClick={() =>
													setOpenPreviewDialog(true)
												}
												style={buttonsStyle}
											>
												<VisibilityIcon />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={"Preview banner"}
											open={openPreviewDialog}
											setOpen={setOpenPreviewDialog}
											src={
												selectedBanner
													? URL.createObjectURL(
															selectedBanner
													  )
													: `${SERVER_URL}/${routinePlan.bannerURL}`
											}
										/>
										<Tooltip title="Delete">
											<Button
												size="small"
												style={buttonsStyle}
												onClick={() => {
													setSelectedBanner(null);
													setRoutinePlan({
														...routinePlan,
														bannerURL: "",
													});
												}}
											>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedBanner
												? selectedBanner?.name
												: routinePlan.bannerURL &&
												  routinePlan.bannerURL !== ""
												? routinePlan.bannerURL.split(
														/-(.*)/s
												  )[1]
												: ""}
										</span>
									</>
								)}
								<br />
								<br />
								<div className="row">
									<div className="col-12"><h3>Compatibility algorithm parameters</h3></div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`forLosing`}
													label="Weight > for Losing"
													value={ routinePlan?.weight?.forLosing || ""}
													onChange={handleChangeWeight("forLosing")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`forMantain`}
													label="Weight > for Mantain"
													value={ routinePlan?.weight?.forMantain || ""}
													onChange={handleChangeWeight("forMantain")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`forGaining`}
													label="Weight > for Gaining"
													value={ routinePlan?.weight?.forGaining || ""}
													onChange={handleChangeWeight("forGaining")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`slow`}
													label="Velocity > slow"
													value={ routinePlan?.velocity?.slow || ""}
													onChange={handleChangeVelocity("slow")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`recomended`}
													label="Velocity > recomended"
													value={ routinePlan?.velocity?.recomended || ""}
													onChange={handleChangeVelocity("recomended")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`accelerated`}
													label="Velocity > accelerated"
													value={ routinePlan?.velocity?.accelerated || ""}
													onChange={handleChangeVelocity("accelerated")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`less29`}
													label="Age > less than 29"
													value={ routinePlan?.age?.less29 || ""}
													onChange={handleChangeAge("less29")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`less39`}
													label="Age > less than 39"
													value={ routinePlan?.age?.less39 || ""}
													onChange={handleChangeAge("less39")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`less49`}
													label="Age > less than 49"
													value={ routinePlan?.age?.less49 || ""}
													onChange={handleChangeAge("less49")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`more50`}
													label="Age > more than 50"
													value={ routinePlan?.age?.more50 || ""}
													onChange={handleChangeAge("more50")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`femenine`}
													label="Gender > femenine"
													value={ routinePlan?.gender?.femenine || ""}
													onChange={handleChangeGender("femenine")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`masculine`}
													label="Gender > masculine"
													value={ routinePlan?.gender?.masculine || ""}
													onChange={handleChangeGender("masculine")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`other`}
													label="Gender > other"
													value={ routinePlan?.gender?.other || ""}
													onChange={handleChangeGender("other")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`low`}
													label="IMC > low"
													value={ routinePlan?.imc?.low || ""}
													onChange={handleChangeImc("low")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`normal`}
													label="IMC > normal"
													value={ routinePlan?.imc?.normal || ""}
													onChange={handleChangeImc("normal")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`high`}
													label="IMC > high"
													value={ routinePlan?.imc?.high || ""}
													onChange={handleChangeImc("high")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`veryHigh`}
													label="IMC > very high"
													value={ routinePlan?.imc?.veryHigh || ""}
													onChange={handleChangeImc("veryHigh")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<br />
								<FormControlLabel
									control={
										<Checkbox
											checked={routinePlan.active}
											onChange={() =>
												setRoutinePlan({
													...routinePlan,
													active: !routinePlan.active,
												})
											}
											name="checkActive"
										/>
									}
									label="Active"
								/>
								{user.role.includes("admin") && (
									<>
										<FormControlLabel
											control={
												<Checkbox
													checked={
														routinePlan.premium
													}
													onChange={() =>
														setRoutinePlan({
															...routinePlan,
															premium: !routinePlan.premium,
															price: 0,
														})
													}
													name="checkPremium"
												/>
											}
											label="Premium"
										/>
										<br />
										{routinePlan.premium && (
											<TextField
												id={`price`}
												label={`Price (€)`}
												value={routinePlan.price || ""}
												onChange={handleChange("price")}
												InputLabelProps={{
													shrink: true,
												}}
												margin="normal"
												variant="outlined"
												type="number"
												InputProps={{
													inputProps: {
														min: 0,
													},
												}}
											/>
										)}
									</>
								)}
								<br />
								<TextField
									id={`daysPerWeek`}
									label="Days per week"
									value={routinePlan.daysPerWeek}
									onChange={handleChange("daysPerWeek")}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											min: 1,
											max: 7,
											step: 1,
											onKeyDown: (event) => {
												event.preventDefault();
											},
										},
									}}
									margin="normal"
									variant="outlined"
									type="number"
								/>
								<br />
								<br />
								<DaysTabBlock
									daysTabContent={renderDaysTabContent}
									days={days}
									subtitles={routinePlan.routines?.map(
										(item) => item.title
									)}
								/>
							</CardBody>
						</>
					) : (
						<>
							<CardHeader
								title={"View routine plan " + routinePlan.title}
							/>
							<CardBody>
								<PlansContent
									type="training"
									plan={{
										...routinePlan,
										type: getNonEmpty(
											types?.find(
												(item) =>
													item._id ===
													routinePlan.type
											)?.fullName
										),
										standard: !routinePlan.trainer,
									}}
									user={user}
									routines={routines}
								/>
							</CardBody>
						</>
					)}
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/training/routine-plans")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					{(!routinePlanId ||
						user?.role.includes("admin") ||
						(user?.role.includes("trainer") &&
							routinePlan.trainer === user?._id)) && (
						<>
							<Button
								onClick={() => {
									if (routinePlan.used)
										setOpenConfirmDialog(1);
									else saveRoutinePlan();
								}}
								variant="outlined"
								color="primary"
								style={{ marginRight: "20px" }}
							>
								Save routine plan
							</Button>
							<ConfirmDialog
								title={
									"This routine plan is already associated to some users, are you sure you want to modify it?"
								}
								open={openConfirmDialog === 1}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => saveRoutinePlan()}
							/>
						</>
					)}
					{routinePlanId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(2)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete routine plan
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this routine plan?"
								}
								open={openConfirmDialog === 2}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteRoutinePlan(routinePlanId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Routine plan deleted successfully",
												});
												history.push(
													"/training/routine-plans"
												);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete routine plan.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
