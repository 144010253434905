import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import DaysTabBlock from "../DaysTabBlock";
import { MEALS_AND_ID, getNonEmpty } from "../../../utils/helpers";
import {
  AddShoppingCart,
  ExpandLess,
  ExpandMore,
  HighlightOff,
  Visibility,
} from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import { TextField, Tooltip } from "@material-ui/core";
import TableDialog from "./TableDialog";
import { getMeals } from "../../../api/meal";
import { getFoodPlates } from "../../../api/foodPlate";
import { alertError } from "../../../utils/logger";

function getFoodPlateData(foodPlates) {
  let data = [];
  for (let i = 0; i < foodPlates.length; ++i) {
    const elem = {};

    elem.description = getNonEmpty(foodPlates[i].description);
    elem.meals = foodPlates[i]?.meals
      ?.map((x) => getNonEmpty(x?.fullName))
      ?.join(", ");
    elem.dietTypes = foodPlates[i]?.dietTypes
      ?.map((x) => getNonEmpty(x?.fullName))
      ?.join(", ");
    elem.calories = foodPlates[i].calories;
    elem.imageURL = foodPlates[i].imageURL;
    elem.createdAt = foodPlates[i].createdAt;
    elem._id = foodPlates[i]._id;

    data = data.concat(elem);
  }
  return data;
}

const weekdays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const EditPersonalizedNutritionalPlanDialog = (props) => {
  const { title, open, src, setOpen, text, plan, setPlan } = props;
  const [nutritionalPlan, setNutritionalPlan] = useState(plan);
  const [foodPlates, setFoodPlates] = useState([]);
  const [meals, setMeals] = useState([]);
  const [showOldPlanStructure, setShowOldPlanStructure] = useState(false);
  const [showDietTypes, setShowDietTypes] = useState(false);
  const [openFoodTableDialog, setOpenFoodTableDialog] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState({
    meal: "",
    submeal: "",
    mealId: "",
  });
  const history = useHistory();

  const handleChange = (element, day, subelement, value) => (event) => {
    let newValue = event.target.value;
    if (element === "totalCaloriesPlan") {
      for (const day of Object.keys(nutritionalPlan.plan)) {
        if (
          nutritionalPlan.plan[day].totalCalories &&
          nutritionalPlan.plan[day].totalCalories !== ""
        )
          return alertError({
            error: null,
            customMessage:
              "The total calories of the plan are automatically calculated based on the calories of each dish.",
          });
      }
    }
    if (day) {
      if (newValue === " ") return;
      let newText = nutritionalPlan.plan;
      if (subelement) newText[day][element][subelement] = newValue;
      else newText[day][element] = newValue;
      setNutritionalPlan({ ...nutritionalPlan, plan: newText });
    } else
      setNutritionalPlan({
        ...nutritionalPlan,
        [element]: newValue,
      });
  };

  useEffect(() => {
    getMeals()
      .then((res) => {
        if (res.status === 200) {
          setMeals(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get food meals.",
        });
        history.push("/nutritional-plans");
      });
    getFoodPlates()
      .then((res) => {
        if (res.status === 200) {
          setFoodPlates(getFoodPlateData(res.data));
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get food plates.",
        });
        history.push("/nutritional-plans");
      });
  }, []);

  const renderDaysTabContent = (day) => {
    const dayLabel = day.charAt(0).toUpperCase() + day.slice(1);
    const getPlateDescription = (meal, subMeal) => {
      const item = subMeal
        ? nutritionalPlan.plan?.[day]?.[meal]?.[subMeal]
        : nutritionalPlan.plan?.[day]?.[meal];

      if (item?.foodPlate) {
        const foodPlate = foodPlates?.find((x) => x._id === item.foodPlate._id);
        if (foodPlate) {
          const description = foodPlate.description || "";
          const dietTypes = foodPlate.dietTypes || "";
          return `${description}${showDietTypes ? " (" + dietTypes + ")" : ""}`;
        }
      }
      return "";
    };

    return (
      <>
        <br />
        <br />
        <h5>Breakfast</h5>
        <TextField
          id={`${dayLabel} food plate`}
          label={getNonEmpty(
            meals?.find((x) => x.internalId === MEALS_AND_ID.BREAKFAST)
              ?.fullName
          )}
          value={
            nutritionalPlan.plan &&
            nutritionalPlan.plan[day] &&
            nutritionalPlan.plan[day].breakfast &&
            nutritionalPlan.plan[day].breakfast.foodPlate
              ? getPlateDescription("breakfast")
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Select food plate"
          margin="normal"
          variant="outlined"
          inputProps={{ readOnly: true }}
          InputProps={
            ({ readOnly: true },
            {
              endAdornment: (
                <>
                  {nutritionalPlan.plan[day].breakfast.foodPlate && (
                    <>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            window.open(
                              "/edit-plate/" +
                                nutritionalPlan.plan[day].breakfast.foodPlate
                                  ._id,
                              "_blank"
                            );
                          }}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            let newPlan = nutritionalPlan;
                            newPlan.plan[day].breakfast.foodPlate = null;
                            newPlan.plan[day].breakfast.meal = null;

                            newPlan.plan[
                              day
                            ].totalCalories = calculateTotalCalories(
                              newPlan,
                              day
                            );
                            newPlan.totalCaloriesPlan = calculateTotalCalories(
                              newPlan
                            );

                            setNutritionalPlan({
                              ...nutritionalPlan,
                              plan: newPlan.plan,
                            });
                          }}
                        >
                          <HighlightOff />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Select food plate">
                    <Button
                      onClick={() => {
                        setSelectedMeal({
                          meal: "breakfast",
                          mealId: meals.find(
                            (x) => x.internalId === MEALS_AND_ID.BREAKFAST
                          )?._id,
                        });
                        setOpenFoodTableDialog(true);
                      }}
                    >
                      <AddShoppingCart />
                    </Button>
                  </Tooltip>
                </>
              ),
            })
          }
        />
        <br />
        <br />
        <br />
        <h5>Lunch</h5>
        <TextField
          id={`${dayLabel} food plate`}
          label={getNonEmpty(
            meals?.find((x) => x.internalId === MEALS_AND_ID.LUNCH1)?.fullName
          )}
          value={
            nutritionalPlan.plan &&
            nutritionalPlan.plan[day] &&
            nutritionalPlan.plan[day].lunch &&
            nutritionalPlan.plan[day].lunch?.lunch1?.foodPlate
              ? getPlateDescription("lunch", "lunch1")
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Select food plate"
          margin="normal"
          variant="outlined"
          inputProps={{ readOnly: true }}
          InputProps={
            ({ readOnly: true },
            {
              endAdornment: (
                <>
                  {nutritionalPlan.plan[day].lunch?.lunch1?.foodPlate && (
                    <>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            window.open(
                              "/edit-plate/" +
                                nutritionalPlan.plan[day].lunch?.lunch1
                                  ?.foodPlate._id,
                              "_blank"
                            );
                          }}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            let newPlan = nutritionalPlan;
                            newPlan.plan[day].lunch.lunch1.foodPlate = null;
                            newPlan.plan[day].lunch.lunch1.meal = null;

                            setNutritionalPlan({
                              ...nutritionalPlan,
                              plan: newPlan.plan,
                            });
                          }}
                        >
                          <HighlightOff />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Select food plate">
                    <Button
                      onClick={() => {
                        setSelectedMeal({
                          meal: "lunch",
                          submeal: "lunch1",
                          mealId: meals.find(
                            (x) => x.internalId === MEALS_AND_ID.LUNCH1
                          )?._id,
                        });
                        setOpenFoodTableDialog(true);
                      }}
                    >
                      <AddShoppingCart />
                    </Button>
                  </Tooltip>
                </>
              ),
            })
          }
        />
        <TextField
          id={`${dayLabel} food plate`}
          label={getNonEmpty(
            meals?.find((x) => x.internalId === MEALS_AND_ID.LUNCH2)?.fullName
          )}
          value={
            nutritionalPlan.plan &&
            nutritionalPlan.plan[day] &&
            nutritionalPlan.plan[day].lunch &&
            nutritionalPlan.plan[day].lunch?.lunch2?.foodPlate
              ? getPlateDescription("lunch", "lunch2")
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Select food plate"
          margin="normal"
          variant="outlined"
          inputProps={{ readOnly: true }}
          InputProps={
            ({ readOnly: true },
            {
              endAdornment: (
                <>
                  {nutritionalPlan.plan[day].lunch?.lunch2?.foodPlate && (
                    <>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            window.open(
                              "/edit-plate/" +
                                nutritionalPlan.plan[day].lunch?.lunch2
                                  ?.foodPlate._id,
                              "_blank"
                            );
                          }}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            let newPlan = nutritionalPlan;
                            newPlan.plan[day].lunch.lunch2.foodPlate = null;
                            newPlan.plan[day].lunch.lunch2.meal = null;

                            setNutritionalPlan({
                              ...nutritionalPlan,
                              plan: newPlan.plan,
                            });
                          }}
                        >
                          <HighlightOff />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Select food plate">
                    <Button
                      onClick={() => {
                        setSelectedMeal({
                          meal: "lunch",
                          submeal: "lunch2",
                          mealId: meals.find(
                            (x) => x.internalId === MEALS_AND_ID.LUNCH2
                          )?._id,
                        });
                        setOpenFoodTableDialog(true);
                      }}
                    >
                      <AddShoppingCart />
                    </Button>
                  </Tooltip>
                </>
              ),
            })
          }
        />
        <TextField
          id={`${dayLabel} food plate`}
          label={getNonEmpty(
            meals?.find((x) => x.internalId === MEALS_AND_ID.LUNCH_DESERT)
              ?.fullName
          )}
          value={
            nutritionalPlan.plan &&
            nutritionalPlan.plan[day] &&
            nutritionalPlan.plan[day].lunch &&
            nutritionalPlan.plan[day].lunch?.lunchDesert?.foodPlate
              ? getPlateDescription("lunch", "lunchDesert")
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Select food plate"
          margin="normal"
          variant="outlined"
          inputProps={{
            readOnly: true,
          }}
          InputProps={
            ({ readOnly: true },
            {
              endAdornment: (
                <>
                  {nutritionalPlan.plan[day].lunch?.lunchDesert?.foodPlate && (
                    <>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            window.open(
                              "/edit-plate/" +
                                nutritionalPlan.plan[day].lunch?.lunchDesert
                                  ?.foodPlate._id,
                              "_blank"
                            );
                          }}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            let newPlan = nutritionalPlan;
                            newPlan.plan[
                              day
                            ].lunch.lunchDesert.foodPlate = null;
                            newPlan.plan[day].lunch.lunchDesert.meal = null;

                            setNutritionalPlan({
                              ...nutritionalPlan,
                              plan: newPlan.plan,
                            });
                          }}
                        >
                          <HighlightOff />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Select food plate">
                    <Button
                      onClick={() => {
                        setSelectedMeal({
                          meal: "lunch",
                          submeal: "lunchDesert",
                          mealId: meals.find(
                            (x) => x.internalId === MEALS_AND_ID.LUNCH_DESERT
                          )?._id,
                        });
                        setOpenFoodTableDialog(true);
                      }}
                    >
                      <AddShoppingCart />
                    </Button>
                  </Tooltip>
                </>
              ),
            })
          }
        />
        <br />
        <br />
        <br />
        <h5>Afternoon</h5>
        <TextField
          id={`${dayLabel} food plate`}
          label={getNonEmpty(
            meals?.find((x) => x.internalId === MEALS_AND_ID.AFTERNOON)
              ?.fullName
          )}
          value={
            nutritionalPlan.plan &&
            nutritionalPlan.plan[day] &&
            nutritionalPlan.plan[day].afternoon &&
            nutritionalPlan.plan[day].afternoon?.foodPlate
              ? getPlateDescription("afternoon")
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Select food plate"
          margin="normal"
          variant="outlined"
          inputProps={{ readOnly: true }}
          InputProps={
            ({ readOnly: true },
            {
              endAdornment: (
                <>
                  {nutritionalPlan.plan[day].afternoon?.foodPlate && (
                    <>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            window.open(
                              "/edit-plate/" +
                                nutritionalPlan.plan[day].afternoon?.foodPlate
                                  ._id,
                              "_blank"
                            );
                          }}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            let newPlan = nutritionalPlan;
                            newPlan.plan[day].afternoon.foodPlate = null;
                            newPlan.plan[day].afternoon.meal = null;

                            setNutritionalPlan({
                              ...nutritionalPlan,
                              plan: newPlan.plan,
                            });
                          }}
                        >
                          <HighlightOff />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Select food plate">
                    <Button
                      onClick={() => {
                        setSelectedMeal({
                          meal: "afternoon",
                          mealId: meals.find(
                            (x) => x.internalId === MEALS_AND_ID.AFTERNOON
                          )?._id,
                        });
                        setOpenFoodTableDialog(true);
                      }}
                    >
                      <AddShoppingCart />
                    </Button>
                  </Tooltip>
                </>
              ),
            })
          }
        />
        <br />
        <br />
        <br />
        <h5>Dinner</h5>
        <TextField
          id={`${dayLabel} food plate`}
          label={getNonEmpty(
            meals?.find((x) => x.internalId === MEALS_AND_ID.DINNER1)?.fullName
          )}
          value={
            nutritionalPlan.plan &&
            nutritionalPlan.plan[day] &&
            nutritionalPlan.plan[day].dinner &&
            nutritionalPlan.plan[day].dinner?.dinner1?.foodPlate._id
              ? getPlateDescription("dinner", "dinner1")
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Select food plate"
          margin="normal"
          variant="outlined"
          inputProps={{ readOnly: true }}
          InputProps={
            ({ readOnly: true },
            {
              endAdornment: (
                <>
                  {nutritionalPlan.plan[day].dinner?.dinner1?.foodPlate && (
                    <>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            window.open(
                              "/edit-plate/" +
                                nutritionalPlan.plan[day].dinner?.dinner1
                                  ?.foodPlate._id,
                              "_blank"
                            );
                          }}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            let newPlan = nutritionalPlan;
                            newPlan.plan[day].dinner.dinner1.foodPlate = null;
                            newPlan.plan[day].dinner.dinner1.meal = null;

                            setNutritionalPlan({
                              ...nutritionalPlan,
                              plan: newPlan.plan,
                            });
                          }}
                        >
                          <HighlightOff />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Select food plate">
                    <Button
                      onClick={() => {
                        setSelectedMeal({
                          meal: "dinner",
                          submeal: "dinner1",
                          mealId: meals.find(
                            (x) => x.internalId === MEALS_AND_ID.DINNER1
                          )?._id,
                        });
                        setOpenFoodTableDialog(true);
                      }}
                    >
                      <AddShoppingCart />
                    </Button>
                  </Tooltip>
                </>
              ),
            })
          }
        />
        <TextField
          id={`${dayLabel} food plate`}
          label={getNonEmpty(
            meals?.find((x) => x.internalId === MEALS_AND_ID.DINNER2)?.fullName
          )}
          value={
            nutritionalPlan.plan &&
            nutritionalPlan.plan[day] &&
            nutritionalPlan.plan[day].dinner &&
            nutritionalPlan.plan[day].dinner?.dinner2?.foodPlate
              ? getPlateDescription("dinner", "dinner2")
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Select food plate"
          margin="normal"
          variant="outlined"
          inputProps={{ readOnly: true }}
          InputProps={
            ({ readOnly: true },
            {
              endAdornment: (
                <>
                  {nutritionalPlan.plan[day].dinner?.dinner2?.foodPlate && (
                    <>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            window.open(
                              "/edit-plate/" +
                                nutritionalPlan.plan[day].dinner?.dinner2
                                  ?.foodPlate._id,
                              "_blank"
                            );
                          }}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            let newPlan = nutritionalPlan;
                            newPlan.plan[day].dinner.dinner2.foodPlate = null;
                            newPlan.plan[day].dinner.dinner2.meal = null;

                            setNutritionalPlan({
                              ...nutritionalPlan,
                              plan: newPlan.plan,
                            });
                          }}
                        >
                          <HighlightOff />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Select food plate">
                    <Button
                      onClick={() => {
                        setSelectedMeal({
                          meal: "dinner",
                          submeal: "dinner2",
                          mealId: meals.find(
                            (x) => x.internalId === MEALS_AND_ID.DINNER2
                          )?._id,
                        });
                        setOpenFoodTableDialog(true);
                      }}
                    >
                      <AddShoppingCart />
                    </Button>
                  </Tooltip>
                </>
              ),
            })
          }
        />
        <TextField
          id={`${dayLabel} food plate`}
          label={getNonEmpty(
            meals?.find((x) => x.internalId === MEALS_AND_ID.DINNER_DESERT)
              ?.fullName
          )}
          value={
            nutritionalPlan.plan &&
            nutritionalPlan.plan[day] &&
            nutritionalPlan.plan[day].dinner &&
            nutritionalPlan.plan[day].dinner?.dinnerDesert?.foodPlate
              ? getPlateDescription("dinner", "dinnerDesert")
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Select food plate"
          margin="normal"
          variant="outlined"
          inputProps={{ readOnly: true }}
          InputProps={
            ({ readOnly: true },
            {
              endAdornment: (
                <>
                  {nutritionalPlan.plan[day].dinner?.dinnerDesert
                    ?.foodPlate && (
                    <>
                      <Tooltip title="View">
                        <Button
                          onClick={() => {
                            window.open(
                              "/edit-plate/" +
                                nutritionalPlan.plan[day].dinner?.dinnerDesert
                                  ?.foodPlate._id,
                              "_blank"
                            );
                          }}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Button
                          onClick={() => {
                            let newPlan = nutritionalPlan;
                            newPlan.plan[
                              day
                            ].dinner.dinnerDesert.foodPlate = null;
                            newPlan.plan[day].dinner.dinnerDesert.meal = null;

                            setNutritionalPlan({
                              ...nutritionalPlan,
                              plan: newPlan.plan,
                            });
                          }}
                        >
                          <HighlightOff />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Select food plate">
                    <Button
                      onClick={() => {
                        setSelectedMeal({
                          meal: "dinner",
                          submeal: "dinnerDesert",
                          mealId: meals.find(
                            (x) => x.internalId === MEALS_AND_ID.DINNER_DESERT
                          )?._id,
                        });
                        setOpenFoodTableDialog(true);
                      }}
                    >
                      <AddShoppingCart />
                    </Button>
                  </Tooltip>
                </>
              ),
            })
          }
        />
        <br />
        <br />
        <br />
        <div className="d-flex justify-content-between">
          <h4>Older versions</h4>
          <div>
            <Tooltip title={showOldPlanStructure ? "See less" : "See more"}>
              <Button
                onClick={() => setShowOldPlanStructure(!showOldPlanStructure)}
              >
                {showOldPlanStructure ? <ExpandLess /> : <ExpandMore />}
              </Button>
            </Tooltip>
          </div>
        </div>
        <br />
        <br />
        {showOldPlanStructure && (
          <>
            <h5>Breakfast</h5>
            <TextField
              id={`${dayLabel} breakfast description`}
              label={`Description`}
              placeholder={`Enter ${day} breakfast description here...`}
              value={
                (nutritionalPlan.plan &&
                  nutritionalPlan.plan[day] &&
                  nutritionalPlan.plan[day].breakfast &&
                  nutritionalPlan.plan[day].breakfast.description) ||
                ""
              }
              onChange={handleChange("breakfast", day, "description")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              multiline
              variant="outlined"
              rows={6}
              required
            />
            <TextField
              id={`${dayLabel} breakfast calories`}
              label={`Calories`}
              value={
                (nutritionalPlan.plan &&
                  nutritionalPlan.plan[day] &&
                  nutritionalPlan.plan[day].breakfast &&
                  nutritionalPlan.plan[day].breakfast.calories) ||
                ""
              }
              //onChange={handleChangeCalories("breakfast", day)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ readOnly: true }}
              margin="normal"
              variant="outlined"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
            <br />
            <br />
            <br />
            <h5>Lunch</h5>
            <TextField
              id={`${dayLabel} lunch description`}
              label={`Description`}
              placeholder={`Enter ${day} lunch description here...`}
              value={
                (nutritionalPlan.plan &&
                  nutritionalPlan.plan[day] &&
                  nutritionalPlan.plan[day].lunch &&
                  nutritionalPlan.plan[day].lunch.description) ||
                ""
              }
              onChange={handleChange("lunch", day, "description")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              multiline
              variant="outlined"
              rows={6}
              required
            />
            <TextField
              id={`${dayLabel} lunch calories`}
              label={`Calories`}
              value={
                (nutritionalPlan.plan &&
                  nutritionalPlan.plan[day] &&
                  nutritionalPlan.plan[day].lunch &&
                  nutritionalPlan.plan[day].lunch.calories) ||
                ""
              }
              //onChange={handleChangeCalories("lunch", day)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ readOnly: true }}
              margin="normal"
              variant="outlined"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
            <br />
            <br />
            <br />
            <h5>Afternoon</h5>
            <TextField
              id={`${dayLabel} afternoon snack description`}
              label={`Description`}
              placeholder={`Enter ${day} afternoon description here...`}
              value={
                (nutritionalPlan.plan &&
                  nutritionalPlan.plan[day] &&
                  nutritionalPlan.plan[day].afternoon &&
                  nutritionalPlan.plan[day].afternoon.description) ||
                ""
              }
              onChange={handleChange("afternoon", day, "description")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              multiline
              variant="outlined"
              rows={6}
              required
            />
            <TextField
              id={`${dayLabel} afternoon snack calories`}
              label={`Calories`}
              value={
                (nutritionalPlan.plan &&
                  nutritionalPlan.plan[day] &&
                  nutritionalPlan.plan[day].afternoon &&
                  nutritionalPlan.plan[day].afternoon.calories) ||
                ""
              }
              //onChange={handleChangeCalories("afternoon", day)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ readOnly: true }}
              margin="normal"
              variant="outlined"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
            <br />
            <br />
            <br />
            <h5>Dinner</h5>
            <TextField
              id={`${dayLabel} dinner description`}
              label={`Description`}
              placeholder={`Enter ${day} dinner description here...`}
              value={
                (nutritionalPlan.plan &&
                  nutritionalPlan.plan[day] &&
                  nutritionalPlan.plan[day].dinner &&
                  nutritionalPlan.plan[day].dinner.description) ||
                ""
              }
              onChange={handleChange("dinner", day, "description")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              multiline
              variant="outlined"
              rows={6}
              required
            />
            <TextField
              id={`${dayLabel} dinner calories`}
              label={`Calories`}
              value={
                (nutritionalPlan.plan &&
                  nutritionalPlan.plan[day] &&
                  nutritionalPlan.plan[day].dinner &&
                  nutritionalPlan.plan[day].dinner.calories) ||
                ""
              }
              //onChange={handleChangeCalories("dinner", day)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ readOnly: true }}
              margin="normal"
              variant="outlined"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
          </>
        )}
        <CardFooter className="px-0 mx-0">
          <TextField
            id={`${dayLabel} total calories`}
            label={`Total calories ${day}`}
            value={
              (nutritionalPlan.plan &&
                nutritionalPlan.plan[day] &&
                nutritionalPlan.plan[day].totalCalories) ||
              ""
            }
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ readOnly: true }}
            margin="normal"
            variant="outlined"
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
          />
        </CardFooter>
        <TableDialog
          open={openFoodTableDialog}
          setOpen={setOpenFoodTableDialog}
          data={foodPlates}
          foodPlatesTable={true}
          onSelectRow={(foodPlateId) => {
            const foodPlate = foodPlates?.find((x) => x._id === foodPlateId);
            const { meal, submeal, mealId } = selectedMeal;
            const mealInfo = meals.find((x) => x._id === mealId);

            let newPlan = nutritionalPlan;

            if (submeal) {
              if (!newPlan.plan[day][meal][submeal]) {
                newPlan.plan[day][meal][submeal] = {};
              }

              newPlan.plan[day][meal][submeal].foodPlate = foodPlate;
              newPlan.plan[day][meal][submeal].meal = mealInfo;
            } else {
              newPlan.plan[day][meal].foodPlate = foodPlate;
              newPlan.plan[day][meal].meal = mealInfo;
            }

            newPlan.plan[day].totalCalories = calculateTotalCalories(
              newPlan,
              day
            );

            newPlan.totalCaloriesPlan = calculateTotalCalories(newPlan);

            setNutritionalPlan({
              ...nutritionalPlan,
              plan: newPlan.plan,
            });

            setOpenFoodTableDialog(false);
          }}
        />
      </>
    );
  };

  useEffect(() => {
    console.log("NUTRITIONAL PLAN:", nutritionalPlan);
  }, [nutritionalPlan]);

  function calculateTotalCalories(nutritionalPlan, day) {
    function calculateCaloriesOfDay(day) {
      let result = 0;

      Object.keys(nutritionalPlan.plan[day]).forEach((mealLabel) => {
        let mealCalorieSum = 0;
        console.log(mealLabel, nutritionalPlan.plan[day][mealLabel]);

        let item = nutritionalPlan.plan[day][mealLabel];

        const calories = item?.foodPlate
          ? foodPlates.find((x) => x._id === item?.foodPlate._id)?.calories
          : item?.calories
          ? item?.calories
          : 0;

        mealCalorieSum = calories;

        let subMealCalorieSum = 0;

        Object.keys(item).forEach((subMealLabel) => {
          let subMealElem = nutritionalPlan.plan[day][mealLabel][subMealLabel];

          const calories = subMealElem?.foodPlate
            ? foodPlates.find((x) => x._id === subMealElem?.foodPlate._id)
                ?.calories
            : 0;
          subMealCalorieSum += calories;
        });

        if (item?.calories && subMealCalorieSum != 0)
          mealCalorieSum = subMealCalorieSum;

        result += mealCalorieSum;
      });
      return result;
    }
    console.log(nutritionalPlan, day);
    let result = 0;

    if (day) {
      result = calculateCaloriesOfDay(day);
    } else {
      Object.keys(nutritionalPlan.plan).forEach((dayLabel) => {
        result += calculateCaloriesOfDay(dayLabel);
      });
    }
    console.log(result);
    return result;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {text ? (
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
          ) : (
            <embed src={src} height="100%" width="100%" />
          )}
        </div>
        {plan && (
          <DaysTabBlock daysTabContent={renderDaysTabContent} days={weekdays} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setPlan(nutritionalPlan);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
