import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React from "react";
import ChatBox from "../chat/ChatBox";

const ConversationDialog = (props) => {
	const { title, open, setOpen, chat } = props;

	return (
		<Dialog
			fullWidth={true}
			maxWidth="xl"
			open={open}
			onClose={() => setOpen(null)}
			aria-labelledby="view-conversation-dialog"
		>
			<DialogTitle id="view-conversation-dialog">{title}</DialogTitle>
			<DialogContent>
				<ChatBox viewMode={true} />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="default"
					onClick={() => setOpen(null)}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default ConversationDialog;
