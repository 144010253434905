import { Button, Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useState } from "react";
import { SERVER_URL } from "../../../../api";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Table, {
	booleanFormatter,
	buttonsStyle,
} from "../../../components/tables/table";
import ViewAnswerDialog from "../../../components/dialogs/ViewAnswerDialog";

function getAnswerData(answers, lang) {
	let data = [];

	for (let i = 0; i < answers?.length; ++i) {
		const elem = {};
		const answer = answers[i];

		elem.imageURL = answer?.imageURL;
		elem.title = lang ? answer?.title[lang] : answer?.title;
		elem.explanation = lang
			? answer?.explanation[lang]
			: answer?.explanation;
		elem.correct = answer?.correct;
		elem.id = i;

		data.push(elem);
	}

	return data;
}

const QuestionContent = (props) => {
	const { question, language } = props;
	const [openPreviewDialog, setOpenPreviewDialog] = useState(null);
	const [openViewAnswerDialog, setOpenViewAnswerDialog] = useState(null);
	const [selectedAnswer, setSelectedAnswer] = useState(null);

	function imageFormatter(imageURL) {
		return imageURL && imageURL !== "" ? (
			<img
				src={`${SERVER_URL}/${imageURL}`}
				alt="answer"
				style={{ width: "50px", height: "50px" }}
				onClick={() => {}}
			/>
		) : (
			<div />
		);
	}

	function buttonFormatter(cell) {
		const answers = getAnswerData(question?.answers);
		const answer = answers?.find((x) => x.id === cell);

		console.log("CELL", cell, answers, answer);

		return (
			<>
				<Tooltip title="View">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenViewAnswerDialog(true);
							setSelectedAnswer(answer);
						}}
					>
						<Visibility />
					</Button>
				</Tooltip>
			</>
		);
	}

	const answerColumns = [
		{
			dataField: "imageURL",
			text: "",
			formatter: imageFormatter,
			headerAlign: "center",
			align: "center",
		},
		{ dataField: "title", text: "Title" },
		{
			dataField: "correct",
			text: "Correct",
			headerAlign: "center",
			align: "center",
			formatter: booleanFormatter,
		},
		{
			dataField: "id",
			text: "",
			formatter: buttonFormatter,
		},
	];

	const renderContentOfLanguage = (lang) => {
		return (
			<>
				<h5>Statement</h5>
				{question?.statement[lang] || "---"}
				<br />
				<br />
				<h5>Long Statement</h5>
				<div
					dangerouslySetInnerHTML={{
						__html: question?.longStatement[lang] || "---",
					}}
				></div>
				<br />
				<br />
				{question?.imageURL && question?.imageURL !== "" && (
					<>
						<img
							src={SERVER_URL + "/" + question?.imageURL}
							alt={question?.imageURL.split(/-(.*)/s)[1]}
							style={{ maxWidth: "200px", cursor: "zoom-in" }}
							onClick={() => setOpenPreviewDialog("main")}
						/>
						<PreviewDialog
							open={openPreviewDialog === "main"}
							setOpen={setOpenPreviewDialog}
							src={SERVER_URL + "/" + question?.imageURL}
						/>
					</>
				)}
				<br />
				<br />
				<br />
				<h5>Answers</h5>
				<div
					className="my-5"
					style={{
						width: "100%",
						height: "1px",
						backgroundColor: "#EBEDF3",
					}}
				></div>
				<Table
					columns={answerColumns}
					data={getAnswerData(question?.answers, lang)}
					hideSearchBar={true}
				></Table>
			</>
		);
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				{renderContentOfLanguage(lang)}
			</>
		);
	};

	if (!question) return <></>;
	return (
		<>
			{language ? (
				renderContentOfLanguage(language)
			) : (
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
			)}
			<br />
			<br />
			<br />
			<FormControlLabel
				control={
					<Checkbox checked={question?.active} name="checkActive" />
				}
				label="Active"
			/>
			<ViewAnswerDialog
				open={openViewAnswerDialog}
				setOpen={setOpenViewAnswerDialog}
				answer={selectedAnswer}
			/>
		</>
	);
};

export default QuestionContent;
