import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const postStandardChallenge = async (standardChallenge, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "standardChallenges");
		standardChallenge.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/standard-challenge`, standardChallenge);
};

export const getStandardChallenges = () => {
	return authClient().get(`${API}/standard-challenge?getDisabled=true`);
};

export const getStandardChallengeById = (id) => {
	return authClient().get(`${API}/standard-challenge/${id}`);
};

export const updateStandardChallenge = async (id, standardChallenge, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "standardChallenges");
		standardChallenge.imageURL = response.data.fileURL;
	}
	return authClient().put(
		`${API}/standard-challenge/${id}`,
		standardChallenge
	);
};

export const changeStatusStandardChallenge = async (id, active) => {
	return authClient().put(`${API}/standard-challenge/change-status/${id}`, {
		active,
	});
};

export const deleteStandardChallenge = (id) => {
	return authClient().delete(`${API}/standard-challenge/${id}`);
};
