import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InputEmoji from "react-input-emoji";
import { updateReply } from "../../../api/reply";
import { checkIsEmpty } from "../../../utils/helpers";
import { alertError, alertSuccess } from "../../../utils/logger";

function getEmptyReply() {
	return {
		thread: null,
		text: "test",
		parent: null,
		sender: null,
	};
}

const EditReplyDialog = (props) => {
	const { title, data, open, setOpen, onSave } = props;

	const [reply, setReply] = useState(getEmptyReply());
	const [text, setText] = useState("");

	useEffect(() => {
		if (!open || !data) {
			setReply(getEmptyReply());
			setText("");
		} else {
			setReply(data);
			setText(data?.text);
		}
	}, [data, open]);

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent
				style={{
					height: "50vh",
				}}
			>
				<InputEmoji
					value={text}
					onChange={(message) => {
						if (message?.trim() !== "") {
							setText(message);
						}
					}}
					shouldReturn={true}
				></InputEmoji>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setReply(getEmptyReply());
						setText("");
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (checkIsEmpty(reply?.text)) {
							alertError({
								error: null,
								customMessage:
									"The text is required in at least one of the languages",
							});
						} else {
							let saveReply = { ...reply, text: text };
							console.log("save", saveReply);

							updateReply(saveReply?._id, saveReply)
								.then((res) => {
									if (res.status === 200) {
										setOpen(false);
										onSave();
										setReply(getEmptyReply());
										alertSuccess({
											title: "Saved!",
											customMessage:
												"Changes successfully saved.",
										});
										setText("");
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not save changes.",
									});
								});
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditReplyDialog;
