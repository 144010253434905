export const UPDATE_ONLINE_USERS = 'UPDATE_ONLINE_USERS';
export const UPDATE_CHATS = 'UPDATE_CHATS';
export const UPDATE_CURRENT_CHAT = 'UPDATE_CURRENT_CHAT';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const READ_MESSAGES = 'READ_MESSAGES';

export function getOnlineUsers(list) {
	//console.log('NEW ONLINE USERS: ', list);
	return {
		type: UPDATE_ONLINE_USERS,
		list,
	};
}

export function updateChats(chats) {
	//console.log('NEW CHATS USER: ', chats);
	return {
		type: UPDATE_CHATS,
		chats,
	};
}

export function updateCurrentChat(chat, messages) {
	//console.log('NEW CURRENT CHAT', chat, messages);
	return {
		type: UPDATE_CURRENT_CHAT,
		data: { chat, messages },
	};
}

export function addMessage(message) {
	//console.log('NEW MESSAGE', message);
	return {
		type: NEW_MESSAGE,
		message,
	};
}

export function addNotifications(chat) {
	//console.log('ADD NOTIFICATIONS CHAT', chat);
	return {
		type: ADD_NOTIFICATIONS,
		chat,
	};
}

export function setNotifications(notifications) {
	//console.log('SET NOTIFICATIONS CHATS', notifications);
	return {
		type: SET_NOTIFICATIONS,
		notifications,
	};
}


export function readMessages(chat) {
	//console.log('READ MESSAGES CHAT', chat);
	return {
		type: READ_MESSAGES,
		chat,
	};
}

