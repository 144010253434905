import { API, authClient } from '../index';
import { uploadSingleFile } from '../files';

// Get all equipments
export const getEquipments = () => {
	return authClient().get(`${API}/equipment?getDisabled=true`);
};

// Get equipment by id
export const getEquipmentById = (id) => {
	return authClient().get(`${API}/equipment/${id}`);
};

// Delete equipment
export const deleteEquipment = (id) => {
	return authClient().delete(`${API}/equipment/${id}`);
};

// Create equipment 
export const postEquipment = async (equipment, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'equipments')
		equipment.iconURL = response.data.fileURL
	}
	return authClient().post(`${API}/equipment`, equipment);
};

// Update equipment
export const updateEquipment = async (id, equipment, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'equipments')
		equipment.iconURL = response.data.fileURL
	}
	return authClient().put(`${API}/equipment/${id}`, equipment);
};

// Change status equipment
export const changeStatusEquipment = async (id, active) => {
	return authClient().put(`${API}/equipment/change-status/${id}`, { active });
};