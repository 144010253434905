import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Edit } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
	deleteCourse,
	getCourseById,
	postCourse,
	updateCourse,
} from "../../../../api/course";
import { SERVER_URL } from "../../../../api/index";
import { getLanguages } from "../../../../api/language";
import { getLessons } from "../../../../api/lesson";
import { getRoutines } from "../../../../api/routine";
import { getSports } from "../../../../api/sport";
import { getTests } from "../../../../api/test";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import TableDialog from "../../../components/dialogs/TableDialog";
import ViewLessonDialog from "../../../components/dialogs/ViewLessonDialog";
import ViewTestDialog from "../../../components/dialogs/ViewTestDialog";
import Editor from "../../../components/editor/Editor";
import PlansContent from "../../../components/plansContent";
import Table, { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import EditRoutineDialog from "../routines/EditRoutineDialog";
import ViewRoutineDialog from "../routines/ViewRoutineDialog";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

const UNIT_TYPES = { LESSON: "lesson", TEST: "test", ROUTINE: "routine" };

const LEVELS = ["beginner", "intermediate", "advanced"];

function getEmptyCourse() {
	return {
		title: {},
		description: {},
		references: "",
		level: "",
		sport: null,
		language: null,
		units: [],
		trainer: null,
		bannerURL: "",
		premium: false,
		price: 0,
		active: true,
	};
}

function getLessonData(lessons) {
	let data = [];

	for (const lesson of lessons) {
		const elem = {};

		elem.imageURL = lesson?.imageURL;
		elem.title = getNonEmpty(lesson?.title);
		elem.sport = getNonEmpty(lesson?.sport?.fullName);
		elem.equipments = lesson?.equipments
			?.map((x) => getNonEmpty(x?.fullName))
			.join(", ");
		elem._id = lesson?._id;

		data.push(elem);
	}

	return data;
}

function getTestData(tests) {
	let data = [];

	for (const test of tests) {
		const elem = {};

		elem.imageURL = test?.imageURL;
		elem.title = getNonEmpty(test?.title);
		elem.questions = test?.questions;
		elem._id = test?._id;

		data.push(elem);
	}

	return data;
}

export default function EditCoursesPage() {
	const [course, setCourse] = useState(getEmptyCourse());

	const [sports, setSports] = useState([]);
	const [lessons, setLessons] = useState(null);
	const [tests, setTests] = useState([]);
	const [routines, setRoutines] = useState([]);
	const [languages, setLanguages] = useState([]);

	const [openLessonTableDialog, setOpenLessonTableDialog] = useState(false);
	const [openTestTableDialog, setOpenTestTableDialog] = useState(false);
	const [openRoutineTableDialog, setOpenRoutineTableDialog] = useState(false);

	const [selectedUnitId, setSelectedUnitId] = useState(null);
	const [openViewUnitDialog, setOpenViewUnitDialog] = useState(null);
	const [openEditRoutineDialog, setOpenEditRoutineDialog] = useState(null);
	const [selectedRoutine, setSelectedRoutine] = useState(null);

	const [refresh, setRefresh] = useState(false);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [selectedBanner, setSelectedBanner] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

	const courseId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getLessons()
			.then((res) => {
				if (res.status === 200) {
					const _lessons = res.data.map((item) => ({
						...item,
						standard: !item.trainer,
					}));
					setLessons(
						_lessons.filter(
							(item) =>
								item.active === true &&
								(item.trainer === user || item.trainer === null)
						)
					);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get lessons.",
				});
				history.push("/sports/courses");
			});
		getSports()
			.then((res) => {
				if (res.status === 200) {
					setSports(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get sports.",
				});
				history.push("/sports/courses");
			});
		getLanguages()
			.then((res) => {
				if (res.status === 200) {
					setLanguages(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get languages.",
				});
				history.push("/sports/courses");
			});
		getTests()
			.then((res) => {
				if (res.status === 200) {
					setTests(getTestData(res.data));
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get tests.",
				});
				history.push("/sports/courses");
			});
		getRoutines()
			.then((res) => {
				if (res.status === 200) {
					const _routines = res.data.map((item) => ({
						...item,
						standard: !item.trainer,
						muscleGroups: [getNonEmpty(item.muscleGroup?.fullName)]
							.concat(
								item.otherMuscleGroups.map((mg) =>
									getNonEmpty(mg?.fullName)
								)
							)
							.join(", "),
					}));
					setRoutines(
						_routines.filter(
							(item) =>
								item.active === true &&
								(item.trainer === user || item.trainer === null)
						)
					);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get routines.",
				});
				history.push("/sports/courses");
			});
		if (!courseId) {
			disableLoadingData();
			return;
		}
		getCourseById(courseId)
			.then((res) => {
				if (res.status === 200) {
					let course = { ...res.data, sport: res.data.sport?._id };
					course.units.forEach((unit, index) => {
						if (unit.routine) {
							unit.type = UNIT_TYPES.ROUTINE;
							unit.id = unit.routine;
							delete unit?.routine;
						} else if (unit.lesson) {
							unit.type = UNIT_TYPES.LESSON;
							unit.id = unit.lesson;
							delete unit?.lesson;
						} else if (unit.test) {
							unit.type = UNIT_TYPES.TEST;
							unit.id = unit.test;
							delete unit?.test;
						}
					});

					setCourse(course);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get course.",
				});
				history.push("/sports/courses");
			});
	}, [courseId, disableLoadingData, history, user]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	function unitButtonFormatter(cell) {
		const data = getUnitData(course?.units);
		const elem = data.find((item) => item.id === cell);
		const index = course?.units?.findIndex((x) => x.id === cell);
		return (
			<>
				{((user.role.includes("trainer") &&
					(course.trainer === user._id || !course.trainer)) ||
					user.role.includes("admin")) && (
					<>
						<Tooltip title={"View"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setOpenViewUnitDialog(elem.type);
									setSelectedUnitId(cell);
								}}
							>
								{<VisibilityIcon />}
							</Button>
						</Tooltip>
						{elem?.type === UNIT_TYPES.ROUTINE && (
							<Tooltip title={"Edit"}>
								<Button
									style={buttonsStyle}
									size="small"
									onClick={() => {
										let routine = course.units.find(
											(x) => x.id === cell
										);
										setOpenEditRoutineDialog(true);
										setSelectedRoutine(routine);
									}}
								>
									{<Edit />}
								</Button>
							</Tooltip>
						)}
						<Tooltip title="Move up">
							<Button
								size="small"
								style={buttonsStyle}
								disabled={index === 0}
								onClick={() => handleMoveUnit(index, index - 1)}
							>
								<ArrowUpward />
							</Button>
						</Tooltip>
						<Tooltip title="Move down">
							<Button
								size="small"
								disabled={index >= course.units.length - 1}
								style={buttonsStyle}
								onClick={() => handleMoveUnit(index, index + 1)}
							>
								<ArrowDownward />
							</Button>
						</Tooltip>
						<Tooltip title={"Delete"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									let _units = [...course.units];
									_units.splice(index, 1);
									setCourse({
										...course,
										units: _units,
									});
									setRefresh(true);
								}}
							>
								{<DeleteIcon />}
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	function imageFormatter(cell) {
		return cell && cell !== "" ? (
			<img
				src={SERVER_URL + "/" + cell}
				alt="image"
				style={{ width: "50px", height: "50px" }}
				onClick={() => {}}
			/>
		) : (
			<div />
		);
	}

	const unitColumns = [
		{
			dataField: "imageURL",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: imageFormatter,
		},
		{ dataField: "title", text: "Title" },
		{
			dataField: "type",
			text: "Unit",
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "info",
			text: "Info",
			headerAlign: "center",
			align: "center",
		},
		{ dataField: "id", formatter: unitButtonFormatter },
	];

	function getUnitData(units) {
		let data = [];

		for (const unit of units) {
			const elem = {};

			if (unit?.type === UNIT_TYPES.LESSON) {
				elem.title = getNonEmpty(
					lessons.find((x) => x._id === unit.id)?.title
				);
				elem.imageURL = lessons.find(
					(x) => x._id === unit.id
				)?.imageURL;
			} else if (unit?.type === UNIT_TYPES.TEST) {
				elem.title = tests.find((x) => x._id === unit.id)?.title;
				elem.imageURL = tests.find((x) => x._id === unit.id)?.imageURL;
			} else if (unit?.type === UNIT_TYPES.ROUTINE) {
				elem.title = routines.find((x) => x._id === unit.id)?.title;
				elem.imageURL = routines.find(
					(x) => x._id === unit.id
				)?.imageURL;
			}

			elem.type = unit.type;
			elem.info = `${
				unit?.series
					? "Series repetitions:"
					: unit?.nTimes
					? "Number of times:"
					: unit?.time
					? "Minutes:"
					: ""
			} ${
				unit?.series
					? unit?.series.join(", ")
					: unit?.nTimes
					? unit?.nTimes
					: unit?.time
					? unit?.time
					: ""
			}`;
			elem.id = unit?.id;

			data.push(elem);
		}

		return data;
	}

	function renderUnits() {
		return (
			<>
				<CardHeader title="Units">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary mr-2"
							onClick={() => setOpenLessonTableDialog(true)}
						>
							Add lesson
						</button>
						<button
							type="button"
							className="btn btn-primary mr-2"
							onClick={() => setOpenTestTableDialog(true)}
						>
							Add test
						</button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => setOpenRoutineTableDialog(true)}
						>
							Add routine
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					{!refresh && course?.units?.length > 0 && (
						<Table
							columns={unitColumns}
							data={getUnitData(course.units)}
						></Table>
					)}
					<EditRoutineDialog
						title={
							routines?.find((x) => x._id === selectedRoutine?.id)
								?.title
						}
						open={openEditRoutineDialog}
						setOpen={setOpenEditRoutineDialog}
						routine={routines?.find(
							(x) => x._id === selectedRoutine?.id
						)}
						initialData={{
							series: selectedRoutine?.series,
							time: selectedRoutine?.time,
							nTimes: selectedRoutine?.nTimes,
						}}
						onConfirm={(data) => {
							setOpenEditRoutineDialog(null);
							let _units = [...course.units];
							const index = _units.findIndex(
								(x) => x.id === selectedRoutine?.id
							);

							_units[index] = {
								..._units[index],
								series: data.series,
								time: data.time,
								nTimes: data.nTimes,
							};

							setCourse({
								...course,
								units: _units,
							});
						}}
					/>
					<ViewLessonDialog
						open={openViewUnitDialog === UNIT_TYPES.LESSON}
						setOpen={setOpenViewUnitDialog}
						lesson={lessons?.find((x) => x._id === selectedUnitId)}
					/>
					<ViewRoutineDialog
						open={openViewUnitDialog === UNIT_TYPES.ROUTINE}
						setOpen={setOpenViewUnitDialog}
						routine={routines?.find(
							(x) => x._id === selectedUnitId
						)}
					/>
					<ViewTestDialog
						open={openViewUnitDialog === UNIT_TYPES.TEST}
						setOpen={setOpenViewUnitDialog}
						test={tests?.find((x) => x._id === selectedUnitId)}
					/>
					<TableDialog
						open={openLessonTableDialog}
						setOpen={setOpenLessonTableDialog}
						lessonsTable={true}
						data={
							user.role.includes("trainer")
								? getLessonData(
										lessons?.filter(
											(item) =>
												item.trainer === user._id ||
												item.trainer === null
										)
								  )
								: getLessonData(lessons)
						}
						onSelectRow={(id) => {
							let _units = [...course.units];
							if (_units)
								_units.push({
									id: id,
									type: UNIT_TYPES.LESSON,
								});
							else
								_units = [
									{
										id: id,
										type: UNIT_TYPES.LESSON,
									},
								];
							setRefresh(true);
							setCourse({ ...course, units: _units });
							setOpenLessonTableDialog(null);
							// TODO: open modal to edit series/time ???
						}}
					/>
					<TableDialog
						open={openTestTableDialog}
						setOpen={setOpenTestTableDialog}
						testsTable={true}
						data={tests}
						onSelectRow={(id) => {
							let _units = [...course.units];
							if (_units)
								_units.push({
									id: id,
									type: UNIT_TYPES.TEST,
								});
							else
								_units = [
									{
										id: id,
										type: UNIT_TYPES.TEST,
									},
								];
							setRefresh(true);
							setCourse({ ...course, units: _units });
							setOpenTestTableDialog(null);
						}}
					/>
					<TableDialog
						open={openRoutineTableDialog}
						setOpen={setOpenRoutineTableDialog}
						routinesTable={true}
						data={
							user.role.includes("trainer")
								? routines?.filter(
										(item) =>
											item.trainer === user._id ||
											item.trainer === null
								  )
								: routines
						}
						onSelectRow={(id) => {
							let _units = [...course.units];
							if (_units)
								_units.push({
									id: id,
									series: null,
									time: null,
									nTimes: null,
									type: UNIT_TYPES.ROUTINE,
								});
							else
								_units = [
									{
										id: id,
										series: null,
										time: null,
										nTimes: null,
										type: UNIT_TYPES.ROUTINE,
									},
								];
							setRefresh(true);
							setCourse({ ...course, units: _units });
							setOpenRoutineTableDialog(null);
						}}
					/>
				</CardBody>
			</>
		);
	}

	function saveCourse() {
		let saveCourse = course;

		course.units.forEach((unit) => {
			if (unit.type === UNIT_TYPES.ROUTINE) {
				unit.routine = unit?.id;
			} else if (unit.type === UNIT_TYPES.LESSON) {
				unit.lesson = unit?.id;
			} else if (unit.type === UNIT_TYPES.TEST) {
				unit.test = unit?.id;
			}
			delete unit?.id;
			delete unit?.type;
		});

		if (!course.units || course.units.length === 0)
			return alertError({
				error: null,
				customMessage: "At least one unit is required.",
			});

		if (!courseId) {
			postCourse(
				{
					...saveCourse,
					trainer: user.role.includes("trainer") ? user._id : null,
					admin: user.role.includes("admin") ? user._id : null,
				},
				selectedBanner
			)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Course successfully created.",
						});
						history.push("/sports/courses");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save course.",
					});
				});
		} else {
			updateCourse(courseId, saveCourse, selectedBanner)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/sports/courses");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!course[element]) course[element] = {};
			let newText = course[element];
			newText[lang] = event.target.value;
			setCourse({ ...course, [element]: newText });
		} else setCourse({ ...course, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!course[element]) course[element] = {};
				let newText = course[element];
				newText[lang] = value;
				setCourse({ ...course, [element]: newText });
			} else setCourse({ ...course, [element]: value });
		}
	};

	const handleMoveUnit = (index, newIndex) => {
		const aux = course.units[index];
		if (!aux)
			return alertError({
				error: null,
				customMessage:
					"An unexpected error has ocurred trying to move the units, please try again.",
			});
		let _units = course.units;
		_units.splice(index, 1, _units[newIndex]);
		_units.splice(newIndex, 1, aux);
		setRefresh(true);
		setCourse({ ...course, units: _units });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`statement`}
					label="Title"
					value={(course?.title && course?.title[lang]) || ""}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<br />
				<div>Description</div>
				<Editor
					body={
						(course?.description && course?.description[lang]) || ""
					}
					setBody={(new_body) => {
						handleChangeEditor("description", new_body, lang);
					}}
					className="max-height"
					placeholder={"Enter course description here..."}
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					{!course.validated && courseId ? (
						<div class="alert alert-danger">
							{course.validationMessage}
						</div>
					) : (
						<></>
					)}
					{!courseId ||
					user?.role.includes("admin") ||
					(user?.role.includes("trainer") &&
						course.trainer === user?._id) ? (
						<>
							<CardHeader title="Edit course"></CardHeader>
							<CardBody>
								<MultilanguageTabBlock
									multilanguageTabContent={
										renderMultilanguageTabContent
									}
								/>
								<br />
								<TextField
									id={`References`}
									label="References"
									value={course.references || ""}
									margin="normal"
									variant="outlined"
									placeholder={"Enter References here ..."}
									InputLabelProps={{
										shrink: true,
									}}
									required
									onChange={handleChange("references")}
								/>
								<br />
								<br />
								<Autocomplete
									id="sports"
									disablePortal
									options={sports}
									getOptionLabel={(option) =>
										getNonEmpty(option?.fullName)
									}
									value={sports?.find(
										(x) => x._id === course?.sport
									)}
									onChange={(event, selected) => {
										setCourse({
											...course,
											sport: selected?._id || null,
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											margin="normal"
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
											placeholder="Select a sport"
											label="Sport"
											required
										/>
									)}
								/>
								<div className="row d-flex justify-content-center">
									<div className="col-md-6">
										<Autocomplete
											id="levels"
											disablePortal
											options={LEVELS}
											getOptionLabel={(option) => option}
											value={course?.level}
											onChange={(event, selected) => {
												setCourse({
													...course,
													level: selected,
												});
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													margin="normal"
													variant="outlined"
													InputLabelProps={{
														shrink: true,
													}}
													placeholder="Select a level"
													label="Level"
													required
												/>
											)}
										/>
									</div>
									<div className="col-md-6">
										<Autocomplete
											id="language"
											disablePortal
											options={languages}
											getOptionLabel={(option) =>
												option?.fullName
											}
											value={languages?.find(
												(x) =>
													x.isocode ===
													course?.language
											)}
											onChange={(event, selected) => {
												setCourse({
													...course,
													language:
														selected?.isocode ||
														null,
												});
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													margin="normal"
													variant="outlined"
													InputLabelProps={{
														shrink: true,
													}}
													placeholder="Select a language"
													label="Language"
													required
												/>
											)}
										/>
									</div>
								</div>
								<br />
								<br />
								<label
									htmlFor={"upload-image"}
									className="ml-0"
								>
									<input
										style={{ display: "none" }}
										id={"upload-image"}
										name={"upload-image"}
										type="file"
										accept={"image/*"}
										onChange={(e) => {
											setSelectedBanner(
												e.target.files[0]
											);
										}}
									/>
									<Button
										style={{ marginRight: "15px" }}
										color="secondary"
										component="span"
										variant="outlined"
									>
										{selectedBanner ||
										(course.bannerURL &&
											course.bannerURL !== "")
											? "Change banner"
											: "Upload banner"}
									</Button>
								</label>
								{(selectedBanner ||
									(course.bannerURL &&
										course.bannerURL !== "")) && (
									<>
										<Tooltip title={"Preview banner"}>
											<Button
												size="small"
												onClick={() =>
													setOpenPreviewDialog(true)
												}
												style={buttonsStyle}
											>
												<VisibilityIcon />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={"Preview banner"}
											open={openPreviewDialog}
											setOpen={setOpenPreviewDialog}
											src={
												selectedBanner
													? URL.createObjectURL(
															selectedBanner
													  )
													: `${SERVER_URL}/${course.bannerURL}`
											}
										/>
										<Tooltip title="Delete">
											<Button
												size="small"
												style={buttonsStyle}
												onClick={() => {
													setSelectedBanner(null);
													setCourse({
														...course,
														bannerURL: "",
													});
												}}
											>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedBanner
												? selectedBanner?.name
												: course.bannerURL &&
												  course.bannerURL !== ""
												? course.bannerURL.split(
														/-(.*)/s
												  )[1]
												: ""}
										</span>
									</>
								)}
								<br />
								<br />
								<FormControlLabel
									control={
										<Checkbox
											checked={course.active}
											onChange={() =>
												setCourse({
													...course,
													active: !course.active,
												})
											}
											name="checkActive"
										/>
									}
									label="Active"
								/>
								{user.role.includes("admin") && (
									<>
										<FormControlLabel
											control={
												<Checkbox
													checked={course.premium}
													onChange={() =>
														setCourse({
															...course,
															premium: !course.premium,
															price: 0,
														})
													}
													name="checkPremium"
												/>
											}
											label="Premium"
										/>
										<br />
										{course.premium && (
											<TextField
												id={`price`}
												label={`Price (€)`}
												value={course.price || ""}
												onChange={handleChange("price")}
												InputLabelProps={{
													shrink: true,
												}}
												margin="normal"
												variant="outlined"
												type="number"
												InputProps={{
													inputProps: {
														min: 0,
													},
												}}
											/>
										)}
									</>
								)}
							</CardBody>
							{renderUnits()}

							<br />
						</>
					) : (
						<>
							<CardHeader title={"View course " + course.title} />
							<CardBody>
								<PlansContent
									type="training"
									plan={{
										...course,
										type: getNonEmpty(
											sports?.find(
												(item) =>
													item._id === course.type
											)?.fullName
										),
										standard: !course.trainer,
									}}
									user={user}
									lessons={lessons}
								/>
							</CardBody>
						</>
					)}
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/sports/courses")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					{(!courseId ||
						user?.role.includes("admin") ||
						(user?.role.includes("trainer") &&
							course.trainer === user?._id)) && (
						<>
							<Button
								onClick={() => {
									if (course.used) setOpenConfirmDialog(1);
									else saveCourse();
								}}
								variant="outlined"
								color="primary"
								style={{ marginRight: "20px" }}
							>
								Save course
							</Button>
							<ConfirmDialog
								title={
									"This course is already associated to some users, are you sure you want to modify it?"
								}
								open={openConfirmDialog === 1}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => saveCourse()}
							/>
						</>
					)}
					{courseId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(2)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete course
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this course?"
								}
								open={openConfirmDialog === 2}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteCourse(courseId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Course deleted successfully",
												});
												history.push("/sports/courses");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete course.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
