import React, { useEffect, useState } from "react";

import { Visibility } from "@material-ui/icons";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getRatings } from "../../../../api/ratings";
import { alertError } from "../../../../utils/logger";
import RatingInfoDialog from "../../../components/dialogs/RatingInfoDialog";
import Table, {
	buttonsStyle,
	substringFormatter,
} from "../../../components/tables/table";
import { Button, Tooltip } from "@material-ui/core";

function getData(ratings) {
	let data = [];

	ratings.forEach((rating) => {
		const elem = {
			id: rating?._id,
			user: rating.user?.fullName || rating.guest?._id || "---",
			score: rating?.score,
			comments: rating?.comments,
		};

		data = data.concat(elem);
	});

	return data;
}

export function RatingsPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);

	const [openRatingInfoDialog, setOpenRatingInfoDialog] = useState(false);
	const [selectedRating, setSelectedRating] = useState(null);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title="View">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedRating(elem);
							setOpenRatingInfoDialog(true);
						}}
					>
						<Visibility />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: "user", text: "User", sort: true },
		{
			dataField: "score",
			text: "Score",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "comments",
			text: "Comments",
			sort: true,
			headerAlign: "center",
			align: "center",
			formatter: substringFormatter,
		},
		{
			dataField: "id",
			text: "",
			formatter: buttonFormatter,
		},
	];

	useEffect(() => {
		getRatings()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) =>
				alertError({
					error: error,
					customMessage: "Could not get ratings.",
				})
			);
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title={"Ratings"} />
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
			<RatingInfoDialog
				title="Rating"
				open={openRatingInfoDialog}
				setOpen={setOpenRatingInfoDialog}
				rating={selectedRating}
			/>
		</>
	);
}
