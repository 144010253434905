import { API, authClient } from '../index';
import { uploadFilesGetLinks, uploadSingleFile } from "../files"

// Get all lessons
export const getLessons = () => {
	return authClient().get(`${API}/lesson?getDisabled=true`);
};

// Get lesson by id
export const getLessonById = (id) => {
	return authClient().get(`${API}/lesson/${id}`);
};

// Delete lesson
export const deleteLesson = (id) => {
	return authClient().delete(`${API}/lesson/${id}`);
};

// Create lesson 
export const postLesson = async (lesson, audio, video, mainImage, document, images) => {
	if (audio) {
		const response = await uploadSingleFile(audio, 'lessons')
		lesson.audioTipURL = response.data.fileURL
	}
	if (video) {
		const response = await uploadSingleFile(video, 'lessons')
		lesson.videoURL = response.data.fileURL
	}
	if (mainImage) {
		const response = await uploadSingleFile(mainImage, 'lessons')
		lesson.imageURL = response.data.fileURL
	}
	if (document) {
		const response = await uploadSingleFile(document, 'lessons')
		lesson.documentURL = response.data.fileURL
	}
	if (images) {
		const imagesURLs = await uploadFilesGetLinks(images, 'lessons')
		lesson.imagesURLs = imagesURLs
	}
	return authClient().post(`${API}/lesson`, lesson);
};

// Update lesson
export const updateLesson = async (id, lesson, audio, video, mainImage, document, images) => {
	if (audio) {
		const response = await uploadSingleFile(audio, 'lessons')
		lesson.audioTipURL = response.data.fileURL
	}
	if (video) {
		const response = await uploadSingleFile(video, 'lessons')
		lesson.videoURL = response.data.fileURL
	}
	if (mainImage) {
		const response = await uploadSingleFile(mainImage, 'lessons')
		lesson.imageURL = response.data.fileURL
	}
	if (document) {
		const response = await uploadSingleFile(document, 'lessons')
		lesson.documentURL = response.data.fileURL
	}
    if (images) {
        for (const index in images) {
            const response = await uploadSingleFile(images[index], 'lessons')
            lesson.imagesURLs[index] = response.data.fileURL
        }
    }
	return authClient().put(`${API}/lesson/${id}`, lesson);
};

// Change status lesson
export const changeStatusLesson = async (id, active) => {
	console.log(id, active);
	return authClient().put(`${API}/lesson/change-status/${id}`, { active });
};