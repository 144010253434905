import { API, authClient } from '../index';

// Get all routine plan types
export const getRoutinePlanTypes = () => {
	return authClient().get(`${API}/routine-plan-type?getDisabled=true`);
};

// Get routine plan type by id
export const getRoutinePlanTypeById = (id) => {
	return authClient().get(`${API}/routine-plan-type/${id}`);
};

// Delete routine plan type
export const deleteRoutinePlanType = (id) => {
	return authClient().delete(`${API}/routine-plan-type/${id}`);
};

// Create routine plan type 
export const postRoutinePlanType = async (routinePlanType) => {
	return authClient().post(`${API}/routine-plan-type`, routinePlanType);
};

// Update routine plan type
export const updateRoutinePlanType = async (id, routinePlanType) => {
	return authClient().put(`${API}/routine-plan-type/${id}`, routinePlanType);
};

// Change status routine plan type
export const changeStatusRoutinePlanType = async (id, active) => {
	return authClient().put(`${API}/routine-plan-type/change-status/${id}`, { active });
};