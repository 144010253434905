import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getRoutines } from "../../../../api/routine";
import { getRoutinePlanById } from "../../../../api/routinePlan";
import { getTranslationsByEntityId } from "../../../../api/translation";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import PlansContent from "../../../components/plansContent";
import { useSkeleton } from "../../../hooks/useSkeleton";

export default function RoutinePlansTranslationsPage() {
	const [translations, setTranslations] = useState([]);
	const [routinePlan, setRoutinePlan] = useState(null);
	const [routines, setRoutines] = useState(null);
	const routinePlanId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!routinePlanId) {
			disableLoadingData();
			alertError({
				error: null,
				customMessage: "Could not get routine plan translations.",
			});
			history.push("/training/routine-plans");
			return;
		}
		getRoutines()
			.then((res) => {
				if (res.status === 200) {
					const _routines = res.data.map((item) => ({
						...item,
						standard: !item.trainer,
						muscleGroups: [getNonEmpty(item.muscleGroup?.fullName)]
							.concat(
								item.otherMuscleGroups.map((mg) =>
									getNonEmpty(mg?.fullName)
								)
							)
							.join(", "),
					}));
					setRoutines(_routines);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get routines.",
				});
				history.push("/training/routine-plans");
			});
		getTranslationsByEntityId(routinePlanId)
			.then((res) => {
				if (res.status === 200) {
					getRoutinePlanById(routinePlanId)
						.then((response) => {
							if (response.status === 200) {
								let translations = {};
								const {
									title,
									description,
									routines,
									..._routinePlan
								} = response.data;
								setRoutinePlan({
									..._routinePlan,
									standard: !_routinePlan.trainer,
									type: getNonEmpty(
										_routinePlan.type?.fullName
									),
								});
								translations.es = {
									title,
									description,
									routines,
								};
								for (const translation of res.data) {
									const content = translation.content;
									translations[
										translation.langIsoCode
									] = content;
								}
								setTranslations(translations);
								disableLoadingData();
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: "Could not get routine plan.",
							});
							history.push("/training/routine-plans");
						});
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get routine plan translations.",
				});
				history.push("/training/routine-plans");
			});
	}, [routinePlanId, disableLoadingData, history]);

	const renderMultilanguageTabContent = (lang, langName) => {
		const canEdit =
			user?.role.includes("admin") ||
			(routinePlan?.trainer === user?._id && !routinePlan?.standard);
		return (
			<>
				<br />
				{translations && translations[lang] ? (
					<PlansContent
						plan={{ ...translations[lang], ...routinePlan }}
						user={user}
						routines={routines}
						type="training"
						showTitle={true}
					/>
				) : (
					<>
						<p>There is no translation into this language yet.</p>
					</>
				)}
				<br />
				{canEdit && (
					<Button
						onClick={() => {
							if (lang === "es")
								history.push(
									"/training/edit-plan-routine/" +
										routinePlanId
								);
							else
								history.push(
									"/training/translate-plan-routine/" +
										routinePlanId +
										"/" +
										lang
								);
						}}
						variant="outlined"
						color="primary"
					>
						{"Edit " + langName}
					</Button>
				)}
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="View routine plan translations" />
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/training/routine-plans")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
				</div>
			</>
		);
}
