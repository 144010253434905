import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { toAbsoluteUrl } from "../_metronic/_helpers";
import { ContentRoute } from "../_metronic/layout";
import { BACKOFFICE_URL } from "../api";
import { getChatsByUserId } from "../api/chat";
import { getMessagesByChatId, getUnreadMessagesByUser } from "../api/message";
import {
	addMessage,
	addNotifications,
	getOnlineUsers,
	setNotifications,
	updateChats,
	updateCurrentChat,
} from "../redux/chatRedux/chatActions";
import socket from "./components/socket/socket";
import { AccessEntriesPage } from "./modules/ContentManager/accessEntries";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import AdvertisementsPage from "./modules/ContentManager/advertisements/AdvertisementsPage";
import EditAdvertisementsPage from "./modules/ContentManager/advertisements/EditAdvertisementsPage";
import ChatPage from "./modules/ContentManager/chat/ChatPage";
import CoursesPage from "./modules/ContentManager/courses/CoursesPage";
import CourseThreadsPage from "./modules/ContentManager/courses/CourseThreadsPage";
import EditCoursesPage from "./modules/ContentManager/courses/EditCoursesPage";
import EditCourseThreadsPage from "./modules/ContentManager/courses/EditCourseThreadsPage";
import ViewCourseThreadsPage from "./modules/ContentManager/courses/ViewCourseThreadsPage";
import DietTypesPage from "./modules/ContentManager/dietTypes/DietTypesPage";
import EditDietTypesPage from "./modules/ContentManager/dietTypes/EditDietTypesPage";
import DietVarietyLevelsPage from "./modules/ContentManager/dietVarietyLevels/DietVarietyLevelsPage";
import EditDietVarietyLevelsPage from "./modules/ContentManager/dietVarietyLevels/EditDietVarietyLevelsPage";
import EditEquipmentsPage from "./modules/ContentManager/equipments/EditEquipmentsPage";
import EquipmentsPage from "./modules/ContentManager/equipments/EquipmentsPage";
import EditEventsPage from "./modules/ContentManager/events/EditEventsPage";
import EventsPage from "./modules/ContentManager/events/EventsPage";
import EditFoodFamiliesPage from "./modules/ContentManager/foodFamilies/EditFoodFamiliesPage";
import FoodFamiliesPage from "./modules/ContentManager/foodFamilies/FoodFamiliesPage";
import EditFoodPlatesPage from "./modules/ContentManager/foodPlates/EditFoodPlatesPage";
import FoodPlatesPage from "./modules/ContentManager/foodPlates/FoodPlatesPage";
import EditFoodsPage from "./modules/ContentManager/foods/EditFoodsPage";
import FoodsPage from "./modules/ContentManager/foods/FoodsPage";
import EditGiveawaysPage from "./modules/ContentManager/giveaways/EditGiveawaysPage";
import GiveawaysPage from "./modules/ContentManager/giveaways/GiveawaysPage";
import { GuestsPage } from "./modules/ContentManager/guests";
import EditLanguagesPage from "./modules/ContentManager/languages/EditLanguagesPage";
import LanguagesPage from "./modules/ContentManager/languages/LanguagesPage";
import EditLessonsPage from "./modules/ContentManager/lessons/EditLessonsPage";
import LessonsPage from "./modules/ContentManager/lessons/LessonsPage";
import EditMealsPage from "./modules/ContentManager/meals/EditMealsPage";
import MealsPage from "./modules/ContentManager/meals/MealsPage";
import EditMultimediasPage from "./modules/ContentManager/multimedias/EditMultimediasPage";
import MultimediasPage from "./modules/ContentManager/multimedias/MultimediasPage";
import EditMuscleGroupsPage from "./modules/ContentManager/muscleGroups/EditMuscleGroupsPage";
import MuscleGroupsPage from "./modules/ContentManager/muscleGroups/MuscleGroupsPage";
import EditNotificationTextsPage from "./modules/ContentManager/notificationText/EditNotificationTextsPage";
import NotificationTextsPage from "./modules/ContentManager/notificationText/NotificationTextsPage";
import EditNutritionalPlansPage from "./modules/ContentManager/nutritionalPlans/EditNutritionalPlansPage";
import NutritionalPlansPage from "./modules/ContentManager/nutritionalPlans/NutritionalPlansPage";
import NutritionalPlansTranslationsPage from "./modules/ContentManager/nutritionalPlans/NutritionalPlansTranslationsPage";
import TranslateNutritionalPlansPage from "./modules/ContentManager/nutritionalPlans/TranslateNutritionalPlansPage";
import EditNutritionistCertificationsPage from "./modules/ContentManager/nutritionistCertifications/EditNutritionistCertificationsPage";
import NutritionistCertificationsPage from "./modules/ContentManager/nutritionistCertifications/NutritionistCertificationsPage";
import EditNutritionistSpecializationsPage from "./modules/ContentManager/nutritionistSpecializations/EditNutritionistSpecializationsPage";
import NutritionistSpecializationsPage from "./modules/ContentManager/nutritionistSpecializations/NutritionistSpecializationsPage";
import EditProfessionalsPage from "./modules/ContentManager/professionals/EditProfessionalsPage";
import ProfessionalsPage from "./modules/ContentManager/professionals/ProfessionalsPage";
import ProfessionalsReportsPage from "./modules/ContentManager/professionals/ProfessionalsReportsPage";
import PurchasePage from "./modules/ContentManager/purchases/PurchasePage";
import PurchasesPage from "./modules/ContentManager/purchases/PurchasesPage";
import EditQuestionsPage from "./modules/ContentManager/questions/EditQuestionsPage";
import QuestionsPage from "./modules/ContentManager/questions/QuestionsPage";
import { RatingsPage } from "./modules/ContentManager/ratings/RatingsPage";
import EditRecipeTypesPage from "./modules/ContentManager/recipeTypes/EditRecipeTypesPage";
import RecipeTypesPage from "./modules/ContentManager/recipeTypes/RecipeTypesPage";
import NutritionistsReportsPage from "./modules/ContentManager/reports/NutritionistsReportsPage";
import PlansReportsPage from "./modules/ContentManager/reports/PlansReportsPage";
import TrainersReportsPage from "./modules/ContentManager/reports/TrainersReportsPage";
import {
	ReviewsByExercisePage,
	ReviewsByRoutinePage,
} from "./modules/ContentManager/reviews";
import { ScreensPage, EditScreenPage } from "./modules/ContentManager/screens";
import EditRoutinePlanTypesPage from "./modules/ContentManager/routinePlanTypes/EditRoutinePlanTypesPage";
import RoutinePlanTypesPage from "./modules/ContentManager/routinePlanTypes/RoutinePlanTypesPage";
import EditRoutinePlansPage from "./modules/ContentManager/routines/EditRoutinePlansPage";
import EditRoutinesPage from "./modules/ContentManager/routines/EditRoutinesPage";
import RoutinePlansPage from "./modules/ContentManager/routines/RoutinePlansPage";
import RoutinePlansTranslationsPage from "./modules/ContentManager/routines/RoutinePlansTranslationsPage";
import RoutinesPage from "./modules/ContentManager/routines/RoutinesPage";
import RoutinesTranslationsPage from "./modules/ContentManager/routines/RoutinesTranslationsPage";
import TranslateRoutinePlansPage from "./modules/ContentManager/routines/TranslateRoutinePlansPage";
import TranslateRoutinesPage from "./modules/ContentManager/routines/TranslateRoutinesPage";
import EditSendingsPage from "./modules/ContentManager/sendings/EditSendingsPage";
import SendingsPage from "./modules/ContentManager/sendings/SendingsPage";
import SessionsPage from "./modules/ContentManager/sessions/SessionsPage";
import ViewSessionsPage from "./modules/ContentManager/sessions/ViewSessionsPage";
import EditSpecialDaysPage from "./modules/ContentManager/specialDays/EditSpecialDaysPage";
import SpecialDaysPage from "./modules/ContentManager/specialDays/SpecialDaysPage";
import EditSportsPage from "./modules/ContentManager/sports/EditSportsPage";
import SportsPage from "./modules/ContentManager/sports/SportsPage";
import EditStandardChallengesPage from "./modules/ContentManager/standardChallenges/EditStandardChallengesPage";
import StandardChallengesPage from "./modules/ContentManager/standardChallenges/StandardChallengesPage";
import EditSubscriptionsPage from "./modules/ContentManager/subscriptions/EditSubscriptionsPage";
import SubscriptionsPage from "./modules/ContentManager/subscriptions/SubscriptionsPage";
import EditTeamsPage from "./modules/ContentManager/teams/EditTeamsPage";
import TeamsPage from "./modules/ContentManager/teams/TeamsPage";
import EditTestsPage from "./modules/ContentManager/tests/EditTestsPage";
import TestsPage from "./modules/ContentManager/tests/TestsPage";
import EditTextsPage from "./modules/ContentManager/texts/EditTextsPage";
import TextsPage from "./modules/ContentManager/texts/TextsPage";
import EditTipsPage from "./modules/ContentManager/tips/EditTipsPage";
import TipsPage from "./modules/ContentManager/tips/TipsPage";
import EditTrainerCertificationsPage from "./modules/ContentManager/trainerCertifications/EditTrainerCertificationsPage";
import TrainerCertificationsPage from "./modules/ContentManager/trainerCertifications/TrainerCertificationsPage";
import EditTrainerSpecializationsPage from "./modules/ContentManager/trainerSpecializations/EditTrainerSpecializationsPage";
import TrainerSpecializationsPage from "./modules/ContentManager/trainerSpecializations/TrainerSpecializationsPage";
import EditUsersPage from "./modules/ContentManager/users/EditUsersPage";
import UsersPage from "./modules/ContentManager/users/UsersPage";
import ViewUsersInfoPage from "./modules/ContentManager/users/ViewUsersInfoPage";
import { DashboardPage } from "./pages/DashboardPage";
import { AdsPage, AdViewPage } from "./modules/ContentManager/ads";
import TeamCategoriesPage from "./modules/ContentManager/teamCategories/TeamCategoriesPage";
import EditTeamCategoriesPage from "./modules/ContentManager/teamCategories/EditTeamCategoriesPage";

export default function BasePage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const currentChat = useSelector((store) => store.chat?.currentChat);
	const chats = useSelector((store) => store.chat?.chats, shallowEqual);
	const [refresh, setRefresh] = useState(false);

	function createNotification(message, user) {
		// Create and show the notification
		const img = toAbsoluteUrl("/media/logo/logo.png");
		const text = `New message from "${user}": ${message}`;
		const notification = new Notification("Eatfity", {
			body: text,
			icon: img,
		});
		notification.onclick = function() {
			window.open(BACKOFFICE_URL + "/chat");
		};
	}

	// Connect to Socket.io
	useEffect(() => {
		if (!socket || !user) return;
		const getNotifications = async () => {
			const res = await getUnreadMessagesByUser(user?._id);
			if (res.data) dispatch(setNotifications(res.data));
		};
		getNotifications();
		socket.emit("new-user-add", user._id);
		const handleOnlineUsers = (users) => {
			dispatch(getOnlineUsers(users));
		};
		socket.on("get-users", handleOnlineUsers);
		return () => {
			socket.off("get-users", handleOnlineUsers);
		};
	}, [user, dispatch]);

	// Get the chat in chat section
	useEffect(() => {
		if (!user) return;
		const getChats = async () => {
			try {
				const { data } = await getChatsByUserId(user._id);
				dispatch(updateChats(data));
				if (!refresh) dispatch(updateCurrentChat(null, []));
				setRefresh(false);
			} catch (error) {
				console.log(error);
			}
		};
		getChats();
	}, [user, refresh, dispatch]);

	// Get the message from socket server
	useEffect(() => {
		const handleReceiveMessage = (data) => {
			if (!data.chatId) return;
			if (
				currentChat?._id === data.chatId &&
				location.pathname === "/chat"
			) {
				dispatch(addMessage(data));
			} else {
				dispatch(addNotifications(data.chatId));
				toastr.options = {
					positionClass: "toast-top-right",
					hideDuration: 1000,
					showDuration: 300,
					timeOut: 60000,
					closeButton: true,
					newestOnTop: true,
					showEasing: "swing",
					hideEasing: "linear",
					showMethod: "fadeIn",
					hideMethod: "fadeOut",
					onclick: () => {
						history.push("/chat");
						getMessagesByChatId(data.chatId)
							.then((res) => {
								if (res.status === 200) {
									dispatch(
										updateCurrentChat(
											chats.find(
												(item) =>
													item._id === data.chatId
											),
											res.data
										)
									);
								}
							})
							.catch((error) => {
								console.log(error);
							});
						toastr.clear();
					},
				};
				let user = "New message";
				const message =
					data.text && data.text !== ""
						? data.text
						: data.fileURL && data.fileURL !== ""
						? data.fileURL.split(/-(.*)/s)[1]
						: "";
				if (data?.senderId?.fullName) user = data?.senderId?.fullName;

				toastr.info(`${message}`, `${user}`);
				createNotification(message, user);
			}
			if (chats.findIndex((item) => item._id === data.chatId) < 0)
				setRefresh(true);
		};
		socket.on("receive-message", handleReceiveMessage);
		return () => {
			socket.off("receive-message", handleReceiveMessage);
		};
	}, [socket, currentChat, chats, dispatch, history, location]);

	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */}
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />
			{/* PROFILES */}
			{/* Admins */}
			<ContentRoute
				from="/admins"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: AdminsPage
				}
			/>
			<ContentRoute
				from="/edit-admin/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditAdminsPage
				}
			/>
			{/* Professionals */}
			<ContentRoute from="/professionals" component={ProfessionalsPage} />
			<ContentRoute
				from="/edit-professional/:id?"
				component={EditProfessionalsPage}
			/>
			<ContentRoute
				from="/statistics-professional/:id"
				component={ProfessionalsReportsPage}
			/>
			{/* Teams */}
			<ContentRoute from="/teams" component={TeamsPage} />
			<ContentRoute from="/edit-team/:id?" component={EditTeamsPage} />
			{/* Users */}
			<ContentRoute from="/users" component={UsersPage} />
			<ContentRoute from="/edit-user/:id" component={EditUsersPage} />
			<ContentRoute
				from="/view-user-info/:id"
				component={ViewUsersInfoPage}
			/>
			{/* Guests */}
			<ContentRoute from="/guests" component={GuestsPage} />
			{/* Access entries */}
			<ContentRoute
				from="/access-entries"
				component={AccessEntriesPage}
			/>
			{/* Reviews entries */}
			<ContentRoute
				from="/reviews/by-exercise"
				component={ReviewsByExercisePage}
			/>
			<ContentRoute
				from="/reviews/by-routine"
				component={ReviewsByRoutinePage}
			/>
			{/* Ratings */}
			<ContentRoute from="/ratings" component={RatingsPage} />
			{/* PLANS */}
			{/* Nutritional plans */}
			<ContentRoute
				from="/nutritional-plans"
				component={NutritionalPlansPage}
			/>
			<ContentRoute
				from="/edit-nutritional-plan/:id?"
				component={EditNutritionalPlansPage}
			/>
			<ContentRoute
				from="/translate-nutritional-plan/:id/:lang"
				component={TranslateNutritionalPlansPage}
			/>
			<ContentRoute
				from="/nutritional-plan-translations/:id"
				component={NutritionalPlansTranslationsPage}
			/>
			{/* Routine plans - Routines */}
			<ContentRoute from="/training/routines" component={RoutinesPage} />
			<ContentRoute
				from="/training/edit-routine/:id?"
				component={EditRoutinesPage}
			/>
			<ContentRoute
				from="/training/translate-routine/:id/:lang"
				component={TranslateRoutinesPage}
			/>
			<ContentRoute
				from="/training/routine-translations/:id"
				component={RoutinesTranslationsPage}
			/>
			{/* Standard challenges */}
			<ContentRoute
				from="/training/standard-challenges"
				component={StandardChallengesPage}
			/>
			<ContentRoute
				from="/training/edit-challenge/:id?"
				component={EditStandardChallengesPage}
			/>
			{/* Routine plans */}
			<ContentRoute
				from="/training/routine-plans"
				component={RoutinePlansPage}
			/>
			<ContentRoute
				from="/training/edit-plan-routine/:id?"
				component={EditRoutinePlansPage}
			/>
			<ContentRoute
				from="/training/translate-plan-routine/:id/:lang"
				component={TranslateRoutinePlansPage}
			/>
			<ContentRoute
				from="/training/plan-routine-translations/:id"
				component={RoutinePlansTranslationsPage}
			/>
			<Redirect exact from="/training" to="/training/routine-plans" />
			{/* Courses */}
			<ContentRoute
				from="/sports/courses/:id?/threads/:threadId"
				component={ViewCourseThreadsPage}
			/>
			<ContentRoute
				from="/sports/courses/:id?/threads"
				component={CourseThreadsPage}
			/>
			<ContentRoute
				from="/sports/courses/:id?/edit-thread/:threadId?"
				component={EditCourseThreadsPage}
			/>
			<ContentRoute from="/sports/courses" component={CoursesPage} />
			<ContentRoute
				from="/sports/edit-course/:id?"
				component={EditCoursesPage}
			/>
			<ContentRoute from="/sports/lessons" component={LessonsPage} />
			<ContentRoute
				from="/sports/edit-lesson/:id?"
				component={EditLessonsPage}
			/>
			<ContentRoute from="/sports/questions" component={QuestionsPage} />
			<ContentRoute
				from="/sports/edit-question/:id?"
				component={EditQuestionsPage}
			/>
			<ContentRoute from="/sports/tests" component={TestsPage} />
			<ContentRoute
				from="/sports/edit-test/:id?"
				component={EditTestsPage}
			/>
			{/* Tips */}
			<ContentRoute
				from="/tips"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: TipsPage
				}
			/>
			<ContentRoute
				from="/edit-tip/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditTipsPage
				}
			/>
			{/* STATISTICS */}
			{/* Sessions */}
			<ContentRoute
				from="/sessions"
				component={
					!user ||
					(!user?.role?.includes("admin") &&
						!user?.role?.includes("trainer"))
						? DashboardPage
						: SessionsPage
				}
			/>
			<ContentRoute
				from="/view-session/:id"
				component={
					!user ||
					(!user?.role?.includes("admin") &&
						!user?.role?.includes("trainer"))
						? DashboardPage
						: ViewSessionsPage
				}
			/>
			{/* Reports */}
			<ContentRoute
				from="/reports/plans"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: PlansReportsPage
				}
			/>
			<ContentRoute
				from="/reports/trainers"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: TrainersReportsPage
				}
			/>
			<ContentRoute
				from="/reports/nutritionists"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: NutritionistsReportsPage
				}
			/>
			<Redirect exact from="/reports" to="/reports/plans" />
			{/* ACTIONS */}
			{/* Sendings */}
			<ContentRoute from="/sendings" component={SendingsPage} />
			<ContentRoute
				from="/edit-sending/:id?"
				component={EditSendingsPage}
			/>
			{/* Giveaways */}
			<ContentRoute from="/giveaways" component={GiveawaysPage} />
			<ContentRoute
				from="/edit-giveaway/:id?"
				component={EditGiveawaysPage}
			/>
			{/* Events */}
			<ContentRoute from="/events" component={EventsPage} />
			<ContentRoute from="/edit-event/:id?" component={EditEventsPage} />
			{/* MONETIZATION */}
			{/* Advertisements */}
			<ContentRoute
				from="/advertisements"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: AdvertisementsPage
				}
			/>
			<ContentRoute
				from="/edit-advertisement/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditAdvertisementsPage
				}
			/>
			{/* Subscriptions */}
			<ContentRoute
				from="/subscriptions"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: SubscriptionsPage
				}
			/>
			<ContentRoute
				from="/edit-subscription/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditSubscriptionsPage
				}
			/>
			{/* Purchases */}
			<ContentRoute
				from="/purchases/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: PurchasesPage
				}
			/>
			<ContentRoute
				from="/view-purchase/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: PurchasePage
				}
			/>
			{/* Ads */}
			<ContentRoute
				from="/ads"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: AdsPage
				}
			/>
			<ContentRoute
				from="/view-ad/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: AdViewPage
				}
			/>
			{/* MISCELLANY */}
			{/* Sports */}
			<ContentRoute
				from="/sports"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: SportsPage
				}
			/>
			<ContentRoute
				from="/edit-sport/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditSportsPage
				}
			/>
			{/* Special days */}
			<ContentRoute
				from="/specialDays"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: SpecialDaysPage
				}
			/>
			<ContentRoute
				from="/edit-specialDay/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditSpecialDaysPage
				}
			/>
			{/* Multimedias */}
			<ContentRoute
				from="/multimedias"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: MultimediasPage
				}
			/>
			<ContentRoute
				from="/edit-multimedia/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditMultimediasPage
				}
			/>
			{/* Team Categories */}
			<ContentRoute
				from="/team-categories"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: TeamCategoriesPage
				}
			/>
			<ContentRoute
				from="/edit-team-category/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditTeamCategoriesPage
				}
			/>
			{/* Screens */}
			<ContentRoute
				from="/screens"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: ScreensPage
				}
			/>
			<ContentRoute
				from="/edit-screen/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditScreenPage
				}
			/>
			{/* Texts */}
			<ContentRoute
				from="/texts"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: TextsPage
				}
			/>
			<ContentRoute
				from="/edit-text/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditTextsPage
				}
			/>
			{/* Tainer specializations */}
			<ContentRoute
				from="/trainer-specializations"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: TrainerSpecializationsPage
				}
			/>
			<ContentRoute
				from="/edit-trainer-specialization/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditTrainerSpecializationsPage
				}
			/>
			{/* Trainer certifications */}
			<ContentRoute
				from="/trainer-certifications"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: TrainerCertificationsPage
				}
			/>
			<ContentRoute
				from="/edit-trainer-certification/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditTrainerCertificationsPage
				}
			/>
			{/* Nutritionist Certifications */}
			<ContentRoute
				from="/nutritionist-certifications"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: NutritionistCertificationsPage
				}
			/>
			<ContentRoute
				from="/edit-nutritionist-certification/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditNutritionistCertificationsPage
				}
			/>
			{/* Nutritionist Specializations */}
			<ContentRoute
				from="/nutritionist-specializations"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: NutritionistSpecializationsPage
				}
			/>
			<ContentRoute
				from="/edit-nutritionist-specialization/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditNutritionistSpecializationsPage
				}
			/>
			{/* Muscle groups */}
			<ContentRoute
				from="/muscle-groups"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: MuscleGroupsPage
				}
			/>
			<ContentRoute
				from="/edit-muscle-group/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditMuscleGroupsPage
				}
			/>
			{/* Equipments */}
			<ContentRoute
				from="/equipments"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EquipmentsPage
				}
			/>
			<ContentRoute
				from="/edit-equipment/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditEquipmentsPage
				}
			/>
			{/* Routine plan types */}
			<ContentRoute
				from="/routine-plan-types"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: RoutinePlanTypesPage
				}
			/>
			<ContentRoute
				from="/edit-routine-plan-type/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditRoutinePlanTypesPage
				}
			/>
			{/* Recipe types */}
			<ContentRoute from="/recipe-types" component={RecipeTypesPage} />
			<ContentRoute
				from="/edit-recipe-type/:id?"
				component={EditRecipeTypesPage}
			/>
			{/* Diet types */}
			<ContentRoute from="/diet-types" component={DietTypesPage} />
			<ContentRoute
				from="/edit-diet-type/:id?"
				component={EditDietTypesPage}
			/>
			{/* Meals */}
			<ContentRoute from="/meals" component={MealsPage} />
			<ContentRoute from="/edit-meal/:id?" component={EditMealsPage} />
			{/* Diet variety levels */}
			<ContentRoute
				from="/diet-variety-levels"
				component={DietVarietyLevelsPage}
			/>
			<ContentRoute
				from="/edit-diet-variety-level/:id?"
				component={EditDietVarietyLevelsPage}
			/>
			{/* Foods */}
			<ContentRoute from="/foods" component={FoodsPage} />
			<ContentRoute from="/edit-food/:id?" component={EditFoodsPage} />
			{/* Food families */}
			<ContentRoute from="/food-families" component={FoodFamiliesPage} />
			<ContentRoute
				from="/edit-food-family/:id?"
				component={EditFoodFamiliesPage}
			/>
			{/* Food plates */}
			<ContentRoute
				from="/food-plates"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: FoodPlatesPage
				}
			/>
			<ContentRoute
				from="/edit-plate/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditFoodPlatesPage
				}
			/>
			{/* Languages */}
			<ContentRoute
				from="/languages"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: LanguagesPage
				}
			/>
			<ContentRoute
				from="/edit-language/:id?"
				component={
					!user || !user?.role?.includes("admin")
						? DashboardPage
						: EditLanguagesPage
				}
			/>
			{/* Chat */}
			<ContentRoute from="/chat" component={ChatPage} />
			{/* Notification text */}
			<ContentRoute
				from="/notification-texts"
				component={NotificationTextsPage}
			/>
			<ContentRoute
				from="/edit-notification-text/:id?"
				component={EditNotificationTextsPage}
			/>
			<Redirect to="/error" />
		</Switch>
	);
}
