import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import { alertError  } from '../../../../utils/logger'
import { getTrainingStatistics } from '../../../../api/routinePlan'
import { getNutritionalStatistics } from '../../../../api/nutritionalPlan'
import CircularChartContainer from '../../../components/charts/CircularChartContainer'
import BarChartContainer from '../../../components/charts/BarChartContainer'

export default function PlansReportsPage() {
  const [dataTraining, setDataTraining] = useState([])
  const [dataNutrition, setDataNutrition] = useState([])

  useEffect(() => {
    getTrainingStatistics().then(res => {
      setDataTraining(res.data)
    }).catch((error) => {
      alertError({ error: error, customMessage: 'Could not get statistics.' });
    });
    getNutritionalStatistics().then(res => {
      const modifiedData = res.data.plans.map(item => {
        if (item._id === null) {
          return { _id: {_id: null, title: "Custom nutrition plans"}, count: item.count };
        }
        return item;
      });
      res.data.plans = modifiedData
      setDataNutrition(res.data)
    }).catch((error) => {
      alertError({ error: error, customMessage: 'Could not get statistics.' });
    });
  }, [])

  return (
    <>
      <Card>
        <CardHeader title='Training plans report'></CardHeader>
        <CardBody>          
          <CircularChartContainer
            data={dataTraining?.standardRoutines?.map(item => ({ key: item._id ? 'Standard' : 'Non-standard', value: item.count, id: item._id }))}
            title={'Standard routines'}
            units="routines"
            noDataMessage="There are no routines created yet."
          />
        </CardBody>
        <CardBody>          
          <BarChartContainer
            data={dataTraining?.routines?.filter(item => item._id !== null).map(item => ({ key: item._id?.title, value: item.count, id: item._id?._id }))?.slice(0,20)}
            title={'Most used routines'}
            units="plans"
            noDataMessage="There are no routines created yet."
          />
        </CardBody>
        <CardBody>
          <CircularChartContainer
            data={dataTraining?.standardPlans?.map(item => ({ key: item._id ? 'Standard' : 'Non-standard', value: item.count, id: item._id }))}
            title={'Standard routine plans'}
            units="plans"
            noDataMessage="There are no routine plans created yet."
          />
        </CardBody>
        <CardBody>          
          <BarChartContainer
            data={dataTraining?.plans?.filter(item => item._id !== null).map(item => ({ key: item._id?.title, value: item.count, id: item._id?._id }))}
            title={'Most used routine plans'}
            units="users"
            noDataMessage="There are no routine plans created yet."
          />
        </CardBody>
        <CardHeader title='Nutrition plans report'></CardHeader>
        <CardBody>
          <CircularChartContainer
            data={dataNutrition?.standard?.map(item => ({ key: item._id ? 'Standard' : 'Non-standard', value: item.count, id: item._id }))}
            title={'Standard nutritional plans'}
            units="plans"
            noDataMessage="There are no nutritional plans created yet."
          />
        </CardBody>
        <CardBody>          
          <BarChartContainer
            data={dataNutrition?.plans?.filter(item => item._id !== null).map(item => ({ key: item._id?.title, value: item.count, id: item._id?._id }))}
            title={'Most used nutritional plans'}
            units="users"
            noDataMessage="There are no nutritional plans created yet."
          />
        </CardBody>
      </Card>
    </>
  );
}
