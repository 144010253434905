/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import ForumIcon from '@material-ui/icons/Forum';
import { useHistory } from 'react-router-dom'

export function NotificationsChat() {
	const notifications = useSelector((store) => store.chat?.notifications, shallowEqual)
    const [totalNotifications, setTotalNotifications] = useState(0)
    const history = useHistory()

    useEffect(() => {
        if (!notifications) return
        let total = 0
        for (const n of Object.values(notifications)) {
            if (!isNaN(n)) total += n
        }
        setTotalNotifications(total)
    }, [notifications])

	return (
		<>
			<div className="topbar-item" style={{ position: 'relative' }} onClick={() => history.push('/chat')}>
				<div
					className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
					id="kt_quick_user_toggle"
				>
					<span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">Chat room</span>
					<ForumIcon style={{ color: '#06D6A1' }}/>
				</div>
                {totalNotifications ? <div className="notification-dot-topbar">{totalNotifications > 99 ? '...' : totalNotifications}</div> : null}
			</div>
		</>
	)
}

export default NotificationsChat;