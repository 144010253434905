import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteFoodFamily,
	getFoodFamilyById,
	postFoodFamily,
	updateFoodFamily,
} from "../../../../api/foodFamily";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyFoodFamily() {
	return {
		fullName: {},
		imageURL: null,
		active: true,
	};
}

export default function EditFoodFamiliesPage() {
	const [foodFamily, setFoodFamily] = useState(getEmptyFoodFamily());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	const foodFamilyId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!foodFamilyId) {
			disableLoadingData();
			return;
		}
		getFoodFamilyById(foodFamilyId)
			.then((res) => {
				if (res.status === 200) {
					setFoodFamily(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food family.",
				});
				history.push("/food-families");
			});
	}, [foodFamilyId, disableLoadingData, history]);

	function saveFoodFamily() {
		if (checkIsEmpty(foodFamily.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!foodFamilyId) {
			postFoodFamily(foodFamily, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Food family successfully created.",
						});
						history.push("/food-families");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save food family.",
					});
				});
		} else {
			updateFoodFamily(foodFamilyId, foodFamily, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/food-families");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!foodFamily[element]) foodFamily[element] = {};
			let newText = foodFamily[element];
			newText[lang] = event.target.value;
			setFoodFamily({ ...foodFamily, [element]: newText });
		} else
			setFoodFamily({
				...foodFamily,
				[element]: event.target.value,
			});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={
						(foodFamily.fullName && foodFamily.fullName[lang]) || ""
					}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit food family"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedImage ||
								(foodFamily.imageURL &&
									foodFamily.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(foodFamily.imageURL &&
								foodFamily.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview image"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${foodFamily.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: foodFamily.imageURL &&
										  foodFamily.imageURL !== ""
										? foodFamily.imageURL?.split(
												/-(.*)/s
										  )[1]
										: ""}
								</span>
								<Tooltip title={"Delete image"}>
									<Button
										size="small"
										onClick={() => {
											setSelectedImage(null);
											setFoodFamily({
												...foodFamily,
												imageURL: null,
											});
										}}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Delete />
									</Button>
								</Tooltip>
							</>
						)}
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={foodFamily.active}
									onChange={() =>
										setFoodFamily({
											...foodFamily,
											active: !foodFamily.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/food-families")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveFoodFamily()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save food family
					</Button>
					{foodFamilyId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete food family
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this food family?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteFoodFamily(foodFamilyId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Food family deleted successfully",
												});
												history.push("/food-families");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete food family.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
