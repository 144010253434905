import { API, authClient } from "../index";

export const getNotificationTexts = () => {
	return authClient().get(`${API}/notification-text?getDisabled=true`);
};

export const getNotificationTextById = (id) => {
	return authClient().get(`${API}/notification-text/${id}`);
};

export const deleteNotificationText = (id) => {
	return authClient().delete(`${API}/notification-text/${id}`);
};

export const postNotificationText = async (notificationText) => {
	return authClient().post(`${API}/notification-text`, notificationText);
};

export const updateNotificationText = async (id, notificationText) => {
	return authClient().put(`${API}/notification-text/${id}`, notificationText);
};

export const changeStatusNotificationText = async (id, active) => {
	return authClient().put(`${API}/notification-text/change-status/${id}`, {
		active,
	});
};
