import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  getSessionById,
  updateSession,
  updateTrainerReview,
} from "../../../../api/session";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { shallowEqual, useSelector } from "react-redux";
import { getUserById } from "../../../../api/user";
import { getRoutinePlanById } from "../../../../api/routinePlan";
import { getRoutineById } from "../../../../api/routine";
import { ErrorMessage } from "formik";

function getEmptySession() {
  return {
    date: null,
    user: null,
    comments: "",
    routinesProgress: [],
    trainerReview: "",
    reviewedByTrainer: false,
  };
}

async function getRoutines(routines) {
  const routineTitles = [];

  for (const key in routines) {
    const id = routines[key].id;
    const exerciseTitle = await getRoutineById(id.toString());
    routineTitles.push({ id: id.toString(), title: exerciseTitle.data.title });
  }
  return routineTitles;
}

export default function EditSessionsPage() {
  const [session, setSession] = useState(getEmptySession());
  const [userData, setUserData] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const [userRoutine, setUserRoutine] = useState(null);
  const [statistics, setStatistics] = useState([]);
  const [status, setStatus] = useState(0);
  const [trainerReview, setTrainerReview] = useState("");
  const [sendInfo, setSendInfo] = useState(false);
  const sessionId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  const ObtenirEstadístiques = (pla, userSession, exercisesTitle) => {
    console.log("PLAN: ", pla);
    console.log("Session: ", userSession);
    console.log("Exercises: ", exercisesTitle);
    const exercisesDone = pla.filter((p) =>
      userSession.some((s) => p.id === s.id)
    );
    let data = [];
    //Estructura: {"TítolExercici", "Planejat", "Efectuat"}
    exercisesDone.forEach((e) => {
      const extitle = exercisesTitle.filter((t) => t.id === e.id);
      const title = extitle[0];
      if (e.series != null) {
        //Càlcul score
        let repes = 0;
        e.series.map((r) => (repes += r));
        const planejat = { series: e.series.length, reps: repes };
        let exercise = userSession.find((ue) => ue.id === e.id);
        let reps = 0,
          weight = [];
        exercise.progress.forEach((ep) => {
          reps += ep.reps;
          weight.push(`${ep.weight} `);
        });
        const efectuat = {
          series: exercise.progress.length,
          reps: reps,
          weight: weight,
        };
        data.push({
          Type: "Series",
          Title: title,
          Planejat: planejat,
          Efectuat: efectuat,
        });
      } else if (e.nTimes != null) {
        let exercise = userSession.find((ue) => ue.id === e.id);
        const { nTimes } = Object(...exercise.progress);
        console.log(exercise.progress);
        data.push({
          Type: "nTimes",
          Title: title,
          Planejat: e.nTimes,
          Efectuat: nTimes,
        });
      } else {
        //Afegir dades
        let exercise = userSession.find((ue) => ue.id === e.id);
        const { time } = Object(...exercise.progress);
        data.push({
          Type: "Time",
          Title: title,
          Planejat: e.time,
          Efectuat: time,
        });
      }
    });

    //Estructura: {"TítolExercici", "Planejat", "Efectuat"}
    const exercisesNotDone = pla.filter(
      (p) => !userSession.some((s) => p.id === s.id)
    );
    console.log("EXERCISES NOT DONE: ", exercisesNotDone);
    exercisesNotDone.forEach((e) => {
      const extitle = exercisesTitle.filter((t) => t.id === e.id);
      const title = extitle[0];
      if (e.series != null) {
        let repes = 0;
        e.series.map((r) => (repes += r));
        const planejat = { series: e.series.length, reps: repes };
        data.push({
          Type: "Series",
          Title: title,
          Planejat: planejat,
          Efectuat: {},
        });
      } else if (e.nTimes != null) {
        data.push({
          Type: "nTimes",
          Title: title,
          Planejat: e.nTimes,
          Efectuat: 0,
        });
      } else {
        data.push({
          Type: "Time",
          Title: title,
          Planejat: e.time,
          Efectuat: 0,
        });
      }
    });
    console.log("DADES FINALS: ", data);
    return data;
  };

  useEffect(() => {
    if (!sessionId) {
      alertError({ error: null, customMessage: "Could not get session." });
      history.push("/sessions");
      disableLoadingData();
      return;
    }
    getSessionById(sessionId)
      .then((res) => {
        if (res.status === 200) {
          getUserById(res.data.user)
            .then((resp) => {
              if (resp.status === 200) {
                getRoutinePlanById(res.data.plan)
                  .then(async (respo) => {
                    if (respo.status === 200) {
                      setSession(res.data);
                      setTrainerReview(res.data.trainerReview);
                      setSendInfo(res.data.reviewedByTrainer);
                      console.log("SESSION INFO------", res.data);
                      setUserData(resp.data);
                      console.log("USER INFO-----", resp.data);
                      setUserRoutine(respo.data.routines[res.data.day]);
                      setUserPlan(respo.data);
                      console.log(
                        "PLANNING INFO: ",
                        respo.data.routines[res.data.day]
                      );
                      console.log(
                        "ROUTINES PROGRESS",
                        res.data.routinesProgress
                      );
                      //Obtenir els titles dels exercicis que es realitzen a la sessió
                      const sessionRoutine = await getRoutines(
                        respo.data.routines[res.data.day].plans
                      );
                      const routineProgress = [];
                      for (const key in res.data.routinesProgress) {
                        routineProgress.push({
                          id: key,
                          progress: res.data.routinesProgress[key].progress,
                        });
                      }
                      setStatus(res.data.score);
                      setStatistics(
                        ObtenirEstadístiques(
                          respo.data.routines[res.data.day].plans,
                          routineProgress,
                          sessionRoutine
                        )
                      );
                      //const planRoutines = getRoutines(respo.data.routines[res.data.day]);
                      disableLoadingData();
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not get plan data",
                    });
                    history.push("/sessions");
                  });
              }
            })
            .catch((error) => {
              alertError({
                error: error,
                customMessage: "Could not get user data.",
              });
              history.push("/sessions");
            });
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get session." });
        history.push("/sessions");
      });
  }, [sessionId, disableLoadingData, history]);

  function saveSession() {
    updateSession(sessionId, session)
      .then((res) => {
        if (res.status === 200) {
          alertSuccess({
            title: "Saved!",
            customMessage: "Changes successfully saved.",
          });
          history.push("/sessions");
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not save changes.",
        });
      });
  }

  const handleSave = () => {
    //CREAR ESTRUCTURA PER GUARDAR
    const body = {
      id: session.user,
      plan: session.plan,
      day: session.day,
      trainerReview: trainerReview,
      send: sendInfo,
    };
    updateTrainerReview(body)
      .then((res) => {
        if (res.status === 200) {
          alertSuccess({
            title: "Saved!",
            curstomMessage: "Feedback successfully sended",
          });
          history.push("/sessions");
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not send feedback",
        });
      });
  };



  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Review session feedback"></CardHeader>
          <CardBody>
            <div className="d-flex flex-column">
              <h4 className="font-weight-bold my-2">
                {userPlan.title} Status: {status ? status.toFixed(2) : 0}%
              </h4>
              <p className="font-weight-bold my-2">Description: </p>
              <div
                dangerouslySetInnerHTML={{ __html: userPlan.description }}
              ></div>
              <div>
                <h3>Session info: </h3>
                <ul className="list-group my-4">
                  <li className="list-group-item active">
                    {userData?.fullName} Session:
                  </li>
                  <li className="list-group-item">
                    {statistics?.map((e, index) => {
                      if (e.Type === "Series") {
                        if (Object.keys(e.Efectuat).length != 0) {
                          return (
                            <div className="row" key={index}>
                              <div className="col">
                                <h6>{e.Title.title}</h6>
                              </div>
                              <div className="col">
                                <span>Series: {e.Efectuat.series}</span>
                              </div>
                              <div className="col">
                                <span>Repeticions: {e.Efectuat.reps}</span>
                              </div>
                              <div className="col">
                                <span>Pes: {e.Efectuat.weight} Kg</span>
                              </div>
                            </div>
                          );
                        }
                      } else if (e.Type == "nTimes") {
                        if (e.Efectuat != 0) {
                          return (
                            <div className="row" key={index}>
                              <div className="col">
                                <h6>{e.Title.title}</h6>
                              </div>
                              <div className="col">
                                <span>
                                  Nombre de vegades realitzat: {e.Efectuat}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      } else {
                        if (e.Efectuat != 0) {
                          return (
                            <div className="row" key={index}>
                              <div className="col">
                                <h6>{e.Title.title}</h6>
                              </div>
                              <div className="col">
                                <span>
                                  Temps realitzat: {e.Efectuat} segons
                                </span>
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                  </li>
                </ul>
              </div>
              <div>
                <h3>Planning info: </h3>
                <li className="list-group-item active">Planning Routine:</li>
                <li className="list-group-item">
                  {statistics?.map((e, index) => {
                    if (e.Type === "Series") {
                      if (Object.keys(e.Planejat).length != 0) {
                        return (
                          <div className="row" key={index}>
                            <div className="col">
                              <h6 className="">{e.Title.title}</h6>
                            </div>
                            <div className="col">
                              <span>Series: {e.Planejat.series}</span>
                            </div>
                            <div className="col">
                              <span>Repeticions: {e.Planejat.reps}</span>
                            </div>
                          </div>
                        );
                      }
                    } else if (e.Type == "nTimes") {
                      if (e.Planejat != 0) {
                        return (
                          <div className="row" key={index}>
                            <div className="col-md-5">
                              <h6>{e.Title.title}</h6>
                            </div>
                            <div className="col-md-7">
                              <span>
                                Nombre de vegades a realitzar: {e.Planejat}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    } else {
                      if (e.Planejat != 0) {
                        return (
                          <div className="row" key={index}>
                            <div className="col-md-5">
                              <h6>{e.Title.title}</h6>
                            </div>
                            <div className="col-md-7">
                              <span>
                                Temps a realitzar: {e.Planejat} segons
                              </span>
                            </div>
                          </div>
                        );
                      } else
                        return <span>no es va ingressar cap valor...</span>;
                    }
                  })}
                </li>
              </div>
              <div className="my-2">
                <h4>Trainer Review:</h4>
                <div className="form-floating my-2">
                  <textarea
                    className="form-control"
                    placeholder="Leave a comment here"
                    value={trainerReview ? trainerReview : ""}
                    id="floatingTextarea2"
                    style={{ height: "10em" }}
                    onChange={(event) => {
                      let newMessage = event.target.value;
                      setTrainerReview(newMessage);
                    }}
                  />
                </div>
                <div className="d-flex justify-around mx-2 my-2">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mx-2"
                    onClick={() => handleSave()}
                  >
                    Guardar
                  </Button>
                  <div className="form-check form-check-inline d-flex align-items-center">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      defaultChecked={sendInfo}
                      disabled={sendInfo}
                      onClick={(event) => {
                        const value = event.target.value;
                        setSendInfo(value);
                      }}
                    />
                    <label
                      className="form-check-label cursor-pointer mx-1"
                      htmlFor="inlineRadio1"
                    >
                      Enviar Feedback
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => history.push("/sessions")}
            variant="outlined"
            style={{ marginRight: "20px" }}
          >
            Back
          </Button>
          <Button
            onClick={() => saveSession()}
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Save session review
          </Button>
        </div>
      </>
    );
}
