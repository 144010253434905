import { API, authClient } from '../index';
import { uploadSingleFile } from '../files';

// Get all muscle groups
export const getMuscleGroups = () => {
	return authClient().get(`${API}/muscle-group?getDisabled=true`);
};

// Get muscle group by id
export const getMuscleGroupById = (id) => {
	return authClient().get(`${API}/muscle-group/${id}`);
};

// Delete muscle group
export const deleteMuscleGroup = (id) => {
	return authClient().delete(`${API}/muscle-group/${id}`);
};

// Create muscle group 
export const postMuscleGroup = async (muscleGroup, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'muscleGroups')
		muscleGroup.iconURL = response.data.fileURL
	}
	return authClient().post(`${API}/muscle-group`, muscleGroup);
};

// Update muscle group
export const updateMuscleGroup = async (id, muscleGroup, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'muscleGroups')
		muscleGroup.iconURL = response.data.fileURL
	}
	return authClient().put(`${API}/muscle-group/${id}`, muscleGroup);
};

// Change status muscle group
export const changeStatusMuscleGroup = async (id, active) => {
	return authClient().put(`${API}/muscle-group/change-status/${id}`, { active });
};