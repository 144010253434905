import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	FormHelperText,
	Tooltip
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteUser,
	getAIModels,
	getUserById,
	postUser,    
	updateUser,
} from '../../../../api/user';
import { getTrainerCertifications } from '../../../../api/trainerCertification';
import { getTrainerSpecializations } from '../../../../api/trainerSpecialization';
import { getNutritionistCertifications } from '../../../../api/nutritionistCertification';
import { getNutritionistSpecializations } from '../../../../api/nutritionistSpecialization';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { ArrowUpward, ArrowDownward, Visibility } from '@material-ui/icons'
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import { SERVER_URL } from "../../../../api/index"
import DeleteIcon from '@material-ui/icons/Delete'
import { buttonsStyle } from '../../../components/tables/table'
import Editor from '../../../components/editor/Editor'
import { useSelector, shallowEqual } from "react-redux";
import { getNonEmpty } from '../../../../utils/helpers';
import { weekdays } from '../nutritionalPlans/EditNutritionalPlansPage';
import DaysTabBlock from '../../../components/DaysTabBlock';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

let initialOfficeHours = {}
weekdays.forEach(day => initialOfficeHours = {...initialOfficeHours, [day]: {
	start: null, end: null
}})

function getEmptyProfessional() {

	return {
		fullName: '',
		active: true,
		email: '',
		role: [],
		birthdate: null,
		imagesURLs: [],
		priceHour: '',
		skills: '',
		password: '',
		repeatPassword: '',
		certifications: [],
		specializations: [],
		isAI: false,
		AIdescription: '',
		AIModel: null,
		officeHours: initialOfficeHours
	};
}

export default function EditProfessionalsPage() {
	const [professional, setProfessional] = useState(getEmptyProfessional());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [selectedImages, setSelectedImages] = useState(null)
	const [deletedImages, setDeletedImages] = useState(null)
	const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
	const [trainerSpecializations, setTrainerSpecializations] = useState(null)
	const [trainerCertifications, setTrainerCertifications] = useState(null)
	const [nutritionistCertifications, setNutritionistCertifications] = useState(null);
	const [nutritionistSpecializations, setNutritionistSpecializations] = useState(null);
	const [newPassword, setNewPassword] = useState({ password: null, repeatPassword: null })
	const [changePassword, setChangePassword] = useState(false)
	const [AIModels, setAIModels] = useState(null)
	const professionalId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getAIModels()
			.then((res) => {
				if (res.status === 200) {
					setAIModels(res.data.data)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get AI models.' });
				history.push('/professionals');
			});
		getTrainerSpecializations()
			.then((res) => {
				if (res.status === 200) {
					setTrainerSpecializations(res.data)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get trainer specializations.' });
				history.push('/professionals');
			});
		getTrainerCertifications()
			.then((res) => {
				if (res.status === 200) {
					setTrainerCertifications(res.data)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get trainer certifications.' });
				history.push('/professionals');
			});
			getNutritionistSpecializations()
			.then((res) => {
				if (res.status === 200) {
					setNutritionistSpecializations(res.data)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get nutritionist specializations.' });
				history.push('/admins');
			});
		getNutritionistCertifications()
			.then((res) => {
				if (res.status === 200) {
					setNutritionistCertifications(res.data)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get nutritionist certifications.' });
				history.push('/admins');
			});
		if (!professionalId) {
			disableLoadingData();
			return;
		}
		getUserById(professionalId)
			.then((res) => {
				if (res.status === 200) {
					const professional = res.data;
					delete professional.password;
					setProfessional(professional);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get professional.' });
				history.push('/professionals');
			});
	}, [professionalId, disableLoadingData, history]);

	function saveProfessional() {
		let saveProfessional = professional
		if (!professionalId || changePassword) {
		  if (!newPassword.password || !newPassword.repeatPassword) {
			alertError({ error: null, customMessage: 'Please enter the password.' })
			return
		  }
		  if (newPassword.password !== newPassword.repeatPassword) {
			alertError({ error: null, customMessage: 'Passwords do not match.' })
			return
		  }
		  saveProfessional = {...saveProfessional, password: newPassword.password }
		}
		if (typeof saveProfessional.priceHour === 'string') {
			saveProfessional.priceHour = saveProfessional.priceHour.includes(',') ? saveProfessional.priceHour.replace(',', '.') : saveProfessional.priceHour
			if (isNaN(saveProfessional.priceHour)) {
			  alertError({ error: null, customMessage: 'Price/Hour should be a number.' })
			  return
			}
			saveProfessional.priceHour = parseFloat(saveProfessional.priceHour)
		}
		if (!professionalId) {
			postUser(saveProfessional, selectedImages)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Professional successfully created.',
						});
						history.push('/professionals');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save professional.' });
				});
		} else {
			updateUser(professionalId, saveProfessional, selectedImages)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/professionals');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, day) => (event) => {
		if (day) {
			if (event.target.value === ' ') return
			let newText = professional.officeHours || initialOfficeHours
			newText[day][element] = event.target.value
			setProfessional({ ...professional, officeHours: newText })
		} else setProfessional({ ...professional, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value) => {
		setProfessional({ ...professional, [element]: value })
	  }


	const handleDeleteImage = (index) => {
		// TODO: DELETE IMAGES FROM SERVER
		let newImages = [...professional.imagesURLs]
		const deletedImage = newImages.splice(index, 1)
		setProfessional({ ...professional, imagesURLs: newImages })
		if (selectedImages && selectedImages[index]) {
		  let _selectedImages = {}
		  for (const [key, value] of Object.entries(selectedImages)) {
			if (key !== index) {
			  if (key > index) {
				_selectedImages[key - 1] = value
			  } else _selectedImages[key] = value
			} 
		  }
		  if (!Object.keys(_selectedImages).length) _selectedImages = null
		  setSelectedImages(_selectedImages)
		} else setDeletedImages(deletedImages ? deletedImages.concat(deletedImage) : [deletedImage])
	  }
	
	  const handleMoveImage = (index, newIndex) => {
		const aux = professional.imagesURLs[index]
		let images = [...professional.imagesURLs]
		images.splice(index, 1, images[newIndex])
		images.splice(newIndex, 1, aux)
		setProfessional({ ...professional, imagesURLs: images })
		if (selectedImages && (selectedImages[index] || selectedImages[newIndex])) {
		  let _selectedImages = {}
		  for (const [key, value] of Object.entries(selectedImages)) {
			if (key === index.toString()) _selectedImages[newIndex] = value
			else if (key === newIndex.toString()) _selectedImages[index] = value
			else _selectedImages[key] = value
		  }
		  setSelectedImages(_selectedImages)
		}
	  }

	  const renderDaysTabContent = day => {
		const dayLabel = day.charAt(0).toUpperCase() + day.slice(1)
		return (
		  <>
			<br/>
			<h5>{`${dayLabel} office hours`}</h5>
			<br/>
			<TextField
				id={`${dayLabel} start office hours`}
				label={`${dayLabel} start office hours`}
				placeholder={`Enter ${day} start office hours here...`}
				value={(professional.officeHours && professional.officeHours[day] && professional.officeHours[day].start) || ''}
				onChange={handleChange('start', day)}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				variant="outlined"
				type="time"
				required
			/>
			<br/>
			<TextField
				id={`${dayLabel} end office hours`}
				label={`${dayLabel} end office hours`}
				placeholder={`Enter ${day} end office hours here...`}
				value={(professional.officeHours && professional.officeHours[day] && professional.officeHours[day].end) || ''}
				onChange={handleChange('end', day)}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				type="time"
				variant="outlined"
				required
			/>
		  </>
		)
	  }

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit professional'></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label='Full name'
							value={professional.fullName}
							onChange={handleChange('fullName')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`email`}
							label='Email'
							value={professional.email}
							onChange={handleChange('email')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={professional.role || ''}
								multiple
								onChange={handleChange('role')}
								MenuProps={MenuProps}
							>
							{['nutritionist', 'trainer', 'sportsman'].map((option) => (
								<MenuItem key={option} value={option}>{option}</MenuItem>
							))}
							</Select>
							<FormHelperText>Select at least one role</FormHelperText>
						</FormControl>
						<br />
						<br />
						{professional.role?.includes('trainer') && <>
							<FormControl style={{ width: '100%' }}>
								<InputLabel id="demo-simple-select-standard-label">Trainer specializations</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={professional.specializations}
									multiple
									onChange={handleChange('specializations')}
									MenuProps={MenuProps}
								>
								{trainerSpecializations?.map((option) => (
									<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.fullName)}</MenuItem>
								))}
								</Select>
								<FormHelperText>Select at least one specialization</FormHelperText>
							</FormControl>
							<br />
							<br />
							<FormControl style={{ width: '100%' }}>
								<InputLabel id="demo-simple-select-standard-label">Trainer certifications</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={professional.certifications}
									multiple
									onChange={handleChange('certifications')}
									MenuProps={MenuProps}
								>
								{trainerCertifications?.map((option) => (
									<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.fullName)}</MenuItem>
								))}
								</Select>
								<FormHelperText>Select at least one certification</FormHelperText>
							</FormControl>
							<br />
							<br />
						</>}
						<br />
						{professional.role?.includes('nutritionist') && <>
							<FormControl style={{ width: '100%' }}>
								<InputLabel id="demo-simple-select-standard-label">Nutritionist specializations</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={professional.specializations}
									multiple
									onChange={handleChange('specializations')}
									MenuProps={MenuProps}
								>
								{nutritionistSpecializations?.map((option) => (
									<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.fullName)}</MenuItem>
								))}
								</Select>
								<FormHelperText>Select at least one specialization</FormHelperText>
							</FormControl>
							<br />
							<FormControl style={{ width: '100%' }}>
								<InputLabel id="demo-simple-select-standard-label">Nutritionists certifications</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={professional.certifications}
									multiple
									onChange={handleChange('certifications')}
									MenuProps={MenuProps}
								>
								{nutritionistCertifications?.map((option) => (
									<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.fullName)}</MenuItem>
								))}
								</Select>
								<FormHelperText>Select at least one certification</FormHelperText>
							</FormControl>
							<br />
							<br />
						</>}
						<TextField
							id={`priceHour`}
							label="Price/Hour"
							value={professional.priceHour}
							onChange={handleChange('priceHour')}
							InputLabelProps={{
								shrink: true
							}}
							type="number"
							margin="normal"
							variant="outlined"
							required
						/>
						<br/>
						<Editor
							body={professional.skills || ''}
							setBody={new_body => handleChangeEditor('skills', new_body)}
							className='max-height'
							placeholder={'Enter skills description here...'}
							label="Skills"
						/>
						<br/>
						{(!professionalId || changePassword) ? <>
							<br />
							<br />
							<TextField
								id={`password`}
								label="Password"
								value={newPassword.password}
								onChange={(event) => {
									if (event.target.value !== ' ') setNewPassword({ ...newPassword, password: event.target.value })
								}}
								InputLabelProps={{
									shrink: true
								}}
								type="password"
								margin="normal"
								variant="outlined"
								required
							/>
							<TextField
								id={`repeatPassword`}
								label="Repeat password"
								value={newPassword.repeatPassword}
								onChange={(event) => {
									if (event.target.value !== ' ') setNewPassword({ ...newPassword, repeatPassword: event.target.value })
								}}
								InputLabelProps={{
									shrink: true
								}}
								type="password"
								margin="normal"
								variant="outlined"
								required
							/>
							<br/>
							<br/>
							{professionalId && <>
								<Button
									onClick={() => {
										setChangePassword(false)
										setNewPassword({ password: null, repeatPassword: null })
									}}
									variant="outlined"
									style={{ marginRight: '20px' }}
								>
									Cancel change password
								</Button>
								<br />
								<br />  
							</>}
							</> : <>
							<br />
							<br />
							<Button
								onClick={() => setChangePassword(true)}
								variant="outlined"
								color="primary"
								style={{ marginRight: '20px' }}>
								Change password
							</Button>
							<br />
							<br />
						</>}
						<br/>
						<FormControlLabel
							control={<Checkbox checked={professional.active} onChange={() => setProfessional({ ...professional, active: !professional.active })} name="checkActive" />}
							label="Active"
						/>
						{user.role.includes('admin') && <>
							<FormControlLabel
								control={<Checkbox checked={professional.isAI} onChange={() => setProfessional({ ...professional, isAI: !professional.isAI })} name="checkIsAI" />}
								label="Is AI?"
							/>
							<br />
							{professional.isAI && <>
								<TextField
									id={`AIdescription`}
									label='AI description'
									value={professional.AIdescription}
									onChange={handleChange('AIdescription')}
									InputLabelProps={{
										shrink: true,
									}}
									multiline
									rows={3}
									margin='normal'
									variant='outlined'
									required
								/>
								<br />
								<br />
								<FormControl style={{ width: '100%' }}>
									<InputLabel id="demo-simple-select-standard-label">AI model</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={professional.AImodel}
										onChange={handleChange('AImodel')}
										MenuProps={MenuProps}
									>
									{AIModels?.map((option) => (
										<MenuItem key={option.id} value={option.id}>{option.id}</MenuItem>
									))}
									</Select>
									<FormHelperText>Select an AI model</FormHelperText>
								</FormControl>
								<br />
								<br />
							</>}
						</>}
						<br />
						<br />
						{professional.imagesURLs?.map((_, index) => {
							return (
							<>
								<label htmlFor={"upload-image" + index}>
									<input
										style={{ display: 'none' }}
										id={"upload-image" + index}
										name={"upload-image" + index}
										type="file"
										accept={'image/*'}
										onChange={(e) => {
											setSelectedImages({ ...selectedImages, [index]: e.target.files[0] })
										}}
									/>
									<Button
										style={{ marginRight: '15px' }}
										color="secondary"
										component="span"
										variant="outlined">
										{((selectedImages && selectedImages[index]) || professional.imagesURLs[index] !== '') ? 'Change image ' + (index + 1) : 'Upload image ' + (index + 1)}
									</Button>
								</label>
								{((selectedImages && selectedImages[index]) || professional.imagesURLs[index] !== '') &&
								<>
									<Tooltip title={'Preview image ' + (index + 1)}>
										<Button
											size="small"
											onClick={() => setOpenPreviewDialog(index)}
											style={buttonsStyle}>
											<Visibility/>
										</Button>
									</Tooltip>
									<PreviewDialog
										title={'Preview image ' + (index + 1)}
										open={openPreviewDialog === index}
										setOpen={setOpenPreviewDialog}
										src={(selectedImages && selectedImages[index]) ? URL.createObjectURL(selectedImages[index]) : `${SERVER_URL}/${professional.imagesURLs[index]}`}
									/>
									<Tooltip title="Delete">
										<Button
											size="small"
											style={buttonsStyle}
											onClick={() => handleDeleteImage(index)}>
											<DeleteIcon/>
										</Button>
									</Tooltip>
									<Tooltip title="Move up">
										<Button
											size="small"
											style={buttonsStyle}
											disabled={index === 0}
											onClick={() => handleMoveImage(index, index - 1)}>
											<ArrowUpward/>
										</Button>
									</Tooltip>
									<Tooltip title="Move down">
										<Button
											size="small"
											disabled={!(index < professional.imagesURLs.length - 1 && ((selectedImages && selectedImages[index + 1]) || professional.imagesURLs[index + 1] !== ''))}
											style={{...buttonsStyle, marginRight: '1em' }}
											onClick={() => handleMoveImage(index, index + 1)}>
											<ArrowDownward/>
										</Button>
									</Tooltip>
									<span>
										{selectedImages && selectedImages[index] ? selectedImages[index]?.name : (professional.imagesURLs[index] !== '' ? professional.imagesURLs[index].split(/-(.*)/s)[1] : '')}
									</span>
									<br />
								</>
								}
							</>
							)
						})}
						<br />
						<br />
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setProfessional({ ...professional, imagesURLs: professional.imagesURLs.concat('') })}
						>
							Add image
						</Button>
						<br/>
						<br/>
						{!professional.isAI && <DaysTabBlock
							daysTabContent = {renderDaysTabContent}
							days = {weekdays}
						/>}
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/professionals')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveProfessional()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save professional
					</Button>
					{professionalId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete professional
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this professional?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteUser(professionalId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Professional deleted successfully',
												});
												history.push('/professionals');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete professional.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
