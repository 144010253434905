import { API, authClient } from "../index";
import { uploadSingleFile } from "../files";

// Get all teams
export const getTeams = () => {
	return authClient().get(`${API}/team?getPrivate=true`);
};

// Get team by id
export const getTeamById = (id) => {
	return authClient().get(`${API}/team/${id}`);
};

// Delete team
export const deleteTeam = (id) => {
	return authClient().delete(`${API}/team/${id}`);
};

// Create team
export const postTeam = async (team, logo) => {
	if (logo) {
		const response = await uploadSingleFile(logo, "teams");
		team.logoURL = response.data.fileURL;
	}
	return authClient().post(`${API}/team`, team);
};

// Update team
export const updateTeam = async (id, team, logo) => {
	if (logo) {
		const response = await uploadSingleFile(logo, "teams");
		team.logoURL = response.data.fileURL;
	}
	return authClient().put(`${API}/team/${id}`, team);
};
