import { authClient, API } from '../index'

// Get all translations
export const getTranslations = () => {
  return authClient().get(`${API}/translation`)
}

// Get translation by id
export const getTranslationById = (id) => {
  return authClient().get(`${API}/translation/${id}`)
}

// Get translations by entity id
export const getTranslationsByEntityId = (entityId, lang) => {
  return authClient().get(`${API}/translation/entity/${entityId}${lang ? '?lang=' + lang : ''}`)
}

// Delete translation
export const deleteTranslation = (id) => {
  return authClient().delete(`${API}/translation/${id}`)
}

// Create translation
export const postTranslation = (translation) => {
  return authClient().post(`${API}/translation`, translation)
}

// Update translation
export const updateTranslation = (id, translation) => {
  return authClient().put(`${API}/translation/${id}`, translation)
}
