import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import {
	ArrowDownward,
	ArrowUpward,
	Delete,
	Edit,
	Visibility,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api/index";
import { getSports } from "../../../../api/sport";
import {
	deleteQuestion,
	getQuestionById,
	postQuestion,
	updateQuestion,
} from "../../../../api/question";
import { checkIsEmpty, getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import EditAnswerDialog from "../../../components/dialogs/EditAnswerDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import ViewAnswerDialog from "../../../components/dialogs/ViewAnswerDialog";
import Editor from "../../../components/editor/Editor";
import Table, {
	booleanFormatter,
	buttonsStyle,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import QuestionMarkIcon from "@material-ui/icons/QuestionAnswer";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyQuestion() {
	return {
		statement: {},
		longStatement: {},
		sport: null,
		imageURL: "",
		videoURL: "",
		documentURL: "",
		answers: [],
		active: true,
	};
}

function getAnswerTableData(answers) {
	let data = [];

	for (let i = 0; i < answers.length; ++i) {
		const answer = answers[i];
		const elem = {};

		elem.title = getNonEmpty(answer?.title);
		elem.explanation = getNonEmpty(answer?.explanation);
		elem.correct = answer?.correct;
		elem.imageURL = answer?.selectedImage
			? URL.createObjectURL(answer?.selectedImage)
			: "";
		elem.id = answers[i].id;

		data.push(elem);
	}

	return data;
}

export default function EditQuestionsPage() {
	const [question, setQuestion] = useState(getEmptyQuestion());
	const [sports, setSports] = useState([]);

	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [selectedDocument, setSelectedDocument] = useState(null);
	const [selectedAnswer, setSelectedAnswer] = useState(null);

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openEditAnswerDialog, setOpenEditAnswerDialog] = useState(false);
	const [openViewAnswerDialog, setOpenViewAnswerDialog] = useState(false);

	const [refresh, setRefresh] = useState(false);
	const questionId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getSports()
			.then((res) => {
				if (res.status === 200) {
					setSports(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get sports.",
				});
				history.push("/sports/lessons");
			});
		if (!questionId) {
			disableLoadingData();
			return;
		}
		getQuestionById(questionId)
			.then((res) => {
				if (res.status === 200) {
					let question = {
						...res.data, sport: res.data.sport?._id
					};
					question.answers.forEach((answer, index) => {
						answer.id = index;
					});
					setQuestion(question);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get question.",
				});
				history.push("/sports/questions");
			});
	}, [questionId, disableLoadingData, history]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	function saveQuestion() {
		let saveQuestion = question;
		question.answers.forEach((answer) => {
			delete answer.id;
		});

		if (checkIsEmpty(saveQuestion.statement))
			return alertError({
				error: null,
				customMessage:
					"The statement is required in at least one of the languages.",
			});
		if (!questionId) {
			postQuestion(
				saveQuestion,
				selectedImage,
				selectedVideo,
				selectedDocument
			)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Question successfully created.",
						});
						history.push("/sports/questions");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save question.",
					});
				});
		} else {
			updateQuestion(
				questionId,
				saveQuestion,
				selectedImage,
				selectedVideo,
				selectedDocument
			)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/sports/questions");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!question[element]) question[element] = {};
			let newText = question[element];
			newText[lang] = event.target.value;
			setQuestion({ ...question, [element]: newText });
		} else setQuestion({ ...question, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!question[element]) question[element] = {};
				let newText = question[element];
				newText[lang] = value;
				setQuestion({ ...question, [element]: newText });
			} else setQuestion({ ...question, [element]: value });
		}
	};

	const handleMoveAnswer = (index, newIndex) => {
		const aux = question.answers[index];
		if (!aux)
			return alertError({
				error: null,
				customMessage:
					"An unexpected error has ocurred trying to move the answers, please try again.",
			});
		let _answers = question.answers;
		_answers.splice(index, 1, _answers[newIndex]);
		_answers.splice(newIndex, 1, aux);
		setRefresh(true);
		setQuestion({ ...question, answers: _answers });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`statement`}
					label="Statement"
					value={
						(question.statement && question.statement[lang]) || ""
					}
					onChange={handleChange("statement", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<br />
				<div>Long Statement</div>
				<Editor
					body={
						(question.longStatement &&
							question.longStatement[lang]) ||
						""
					}
					setBody={(new_body) => {
						handleChangeEditor("longStatement", new_body, lang);
					}}
					className="max-height"
					placeholder={"Enter long statement here..."}
				/>
			</>
		);
	};

	function buttonFormatter(cell) {
		const answer = question?.answers?.find((x) => x.id === cell);
		const index = question?.answers?.findIndex((x) => x.id === cell);

		return (
			<>
				<Tooltip title="View">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenViewAnswerDialog(true);
							setSelectedAnswer(answer);
						}}
					>
						<Visibility />
					</Button>
				</Tooltip>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditAnswerDialog(true);
							setSelectedAnswer(answer);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() => handleMoveAnswer(index, index - 1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={index >= question.answers.length - 1}
						style={buttonsStyle}
						onClick={() => handleMoveAnswer(index, index + 1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let _answers = [...question.answers];
							_answers.splice(index, 1);
							setQuestion({
								...question,
								answers: _answers,
							});
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	function imageFormatter(absoluteImageURL) {
		return absoluteImageURL && absoluteImageURL !== "" ? (
			<img
				src={absoluteImageURL}
				alt="answer"
				style={{ width: "50px", height: "50px" }}
				onClick={() => {}}
			/>
		) : (
			<div />
		);
	}

	const answerColumns = [
		{
			dataField: "imageURL",
			text: "",
			formatter: imageFormatter,
			headerAlign: "center",
			align: "center",
		},
		{ dataField: "title", text: "Title" },
		{
			dataField: "correct",
			text: "Correct",
			headerAlign: "center",
			align: "center",
			formatter: booleanFormatter,
		},
		{
			dataField: "id",
			text: "",
			formatter: buttonFormatter,
		},
	];

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit question"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<br />
						<Autocomplete
							id="sports"
							disablePortal
							options={sports}
							getOptionLabel={(option) =>
								getNonEmpty(option?.fullName)
							}
							value={sports?.find(
								(x) => x._id === question?.sport
							)}
							onChange={(event, selected) => {
								setQuestion({
									...question,
									sport: selected?._id || null,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									placeholder="Select a sport"
									label="Sport"
									required
								/>
							)}
						/>
						<br />
						<br />
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedImage ||
								(question.imageURL && question.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(question.imageURL &&
								question.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview image"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${question.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: question.imageURL &&
										  question.imageURL !== ""
										? question.imageURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<label htmlFor={"upload-video"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-video"}
								name={"upload-video"}
								type="file"
								accept={"video/*"}
								onChange={(e) => {
									setSelectedVideo(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedVideo ||
								(question.videoURL && question.videoURL !== "")
									? "Change video"
									: "Upload video"}
							</Button>
						</label>
						{(selectedVideo ||
							(question.videoURL &&
								question.videoURL !== "")) && (
							<>
								<Tooltip title={"Preview video"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview video"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedVideo
											? URL.createObjectURL(selectedVideo)
											: `${SERVER_URL}/${question.videoURL}`
									}
								/>
								<span>
									{selectedVideo
										? selectedVideo?.name
										: question.videoURL &&
										  question.videoURL !== ""
										? question.videoURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<label htmlFor={"upload-document"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-document"}
								name={"upload-document"}
								type="file"
								accept={"application/pdf"}
								onChange={(e) => {
									setSelectedDocument(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedDocument ||
								(question.documentURL &&
									question.documentURL !== "")
									? "Change document"
									: "Upload document"}
							</Button>
						</label>
						{(selectedDocument ||
							(question.documentURL &&
								question.documentURL !== "")) && (
							<>
								<Tooltip title={"Preview document"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview document"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedDocument
											? URL.createObjectURL(
													selectedDocument
											  )
											: `${SERVER_URL}/${question.documentURL}`
									}
								/>
								<span>
									{selectedDocument
										? selectedDocument?.name
										: question.documentURL &&
										  question.documentURL !== ""
										? question.documentURL?.split(
												/-(.*)/s
										  )[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={question.active}
									onChange={() =>
										setQuestion({
											...question,
											active: !question.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
					<CardHeader title="Answers">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenEditAnswerDialog(true);
									setSelectedAnswer(null);
								}}
							>
								Add new answer
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh && question?.answers?.length > 0 && (
							<Table
								columns={answerColumns}
								data={getAnswerTableData(question.answers)}
							></Table>
						)}
						<EditAnswerDialog
							title="Edit answer"
							open={openEditAnswerDialog}
							data={selectedAnswer}
							setOpen={setOpenEditAnswerDialog}
							onSave={(answer) => {
								let answers = [...question.answers];

								const index = answers.findIndex(
									(x) => x.id === answer.id
								);

								if (index !== -1) {
									answers[index] = { ...answer };
								} else {
									answers.push({
										...answer,
										id: answers.length,
									});
								}

								setRefresh(true);
								setQuestion({ ...question, answers: answers });
							}}
						></EditAnswerDialog>
						<ViewAnswerDialog
							open={openViewAnswerDialog}
							answer={selectedAnswer}
							setOpen={setOpenViewAnswerDialog}
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/sports/questions")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveQuestion()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save question
					</Button>
					{questionId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete question
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this question?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteQuestion(questionId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Question deleted successfully",
												});
												history.push(
													"/sports/questions"
												);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete question.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
