import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	Button,
	TextField,
	Select,
	Checkbox,
	InputLabel,
	MenuItem,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Tooltip,
	MuiThemeProvider,
	createMuiTheme,
} from '@material-ui/core';
import { Visibility, Delete } from '@material-ui/icons';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { SERVER_URL } from 'api/index';
import { deleteScreen, getScreenById, postScreen, updateScreen } from 'api/screen';
import { useSkeleton } from 'app/hooks/useSkeleton';
import MultiLanguageTabBlock from 'app/components/MultilanguageTabBlock';
import Editor from 'app/components/editor/Editor';
import ConfirmDialog from 'app/components/dialogs/ConfirmDialog';
import PreviewDialog from 'app/components/dialogs/PreviewDialog';
import { buttonsStyle } from 'app/components/tables/table';
import { alertError, alertSuccess } from 'utils/logger';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 },
	},
	getContentAnchorEl: () => null,
};

function getEmptyScreen() {
	return {
		name: '',
		title: {},
		content: {},
		active: false,
		asset: null,
		theme: '#FFF',
		group: '',
		orderWithinGroup: 1,
	};
}

export function EditScreenPage() {
	const [screen, setScreen] = useState(getEmptyScreen());
	const [selectedAsset, selectAsset] = useState(null);

	const [openPreviewAsset, setOpenPreviewAsset] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const screenId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!screenId) {
			disableLoadingData();
			return;
		}
		getScreenById(screenId)
			.then((res) => {
				if (res.status === 200) {
					setScreen(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get screen.' });
				history.push('/screens');
			});
	}, [screenId, disableLoadingData, history]);

	function saveScreen() {
		const data = {
			...screen,
			orderWithinGroup:
				screen.group !== 'other' ? parseInt(screen.orderWithinGroup) : null,
		};
		if (!screenId)
			postScreen(data, selectedAsset)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Screen successfully created.',
						});
						history.push('/screens');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save screen.',
					})
				);
		else
			updateScreen(screenId, data, selectedAsset)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/screens');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					})
				);
	}

	const handleChange = (key, lang) => (event) => {
		if (lang) {
			if (event.target.value === ' ') return;
			if (!screen[key]) screen[key] = {};
			let newScreen = screen[key];
			newScreen[lang] = event.target.value;
			setScreen({ ...screen, [key]: newScreen });
		} else setScreen({ ...screen, [key]: event.target.value });
	};

	const handleChangeEditor = (key, value, lang) => {
		if (value === ' ') return;
		if (lang) {
			if (!screen[key]) screen[key] = {};
			let newScreen = screen[key];
			newScreen[lang] = value;
			setScreen({ ...screen, [key]: newScreen });
		} else setScreen({ ...screen, [key]: value });
	};

	const renderMultiLanguageTabContent = (lang) => {
		return (
			<>
				<TextField
					id='title'
					label='Title'
					value={(screen.title && screen.title[lang]) || ''}
					onChange={handleChange('title', lang)}
					InputLabelProps={{ shrink: true }}
					margin='normal'
					variant='outlined'
					required
				/>
				<br />
				<br />
				<div>{'Content (optional)'}</div>
				<Editor
					body={(screen.content && screen.content[lang]) || ''}
					setBody={(text) => handleChangeEditor('content', text, lang)}
					className='max-height'
					placeholder={'Enter screen content here...'}
					lang={lang}
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	return (
		<>
			<Card>
				<CardHeader title='Edit Screen' />
				<CardBody>
					<TextField
						id='name'
						label='Name'
						value={screen.name}
						onChange={handleChange('name')}
						InputLabelProps={{ shrink: true }}
						margin='normal'
						variant='outlined'
						required
					/>
					<br />
					<br />
					<MultiLanguageTabBlock
						multilanguageTabContent={renderMultiLanguageTabContent}
					/>
					<br />
					<TextField
						id='theme'
						label='Theme (HEX color)'
						value={screen.theme}
						onChange={handleChange('theme')}
						InputLabelProps={{ shrink: true }}
						margin='normal'
						variant='outlined'
						required
					/>
					<br />
					<br />
					<FormControl style={{ width: '100%' }}>
						<InputLabel id='demo-simple-select-standard-label'>
							Screen Group
						</InputLabel>
						<Select
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							value={screen.group}
							onChange={handleChange('group')}
							MenuProps={MenuProps}>
							<MenuItem key={'onboarding'} value={'onboarding'}>
								{'Onboarding'}
							</MenuItem>
							<MenuItem key={'other'} value={'other'}>
								{'Other'}
							</MenuItem>
						</Select>
						<FormHelperText>Select the screen group</FormHelperText>
					</FormControl>
					<br />
					{screen.group && screen.group !== 'other' && (
						<>
							<br />
							<TextField
								id={`orderWithinGroup`}
								label='Order within the group'
								value={screen.orderWithinGroup}
								onInput={handleChange('orderWithinGroup')}
								InputLabelProps={{ shrink: true }}
								type='number'
								inputProps={{ min: 0, step: 1, type: 'number' }}
								margin='normal'
								variant='outlined'
							/>
						</>
					)}
					<br />
					<br />
					<label htmlFor={'upload-image'}>
						<input
							style={{ display: 'none' }}
							id={'upload-image'}
							name={'upload-image'}
							type='file'
							accept={'image/*'}
							onChange={(e) => selectAsset(e.target.files[0])}
						/>
						<Button
							style={{ marginRight: '15px' }}
							color='secondary'
							component='span'
							variant='outlined'>
							{selectedAsset || (screen.asset && screen.asset !== '')
								? 'Change image'
								: 'Upload image'}
						</Button>
					</label>
					{(selectedAsset || (screen.asset && screen.asset !== '')) && (
						<>
							<Tooltip title={'Delete image'}>
								<Button
									size='small'
									onClick={() => setOpenConfirmDialog(2)}
									style={{ ...buttonsStyle, marginRight: '15px' }}>
									<Delete />
								</Button>
							</Tooltip>
							<Tooltip title={'Preview image'}>
								<Button
									size='small'
									onClick={() => setOpenPreviewAsset(true)}
									style={{ ...buttonsStyle, marginRight: '15px' }}>
									<Visibility />
								</Button>
							</Tooltip>
							<PreviewDialog
								title={'Preview image'}
								open={openPreviewAsset}
								setOpen={setOpenPreviewAsset}
								src={
									selectedAsset
										? URL.createObjectURL(selectedAsset)
										: `${SERVER_URL}/${screen.asset}`
								}
							/>
							<span>
								{selectedAsset
									? selectedAsset?.name
									: screen.asset && screen.asset !== ''
									? screen.asset?.split(/-(.*)/s)[1]
									: ''}
							</span>
						</>
					)}
					<br />
					<br />
					<FormControlLabel
						control={
							<Checkbox
								checked={screen.active}
								onChange={() =>
									setScreen({ ...screen, active: !screen.active })
								}
								name='checkActive'
							/>
						}
						label='Active'
					/>
				</CardBody>
			</Card>
			<Button
				onClick={() => history.push('/screens')}
				variant='outlined'
				style={{ marginRight: '20px' }}>
				Back
			</Button>
			<Button
				onClick={() => saveScreen()}
				variant='outlined'
				color='primary'
				style={{ marginRight: '20px' }}>
				Save screen
			</Button>
			{screenId && (
				<>
					<MuiThemeProvider theme={theme}>
						<Button
							onClick={() => setOpenConfirmDialog(1)}
							variant='outlined'
							color='secondary'>
							Delete screen
						</Button>
					</MuiThemeProvider>
					<ConfirmDialog
						title={'Are you sure you want to delete this screen?'}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							deleteScreen(screenId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage:
												'Screen deleted successfully',
										});
										history.push('/screens');
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage:
											'Could not delete screen sector.',
									})
								)
						}
					/>
					<ConfirmDialog
						title={'Are you sure you want to delete the screen image?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							setScreen({ ...screen, asset: '' }) && selectAsset(null)
						}
					/>
				</>
			)}
		</>
	);
}
