import { API, authClient } from "../index";

// Get all chats by user id
export const getChatsByUserId = (id) => {
	return authClient().get(`${API}/chat/${id}`);
};

// Get chat
export const getChat = (firstId, secondId) => {
	return authClient().get(`${API}/chat/find/${firstId}/${secondId}`);
};

export const getChatByTeam = (teamId) => {
	return authClient().get(`${API}/chat/by-team/${teamId}`);
};

// Create chat
export const postChat = async (chat) => {
	return authClient().post(`${API}/chat`, chat);
};
