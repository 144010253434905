import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getEquipments } from "../../../../api/equipment";
import { SERVER_URL } from "../../../../api/index";
import { getSports } from "../../../../api/sport";
import {
	deleteLesson,
	getLessonById,
	postLesson,
	updateLesson,
} from "../../../../api/lesson";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Editor from "../../../components/editor/Editor";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import LessonContent from "./LessonContent";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyLesson() {
	return {
		title: {},
		description: {},
		sport: null,
		commonErrors: {},
		equipments: [],
		imageURL: "",
		videoURL: "",
		audioTipURL: "",
		documentURL: "",
		otherImagesURLs: [],
		trainer: null,
		active: true,
	};
}

export default function EditLessonsPage() {

	const [sports, setSports] = useState([]);
	const [lesson, setLesson] = useState(getEmptyLesson());
	const [equipments, setEquipments] = useState(null);

	const [selectedMainImage, setSelectedMainImage] = useState(null);
	const [selectedAudio, setSelectedAudio] = useState(null);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [selectedImages, setSelectedImages] = useState(null);
	const [selectedDocument, setSelectedDocument] = useState(null);

	const [deletedImages, setDeletedImages] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);

	const lessonId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getEquipments()
			.then((res) => {
				if (res.status === 200) {
					setEquipments(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get equipments.",
				});
				history.push("/sports/lessons");
			});
		getSports()
			.then((res) => {
				if (res.status === 200) {
					setSports(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get sports.",
				});
				history.push("/sports/lessons");
			});
		if (!lessonId) {
			disableLoadingData();
			return;
		}
		getLessonById(lessonId)
			.then((res) => {
				if (res.status === 200) {
					let lesson = {
						...res.data, sport: res.data.sport?._id
					};

					setLesson(lesson);

					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get lesson.",
				});
				history.push("/sports/lessons");
			});
	}, [lessonId, disableLoadingData, history]);

	function saveLesson() {
		if (!lessonId) {
			postLesson(
				{
					...lesson,
					trainer: user.role.includes("trainer") ? user._id : null,
				},
				selectedAudio,
				selectedVideo,
				selectedMainImage,
				selectedDocument,
				selectedImages
			)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Lesson successfully created.",
						});
						history.push("/sports/lessons");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save lesson.",
					});
				});
		} else {
			updateLesson(
				lessonId,
				lesson,
				selectedAudio,
				selectedVideo,
				selectedMainImage,
				selectedDocument,
				selectedImages
			)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/sports/lessons");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!lesson[element]) lesson[element] = {};
			let newText = lesson[element];
			newText[lang] = event.target.value;
			setLesson({ ...lesson, [element]: newText });
		} else setLesson({ ...lesson, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!lesson[element]) lesson[element] = {};
				let newText = lesson[element];
				newText[lang] = value;
				setLesson({ ...lesson, [element]: newText });
			} else setLesson({ ...lesson, [element]: value });
		}
	};

	const handleDeleteImage = (index) => {
		// TODO: DELETE IMAGES FROM SERVER
		let newImages = [...lesson.otherImagesURLs];
		const deletedImage = newImages.splice(index, 1);
		setLesson({ ...lesson, otherImagesURLs: newImages });
		if (selectedImages && selectedImages[index]) {
			let _selectedImages = {};
			for (const [key, value] of Object.entries(selectedImages)) {
				if (key !== index) {
					if (key > index) {
						_selectedImages[key - 1] = value;
					} else _selectedImages[key] = value;
				}
			}
			if (!Object.keys(_selectedImages).length) _selectedImages = null;
			setSelectedImages(_selectedImages);
		} else
			setDeletedImages(
				deletedImages
					? deletedImages.concat(deletedImage)
					: [deletedImage]
			);
	};

	const handleMoveImage = (index, newIndex) => {
		const aux = lesson.otherImagesURLs[index];
		let images = [...lesson.otherImagesURLs];
		images.splice(index, 1, images[newIndex]);
		images.splice(newIndex, 1, aux);
		setLesson({ ...lesson, otherImagesURLs: images });
		if (
			selectedImages &&
			(selectedImages[index] || selectedImages[newIndex])
		) {
			let _selectedImages = {};
			for (const [key, value] of Object.entries(selectedImages)) {
				if (key === index.toString()) _selectedImages[newIndex] = value;
				else if (key === newIndex.toString())
					_selectedImages[index] = value;
				else _selectedImages[key] = value;
			}
			setSelectedImages(_selectedImages);
		}
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Title"
					value={(lesson.title && lesson.title[lang]) || ""}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<br />
				<div>Description</div>
				<Editor
					body={
						(lesson.description && lesson.description[lang]) || ""
					}
					setBody={(new_body) => {
						handleChangeEditor("description", new_body, lang);
					}}
					className="max-height"
					placeholder={"Enter lesson description here..."}
				/>
				<br />
				<div>Common errors</div>
				<Editor
					body={
						(lesson.commonErrors && lesson.commonErrors[lang]) || ""
					}
					setBody={(new_body) => {
						handleChangeEditor("commonErrors", new_body, lang);
					}}
					className="max-height"
					placeholder={"Enter lesson common errors here..."}
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					{!lessonId ||
					user?.role.includes("admin") ||
					(user?.role.includes("trainer") &&
						lesson.trainer === user?._id) ? (
						<>
							<CardHeader title="Edit lesson"></CardHeader>
							<CardBody>
								<MultilanguageTabBlock
									multilanguageTabContent={
										renderMultilanguageTabContent
									}
								/>
								<br />
								<br />
								<Autocomplete
									id="sports"
									disablePortal
									options={sports}
									getOptionLabel={(option) =>
										getNonEmpty(option?.fullName)
									}
									value={sports?.find(
										(x) => x._id === lesson?.sport
									)}
									onChange={(event, selected) => {
										setLesson({
											...lesson,
											sport: selected?._id || null,
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											margin="normal"
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
											placeholder="Select a sport"
											label="Sport"
											required
										/>
									)}
								/>
								<br />
								<br />
								<Autocomplete
									multiple
									id="equipments"
									filterSelectedOptions
									disablePortal
									disableCloseOnSelect
									options={equipments}
									getOptionLabel={(option) =>
										getNonEmpty(option.fullName)
									}
									value={equipments.filter((x) =>
										lesson.equipments?.includes(x._id)
									)}
									onChange={(event, selected) => {
										console.log("selected", selected);
										setLesson({
											...lesson,
											equipments: selected?.map(
												(x) => x._id
											),
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											margin="normal"
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
											required
											placeholder="Select equipment"
											label={"Equipments"}
										/>
									)}
								/>
								<br />
								<br />
								{/* MAIN IMAGE */}
								<label
									htmlFor={"upload-image"}
									className="ml-0"
								>
									<input
										style={{ display: "none" }}
										id={"upload-image"}
										name={"upload-image"}
										type="file"
										accept={"image/*"}
										onChange={(e) => {
											setSelectedMainImage(
												e.target.files[0]
											);
										}}
									/>
									<Button
										style={{ marginRight: "15px" }}
										color="secondary"
										component="span"
										variant="outlined"
									>
										{selectedMainImage ||
										lesson.imageURL !== ""
											? "Change main image"
											: "Upload main image"}
									</Button>
								</label>
								{(selectedMainImage ||
									lesson.imageURL !== "") && (
									<>
										<Tooltip title={"Preview main image"}>
											<Button
												size="small"
												onClick={() =>
													setOpenPreviewDialog(
														"main-image"
													)
												}
												style={buttonsStyle}
											>
												<Visibility />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={"Preview main image"}
											open={
												openPreviewDialog ===
												"main-image"
											}
											setOpen={setOpenPreviewDialog}
											src={
												selectedMainImage
													? URL.createObjectURL(
															selectedMainImage
													  )
													: `${SERVER_URL}/${lesson.imageURL}`
											}
										/>
										<Tooltip title="Delete">
											<Button
												size="small"
												style={buttonsStyle}
												onClick={() => {
													setSelectedMainImage(null);
													setLesson({
														...lesson,
														imageURL: "",
													});
												}}
											>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedMainImage
												? selectedMainImage?.name
												: lesson.imageURL !== ""
												? lesson.imageURL.split(
														/-(.*)/s
												  )[1]
												: ""}
										</span>
									</>
								)}
								<br />
								<br />
								{/* AUDIO TIP */}
								<label
									htmlFor={"upload-audio"}
									className="ml-0"
								>
									<input
										style={{ display: "none" }}
										id={"upload-audio"}
										name={"upload-audio"}
										type="file"
										accept={"audio/*"}
										onChange={(e) => {
											setSelectedAudio(e.target.files[0]);
										}}
									/>
									<Button
										style={{ marginRight: "15px" }}
										color="secondary"
										component="span"
										variant="outlined"
									>
										{selectedAudio ||
										lesson.audioTipURL !== ""
											? "Change audio tip"
											: "Upload audio tip"}
									</Button>
								</label>
								{(selectedAudio ||
									lesson.audioTipURL !== "") && (
									<>
										<Tooltip title={"Preview audio tip"}>
											<Button
												size="small"
												onClick={() =>
													setOpenPreviewDialog(
														"audio"
													)
												}
												style={buttonsStyle}
											>
												<Visibility />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={"Preview audio tip"}
											open={openPreviewDialog === "audio"}
											setOpen={setOpenPreviewDialog}
											src={
												selectedAudio
													? URL.createObjectURL(
															selectedAudio
													  )
													: `${SERVER_URL}/${lesson.audioTipURL}`
											}
										/>
										<Tooltip title="Delete">
											<Button
												size="small"
												style={buttonsStyle}
												onClick={() => {
													setSelectedAudio(null);
													setLesson({
														...lesson,
														audioTipURL: "",
													});
												}}
											>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedAudio
												? selectedAudio?.name
												: lesson.audioTipURL !== ""
												? lesson.audioTipURL.split(
														/-(.*)/s
												  )[1]
												: ""}
										</span>
									</>
								)}
								<br />
								<br />
								{/* VIDEO */}
								<label
									htmlFor={"upload-video"}
									className="ml-0"
								>
									<input
										style={{ display: "none" }}
										id={"upload-video"}
										name={"upload-video"}
										type="file"
										accept={"video/*"}
										onChange={(e) => {
											setSelectedVideo(e.target.files[0]);
										}}
									/>
									<Button
										style={{ marginRight: "15px" }}
										color="secondary"
										component="span"
										variant="outlined"
									>
										{selectedVideo || lesson.videoURL !== ""
											? "Change video"
											: "Upload video"}
									</Button>
								</label>
								{(selectedVideo || lesson.videoURL !== "") && (
									<>
										<Tooltip title={"Preview video"}>
											<Button
												size="small"
												onClick={() =>
													setOpenPreviewDialog(
														"video"
													)
												}
												style={buttonsStyle}
											>
												<Visibility />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={"Preview video"}
											open={openPreviewDialog === "video"}
											setOpen={setOpenPreviewDialog}
											type="video"
											src={
												selectedVideo
													? URL.createObjectURL(
															selectedVideo
													  )
													: `${SERVER_URL}/${lesson.videoURL}`
											}
										/>
										<Tooltip title="Delete">
											<Button
												size="small"
												style={buttonsStyle}
												onClick={() => {
													setSelectedVideo(null);
													setLesson({
														...lesson,
														videoURL: "",
													});
												}}
											>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedVideo
												? selectedVideo?.name
												: lesson.videoURL !== ""
												? lesson.videoURL.split(
														/-(.*)/s
												  )[1]
												: ""}
										</span>
									</>
								)}
								<br />
								<br />
								{/* DOCUMENT */}
								<label
									htmlFor={"upload-document"}
									className="ml-0"
								>
									<input
										style={{ display: "none" }}
										id={"upload-document"}
										name={"upload-document"}
										type="file"
										accept={"document/*"}
										onChange={(e) => {
											setSelectedDocument(
												e.target.files[0]
											);
										}}
									/>
									<Button
										style={{ marginRight: "15px" }}
										color="secondary"
										component="span"
										variant="outlined"
									>
										{selectedDocument ||
										lesson.documentURL !== ""
											? "Change document"
											: "Upload document"}
									</Button>
								</label>
								{(selectedDocument ||
									lesson.documentURL !== "") && (
									<>
										<Tooltip title={"Preview document"}>
											<Button
												size="small"
												onClick={() =>
													setOpenPreviewDialog(
														"document"
													)
												}
												style={buttonsStyle}
											>
												<Visibility />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={"Preview document"}
											open={
												openPreviewDialog === "document"
											}
											setOpen={setOpenPreviewDialog}
											src={
												selectedDocument
													? URL.createObjectURL(
															selectedDocument
													  )
													: `${SERVER_URL}/${lesson.documentURL}`
											}
										/>
										<Tooltip title="Delete">
											<Button
												size="small"
												style={buttonsStyle}
												onClick={() => {
													setSelectedDocument(null);
													setLesson({
														...lesson,
														documentURL: "",
													});
												}}
											>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedDocument
												? selectedDocument?.name
												: lesson.documentURL !== ""
												? lesson.documentURL?.split(
														/-(.*)/s
												  )[1]
												: ""}
										</span>
									</>
								)}
								<br />
								<br />
								{/* OTHER IMAGES */}
								{lesson.otherImagesURLs?.map((_, index) => {
									return (
										<>
											<label
												htmlFor={"upload-image" + index}
												className="ml-0"
											>
												<input
													style={{ display: "none" }}
													id={"upload-image" + index}
													name={
														"upload-image" + index
													}
													type="file"
													accept={"image/*"}
													onChange={(e) => {
														setSelectedImages({
															...selectedImages,
															[index]:
																e.target
																	.files[0],
														});
													}}
												/>
												<Button
													style={{
														marginRight: "15px",
													}}
													color="secondary"
													component="span"
													variant="outlined"
												>
													{(selectedImages &&
														selectedImages[
															index
														]) ||
													lesson.otherImagesURLs[
														index
													] !== ""
														? "Change image " +
														  (index + 1)
														: "Upload image " +
														  (index + 1)}
												</Button>
											</label>
											{((selectedImages &&
												selectedImages[index]) ||
												lesson.otherImagesURLs[
													index
												] !== "") && (
												<>
													<Tooltip
														title={
															"Preview image " +
															(index + 1)
														}
													>
														<Button
															size="small"
															onClick={() =>
																setOpenPreviewDialog(
																	index
																)
															}
															style={buttonsStyle}
														>
															<Visibility />
														</Button>
													</Tooltip>
													<PreviewDialog
														title={
															"Preview image " +
															(index + 1)
														}
														open={
															openPreviewDialog ===
															index
														}
														setOpen={
															setOpenPreviewDialog
														}
														src={
															selectedImages &&
															selectedImages[
																index
															]
																? URL.createObjectURL(
																		selectedImages[
																			index
																		]
																  )
																: `${SERVER_URL}/${lesson.otherImagesURLs[index]}`
														}
													/>
													<Tooltip title="Delete">
														<Button
															size="small"
															style={buttonsStyle}
															onClick={() =>
																handleDeleteImage(
																	index
																)
															}
														>
															<DeleteIcon />
														</Button>
													</Tooltip>
													<Tooltip title="Move up">
														<Button
															size="small"
															style={buttonsStyle}
															disabled={
																index === 0
															}
															onClick={() =>
																handleMoveImage(
																	index,
																	index - 1
																)
															}
														>
															<ArrowUpward />
														</Button>
													</Tooltip>
													<Tooltip title="Move down">
														<Button
															size="small"
															disabled={
																!(
																	index <
																		lesson
																			.otherImagesURLs
																			.length -
																			1 &&
																	((selectedImages &&
																		selectedImages[
																			index +
																				1
																		]) ||
																		lesson
																			.otherImagesURLs[
																			index +
																				1
																		] !==
																			"")
																)
															}
															style={{
																...buttonsStyle,
																marginRight:
																	"1em",
															}}
															onClick={() =>
																handleMoveImage(
																	index,
																	index + 1
																)
															}
														>
															<ArrowDownward />
														</Button>
													</Tooltip>
													<span>
														{selectedImages &&
														selectedImages[index]
															? selectedImages[
																	index
															  ]?.name
															: lesson
																	.otherImagesURLs[
																	index
															  ] !== ""
															? lesson.otherImagesURLs[
																	index
															  ].split(
																	/-(.*)/s
															  )[1]
															: ""}
													</span>
													<br />
												</>
											)}
										</>
									);
								})}
								<br />
								<br />
								<Button
									variant="outlined"
									color="primary"
									onClick={() =>
										setLesson({
											...lesson,
											otherImagesURLs: lesson.otherImagesURLs.concat(
												""
											),
										})
									}
								>
									Add image
								</Button>
								<br />
								<br />
								<br />
								<FormControlLabel
									control={
										<Checkbox
											checked={lesson.active}
											onChange={() =>
												setLesson({
													...lesson,
													active: !lesson.active,
												})
											}
											name="checkActive"
										/>
									}
									label="Active"
								/>
							</CardBody>
						</>
					) : (
						<>
							<CardHeader
								title={
									"View lesson " + getNonEmpty(lesson?.title)
								}
							/>
							<CardBody>
								<LessonContent
									lesson={{
										...lesson,
										standard: !lesson.trainer,
									}}
								/>
							</CardBody>
						</>
					)}
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/sports/lessons")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					{(!lessonId ||
						user?.role.includes("admin") ||
						(user?.role.includes("trainer") &&
							lesson.trainer === user?._id)) && (
						<>
							<Button
								onClick={() => {
									if (lesson.used) setOpenConfirmDialog(1);
									else saveLesson();
								}}
								variant="outlined"
								color="primary"
								style={{ marginRight: "20px" }}
							>
								Save lesson
							</Button>
							<ConfirmDialog
								title={
									"This lesson is already associated to some plans and/or users, are you sure you want to modify it?"
								}
								open={openConfirmDialog === 1}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => saveLesson()}
							/>
						</>
					)}
					{lessonId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(2)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete lesson
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this lesson?"
								}
								open={openConfirmDialog === 2}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteLesson(lessonId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Lesson deleted successfully",
												});
												history.push("/sports/lessons");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete lesson.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
