import {
	Button,
	Checkbox,
	FormControlLabel,
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getMealById, updateMeal } from "../../../../api/meal";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyMeal() {
	return {
		fullName: {},
		active: true,
	};
}

export default function EditMealsPage() {
	const [meal, setMeal] = useState(getEmptyMeal());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const mealId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!mealId) {
			history.push("/meals");
			disableLoadingData();
			return;
		}
		getMealById(mealId)
			.then((res) => {
				if (res.status === 200) {
					setMeal(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get meal.",
				});
				history.push("/meals");
			});
	}, [mealId, disableLoadingData, history]);

	function saveMeal() {
		if (checkIsEmpty(meal.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});

		updateMeal(mealId, meal)
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: "Saved!",
						customMessage: "Changes successfully saved.",
					});
					history.push("/meals");
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not save changes.",
				});
			});
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!meal[element]) meal[element] = {};
			let newText = meal[element];
			newText[lang] = event.target.value;
			setMeal({ ...meal, [element]: newText });
		} else setMeal({ ...meal, [element]: event.target.value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={(meal.fullName && meal.fullName[lang]) || ""}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit meal"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={meal.active}
									onChange={() =>
										setMeal({
											...meal,
											active: !meal.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/meals")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveMeal()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save meal
					</Button>
				</div>
			</>
		);
}
