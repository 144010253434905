import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
	changeStatusNotificationText,
	deleteNotificationText,
	getNotificationTexts,
} from "../../../../api/notificationText";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";

function getData(notificationTexts) {
	let data = [];
	for (let i = 0; i < notificationTexts.length; ++i) {
		const elem = {};
		elem.title = getNonEmpty(notificationTexts[i].title);
		elem.description = getNonEmpty(notificationTexts[i].description);
		elem.notificationType = notificationTexts[i].notificationType;
		elem.createdAt = notificationTexts[i].createdAt;
		elem.id = notificationTexts[i]._id;
		elem.active = notificationTexts[i].active;
		data = data.concat(elem);
	}
	return data;
}

export default function NotificationTextsPage() {
	const [data, setData] = useState([]);
	const [notificationTextId, setNotificationTextId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-notification-text/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setNotificationTextId(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setNotificationTextId(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: "title", text: "Título", sort: true },
		{ dataField: "description", text: "Description", sort: true },
		{ dataField: "notificationType", text: "Notification type", sort: true },
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getNotificationTexts()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get notification texts.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Notification texts list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-notification-text")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							notificationTextId?.active ? "disable" : "enable"
						} this notification text?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusNotificationText(
								notificationTextId.id,
								!notificationTextId?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												notificationTextId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Notification text ${
												notificationTextId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											notificationTextId?.active
												? "disable"
												: "enable"
										} notification text.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							"Are you sure you want to remove this notification text?"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteNotificationText(notificationTextId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Notification text removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not remove notification text.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
