import { API, authClient } from '../index';

export const getAds = () => authClient().get(`${API}/ad`);

export const getAdById = (id) => authClient().get(`${API}/ad/${id}`);

export const deleteAd = (id) => authClient().delete(`${API}/ad/${id}`);

export const postAd = async (ad) => authClient().post(`${API}/ad`, ad);

export const updateAd = async (id, ad) => authClient().put(`${API}/ad/${id}`, ad);

export const changeStatusAd = async (id, active) =>
	authClient().put(`${API}/ad/change-status/${id}`, { active });
