import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import { alertError } from '../../../../utils/logger'
import { getUserStatisticsById } from '../../../../api/user'
import { useParams } from 'react-router-dom';
import LineChartContainer from '../../../components/charts/LineChartContainer'

export default function ProfessionalsReportsPage() {
  const [data, setData] = useState([])
  const professionalId = useParams().id;

  useEffect(() => {
    getUserStatisticsById(professionalId).then(res => {
      if (res.status === 200) {
        setData(res.data)
      }
    }).catch((error) => {
      alertError({ error: error, customMessage: 'Could not get statistics.' });
    });
  }, [professionalId])

  return (
    <>
      <Card>
        <CardHeader title={`${data?.professionalData ? data.professionalData.fullName : "Professional"} report`}></CardHeader>
        <CardBody>
          <h5>Total active users: </h5>{data?.users}
          <br/>
          <LineChartContainer
            data={data?.usersHistory}
            title={'Total users historically'}
            units="users"
            noDataMessage="This professional does not have any user assigned yet."
          />
        </CardBody>
        {data?.professionalData?.role?.includes('trainer') && <>
          <CardBody>
            <LineChartContainer
              data={data?.routines?.map(item => ({ key: item._id, value: item.count, id: item._id, tooltip: item.data }))}
              title={'Routines created'}
              units="routines"
              noDataMessage="This professional has not created any routine yet."
            />
          </CardBody>
          <CardBody>
            <LineChartContainer
              data={data?.routinePlans?.map(item => ({ key: item._id, value: item.count, id: item._id, tooltip: item.data }))}
              title={'Routine plans created'}
              units="plans"
              noDataMessage="This professional has not created any routine plan yet."
            />
          </CardBody>
        </>}
        {data?.professionalData?.role?.includes('nutritionist') && <>
          <CardBody>
            <LineChartContainer
              data={data?.nutritionalPlans?.map(item => ({ key: item._id, value: item.count, id: item._id, tooltip: item.data }))}
              title={'Nutritional plans created'}
              units="plans"
              noDataMessage="This professional has not created any nutritional plan yet."
            />
          </CardBody>
        </>}
      </Card>
    </>
  );
}
