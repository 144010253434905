import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React from "react";

const RatingInfoDialog = (props) => {
	const { title, rating, open, setOpen } = props;
	return (
		<Dialog
			open={open}
			maxWidth="md"
			onClose={() => setOpen(false)}
			aria-labelledby="confirm-dialog"
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent>
				<div>
					<p>
						<strong>User: </strong>
						<span>{rating?.user || "---"}</span>
					</p>
					<p>
						<strong className="mt-4">Score: </strong>
						<span>{rating?.score}</span>
					</p>
					<p>
						<strong className="mt-3">Comments: </strong>
						<span>{rating?.comments || "---"}</span>
					</p>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					onClick={() => {
						setOpen(false);
					}}
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default RatingInfoDialog;
