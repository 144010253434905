import { API, authClient } from "../index";

export const postReply = async (reply) => {
	return authClient().post(`${API}/reply`, reply);
};

export const getRepliesByThread = (threadId) => {
	return authClient().get(`${API}/reply/by-thread/${threadId}`);
};

export const updateReply = async (id, reply) => {
	return authClient().put(`${API}/reply/${id}`, reply);
};

export const deleteReply = (id) => {
	return authClient().delete(`${API}/reply/${id}`);
};
