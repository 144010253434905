import {
	AppBar,
	Button,
	Checkbox,
	Chip,
	FormControlLabel,
	MuiThemeProvider,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import {
	AddShoppingCart,
	ExpandLess,
	ExpandMore,
	HighlightOff,
	Visibility,
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getFoodPlates } from "../../../../api/foodPlate";
import { SERVER_URL } from "../../../../api/index";
import { getMeals } from "../../../../api/meal";
import {
	deleteNutritionalPlan,
	getNutritionalPlanById,
	postNutritionalPlan,
	updateNutritionalPlan,
} from "../../../../api/nutritionalPlan";
import { MEALS_AND_ID, getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import DaysTabBlock from "../../../components/DaysTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import TableDialog from "../../../components/dialogs/TableDialog";
import Editor from "../../../components/editor/Editor";
import PlansContent from "../../../components/plansContent";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

export const weekdays = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

function getEmptyNutritionalPlan() {
	let plan = {};
	weekdays.forEach(
		(day) =>
			(plan = {
				...plan,
				[day]: {
					breakfast: {
						description: "",
						calories: 0,
						foodPlate: "",
						meal: "",
					},
					lunch: {
						description: "",
						calories: 0,
						lunch1: { foodPlate: "", meal: "" },
						lunch2: { foodPlate: "", meal: "" },
						lunchDesert: { foodPlate: "", meal: "" },
					},
					afternoon: {
						description: "",
						calories: 0,
						foodPlate: "",
						meal: "",
					},
					dinner: {
						description: "",
						calories: 0,
						dinner1: { foodPlate: "", meal: "" },
						dinner2: { foodPlate: "", meal: "" },
						dinnerDesert: { foodPlate: "", meal: "" },
					},
					totalCalories: "",
				},
			})
	);
	return {
		title: "",
		description: "",
		references: "",
		plan,
		nutritionist: null,
		totalCaloriesPlan: "",
		active: true,
		bannerURL: "",
		premium: false,
		price: 0,
		weight: {
			forLosing: null,
			forMantain: null,
			forGaining: null
		},
		velocity: {
			slow: null,
			recomended: null,
			accelerated: null
		},
		age: {
			less29: null,
			less39: null,
			less49: null,
			more50: null
		},
		gender: {
			femenine: null,
			masculine: null,
			other: null
		},
		imc: {
			low: null,
			normal: null,
			high: null,
			veryHigh: null
		}
	};
}

function getFoodPlateData(foodPlates) {
	let data = [];
	for (let i = 0; i < foodPlates.length; ++i) {
		const elem = {};

		elem.description = getNonEmpty(foodPlates[i].description);
		elem.meals = foodPlates[i]?.meals
			?.map((x) => getNonEmpty(x?.fullName))
			?.join(", ");
		elem.dietTypes = foodPlates[i]?.dietTypes
			?.map((x) => getNonEmpty(x?.fullName))
			?.join(", ");
		elem.calories = foodPlates[i].calories;
		elem.imageURL = foodPlates[i].imageURL;
		elem.createdAt = foodPlates[i].createdAt;
		elem._id = foodPlates[i]._id;

		data = data.concat(elem);
	}
	return data;
}

export default function EditNutritionalPlansPage() {
	const [nutritionalPlan, setNutritionalPlan] = useState(
		getEmptyNutritionalPlan()
	);

	const [foodPlates, setFoodPlates] = useState([]);
	const [meals, setMeals] = useState([]);
	const [openFoodTableDialog, setOpenFoodTableDialog] = useState(null);
	const [selectedMeal, setSelectedMeal] = useState({
		meal: "",
		submeal: "",
		mealId: "",
	});

	const [showOldPlanStructure, setShowOldPlanStructure] = useState(false);
	const [showDietTypes, setShowDietTypes] = useState(false);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [selectedBanner, setSelectedBanner] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const nutritionalPlanId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const languages = useSelector(
		(store) => store.authentication?.languages,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getMeals()
			.then((res) => {
				if (res.status === 200) {
					setMeals(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food meals.",
				});
				history.push("/nutritional-plans");
			});
		getFoodPlates()
			.then((res) => {
				if (res.status === 200) {
					setFoodPlates(getFoodPlateData(res.data));
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food plates.",
				});
				history.push("/nutritional-plans");
			});
		if (!nutritionalPlanId) {
			disableLoadingData();
			return;
		}
		getNutritionalPlanById(nutritionalPlanId)
			.then((res) => {
				if (res.status === 200) {
					let nutritionalPlan = res.data;

					for (const dayKey in nutritionalPlan.plan) {
						const day = nutritionalPlan.plan[dayKey];

						for (const mealKey in day) {
							let meal = day[mealKey];

							if (meal?.foodPlate) {
								meal.foodPlate = meal?.foodPlate?._id;
							}
							if (meal?.meal) {
								meal.meal = meal?.meal?._id;
							}

							for (const subMealKey in meal) {
								let subMeal = meal[subMealKey];

								if (subMeal?.foodPlate) {
									subMeal.foodPlate = subMeal?.foodPlate?._id;
								}

								if (subMeal?.foodPlate) {
									subMeal.meal = subMeal?.meal?._id;
								}
							}
						}
					}

					setNutritionalPlan(nutritionalPlan);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get nutritional plan.",
				});
				history.push("/nutritional-plans");
			});
	}, [nutritionalPlanId, disableLoadingData, history]);

	function saveNutritionalPlan() {
		if (!nutritionalPlan.plan)
			return alertError({
				error: null,
				customMessage:
					"A description for each of the meals for each day is required, please check that it is all filled out.",
			});
		for (const key in nutritionalPlan.plan) {
			for (const k in nutritionalPlan.plan[key]) {
				if (
					k !== "totalCalories" &&
					!nutritionalPlan.plan[key][k].description?.length
				)
					return alertError({
						error: null,
						customMessage:
							"A description for each of the meals for each day is required, please check that it is all filled out.",
					});
			}
		}

		let saveNutritionalPlan = nutritionalPlan;

		const lunchDesertMealId = meals.find(
			(m) => m.internalId === MEALS_AND_ID.LUNCH_DESERT
		)?._id;
		const dinnerDesertMealId = meals.find(
			(m) => m.internalId === MEALS_AND_ID.DINNER_DESERT
		)?._id;

		Object.keys(nutritionalPlan.plan).forEach((dayLabel) => {
			Object.keys(nutritionalPlan.plan[dayLabel]).forEach((mealLabel) => {
				let item = nutritionalPlan.plan[dayLabel][mealLabel];

				if (item?.foodPlate) {
					const foodPlate = foodPlates.find(
						(x) => x._id === item?.foodPlate
					);

					item.calories = foodPlate?.calories;
					//item.description = foodPlate?.description;
				}

				let subMealsCalorieSum = 0;

				Object.keys(item).forEach((subMealLabel) => {
					let subMealElem =
						nutritionalPlan.plan[dayLabel][mealLabel][subMealLabel];

					const foodPlate = foodPlates?.find(
						(x) => x._id === subMealElem?.foodPlate
					);

					const calories = subMealElem?.foodPlate
						? foodPlate?.calories
						: 0;

					subMealsCalorieSum += calories;
				});

				if (subMealsCalorieSum !== 0)
					item.calories = subMealsCalorieSum;

				const itemSubMealsLabels = Object.keys(item).filter(
					(subMealLabel) => item[subMealLabel]?.foodPlate
				);

				if (
					(itemSubMealsLabels.length === 2 &&
						(itemSubMealsLabels.some(
							(x) => lunchDesertMealId === item[x]?.meal
						) ||
							itemSubMealsLabels.some(
								(x) => dinnerDesertMealId === item[x]?.meal
							))) ||
					(itemSubMealsLabels.length === 1 &&
						!itemSubMealsLabels.some(
							(x) => lunchDesertMealId === item[x]?.meal
						) &&
						!itemSubMealsLabels.some(
							(x) => dinnerDesertMealId === item[x]?.meal
						))
				) {
					item[
						itemSubMealsLabels.find(
							(key) =>
								item[key]?.meal !== lunchDesertMealId &&
								item[key]?.meal !== dinnerDesertMealId
						)
					].meal = meals.find(
						(x) => x.internalId === MEALS_AND_ID.MAIN_COURSE
					)?._id;
				}
			});
		});

		if (!nutritionalPlanId) {
			postNutritionalPlan(
				{
					...saveNutritionalPlan,
					nutritionist: user.role.includes("nutritionist")
						? user._id
						: null,
					admin: user.role.includes("admin") ? user._id : null,
				},
				selectedBanner
			)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage:
								"Nutritional plan successfully created.",
						});
						history.push("/nutritional-plans");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save nutritional plan.",
					});
				});
		} else {
			updateNutritionalPlan(
				nutritionalPlanId,
				{ ...saveNutritionalPlan, admin: user._id },
				selectedBanner
			)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/nutritional-plans");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, day, subelement, value) => (event) => {
		let newValue = event.target.value;
		if (element === "totalCaloriesPlan") {
			for (const day of Object.keys(nutritionalPlan.plan)) {
				if (
					nutritionalPlan.plan[day].totalCalories &&
					nutritionalPlan.plan[day].totalCalories !== ""
				)
					return alertError({
						error: null,
						customMessage:
							"The total calories of the plan are automatically calculated based on the calories of each dish.",
					});
			}
		}
		if (day) {
			if (newValue === " ") return;
			let newText = nutritionalPlan.plan;
			if (subelement) newText[day][element][subelement] = newValue;
			else newText[day][element] = newValue;
			setNutritionalPlan({ ...nutritionalPlan, plan: newText });
		} else
			setNutritionalPlan({
				...nutritionalPlan,
				[element]: newValue,
			});
	};

	const handleChangeWeight = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		setNutritionalPlan(( prevNutritionPlan) => ({
			... prevNutritionPlan,
			weight: {
				... prevNutritionPlan.weight,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeVelocity = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		setNutritionalPlan(( prevNutritionPlan) => ({
			... prevNutritionPlan,
			velocity: {
				... prevNutritionPlan.velocity,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeAge = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		setNutritionalPlan(( prevNutritionPlan) => ({
			... prevNutritionPlan,
			age: {
				... prevNutritionPlan.age,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeGender = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		setNutritionalPlan(( prevNutritionPlan) => ({
			... prevNutritionPlan,
			gender: {
				... prevNutritionPlan.gender,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeImc = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		setNutritionalPlan(( prevNutritionPlan) => ({
			... prevNutritionPlan,
			imc: {
				... prevNutritionPlan.imc,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeEditor = (element, value) => {
		setNutritionalPlan({ ...nutritionalPlan, [element]: value });
	};

	function calculateTotalCalories(nutritionalPlan, day) {
		function calculateCaloriesOfDay(day) {
			let result = 0;

			Object.keys(nutritionalPlan.plan[day]).forEach((mealLabel) => {
				let mealCalorieSum = 0;

				let item = nutritionalPlan.plan[day][mealLabel];

				const calories = item?.foodPlate
					? foodPlates.find((x) => x._id === item?.foodPlate)
							?.calories
					: item?.calories
					? item?.calories
					: 0;

				mealCalorieSum = calories;

				let subMealCalorieSum = 0;

				Object.keys(item).forEach((subMealLabel) => {
					let subMealElem =
						nutritionalPlan.plan[day][mealLabel][subMealLabel];

					const calories = subMealElem?.foodPlate
						? foodPlates.find(
								(x) => x._id === subMealElem?.foodPlate
						  )?.calories
						: 0;
					subMealCalorieSum += calories;
				});

				if (item?.calories && subMealCalorieSum != 0)
					mealCalorieSum = subMealCalorieSum;

				result += mealCalorieSum;
			});
			return result;
		}

		let result = 0;

		if (day) {
			result = calculateCaloriesOfDay(day);
		} else {
			Object.keys(nutritionalPlan.plan).forEach((dayLabel) => {
				result += calculateCaloriesOfDay(dayLabel);
			});
		}

		return result;
	}

	const renderDaysTabContent = (day) => {
		const dayLabel = day.charAt(0).toUpperCase() + day.slice(1);

		const getPlateDescription = (meal, subMeal) => {
			const item = subMeal
				? nutritionalPlan.plan?.[day]?.[meal]?.[subMeal]
				: nutritionalPlan.plan?.[day]?.[meal];

			if (item?.foodPlate) {
				const foodPlate = foodPlates?.find(
					(x) => x._id === item.foodPlate
				);
				if (foodPlate) {
					const description = foodPlate.description || "";
					const dietTypes = foodPlate.dietTypes || "";
					return `${description}${
						showDietTypes ? " (" + dietTypes + ")" : ""
					}`;
				}
			}
			return "";
		};

		return (
			<>
				<br />
				<br />
				<h5>Breakfast</h5>
				<TextField
					id={`${dayLabel} food plate`}
					label={getNonEmpty(
						meals?.find(
							(x) => x.internalId === MEALS_AND_ID.BREAKFAST
						)?.fullName
					)}
					value={
						nutritionalPlan.plan &&
						nutritionalPlan.plan[day] &&
						nutritionalPlan.plan[day].breakfast &&
						nutritionalPlan.plan[day].breakfast.foodPlate
							? getPlateDescription("breakfast")
							: ""
					}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder="Select food plate"
					margin="normal"
					variant="outlined"
					inputProps={{ readOnly: true }}
					InputProps={
						({ readOnly: true },
						{
							endAdornment: (
								<>
									{nutritionalPlan.plan[day].breakfast
										.foodPlate && (
										<>
											<Tooltip title="View">
												<Button
													onClick={() => {
														window.open(
															"/edit-plate/" +
																nutritionalPlan
																	.plan[day]
																	.breakfast
																	.foodPlate,
															"_blank"
														);
													}}
												>
													<Visibility />
												</Button>
											</Tooltip>
											<Tooltip title="Delete">
												<Button
													onClick={() => {
														let newPlan = nutritionalPlan;
														newPlan.plan[
															day
														].breakfast.foodPlate = null;
														newPlan.plan[
															day
														].breakfast.meal = null;

														newPlan.plan[
															day
														].totalCalories = calculateTotalCalories(
															newPlan,
															day
														);
														newPlan.totalCaloriesPlan = calculateTotalCalories(
															newPlan
														);

														setNutritionalPlan({
															...nutritionalPlan,
															plan: newPlan.plan,
														});
													}}
												>
													<HighlightOff />
												</Button>
											</Tooltip>
										</>
									)}
									<Tooltip title="Select food plate">
										<Button
											onClick={() => {
												setSelectedMeal({
													meal: "breakfast",
													mealId: meals.find(
														(x) =>
															x.internalId ===
															MEALS_AND_ID.BREAKFAST
													)?._id,
												});
												setOpenFoodTableDialog(true);
											}}
										>
											<AddShoppingCart />
										</Button>
									</Tooltip>
								</>
							),
						})
					}
				/>
				<br />
				<br />
				<br />
				<h5>Lunch</h5>
				<TextField
					id={`${dayLabel} food plate`}
					label={getNonEmpty(
						meals?.find((x) => x.internalId === MEALS_AND_ID.LUNCH1)
							?.fullName
					)}
					value={
						nutritionalPlan.plan &&
						nutritionalPlan.plan[day] &&
						nutritionalPlan.plan[day].lunch &&
						nutritionalPlan.plan[day].lunch?.lunch1?.foodPlate
							? getPlateDescription("lunch", "lunch1")
							: ""
					}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder="Select food plate"
					margin="normal"
					variant="outlined"
					inputProps={{ readOnly: true }}
					InputProps={
						({ readOnly: true },
						{
							endAdornment: (
								<>
									{nutritionalPlan.plan[day].lunch?.lunch1
										?.foodPlate && (
										<>
											<Tooltip title="View">
												<Button
													onClick={() => {
														window.open(
															"/edit-plate/" +
																nutritionalPlan
																	.plan[day]
																	.lunch
																	?.lunch1
																	?.foodPlate,
															"_blank"
														);
													}}
												>
													<Visibility />
												</Button>
											</Tooltip>
											<Tooltip title="Delete">
												<Button
													onClick={() => {
														let newPlan = nutritionalPlan;
														newPlan.plan[
															day
														].lunch.lunch1.foodPlate = null;
														newPlan.plan[
															day
														].lunch.lunch1.meal = null;

														setNutritionalPlan({
															...nutritionalPlan,
															plan: newPlan.plan,
														});
													}}
												>
													<HighlightOff />
												</Button>
											</Tooltip>
										</>
									)}
									<Tooltip title="Select food plate">
										<Button
											onClick={() => {
												setSelectedMeal({
													meal: "lunch",
													submeal: "lunch1",
													mealId: meals.find(
														(x) =>
															x.internalId ===
															MEALS_AND_ID.LUNCH1
													)?._id,
												});
												setOpenFoodTableDialog(true);
											}}
										>
											<AddShoppingCart />
										</Button>
									</Tooltip>
								</>
							),
						})
					}
				/>
				<TextField
					id={`${dayLabel} food plate`}
					label={getNonEmpty(
						meals?.find((x) => x.internalId === MEALS_AND_ID.LUNCH2)
							?.fullName
					)}
					value={
						nutritionalPlan.plan &&
						nutritionalPlan.plan[day] &&
						nutritionalPlan.plan[day].lunch &&
						nutritionalPlan.plan[day].lunch?.lunch2?.foodPlate
							? getPlateDescription("lunch", "lunch2")
							: ""
					}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder="Select food plate"
					margin="normal"
					variant="outlined"
					inputProps={{ readOnly: true }}
					InputProps={
						({ readOnly: true },
						{
							endAdornment: (
								<>
									{nutritionalPlan.plan[day].lunch?.lunch2
										?.foodPlate && (
										<>
											<Tooltip title="View">
												<Button
													onClick={() => {
														window.open(
															"/edit-plate/" +
																nutritionalPlan
																	.plan[day]
																	.lunch
																	?.lunch2
																	?.foodPlate,
															"_blank"
														);
													}}
												>
													<Visibility />
												</Button>
											</Tooltip>
											<Tooltip title="Delete">
												<Button
													onClick={() => {
														let newPlan = nutritionalPlan;
														newPlan.plan[
															day
														].lunch.lunch2.foodPlate = null;
														newPlan.plan[
															day
														].lunch.lunch2.meal = null;

														setNutritionalPlan({
															...nutritionalPlan,
															plan: newPlan.plan,
														});
													}}
												>
													<HighlightOff />
												</Button>
											</Tooltip>
										</>
									)}
									<Tooltip title="Select food plate">
										<Button
											onClick={() => {
												setSelectedMeal({
													meal: "lunch",
													submeal: "lunch2",
													mealId: meals.find(
														(x) =>
															x.internalId ===
															MEALS_AND_ID.LUNCH2
													)?._id,
												});
												setOpenFoodTableDialog(true);
											}}
										>
											<AddShoppingCart />
										</Button>
									</Tooltip>
								</>
							),
						})
					}
				/>
				<TextField
					id={`${dayLabel} food plate`}
					label={getNonEmpty(
						meals?.find(
							(x) => x.internalId === MEALS_AND_ID.LUNCH_DESERT
						)?.fullName
					)}
					value={
						nutritionalPlan.plan &&
						nutritionalPlan.plan[day] &&
						nutritionalPlan.plan[day].lunch &&
						nutritionalPlan.plan[day].lunch?.lunchDesert?.foodPlate
							? getPlateDescription("lunch", "lunchDesert")
							: ""
					}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder="Select food plate"
					margin="normal"
					variant="outlined"
					inputProps={{
						readOnly: true,
					}}
					InputProps={
						({ readOnly: true },
						{
							endAdornment: (
								<>
									{nutritionalPlan.plan[day].lunch
										?.lunchDesert?.foodPlate && (
										<>
											<Tooltip title="View">
												<Button
													onClick={() => {
														window.open(
															"/edit-plate/" +
																nutritionalPlan
																	.plan[day]
																	.lunch
																	?.lunchDesert
																	?.foodPlate,
															"_blank"
														);
													}}
												>
													<Visibility />
												</Button>
											</Tooltip>
											<Tooltip title="Delete">
												<Button
													onClick={() => {
														let newPlan = nutritionalPlan;
														newPlan.plan[
															day
														].lunch.lunchDesert.foodPlate = null;
														newPlan.plan[
															day
														].lunch.lunchDesert.meal = null;

														setNutritionalPlan({
															...nutritionalPlan,
															plan: newPlan.plan,
														});
													}}
												>
													<HighlightOff />
												</Button>
											</Tooltip>
										</>
									)}
									<Tooltip title="Select food plate">
										<Button
											onClick={() => {
												setSelectedMeal({
													meal: "lunch",
													submeal: "lunchDesert",
													mealId: meals.find(
														(x) =>
															x.internalId ===
															MEALS_AND_ID.LUNCH_DESERT
													)?._id,
												});
												setOpenFoodTableDialog(true);
											}}
										>
											<AddShoppingCart />
										</Button>
									</Tooltip>
								</>
							),
						})
					}
				/>
				<br />
				<br />
				<br />
				<h5>Afternoon</h5>
				<TextField
					id={`${dayLabel} food plate`}
					label={getNonEmpty(
						meals?.find(
							(x) => x.internalId === MEALS_AND_ID.AFTERNOON
						)?.fullName
					)}
					value={
						nutritionalPlan.plan &&
						nutritionalPlan.plan[day] &&
						nutritionalPlan.plan[day].afternoon &&
						nutritionalPlan.plan[day].afternoon?.foodPlate
							? getPlateDescription("afternoon")
							: ""
					}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder="Select food plate"
					margin="normal"
					variant="outlined"
					inputProps={{ readOnly: true }}
					InputProps={
						({ readOnly: true },
						{
							endAdornment: (
								<>
									{nutritionalPlan.plan[day].afternoon
										?.foodPlate && (
										<>
											<Tooltip title="View">
												<Button
													onClick={() => {
														window.open(
															"/edit-plate/" +
																nutritionalPlan
																	.plan[day]
																	.afternoon
																	?.foodPlate,
															"_blank"
														);
													}}
												>
													<Visibility />
												</Button>
											</Tooltip>
											<Tooltip title="Delete">
												<Button
													onClick={() => {
														let newPlan = nutritionalPlan;
														newPlan.plan[
															day
														].afternoon.foodPlate = null;
														newPlan.plan[
															day
														].afternoon.meal = null;

														setNutritionalPlan({
															...nutritionalPlan,
															plan: newPlan.plan,
														});
													}}
												>
													<HighlightOff />
												</Button>
											</Tooltip>
										</>
									)}
									<Tooltip title="Select food plate">
										<Button
											onClick={() => {
												setSelectedMeal({
													meal: "afternoon",
													mealId: meals.find(
														(x) =>
															x.internalId ===
															MEALS_AND_ID.AFTERNOON
													)?._id,
												});
												setOpenFoodTableDialog(true);
											}}
										>
											<AddShoppingCart />
										</Button>
									</Tooltip>
								</>
							),
						})
					}
				/>
				<br />
				<br />
				<br />
				<h5>Dinner</h5>
				<TextField
					id={`${dayLabel} food plate`}
					label={getNonEmpty(
						meals?.find(
							(x) => x.internalId === MEALS_AND_ID.DINNER1
						)?.fullName
					)}
					value={
						nutritionalPlan.plan &&
						nutritionalPlan.plan[day] &&
						nutritionalPlan.plan[day].dinner &&
						nutritionalPlan.plan[day].dinner?.dinner1?.foodPlate
							? getPlateDescription("dinner", "dinner1")
							: ""
					}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder="Select food plate"
					margin="normal"
					variant="outlined"
					inputProps={{ readOnly: true }}
					InputProps={
						({ readOnly: true },
						{
							endAdornment: (
								<>
									{nutritionalPlan.plan[day].dinner?.dinner1
										?.foodPlate && (
										<>
											<Tooltip title="View">
												<Button
													onClick={() => {
														window.open(
															"/edit-plate/" +
																nutritionalPlan
																	.plan[day]
																	.dinner
																	?.dinner1
																	?.foodPlate,
															"_blank"
														);
													}}
												>
													<Visibility />
												</Button>
											</Tooltip>
											<Tooltip title="Delete">
												<Button
													onClick={() => {
														let newPlan = nutritionalPlan;
														newPlan.plan[
															day
														].dinner.dinner1.foodPlate = null;
														newPlan.plan[
															day
														].dinner.dinner1.meal = null;

														setNutritionalPlan({
															...nutritionalPlan,
															plan: newPlan.plan,
														});
													}}
												>
													<HighlightOff />
												</Button>
											</Tooltip>
										</>
									)}
									<Tooltip title="Select food plate">
										<Button
											onClick={() => {
												setSelectedMeal({
													meal: "dinner",
													submeal: "dinner1",
													mealId: meals.find(
														(x) =>
															x.internalId ===
															MEALS_AND_ID.DINNER1
													)?._id,
												});
												setOpenFoodTableDialog(true);
											}}
										>
											<AddShoppingCart />
										</Button>
									</Tooltip>
								</>
							),
						})
					}
				/>
				<TextField
					id={`${dayLabel} food plate`}
					label={getNonEmpty(
						meals?.find(
							(x) => x.internalId === MEALS_AND_ID.DINNER2
						)?.fullName
					)}
					value={
						nutritionalPlan.plan &&
						nutritionalPlan.plan[day] &&
						nutritionalPlan.plan[day].dinner &&
						nutritionalPlan.plan[day].dinner?.dinner2?.foodPlate
							? getPlateDescription("dinner", "dinner2")
							: ""
					}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder="Select food plate"
					margin="normal"
					variant="outlined"
					inputProps={{ readOnly: true }}
					InputProps={
						({ readOnly: true },
						{
							endAdornment: (
								<>
									{nutritionalPlan.plan[day].dinner?.dinner2
										?.foodPlate && (
										<>
											<Tooltip title="View">
												<Button
													onClick={() => {
														window.open(
															"/edit-plate/" +
																nutritionalPlan
																	.plan[day]
																	.dinner
																	?.dinner2
																	?.foodPlate,
															"_blank"
														);
													}}
												>
													<Visibility />
												</Button>
											</Tooltip>
											<Tooltip title="Delete">
												<Button
													onClick={() => {
														let newPlan = nutritionalPlan;
														newPlan.plan[
															day
														].dinner.dinner2.foodPlate = null;
														newPlan.plan[
															day
														].dinner.dinner2.meal = null;

														setNutritionalPlan({
															...nutritionalPlan,
															plan: newPlan.plan,
														});
													}}
												>
													<HighlightOff />
												</Button>
											</Tooltip>
										</>
									)}
									<Tooltip title="Select food plate">
										<Button
											onClick={() => {
												setSelectedMeal({
													meal: "dinner",
													submeal: "dinner2",
													mealId: meals.find(
														(x) =>
															x.internalId ===
															MEALS_AND_ID.DINNER2
													)?._id,
												});
												setOpenFoodTableDialog(true);
											}}
										>
											<AddShoppingCart />
										</Button>
									</Tooltip>
								</>
							),
						})
					}
				/>
				<TextField
					id={`${dayLabel} food plate`}
					label={getNonEmpty(
						meals?.find(
							(x) => x.internalId === MEALS_AND_ID.DINNER_DESERT
						)?.fullName
					)}
					value={
						nutritionalPlan.plan &&
						nutritionalPlan.plan[day] &&
						nutritionalPlan.plan[day].dinner &&
						nutritionalPlan.plan[day].dinner?.dinnerDesert
							?.foodPlate
							? getPlateDescription("dinner", "dinnerDesert")
							: ""
					}
					InputLabelProps={{
						shrink: true,
					}}
					placeholder="Select food plate"
					margin="normal"
					variant="outlined"
					inputProps={{ readOnly: true }}
					InputProps={
						({ readOnly: true },
						{
							endAdornment: (
								<>
									{nutritionalPlan.plan[day].dinner
										?.dinnerDesert?.foodPlate && (
										<>
											<Tooltip title="View">
												<Button
													onClick={() => {
														window.open(
															"/edit-plate/" +
																nutritionalPlan
																	.plan[day]
																	.dinner
																	?.dinnerDesert
																	?.foodPlate,
															"_blank"
														);
													}}
												>
													<Visibility />
												</Button>
											</Tooltip>
											<Tooltip title="Delete">
												<Button
													onClick={() => {
														let newPlan = nutritionalPlan;
														newPlan.plan[
															day
														].dinner.dinnerDesert.foodPlate = null;
														newPlan.plan[
															day
														].dinner.dinnerDesert.meal = null;

														setNutritionalPlan({
															...nutritionalPlan,
															plan: newPlan.plan,
														});
													}}
												>
													<HighlightOff />
												</Button>
											</Tooltip>
										</>
									)}
									<Tooltip title="Select food plate">
										<Button
											onClick={() => {
												setSelectedMeal({
													meal: "dinner",
													submeal: "dinnerDesert",
													mealId: meals.find(
														(x) =>
															x.internalId ===
															MEALS_AND_ID.DINNER_DESERT
													)?._id,
												});
												setOpenFoodTableDialog(true);
											}}
										>
											<AddShoppingCart />
										</Button>
									</Tooltip>
								</>
							),
						})
					}
				/>
				<br />
				<br />
				<br />
				<div className="d-flex justify-content-between">
					<h4>Older versions</h4>
					<div>
						<Tooltip
							title={
								showOldPlanStructure ? "See less" : "See more"
							}
						>
							<Button
								onClick={() =>
									setShowOldPlanStructure(
										!showOldPlanStructure
									)
								}
							>
								{showOldPlanStructure ? (
									<ExpandLess />
								) : (
									<ExpandMore />
								)}
							</Button>
						</Tooltip>
					</div>
				</div>
				<br />
				<br />
				{showOldPlanStructure && (
					<>
						<h5>Breakfast</h5>
						<TextField
							id={`${dayLabel} breakfast description`}
							label={`Description`}
							placeholder={`Enter ${day} breakfast description here...`}
							value={
								(nutritionalPlan.plan &&
									nutritionalPlan.plan[day] &&
									nutritionalPlan.plan[day].breakfast &&
									nutritionalPlan.plan[day].breakfast
										.description) ||
								""
							}
							onChange={handleChange(
								"breakfast",
								day,
								"description"
							)}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							multiline
							variant="outlined"
							rows={6}
							required
						/>
						<TextField
							id={`${dayLabel} breakfast calories`}
							label={`Calories`}
							value={
								(nutritionalPlan.plan &&
									nutritionalPlan.plan[day] &&
									nutritionalPlan.plan[day].breakfast &&
									nutritionalPlan.plan[day].breakfast
										.calories) ||
								""
							}
							//onChange={handleChangeCalories("breakfast", day)}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{ readOnly: true }}
							margin="normal"
							variant="outlined"
							type="number"
							InputProps={{
								inputProps: {
									min: 0,
								},
							}}
						/>
						<br />
						<br />
						<br />
						<h5>Lunch</h5>
						<TextField
							id={`${dayLabel} lunch description`}
							label={`Description`}
							placeholder={`Enter ${day} lunch description here...`}
							value={
								(nutritionalPlan.plan &&
									nutritionalPlan.plan[day] &&
									nutritionalPlan.plan[day].lunch &&
									nutritionalPlan.plan[day].lunch
										.description) ||
								""
							}
							onChange={handleChange("lunch", day, "description")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							multiline
							variant="outlined"
							rows={6}
							required
						/>
						<TextField
							id={`${dayLabel} lunch calories`}
							label={`Calories`}
							value={
								(nutritionalPlan.plan &&
									nutritionalPlan.plan[day] &&
									nutritionalPlan.plan[day].lunch &&
									nutritionalPlan.plan[day].lunch.calories) ||
								""
							}
							//onChange={handleChangeCalories("lunch", day)}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{ readOnly: true }}
							margin="normal"
							variant="outlined"
							type="number"
							InputProps={{
								inputProps: {
									min: 0,
								},
							}}
						/>
						<br />
						<br />
						<br />
						<h5>Afternoon</h5>
						<TextField
							id={`${dayLabel} afternoon snack description`}
							label={`Description`}
							placeholder={`Enter ${day} afternoon description here...`}
							value={
								(nutritionalPlan.plan &&
									nutritionalPlan.plan[day] &&
									nutritionalPlan.plan[day].afternoon &&
									nutritionalPlan.plan[day].afternoon
										.description) ||
								""
							}
							onChange={handleChange(
								"afternoon",
								day,
								"description"
							)}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							multiline
							variant="outlined"
							rows={6}
							required
						/>
						<TextField
							id={`${dayLabel} afternoon snack calories`}
							label={`Calories`}
							value={
								(nutritionalPlan.plan &&
									nutritionalPlan.plan[day] &&
									nutritionalPlan.plan[day].afternoon &&
									nutritionalPlan.plan[day].afternoon
										.calories) ||
								""
							}
							//onChange={handleChangeCalories("afternoon", day)}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{ readOnly: true }}
							margin="normal"
							variant="outlined"
							type="number"
							InputProps={{
								inputProps: {
									min: 0,
								},
							}}
						/>
						<br />
						<br />
						<br />
						<h5>Dinner</h5>
						<TextField
							id={`${dayLabel} dinner description`}
							label={`Description`}
							placeholder={`Enter ${day} dinner description here...`}
							value={
								(nutritionalPlan.plan &&
									nutritionalPlan.plan[day] &&
									nutritionalPlan.plan[day].dinner &&
									nutritionalPlan.plan[day].dinner
										.description) ||
								""
							}
							onChange={handleChange(
								"dinner",
								day,
								"description"
							)}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							multiline
							variant="outlined"
							rows={6}
							required
						/>
						<TextField
							id={`${dayLabel} dinner calories`}
							label={`Calories`}
							value={
								(nutritionalPlan.plan &&
									nutritionalPlan.plan[day] &&
									nutritionalPlan.plan[day].dinner &&
									nutritionalPlan.plan[day].dinner
										.calories) ||
								""
							}
							//onChange={handleChangeCalories("dinner", day)}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{ readOnly: true }}
							margin="normal"
							variant="outlined"
							type="number"
							InputProps={{
								inputProps: {
									min: 0,
								},
							}}
						/>
					</>
				)}
				<CardFooter className="px-0 mx-0">
					<TextField
						id={`${dayLabel} total calories`}
						label={`Total calories ${day}`}
						value={
							(nutritionalPlan.plan &&
								nutritionalPlan.plan[day] &&
								nutritionalPlan.plan[day].totalCalories) ||
							""
						}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{ readOnly: true }}
						margin="normal"
						variant="outlined"
						type="number"
						InputProps={{
							inputProps: {
								min: 0,
							},
						}}
					/>
				</CardFooter>
				<TableDialog
					open={openFoodTableDialog}
					setOpen={setOpenFoodTableDialog}
					data={foodPlates}
					foodPlatesTable={true}
					onSelectRow={(foodPlateId) => {
						const foodPlate = foodPlates?.find(
							(x) => x._id === foodPlateId
						);
						const { meal, submeal, mealId } = selectedMeal;

						let newPlan = nutritionalPlan;

						if (submeal) {
							if (!newPlan.plan[day][meal][submeal]) {
								newPlan.plan[day][meal][submeal] = {};
							}

							newPlan.plan[day][meal][
								submeal
							].foodPlate = foodPlateId;
							newPlan.plan[day][meal][submeal].meal = mealId;
						} else {
							newPlan.plan[day][meal].foodPlate = foodPlateId;
							newPlan.plan[day][meal].meal = mealId;
						}

						newPlan.plan[
							day
						].totalCalories = calculateTotalCalories(newPlan, day);

						newPlan.totalCaloriesPlan = calculateTotalCalories(
							newPlan
						);

						setNutritionalPlan({
							...nutritionalPlan,
							plan: newPlan.plan,
						});

						setOpenFoodTableDialog(false);
					}}
				/>
			</>
		);
	};

	const handleChangeCalories = (element, day) => (event) => {
		if (event.target.value === " ") return;
		let newText = nutritionalPlan.plan;
		if (element === "totalCalories") {
			for (const day of Object.keys(newText)) {
				for (const item of Object.keys(newText[day])) {
					if (
						item !== "totalCalories" &&
						newText[day][item].calories &&
						newText[day][item].calories !== "" &&
						!isNaN(newText[day][item].calories)
					) {
						return alertError({
							error: null,
							customMessage:
								"The total calories of the day have been calculated automatically, to modify it please delete the calories indicated in each of the meals of the day to avoid inconsistencies.",
						});
					}
				}
			}
			newText[day][element] = event.target.value;
		} else newText[day][element].calories = event.target.value;
		let total = 0;
		for (const day of Object.keys(newText)) {
			let totalDay = 0;
			for (const item of Object.keys(newText[day])) {
				if (item !== "totalCalories") {
					if (
						newText[day][item].calories &&
						newText[day][item].calories !== ""
					) {
						if (typeof newText[day][item].calories === "string")
							newText[day][item].calories = newText[day][
								item
							].calories?.includes(",")
								? newText[day][item].calories.replace(",", ".")
								: newText[day][item].calories;
						if (!isNaN(newText[day][item].calories)) {
							const _calories = parseFloat(
								newText[day][item].calories
							);
							newText[day][item].calories = _calories;
							totalDay += _calories;
						} else
							return alertError({
								error: null,
								customMessage: `${day} ${item} calories should be a number.`,
							});
					}
				}
			}
			if (
				totalDay === 0 &&
				newText[day].totalCalories &&
				newText[day].totalCalories !== ""
			) {
				if (typeof newText[day].totalCalories === "string")
					newText[day].totalCalories = newText[
						day
					].totalCalories?.includes(",")
						? newText[day].totalCalories.replace(",", ".")
						: newText[day].totalCalories;
				if (!isNaN(newText[day].totalCalories)) {
					totalDay = parseFloat(newText[day].totalCalories);
				} else
					return alertError({
						error: null,
						customMessage: `${day} total calories should be a number.`,
					});
			}
			newText[day].totalCalories = totalDay;
			total += totalDay;
		}
		setNutritionalPlan({
			...nutritionalPlan,
			plan: newText,
			totalCaloriesPlan: total,
		});
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					{!nutritionalPlanId ||
					user?.role.includes("admin") ||
					(user?.role.includes("nutritionist") &&
						nutritionalPlan.nutritionist === user?._id) ? (
						<>
							<CardHeader title="Edit nutritional plan"></CardHeader>
							<CardBody>
								<AppBar
									position="static"
									color="default"
									key="appbar"
								>
									<Tabs scrollButtons="auto" key="tabs">
										<Tab
											key={"es"}
											label={
												languages.find(
													(item) =>
														item.isocode === "es"
												)?.fullName
											}
										/>
									</Tabs>
								</AppBar>
								<TextField
									id={`title`}
									label="Title"
									value={nutritionalPlan.title}
									onChange={handleChange("title")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									required
								/>
								<br />
								<Editor
									body={nutritionalPlan.description || ""}
									setBody={(new_body) =>
										handleChangeEditor(
											"description",
											new_body
										)
									}
									className="max-height"
									placeholder={
										"Enter plan description here..."
									}
									label="Description"
								/>
								<br />
								<br />
								<TextField
									id={`References`}
									label="References"
									value={nutritionalPlan.references || ""}
									margin="normal"
									variant="outlined"
									placeholder={"Enter References here ..."}
									onChange={handleChange("references")}
								/>
								<br />
								<TextField
									id={`Plan total calories`}
									label={`Total calories plan`}
									value={
										nutritionalPlan.totalCaloriesPlan || ""
									}
									onChange={handleChange("totalCaloriesPlan")}
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{ readOnly: true }}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										readOnly: true,
										inputProps: {
											min: 0,
										},
									}}
								/>
								<br />
								<br />
								<div className="mb-2">Diet Types</div>
								{nutritionalPlan?.dietTypes?.length > 0 ? (
									nutritionalPlan?.dietTypes?.map(
										(dietType) => {
											return (
												<Chip
													label={getNonEmpty(
														dietType.fullName
													)}
													className="m-2"
												></Chip>
											);
										}
									)
								) : (
									<div>
										Choose food plates and save to know the
										compatibility of diets
									</div>
								)}
								<br />
								<br />
								<br />
								<label htmlFor={"upload-image"}>
									<input
										style={{ display: "none" }}
										id={"upload-image"}
										name={"upload-image"}
										type="file"
										accept={"image/*"}
										onChange={(e) => {
											setSelectedBanner(
												e.target.files[0]
											);
										}}
									/>
									<Button
										style={{ marginRight: "15px" }}
										color="secondary"
										component="span"
										variant="outlined"
									>
										{selectedBanner ||
										(nutritionalPlan.bannerURL &&
											nutritionalPlan.bannerURL !== "")
											? "Change banner"
											: "Upload banner"}
									</Button>
								</label>
								{(selectedBanner ||
									(nutritionalPlan.bannerURL &&
										nutritionalPlan.bannerURL !== "")) && (
									<>
										<Tooltip title={"Preview banner"}>
											<Button
												size="small"
												onClick={() =>
													setOpenPreviewDialog(true)
												}
												style={buttonsStyle}
											>
												<VisibilityIcon />
											</Button>
										</Tooltip>
										<PreviewDialog
											title={"Preview banner"}
											open={openPreviewDialog}
											setOpen={setOpenPreviewDialog}
											src={
												selectedBanner
													? URL.createObjectURL(
															selectedBanner
													  )
													: `${SERVER_URL}/${nutritionalPlan.bannerURL}`
											}
										/>
										<Tooltip title="Delete">
											<Button
												size="small"
												style={buttonsStyle}
												onClick={() => {
													setSelectedBanner(null);
													setNutritionalPlan({
														...nutritionalPlan,
														bannerURL: "",
													});
												}}
											>
												<DeleteIcon />
											</Button>
										</Tooltip>
										<span>
											{selectedBanner
												? selectedBanner?.name
												: nutritionalPlan.bannerURL &&
												  nutritionalPlan.bannerURL !==
														""
												? nutritionalPlan.bannerURL.split(
														/-(.*)/s
												  )[1]
												: ""}
										</span>
									</>
								)}
								<br />
								<br />
								<div className="row">
									<div className="col-12"><h3>Compatibility algorithm parameters</h3></div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`forLosing`}
													label="Weight > for Losing"
													value={nutritionalPlan?.weight?.forLosing || ""}
													onChange={handleChangeWeight("forLosing")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`forMantain`}
													label="Weight > for Mantain"
													value={nutritionalPlan?.weight?.forMantain || ""}
													onChange={handleChangeWeight("forMantain")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`forGaining`}
													label="Weight > for Gaining"
													value={nutritionalPlan?.weight?.forGaining || ""}
													onChange={handleChangeWeight("forGaining")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`slow`}
													label="Velocity > slow"
													value={nutritionalPlan?.velocity?.slow || ""}
													onChange={handleChangeVelocity("slow")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`recomended`}
													label="Velocity > recomended"
													value={nutritionalPlan?.velocity?.recomended || ""}
													onChange={handleChangeVelocity("recomended")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`accelerated`}
													label="Velocity > accelerated"
													value={nutritionalPlan?.velocity?.accelerated || ""}
													onChange={handleChangeVelocity("accelerated")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`less29`}
													label="Age > less than 29"
													value={nutritionalPlan?.age?.less29 || ""}
													onChange={handleChangeAge("less29")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`less39`}
													label="Age > less than 39"
													value={nutritionalPlan?.age?.less39 || ""}
													onChange={handleChangeAge("less39")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`less49`}
													label="Age > less than 49"
													value={nutritionalPlan?.age?.less49 || ""}
													onChange={handleChangeAge("less49")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`more50`}
													label="Age > more than 50"
													value={nutritionalPlan?.age?.more50 || ""}
													onChange={handleChangeAge("more50")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`femenine`}
													label="Gender > femenine"
													value={nutritionalPlan?.gender?.femenine || ""}
													onChange={handleChangeGender("femenine")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`masculine`}
													label="Gender > masculine"
													value={nutritionalPlan?.gender?.masculine || ""}
													onChange={handleChangeGender("masculine")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`other`}
													label="Gender > other"
													value={nutritionalPlan?.gender?.other || ""}
													onChange={handleChangeGender("other")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<TextField
													id={`low`}
													label="IMC > low"
													value={nutritionalPlan?.imc?.low || ""}
													onChange={handleChangeImc("low")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`normal`}
													label="IMC > normal"
													value={nutritionalPlan?.imc?.normal || ""}
													onChange={handleChangeImc("normal")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`high`}
													label="IMC > high"
													value={nutritionalPlan?.imc?.high || ""}
													onChange={handleChangeImc("high")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
									<div className="col-md-3">
										<TextField
													id={`veryHigh`}
													label="IMC > very high"
													value={nutritionalPlan?.imc?.veryHigh || ""}
													onChange={handleChangeImc("veryHigh")}
													InputLabelProps={{
														shrink: true,
													}}
													required
													margin="normal"
													variant="outlined"
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 10
														},
													}}
												/>
									</div>
								</div>
								<br />
								<FormControlLabel
									control={
										<Checkbox
											checked={nutritionalPlan.active}
											onChange={() =>
												setNutritionalPlan({
													...nutritionalPlan,
													active: !nutritionalPlan.active,
												})
											}
											name="checkActive"
										/>
									}
									label="Active"
								/>
								<br />
								{user.role.includes("admin") && (
									<>
										<FormControlLabel
											control={
												<Checkbox
													checked={
														nutritionalPlan.premium
													}
													onChange={() =>
														setNutritionalPlan({
															...nutritionalPlan,
															premium: !nutritionalPlan.premium,
															price: 0,
														})
													}
													name="checkPremium"
												/>
											}
											label="Premium"
										/>
										<br />
										{nutritionalPlan.premium && (
											<TextField
												id={`price`}
												label={`Price (€)`}
												value={
													nutritionalPlan.price || ""
												}
												onChange={handleChange("price")}
												InputLabelProps={{
													shrink: true,
												}}
												margin="normal"
												variant="outlined"
												type="number"
												InputProps={{
													inputProps: {
														min: 0,
													},
												}}
											/>
										)}
									</>
								)}
								<br />
							</CardBody>
							<CardHeader title="Weekly plan">
								<CardHeaderToolbar>
									<FormControlLabel
										control={
											<Checkbox
												checked={showDietTypes}
												onChange={() =>
													setShowDietTypes(
														!showDietTypes
													)
												}
												name="checkActive"
											/>
										}
										label="Show course diet types"
									/>
								</CardHeaderToolbar>
							</CardHeader>
							<CardBody>
								<DaysTabBlock
									daysTabContent={renderDaysTabContent}
									days={weekdays}
								/>
							</CardBody>
						</>
					) : (
						<>
							<CardHeader
								title={
									"View nutritional plan " +
									nutritionalPlan.title
								}
							/>
							<CardBody>
								<PlansContent
									type="nutritional"
									plan={{
										...nutritionalPlan,
										standard: !nutritionalPlan.nutritionist,
									}}
									user={user}
								/>
							</CardBody>
						</>
					)}
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/nutritional-plans")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					{(!nutritionalPlanId ||
						user?.role.includes("admin") ||
						(user?.role.includes("nutritionist") &&
							nutritionalPlan.nutritionist === user?._id)) && (
						<>
							<Button
								onClick={() => {
									if (nutritionalPlan.used)
										setOpenConfirmDialog(1);
									else saveNutritionalPlan();
								}}
								variant="outlined"
								color="primary"
								style={{ marginRight: "20px" }}
							>
								Save nutritional plan
							</Button>
							<ConfirmDialog
								title={
									"This nutritional plan is already associated to some users, are you sure you want to modify it?"
								}
								open={openConfirmDialog === 1}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => saveNutritionalPlan()}
							/>
						</>
					)}
					{nutritionalPlanId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(2)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete nutritional plan
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this nutritional plan?"
								}
								open={openConfirmDialog === 2}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteNutritionalPlan(nutritionalPlanId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Nutritional plan deleted successfully",
												});
												history.push(
													"/nutritional-plans"
												);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete nutritional plan.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
