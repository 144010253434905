import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { checkIsEmpty } from "../../../utils/helpers";
import { alertError } from "../../../utils/logger";
import MultilanguageTabBlock from "../MultilanguageTabBlock";
import Editor from "../editor/Editor";

function getEmptySubscriptionFaq() {
	return {
		title: {},
		description: {},
		active: true,
	};
}

const EditSubscriptionFaqDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly } = props;

	const [faq, setFaq] = useState(getEmptySubscriptionFaq());

	useEffect(() => {
		if (!open || !data) setFaq(getEmptySubscriptionFaq());
		else setFaq(data);
	}, [data, open]);

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!faq[element]) faq[element] = {};
			let newText = faq[element];
			newText[lang] = event.target.value;
			setFaq({ ...faq, [element]: newText });
		} else setFaq({ ...faq, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!faq[element]) faq[element] = {};
				let newText = faq[element];
				newText[lang] = value;
				setFaq({ ...faq, [element]: newText });
			} else setFaq({ ...faq, [element]: value });
		}
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Title"
					value={(faq?.title && faq?.title[lang]) || ""}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					disabled={readOnly}
					required
				/>
				<br />
				<br />
				<div>Description</div>
				<Editor
					disabled={readOnly}
					body={(faq.description && faq?.description[lang]) || ""}
					setBody={(new_body) => {
						handleChangeEditor("description", new_body, lang);
					}}
					className="max-height"
					placeholder={"Enter faq description here..."}
				/>
			</>
		);
	};

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
				<br />
				<br />
				<FormControlLabel
					control={
						<Checkbox
							checked={faq?.active}
							onChange={() =>
								setFaq({
									...faq,
									active: !faq?.active,
								})
							}
							name="checkActive"
						/>
					}
					disabled={readOnly}
					label="Active"
				/>
				<br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setFaq(getEmptySubscriptionFaq());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (checkIsEmpty(faq.title)) {
							alertError({
								error: null,
								customMessage:
									"The title is required in at least one of the languages",
							});
						} else {
							setOpen(false);
							onSave(faq);
							setFaq(getEmptySubscriptionFaq());
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditSubscriptionFaqDialog;
