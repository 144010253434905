import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
	Tooltip,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { SERVER_URL } from "../../../api";
import { checkIsEmpty } from "../../../utils/helpers";
import { alertError } from "../../../utils/logger";
import MultilanguageTabBlock from "../MultilanguageTabBlock";
import Editor from "../editor/Editor";
import { buttonsStyle } from "../tables/table";
import PreviewDialog from "./PreviewDialog";

function getEmptyAnswer() {
	return {
		title: {},
		correct: false,
		explanation: {},
		imageURL: "",
		selectedImage: null,
	};
}

const EditAnswerDialog = (props) => {
	const { title, data, open, setOpen, onSave } = props;

	const [answer, setAnswer] = useState(getEmptyAnswer());

	useEffect(() => {
		if (!open || !data) setAnswer(getEmptyAnswer());
		else setAnswer(data);
	}, [data, open]);

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!answer[element]) answer[element] = {};
			let newText = answer[element];
			newText[lang] = event.target.value;
			setAnswer({ ...answer, [element]: newText });
		} else setAnswer({ ...answer, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!answer[element]) answer[element] = {};
				let newText = answer[element];
				newText[lang] = value;
				setAnswer({ ...answer, [element]: newText });
			} else setAnswer({ ...answer, [element]: value });
		}
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Title"
					value={(answer?.title && answer?.title[lang]) || ""}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<br />
				<div>Explanation</div>
				<Editor
					body={
						(answer.explanation && answer?.explanation[lang]) || ""
					}
					setBody={(new_body) => {
						handleChangeEditor("explanation", new_body, lang);
					}}
					className="max-height"
					placeholder={"Enter answer explanation here..."}
				/>
			</>
		);
	};

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
				<br />
				<br />
				<label htmlFor={"upload-answer-image"} className="ml-0">
					<input
						style={{ display: "none" }}
						id={"upload-answer-image"}
						name={"upload-answer-image"}
						type="file"
						accept={"image/*"}
						onChange={(e) => {
							setAnswer({
								...answer,
								selectedImage: e.target.files[0],
							});
						}}
					/>
					<Button
						style={{ marginRight: "15px" }}
						color="secondary"
						component="span"
						variant="outlined"
					>
						{answer?.selectedImage ||
						(answer?.imageURL && answer?.imageURL !== "")
							? "Change image"
							: "Upload image"}
					</Button>
				</label>
				{(answer?.selectedImage ||
					(answer?.imageURL && answer?.imageURL !== "")) && (
					<>
						<Tooltip title={"Preview image"}>
							<Button
								size="small"
								onClick={() => setOpenPreviewDialog(true)}
								style={{
									...buttonsStyle,
									marginRight: "15px",
								}}
							>
								<Visibility />
							</Button>
						</Tooltip>
						<PreviewDialog
							title={"Preview image"}
							open={openPreviewDialog}
							setOpen={setOpenPreviewDialog}
							src={
								answer?.selectedImage
									? URL.createObjectURL(answer?.selectedImage)
									: `${SERVER_URL}/${answer?.imageURL}`
							}
						/>
						<span>
							{answer?.selectedImage
								? answer?.selectedImage?.name
								: answer?.imageURL && answer?.imageURL !== ""
								? answer?.imageURL?.split(/-(.*)/s)[1]
								: ""}
						</span>
					</>
				)}
				<br />
				<FormControlLabel
					control={
						<Checkbox
							checked={answer?.correct}
							onChange={() =>
								setAnswer({
									...answer,

									correct: !answer?.correct,
								})
							}
							name="checkCorrect"
						/>
					}
					label="Correct"
				/>
				<br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setAnswer(getEmptyAnswer());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (checkIsEmpty(answer.title)) {
							alertError({
								error: null,
								customMessage:
									"The title is required in at least one of the languages",
							});
						} else {
							setOpen(false);
							onSave(answer);
							setAnswer(getEmptyAnswer());
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditAnswerDialog;
