import React from 'react';
import 'hammerjs';
import { 
    Chart, 
    ChartSeries, 
    ChartSeriesItem, 
    ChartTitle,
    ChartSubtitle
} from '@progress/kendo-react-charts';
import './charts.css';

const CircularChartContainer = ({ data, units, title, noDataMessage }) => {

  const labelContent = (props) => {
    return `${props.dataItem.value} ${units || ''}`;
  };

    return <>
      <Chart>
            <ChartTitle text={title}/>
            {(!data || data.length === 0) ? <ChartSubtitle text={noDataMessage || 'No data found'}/> : <ChartSubtitle text=""/>}
            <ChartSeries>
                <ChartSeriesItem 
                  type="pie" 
                  data={data} 
                  field="value"
                  labels={{
                    visible: true,
                    content: labelContent
                  }}
                  categoryField="key" 
                />
            </ChartSeries>
      </Chart>
    </>
};

export default CircularChartContainer