/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch } from "react-router-dom";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import EmailVerify from "./EmailVerify";
import ResetPasswordUser from "./ResetPasswordUser";

export function ActionsPage() {
	return (
		<>
			<div className="bg-white h-100">
				<header
					style={{
						backgroundImage: `url(${toAbsoluteUrl(
							"/media/bg/bg-auth.jpg"
						)})`,
					}}
				>
					<div className="container-fluid pt-5">
						<nav
							className={`navbar navbar-expand-lg navbar-light apercubold`}
						>
							<div className="container">
								<span className="navbar-brand">
									<img
										src={toAbsoluteUrl(
											"/media/logo/logo-negro.png"
										)}
										width={280}
									></img>
								</span>
							</div>
						</nav>
					</div>
				</header>
				<div
					className="container-fluid d-flex align-items-center justify-content-center"
					style={{ marginTop: "10vh" }}
				>
					<div className="w-100">
						<Switch>
							<ContentRoute
								path="/verify-email"
								component={EmailVerify}
							/>
							<ContentRoute
								path="/reset-password-user/:userId?/:token?"
								component={ResetPasswordUser}
							/>
						</Switch>
					</div>
				</div>
				<footer
					className="container-fluid"
					style={{
						position: "absolute",
						bottom: "5vh",
					}}
				>
					<div className="container">
						<div className="row">
							<div className="col-12 text-center">
								[{" "}
								<a
									href="https://eatfity.com/contacta-con-eatfity/"
									target="_blank"
									style={{ color: "#000000" }}
								>
									Contactar con eatfity
								</a>{" "}
								·{" "}
								<a
									href="https://eatfity.com"
									target="_blank"
									style={{ color: "#000000" }}
								>
									eatfity.com
								</a>{" "}
								·{" "}
								<a
									href="https://eatfity.com/privacy-policy/"
									target="_blank"
									style={{ color: "#000000" }}
								>
									Política de privacidad
								</a>{" "}
								·{" "}
								<a
									href="https://eatfity.com/privacy-policy/"
									target="_blank"
									style={{ color: "#000000" }}
								>
									Política de cookies
								</a>{" "}
								]
							</div>
						</div>
					</div>
				</footer>
			</div>
		</>
	);
}
