import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import RoutineContent from './RoutineContent'

const ViewRoutineDialog = (props) => {
  const { open, setOpen, routine } = props

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={open}
      onClose={() => setOpen(null)}
      aria-labelledby="view-routine-dialog">
      <DialogTitle id="view-routine-dialog">{routine?.title || 'Routine'}</DialogTitle>
      <DialogContent>
        <RoutineContent routine={routine}/>
      </DialogContent>
      <DialogActions>
        <Button
            variant="outlined"
            color="default"
            onClick={() => setOpen(null)}
        >
            OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ViewRoutineDialog
