import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	FormHelperText,
	Tooltip
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { SERVER_URL } from "../../../../api/index";
import DeleteIcon from '@material-ui/icons/Delete';
import Editor from '../../../components/editor/Editor'
import {
	deleteUser,
	getUserById,
	postUser,
	updateUser,
} from '../../../../api/user';
import { getTrainerCertifications } from '../../../../api/trainerCertification';
import { getTrainerSpecializations } from '../../../../api/trainerSpecialization';
import {getNutritionistCertifications} from'../../../../api/nutritionistCertification';
import {getNutritionistSpecializations} from '../../../../api/nutritionistSpecialization';
import { useSkeleton } from '../../../hooks/useSkeleton';
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import { ArrowUpward, ArrowDownward, Visibility } from '@material-ui/icons'
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { shallowEqual, useSelector } from 'react-redux';
import { getNonEmpty } from '../../../../utils/helpers';
import { buttonsStyle } from '../../../components/tables/table'


// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyAdmin() {
	return {
		fullName: '',
		active: true,
		email: '',
		role: ['admin'],
		admin: true,
		trainer: null,
		nutritionist: null,
		skills: '',
		imagesURLs: [],
		password: '',
		repeatPassword: '',
		trainerCertifications: [],
		trainerSpecializations: [],
		nutritionistCertifications: [],
		nutritionistSpecializations: [],
	};
}

export default function EditAdminsPage() {
	const [admin, setAdmin] = useState(getEmptyAdmin());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [selectedImages, setSelectedImages] = useState(null)
	const [deletedImages, setDeletedImages] = useState(null)
	const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
	const [trainerSpecializations, setTrainerSpecializations] = useState(null)
	const [trainerCertifications, setTrainerCertifications] = useState(null)
	const [nutritionistCertifications, setNutritionistCertifications] = useState(null);
	const [nutritionistSpecializations, setNutritionistSpecializations] = useState(null);
	const [newPassword, setNewPassword] = useState({ password: null, repeatPassword: null })
	const [changePassword, setChangePassword] = useState(false)
	const adminId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)


	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;

	const MenuProps = {
		PaperProps: {
		  style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		  },
		},
		getContentAnchorEl: () => null,
	  };

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getTrainerSpecializations()
			.then((res) => {
				if (res.status === 200) {
					setTrainerSpecializations(res.data)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get trainer specializations.' });
				history.push('/admins');
			});
		getTrainerCertifications()
			.then((res) => {
				if (res.status === 200) {
					setTrainerCertifications(res.data)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get trainer certifications.' });
				history.push('/admins');
			});
		getNutritionistSpecializations()
			.then((res) => {
				if (res.status === 200) {
					setNutritionistSpecializations(res.data)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get nutritionist specializations.' });
				history.push('/admins');
			});
		getNutritionistCertifications()
			.then((res) => {
				if (res.status === 200) {
					setNutritionistCertifications(res.data)
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get nutritionist certifications.' });
				history.push('/admins');
			});
		if (!adminId) {
			disableLoadingData();
			return;
		}
		getUserById(adminId)
			.then((res) => {
				if (res.status === 200) {
					const admin = res.data;
					delete admin.password;
					setAdmin(admin);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get admin.' });
				history.push('/admins');
			});
	}, [adminId, disableLoadingData, history]);

	function saveAdmin() {
		let saveAdmin = admin
		if (!adminId || changePassword) {
		  if (!newPassword.password || !newPassword.repeatPassword) {
			alertError({ error: null, customMessage: 'Please enter the password.' })
			return
		  }
		  if (newPassword.password !== newPassword.repeatPassword) {
			alertError({ error: null, customMessage: 'Passwords do not match.' })
			return
		  }
		  saveAdmin = {...saveAdmin, password: newPassword.password }
		}
		if (!adminId) {
			postUser(saveAdmin, selectedImages)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Admin successfully created.',
						});
						history.push('/admins');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save admin.' });
				});
		} else {
			updateUser(adminId, saveAdmin, selectedImages)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/admins');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleDeleteImage = (index) => {
		// TODO: DELETE IMAGES FROM SERVER
		let newImages = [...admin.imagesURLs]
		const deletedImage = newImages.splice(index, 1)
		setAdmin({ ...admin, imagesURLs: newImages })
		if (selectedImages && selectedImages[index]) {
		  let _selectedImages = {}
		  for (const [key, value] of Object.entries(selectedImages)) {
			if (key !== index) {
			  if (key > index) {
				_selectedImages[key - 1] = value
			  } else _selectedImages[key] = value
			} 
		  }
		  if (!Object.keys(_selectedImages).length) _selectedImages = null
		  setSelectedImages(_selectedImages)
		} else setDeletedImages(deletedImages ? deletedImages.concat(deletedImage) : [deletedImage])
	  }
	
	  const handleMoveImage = (index, newIndex) => {
		const aux = admin.imagesURLs[index]
		let images = [...admin.imagesURLs]
		images.splice(index, 1, images[newIndex])
		images.splice(newIndex, 1, aux)
		setAdmin({ ...admin, imagesURLs: images })
		if (selectedImages && (selectedImages[index] || selectedImages[newIndex])) {
		  let _selectedImages = {}
		  for (const [key, value] of Object.entries(selectedImages)) {
			if (key === index.toString()) _selectedImages[newIndex] = value
			else if (key === newIndex.toString()) _selectedImages[index] = value
			else _selectedImages[key] = value
		  }
		  setSelectedImages(_selectedImages)
		}
	  }

	const handleChange = (element) => (event) => {
		console.log("CANVI A LA DESCRIPTION: ", element, event.target.value);
		setAdmin({ ...admin, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value) => {
		setAdmin({ ...admin, [element]: value })
	  }

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit admin'></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label='Full name'
							value={admin.fullName}
							onChange={handleChange('fullName')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<TextField
							id={`email`}
							label='Email'
							value={admin.email}
							onChange={handleChange('email')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						{(!adminId || changePassword) ? <>
							<br />
							<br />
							<TextField
								id={`password`}
								label="Password"
								value={newPassword.password}
								onChange={(event) => {
									if (event.target.value !== ' ') setNewPassword({ ...newPassword, password: event.target.value })
								}}
								InputLabelProps={{
									shrink: true
								}}
								type="password"
								margin="normal"
								variant="outlined"
								required
							/>
							<TextField
								id={`repeatPassword`}
								label="Repeat password"
								value={newPassword.repeatPassword}
								onChange={(event) => {
									if (event.target.value !== ' ') setNewPassword({ ...newPassword, repeatPassword: event.target.value })
								}}
								InputLabelProps={{
									shrink: true
								}}
								type="password"
								margin="normal"
								variant="outlined"
								required
							/>
							<br/>
							<br/>
							{adminId && <>
								<Button
									onClick={() => {
										setChangePassword(false)
										setNewPassword({ password: null, repeatPassword: null })
									}}
									variant="outlined"
									style={{ marginRight: '20px' }}
								>
									Cancel change password
								</Button>
								<br />
								<br />  
							</>}
							</> : <>
							<br />
							<br />
							<Button
								onClick={() => setChangePassword(true)}
								variant="outlined"
								color="primary"
								style={{ marginRight: '20px' }}
							>
								Change password
							</Button>
							<br />
							<br />
						</>}
						<br/>
						<FormControlLabel
							control={<Checkbox checked={admin.active} onChange={() => setAdmin({ ...admin, active: !admin.active })} name="checkActive" />}
							label="Active"
						/>

						<FormControl style={{ width: '100%' }}>
							<InputLabel id="demo-simple-select-standard-label">Trainer specializations</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={admin.trainerSpecializations}
								multiple
								onChange={handleChange('trainerSpecializations')}
								MenuProps={MenuProps}
							>
							{trainerSpecializations?.map((option) => (
								<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.fullName)}</MenuItem>
							))}
							</Select>
							<FormHelperText>Select at least one specialization</FormHelperText>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id="demo-simple-select-standard-label">Trainer certifications</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={admin.trainerCertifications}
								multiple
								onChange={handleChange('trainerCertifications')}
								MenuProps={MenuProps}
							>
							{trainerCertifications?.map((option) => (
								<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.fullName)}</MenuItem>
							))}
							</Select>
							<FormHelperText>Select at least one certification</FormHelperText>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id="demo-simple-select-standard-label">Nutritionist specializations</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={admin.nutritionistSpecializations}
								multiple
								onChange={handleChange('nutritionistSpecializations')}
								MenuProps={MenuProps}
							>
							{nutritionistSpecializations?.map((option) => (
								<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.fullName)}</MenuItem>
							))}
							</Select>
							<FormHelperText>Select at least one specialization</FormHelperText>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: '100%' }}>
							<InputLabel id="demo-simple-select-standard-label">Nutritionist certifications</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={admin.nutritionistCertifications}
								multiple
								onChange={handleChange('nutritionistCertifications')}
								MenuProps={MenuProps}
							>
							{nutritionistCertifications?.map((option) => (
								<MenuItem key={option._id} value={option._id}>{getNonEmpty(option.fullName)}</MenuItem>
							))}
							</Select>
							<FormHelperText>Select at least one certification</FormHelperText>
						</FormControl>
						<br />
						<br />
						<Editor
							body={admin.skills || ''}
							setBody={new_body => handleChangeEditor('skills', new_body)}
							className='max-height'
							placeholder={'Enter skills description here...'}
							label="Skills"
						/>
						<br/>
						{admin.imagesURLs?.map((_, index) => {
							return (
							<>
								<label htmlFor={"upload-image" + index} key={index}>
									<input
										style={{ display: 'none' }}
										id={"upload-image" + index}
										name={"upload-image" + index}
										type="file"
										accept={'image/*'}
										onChange={(e) => {
											setSelectedImages({ ...selectedImages, [index]: e.target.files[0] })
										}}
										key={index}
									/>
									<Button
										style={{ marginRight: '15px' }}
										color="secondary"
										component="span"
										variant="outlined">
										{((selectedImages && selectedImages[index]) || admin.imagesURLs[index] !== '') ? 'Change image ' + (index + 1) : 'Upload image ' + (index + 1)}
									</Button>
								</label>
								{((selectedImages && selectedImages[index]) || admin.imagesURLs[index] !== '') &&
								<>
									<Tooltip title={'Preview image ' + (index + 1)}>
										<Button
											size="small"
											onClick={() => setOpenPreviewDialog(index)}
											style={buttonsStyle}>
											<Visibility/>
										</Button>
									</Tooltip>
									<PreviewDialog
										title={'Preview image ' + (index + 1)}
										open={openPreviewDialog === index}
										setOpen={setOpenPreviewDialog}
										src={(selectedImages && selectedImages[index]) ? URL.createObjectURL(selectedImages[index]) : `${SERVER_URL}/${admin.imagesURLs[index]}`}
									/>
									<Tooltip title="Delete">
										<Button
											size="small"
											style={buttonsStyle}
											onClick={() => handleDeleteImage(index)}>
											<DeleteIcon/>
										</Button>
									</Tooltip>
									<Tooltip title="Move up">
										<Button
											size="small"
											style={buttonsStyle}
											disabled={index === 0}
											onClick={() => handleMoveImage(index, index - 1)}>
											<ArrowUpward/>
										</Button>
									</Tooltip>
									<Tooltip title="Move down">
										<Button
											size="small"
											disabled={!(index < admin.imagesURLs.length - 1 && ((selectedImages && selectedImages[index + 1]) || admin.imagesURLs[index + 1] !== ''))}
											style={{...buttonsStyle, marginRight: '1em' }}
											onClick={() => handleMoveImage(index, index + 1)}>
											<ArrowDownward/>
										</Button>
									</Tooltip>
									<span>
										{selectedImages && selectedImages[index] ? selectedImages[index]?.name : (admin.imagesURLs[index] !== '' ? admin.imagesURLs[index].split(/-(.*)/s)[1] : '')}
									</span>
									<br />
								</>
								}
							</>
							)
						})}
						<br />
						<br />     
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setAdmin({ ...admin, imagesURLs: admin.imagesURLs.concat('') })}
						>
							Add image
						</Button>
					</CardBody>   
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/admins')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveAdmin()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save admin
					</Button>
					{adminId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete admin
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this admin?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteUser(adminId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Admin deleted successfully',
												});
												history.push('/admins');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete admin.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
