import { API, authClient } from "../index";
import { uploadSingleFile } from "../files";

export const getSports = () => {
	return authClient().get(`${API}/sport?getDisabled=true`);
};

export const getSportById = (id) => {
	return authClient().get(`${API}/sport/${id}`);
};

export const deleteSport = (id) => {
	return authClient().delete(`${API}/sport/${id}`);
};

export const postSport = async (sport, icon, banner) => {
	if (icon) {
		const response = await uploadSingleFile(icon, "sports");
		sport.iconURL = response.data.fileURL;
	}
	if (banner) {
		const response = await uploadSingleFile(banner, "sports");
		sport.bannerURL = response.data.fileURL;
	}
	return authClient().post(`${API}/sport`, sport);
};

export const updateSport = async (id, sport, icon, banner) => {
	if (icon) {
		const response = await uploadSingleFile(icon, "sports");
		sport.iconURL = response.data.fileURL;
	}
	if (banner) {
		const response = await uploadSingleFile(banner, "sports");
		sport.bannerURL = response.data.fileURL;
	}
	return authClient().put(`${API}/sport/${id}`, sport);
};

export const changeStatusSport = async (id, active) => {
	return authClient().put(`${API}/sport/change-status/${id}`, { active });
};
