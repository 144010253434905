import { API, authClient } from "../index";
import { uploadSingleFile } from "../files";

export const getMultimedias = () => {
	return authClient().get(`${API}/multimedia?getDisabled=true`);
};

export const getMultimediaById = (id) => {
	return authClient().get(`${API}/multimedia/${id}`);
};

export const deleteMultimedia = (id) => {
	return authClient().delete(`${API}/multimedia/${id}`);
};

export const postMultimedia = async (multimedia, banner) => {
	if (banner) {
		const response = await uploadSingleFile(banner, "multimedias");
		multimedia.bannerURL = response.data.fileURL;
	}
	return authClient().post(`${API}/multimedia`, multimedia);
};

export const updateMultimedia = async (id, multimedia, banner) => {
	if (banner) {
		const response = await uploadSingleFile(banner, "multimedias");
		multimedia.bannerURL = response.data.fileURL;
	}
	return authClient().put(`${API}/multimedia/${id}`, multimedia);
};

export const changeStatusMultimedia = async (id, active) => {
	return authClient().put(`${API}/multimedia/change-status/${id}`, { active });
};
