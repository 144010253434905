import { API, authClient } from '../index';

// Get all trainer certifications
export const getTrainerCertifications = () => {
	return authClient().get(`${API}/trainer-certification?getDisabled=true`);
};

// Get trainer certification by id
export const getTrainerCertificationById = (id) => {
	return authClient().get(`${API}/trainer-certification/${id}`);
};

// Delete trainer certification
export const deleteTrainerCertification = (id) => {
	return authClient().delete(`${API}/trainer-certification/${id}`);
};

// Create trainer certification 
export const postTrainerCertification = async (trainerCertification) => {
	return authClient().post(`${API}/trainer-certification`, trainerCertification);
};

// Update trainer certification
export const updateTrainerCertification = async (id, trainerCertification) => {
	return authClient().put(`${API}/trainer-certification/${id}`, trainerCertification);
};

// Change status trainer certification
export const changeStatusTrainerCertification = async (id, active) => {
	return authClient().put(`${API}/trainer-certification/change-status/${id}`, { active });
};