import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import UserIcon from '@material-ui/icons/Person';

import { getGuests } from '../../../../api/guest';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import Table, { dateFormatter } from '../../../components/tables/table';
import { alertError } from '../../../../utils/logger';

const getData = (guests) => {
	return guests.map((guest) => ({
		...guest,
		id: guest._id,
		provider: guest.provider || '---',
		linkedWith: guest.linkedWith || '---',
	}));
};

export function GuestsPage() {
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(linkedWith) {
		if (linkedWith === '---') return linkedWith;
		return (
			<Tooltip title={'User'}>
				<Button
					size={'small'}
					onClick={() =>
						history.push('/view-user-info/' + linkedWith._id)
					}>
					<span>{linkedWith.email?.toLowerCase()}</span>
					<UserIcon />
				</Button>
			</Tooltip>
		);
	}

	const columns = [
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: 'ID' },
		{ dataField: 'defaultLanguage', text: 'Language', sort: true },
		{ dataField: 'provider', text: 'Provider', sort: true },
		{
			dataField: 'linkedWith',
			text: 'Linked with user',
			formatter: buttonFormatter,
		},
	];

	useEffect(() => {
		getGuests()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get guests.' })
			);
	}, [refresh]);

	return (
		<Card>
			<CardHeader title={'Guests list'} />
			<CardBody>
				<Table data={data} columns={columns} />
			</CardBody>
		</Card>
	);
}
