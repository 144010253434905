import { Button, Tooltip } from "@material-ui/core";
import RateReviewIcon from "@material-ui/icons/RateReview";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getSessions, getSessionsByTrainerId } from "../../../../api/session";
import { alertError } from "../../../../utils/logger";
import Table, {
	booleanFormatter,
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";

function getData(sessions) {
	let data = [];

	for (let i = 0; i < sessions.length; ++i) {
		const elem = {};
		elem.user = sessions[i]?.user?.fullName;
		elem.date = sessions[i].date;
		elem.reviewedByTrainer = sessions[i].reviewedByTrainer;
		if (sessions[i].score) elem.score = `${sessions[i].score.toFixed(2)}%`;
		else elem.score = "0%";
		elem.trainer = sessions[i]?.user?.trainer?.fullName || "NT";
		elem.id = sessions[i]._id;
		data = data.concat(elem);
	}

	return data;
}

export default function SessionsPage() {
	const [data, setData] = useState([]);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title="Review">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/view-session/" + cell)}
					>
						<RateReviewIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	let columns = [
		{ dataField: "user", text: "User", sort: true },
		{
			dataField: "reviewedByTrainer",
			text: "Reviewed by trainer",
			sort: true,
			formatter: booleanFormatter,
			align: "center",
			headerAlign: "center",
		},
		{
			dataField: "date",
			text: "Date",
			formatter: (cell) => dateFormatter(cell, true),
			sort: true,
		},
		{ dataField: "score", text: "Score", sort: true },
		{
			dataField: user?.role[0] === "admin" ? "trainer" : "",
			text: user?.role[0] === "admin" ? "Trainer" : "",
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		let isMounted = true;
		if (!user) return;
		else console.log(user._id);
		const fetchData = async () => {
			if (user.role[0] === "admin") {
				try {
					const res = await getSessions();
					if (res.status === 200) {
						if (isMounted) setData(getData(res.data));
					}
				} catch (error) {
					alertError({
						error: error,
						customMessage: "Could not get sessions.",
					});
				}
			} else {
				try {
					const res = await getSessionsByTrainerId(user._id);
					if (res.status === 200 && isMounted) {
						setData(getData(res.data));
					}
				} catch (error) {
					alertError({
						error: error,
						customMessage: "Could not get sessions.",
					});
				}
			}
		};

		fetchData();

		return () => {
			isMounted = false;
		};
	}, [user]);

	return (
		<>
			<Card>
				<CardHeader title="Sessions feedback list"></CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
				</CardBody>
			</Card>
		</>
	);
}
