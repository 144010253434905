import { useState, useCallback } from 'react';
import ContentSkeleton from '../modules/Skeleton/ContentSkeleton';

export const useSkeleton = () => {
	const [isLoading, setLoading] = useState(true);

	const disableLoading = useCallback(() => setLoading(false), []);

	return { isLoading, disableLoading, ContentSkeleton };
};
