import { API, authClient } from '../index';

// Get all nutritionist specializations
export const getNutritionistSpecializations = () => {
	return authClient().get(`${API}/nutritionist-specialization?getDisabled=true`);
};

// Get nutritionist specialization by id
export const getNutritionistSpecializationById = (id) => {
	return authClient().get(`${API}/nutritionist-specialization/${id}`);
};

// Delete nutritionist specialization
export const deleteNutritionistSpecialization = (id) => {
	return authClient().delete(`${API}/nutritionist-specialization/${id}`);
};

// Create nutritionist specialization 
export const postNutritionistSpecialization = async (nutritionistSpecialization) => {
	return authClient().post(`${API}/nutritionist-specialization`, nutritionistSpecialization);
};

// Update nutritionist specialization
export const updateNutritionistSpecialization = async (id, nutritionistSpecialization) => {
	return authClient().put(`${API}/nutritionist-specialization/${id}`, nutritionistSpecialization);
};

// Change status nutritionist specialization
export const changeStatusNutritionistSpecialization = async (id, active) => {
	return authClient().put(`${API}/nutritionist-specialization/change-status/${id}`, { active });
};