import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MuiThemeProvider,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AndroidIcon from "@material-ui/icons/Android";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PeopleIcon from "@material-ui/icons/People";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { useState } from "react";
import { SERVER_URL } from "../../../api";
import Table, { booleanFormatter, buttonsStyle } from "../tables/table";
import PreviewDialog from "./PreviewDialog";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

const TableDialog = (props) => {
	const {
		title,
		open,
		setOpen,
		data,
		onSelectRow,
		usersTable,
		routinesTable,
		routinePlan,
		subscriptionsTable,
		foodPlatesTable,
		lessonsTable,
		testsTable,
		questionsTable,
	} = props;
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);
	const [openFullDescription, setOpenFullDescription] = useState(false);
	const [description, setDescription] = useState(null);

	function iconFormatter(cell) {
		return cell.includes("trainer") || cell.includes("nutritionist") ? (
			<AssignmentIndIcon />
		) : cell.includes("user") ? (
			<PeopleIcon />
		) : cell.includes("bot") ? (
			<AndroidIcon />
		) : (
			<SettingsIcon />
		);
	}

	function buttonFormatter(cell) {
		return (
			<>
				<Tooltip title="Add">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let _cell = cell;
							if (usersTable)
								_cell = data.find((item) => item._id === cell);
							onSelectRow(_cell);
						}}
					>
						{usersTable ? (
							<PersonAddIcon />
						) : (
							<AddCircleOutlineIcon />
						)}
					</Button>
				</Tooltip>
			</>
		);
	}

	function imageFormatter(cell) {
		return cell && cell !== "" ? (
			<img
				src={SERVER_URL + "/" + cell}
				alt="image"
				style={{ width: "50px", height: "50px" }}
				onClick={() => {
					setPreviewImage(cell);
					setOpenPreviewDialog(true);
				}}
			/>
		) : (
			<div />
		);
	}

	function longTextFormatter(cell) {
		return (
			<td className="truncate">
				<div
					dangerouslySetInnerHTML={{
						__html:
							cell.substring(0, 50) +
							(cell.length > 50 ? "..." : ""),
					}}
					onClick={() => {
						setDescription(cell);
						setOpenFullDescription(true);
					}}
				></div>
			</td>
		);
	}

	const columns = usersTable
		? [
				{ dataField: "fullName", text: "Full name", sort: true },
				{ dataField: "email", text: "Email", sort: true },
				{
					dataField: "role",
					text: "Role",
					align: "center",
					headerAlign: "center",
					sort: true,
					formatter: iconFormatter,
				},
				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ]
		: subscriptionsTable
		? [
				{ dataField: "title", text: "Title", sort: true },
				{
					dataField: "description",
					text: "Description",
					formatter: longTextFormatter,
					sort: true,
				},
				{ dataField: "monthlyCost", text: "Monthly cost", sort: true },
				{
					dataField: "annualDiscount",
					text: "Annual discount",
					sort: true,
				},
				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ]
		: routinesTable
		? [
				{
					dataField: "imageURL",
					text: "Main image",
					formatter: imageFormatter,
				},
				{ dataField: "title", text: "Title", sort: true },
				{
					dataField: "standard",
					text: "standard",
					align: "center",
					headerAlign: "center",
					formatter: booleanFormatter,
					sort: true,
				},
				{
					dataField: "muscleGroups",
					text: "Muscle groups",
					sort: true,
				},
				{
					dataField: "description",
					text: "Description",
					formatter: longTextFormatter,
					sort: true,
				},
				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ]
		: foodPlatesTable
		? [
				{ dataField: "imageURL", text: "", formatter: imageFormatter },
				{ dataField: "description", text: "Title", sort: true },
				{
					dataField: "dietTypes",
					text: "Diet Type",
					align: "center",
					headerAlign: "center",
					sort: true,
				},
				{
					dataField: "meals",
					text: "Meals",
					align: "center",
					headerAlign: "center",
					sort: true,
				},
				{
					dataField: "calories",
					text: "Calories",
					align: "center",
					headerAlign: "center",
					sort: true,
				},
				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ]
		: lessonsTable
		? [
				{ dataField: "imageURL", text: "", formatter: imageFormatter },
				{ dataField: "title", text: "Title", sort: true },
				{ dataField: "sport", text: "Sport", sort: true },
				{
					dataField: "equipments",
					text: "Equipment",
					align: "center",
					headerAlign: "center",
					sort: true,
				},
				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ]
		: testsTable
		? [
				{ dataField: "imageURL", text: "", formatter: imageFormatter },
				{ dataField: "title", text: "Title", sort: true },
				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ]
		: questionsTable
		? [
				{ dataField: "imageURL", text: "", formatter: imageFormatter },
				{ dataField: "statement", text: "Statement", sort: true },
				{ dataField: "sport", text: "Sport", sort: true },
				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ]
		: [
				{ dataField: "title", text: "Title", sort: true },
				{
					dataField: "standard",
					text: "standard",
					align: "center",
					headerAlign: "center",
					formatter: booleanFormatter,
					sort: true,
				},
				{
					dataField: routinePlan ? "level" : "totalCaloriesPlan",
					text: routinePlan ? "Level" : "Total calories",
					sort: true,
				},
				{
					dataField: "description",
					text: "Description",
					formatter: longTextFormatter,
					sort: true,
				},
				{ dataField: "_id", text: "", formatter: buttonFormatter },
		  ];

	return (
		<Dialog
			fullWidth={true}
			maxWidth="xl"
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
				{!data || !data.length ? (
					<p>
						{usersTable
							? "No users found."
							: `No ${
									subscriptionsTable
										? "subscriptions"
										: "plans"
							  } available, create one first.`}
					</p>
				) : (
					<Table data={data} columns={columns} />
				)}
				<PreviewDialog
					title={"Preview image"}
					open={openPreviewDialog}
					setOpen={setOpenPreviewDialog}
					src={previewImage ? `${SERVER_URL}/${previewImage}` : null}
				/>
				<PreviewDialog
					title="Description"
					text={description}
					open={openFullDescription}
					setOpen={setOpenFullDescription}
				/>
			</DialogContent>
			<DialogActions>
				<MuiThemeProvider theme={theme}>
					<Button
						onClick={() => {
							setOpen(false);
						}}
						variant="outlined"
						color="secondary"
					>
						Cancel
					</Button>
				</MuiThemeProvider>
			</DialogActions>
		</Dialog>
	);
};
export default TableDialog;
