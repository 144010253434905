import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

// Get all questions
export const getQuestions = () => {
	return authClient().get(`${API}/question?getDisabled=true`);
};

// Get question by id
export const getQuestionById = (id) => {
	return authClient().get(`${API}/question/${id}`);
};

// Delete question
export const deleteQuestion = (id) => {
	return authClient().delete(`${API}/question/${id}`);
};

// Create question
export const postQuestion = async (question, image, video, document) => {
	if (image) {
		const response = await uploadSingleFile(image, "questions");
		question.imageURL = response.data.fileURL;
	}
	if (video) {
		const response = await uploadSingleFile(video, "questions");
		question.videoURL = response.data.fileURL;
	}
	if (document) {
		const response = await uploadSingleFile(document, "questions");
		question.documentURL = response.data.fileURL;
	}

	for (let answer of question.answers) {
		if (answer?.selectedImage) {
			const response = await uploadSingleFile(
				answer?.selectedImage,
				"answers"
			);
			answer.imageURL = response.data.fileURL;
			delete answer.selectedImage;
		}
	}

	return authClient().post(`${API}/question`, question);
};

// Update question
export const updateQuestion = async (id, question, image, video, document) => {
	if (image) {
		const response = await uploadSingleFile(image, "questions");
		question.imageURL = response.data.fileURL;
	}
	if (video) {
		const response = await uploadSingleFile(video, "questions");
		question.videoURL = response.data.fileURL;
	}
	if (document) {
		const response = await uploadSingleFile(document, "questions");
		question.documentURL = response.data.fileURL;
	}

	for (let answer of question.answers) {
		if (answer?.selectedImage) {
			const response = await uploadSingleFile(
				answer?.selectedImage,
				"answers"
			);
			answer.imageURL = response.data.fileURL;
			delete answer.selectedImage;
		}
	}

	return authClient().put(`${API}/question/${id}`, question);
};

// Change status question
export const changeStatusQuestion = async (id, active) => {
	return authClient().put(`${API}/question/change-status/${id}`, { active });
};
