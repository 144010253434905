import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import { Delete, Edit, ToggleOn, ToggleOff } from '@material-ui/icons';

import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '_metronic/_partials/controls';
import { getScreens, deleteScreen, changeStatusScreen } from 'api/screen';
import Table, { buttonsStyle } from 'app/components/tables/table';
import ConfirmDialog from 'app/components/dialogs/ConfirmDialog';
import { alertError, alertSuccess } from 'utils/logger';

export function ScreensPage() {
	const [data, setData] = useState([]);
	const [screenId, setScreenId] = useState(null);
	const [selectedScreen, selectScreen] = useState(null);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-screen/' + cell)}>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title={elem?.active ? 'Disable' : 'Enable'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							selectScreen(elem);
							setOpenConfirmDialog(1);
						}}>
						{elem?.active ? (
							<ToggleOff />
						) : (
							<ToggleOn style={{ color: 'red' }} />
						)}
					</Button>
				</Tooltip>
				<Tooltip title='Delete'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setScreenId(cell);
							setOpenConfirmDialog(2);
						}}>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Name', sort: true },
		{ dataField: 'group', text: 'Group', sort: true },
		{ dataField: 'orderWithinGroup', text: 'Order within group', sort: true },
		{ dataField: '_id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getScreens()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data.map((s) => ({ ...s, id: s._id })));
					setRefresh(false);
				}
			})
			.catch((error) =>
				alertError({ error: error, customMessage: 'Could not get screens.' })
			);
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Screens list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-screen')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							selectedScreen?.active ? 'disable' : 'enable'
						} this screen?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							changeStatusScreen(
								selectedScreen.id,
								!selectedScreen?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedScreen?.active
													? 'Disabled!'
													: 'Enabled!'
											}`,
											customMessage: `Screen ${
												selectedScreen?.active
													? 'disabled'
													: 'enabled'
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage: `Could not ${
											selectedScreen?.active
												? 'disable'
												: 'enable'
										} screen.`,
									})
								)
						}
					/>
					<ConfirmDialog
						title={'Are you sure you want to remove this screen?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							deleteScreen(screenId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage:
												'Screen removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage: 'Could not remove screen.',
									})
								)
						}
					/>
				</CardBody>
			</Card>
		</>
	);
}
