import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getDietTypes } from "../../../../api/dietType";
import {
	deleteFood,
	getFoodById,
	postFood,
	updateFood,
} from "../../../../api/food";
import { getFoodFamilies } from "../../../../api/foodFamily";
import { checkIsEmpty, getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyFood() {
	return {
		fullName: {},
		foodFamily: null,
		dietTypes: [],
		calories: "0",
		proteins: "0",
		fiberCarbohydrates: "0",
		sugarCarbohydrates: "0",
		strachCarbohydrates: "0",
		saturatedFats: "0",
		unsaturatedFats: "0",
		usualAmountInRecipe: "100",
		generic: true,
		active: true,
	};
}

export default function EditFoodsPage() {
	const [foodFamilies, setFoodFamilies] = useState([]);
	const [dietTypes, setDietTypes] = useState([]);
	const [food, setFood] = useState(getEmptyFood());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const foodId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getDietTypes()
			.then((res) => {
				if (res.status === 200) {
					setDietTypes(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get diet types.",
				});
			});
		getFoodFamilies()
			.then((res) => {
				if (res.status === 200) {
					setFoodFamilies(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food families.",
				});
			});
		if (!foodId) {
			disableLoadingData();
			return;
		}
		getFoodById(foodId)
			.then((res) => {
				if (res.status === 200) {
					setFood(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food.",
				});
				history.push("/foods");
			});
	}, [foodId, disableLoadingData, history]);

	function saveFood() {
		if (checkIsEmpty(food.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!food?.foodFamily)
			return alertError({
				error: null,
				customMessage: "Food family is required.",
			});
		if (food.dietTypes.length === 0)
			return alertError({
				error: null,
				customMessage: "At least one diet type is required.",
			});
		if (!foodId) {
			postFood(food)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Food successfully created.",
						});
						history.push("/foods");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save food.",
					});
				});
		} else {
			updateFood(foodId, food)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/foods");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!food[element]) food[element] = {};
			let newText = food[element];
			newText[lang] = event.target.value;
			setFood({ ...food, [element]: newText });
		} else
			setFood({
				...food,
				[element]: event.target.value,
			});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={(food.fullName && food.fullName[lang]) || ""}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit food"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<Autocomplete
							filterSelectedOptions
							disablePortal
							disableClearable
							options={foodFamilies}
							getOptionLabel={(option) =>
								getNonEmpty(option.fullName)
							}
							value={
								foodFamilies?.find(
									(x) => x._id === food.foodFamily
								) || null
							}
							onChange={(event, selected) => {
								setFood({
									...food,
									foodFamily: selected?._id,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									required
									placeholder="Select food family"
									label={"Food family"}
								/>
							)}
						/>
						<Autocomplete
							multiple
							id="diet-types"
							filterSelectedOptions
							disablePortal
							disableCloseOnSelect
							options={dietTypes}
							getOptionLabel={(option) =>
								getNonEmpty(option.fullName)
							}
							value={dietTypes.filter((x) =>
								food.dietTypes.includes(x._id)
							)}
							onChange={(event, selected) => {
								setFood({
									...food,
									dietTypes: selected.map((x) => x._id),
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									required
									placeholder="Select diet type"
									label={"Diet type"}
								/>
							)}
						/>
						<br />
						<div className="row">
							<div className="col-md-3">
								<TextField
											id={`calories`}
											label="Calories per gram"
											value={food?.calories || ""}
											onChange={handleChange("calories")}
											InputLabelProps={{
												shrink: true,
											}}
											required
											margin="normal"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
							</div>
							<div className="col-md-3">
								<TextField
											id={`proteins`}
											label="Proteins per gram"
											value={food?.proteins || ""}
											onChange={handleChange("proteins")}
											InputLabelProps={{
												shrink: true,
											}}
											required
											margin="normal"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
							</div>
							<div className="col-md-3">
								<TextField
											id={`fiberCarbohydrates`}
											label="Fiber carbohydrates per gram"
											value={food?.fiberCarbohydrates || ""}
											onChange={handleChange("fiberCarbohydrates")}
											InputLabelProps={{
												shrink: true,
											}}
											required
											margin="normal"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
							</div>
							<div className="col-md-3">
								<TextField
											id={`sugarCarbohydrates`}
											label="Sugar carbohydrates per gram"
											value={food?.sugarCarbohydrates || ""}
											onChange={handleChange("sugarCarbohydrates")}
											InputLabelProps={{
												shrink: true,
											}}
											required
											margin="normal"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
							</div>
							<div className="col-md-3">
								<TextField
											id={`strachCarbohydrates`}
											label="Stratch carbohydrates per gram"
											value={food?.strachCarbohydrates || ""}
											onChange={handleChange("strachCarbohydrates")}
											InputLabelProps={{
												shrink: true,
											}}
											required
											margin="normal"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
							</div>
							<div className="col-md-3">
								<TextField
											id={`saturatedFats`}
											label="Saturated fats per gram"
											value={food?.saturatedFats || ""}
											onChange={handleChange("saturatedFats")}
											InputLabelProps={{
												shrink: true,
											}}
											required
											margin="normal"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
							</div>
							<div className="col-md-3">
								<TextField
											id={`unsaturatedFats`}
											label="Unsaturated fats per gram"
											value={food?.unsaturatedFats || ""}
											onChange={handleChange("unsaturatedFats")}
											InputLabelProps={{
												shrink: true,
											}}
											required
											margin="normal"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
							</div>
							<div className="col-md-3">
								<TextField
											id={`usualAmountInRecipe`}
											label="Usual amount in recipe"
											value={food?.usualAmountInRecipe || ""}
											onChange={handleChange("usualAmountInRecipe")}
											InputLabelProps={{
												shrink: true,
											}}
											required
											margin="normal"
											variant="outlined"
											type="number"
											InputProps={{
												inputProps: {
													min: 0,
												},
											}}
										/>
							</div>
						</div>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={food.generic}
									onChange={() =>
										setFood({
											...food,
											generic: !food.generic,
										})
									}
									name="checkGeneric"
								/>
							}
							label="Generic"
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={food.active}
									onChange={() =>
										setFood({
											...food,
											active: !food.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/foods")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveFood()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save food
					</Button>
					{foodId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete food
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this food?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteFood(foodId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Food deleted successfully",
												});
												history.push("/foods");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete food.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
