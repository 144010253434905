import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
	dateFormatter,
	buttonsStyle,
	badgeFormatter,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
	getRoutines,
	deleteRoutine,
	changeStatusRoutine,
} from "../../../../api/routine";
import {
	Button,
	Tooltip,
	FormControlLabel,
	Switch,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { getMuscleGroups } from "../../../../api/muscleGroup";
import { getUsers } from "../../../../api/user";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { shallowEqual, useSelector } from "react-redux";
import { getNonEmpty } from "../../../../utils/helpers";
import LanguageIcon from "@material-ui/icons/Language";
import { SERVER_URL } from "../../../../api";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import FiltersCard from "../../../components/filters/Filter";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

function getData(routines, users) {
	let data = [];
	for (let i = 0; i < routines.length; ++i) {
		const elem = {};
		elem.img = routines[i].imageURL;
		elem.title = routines[i].title;
		elem.description = routines[i].description;
		elem.muscleGroup = getNonEmpty(routines[i].muscleGroup?.fullName);
		elem.createdBy = !routines[i].trainer
			? null
			: users.find((item) => item._id === routines[i].trainer)
					?.fullName || "----";
		elem.createdAt = routines[i].createdAt;
		elem.id = routines[i]._id;
		elem.active = routines[i].active;
		elem.trainer = routines[i].trainer;
		data = data.concat(elem);
	}
	return data;
}

const initialFilters = {
	active: false,
	createdBy: [],
	mainMuscle: [],
	standard: "All",
};

export default function RoutinesPage() {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [muscleGroups, setMuscleGroups] = useState([]);
	const [users, setUsers] = useState([]);
	const [routineId, setRoutineId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);
	const [filterOptions, setFilterOptions] = useState(initialFilters);
	const [collapsed, setCollapsed] = useState(true);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function imageFormatter(cell) {
		return cell && cell !== "" ? (
			<img
				src={SERVER_URL + "/" + cell}
				alt="routine"
				style={{ width: "50px", height: "50px" }}
				onClick={() => {
					setPreviewImage(cell);
					setOpenPreviewDialog(true);
				}}
			/>
		) : (
			<div />
		);
	}

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		const canEdit =
			user?.role.includes("admin") || elem.trainer === user?._id;
		return (
			<>
				<Tooltip title={canEdit ? "Edit" : "View"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>
							history.push("/training/edit-routine/" + cell)
						}
					>
						{canEdit ? <EditIcon /> : <VisibilityIcon />}
					</Button>
				</Tooltip>
				{canEdit && (
					<>
						<Tooltip title="View translations">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() =>
									history.push(
										"/training/routine-translations/" + cell
									)
								}
							>
								<LanguageIcon />
							</Button>
						</Tooltip>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setRoutineId(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
					</>
				)}
				{user?.role.includes("admin") && (
					<Tooltip title="Delete">
						<Button
							style={buttonsStyle}
							size="small"
							onClick={() => {
								setRoutineId(cell);
								setOpenConfirmDialog(2);
							}}
						>
							<DeleteIcon />
						</Button>
					</Tooltip>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "img",
			text: "Main image",
			sort: false,
			formatter: imageFormatter,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "muscleGroup",
			text: "Main muscle group",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "createdBy",
			text: "Created by",
			sort: true,
			formatter: badgeFormatter,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getUsers()
			.then((response) => {
				if (response.status === 200) {
					setUsers(
						response.data.filter((item) =>
							item.role.includes("trainer")
						)
					);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get users.",
				});
			});
		getMuscleGroups()
			.then((resp) => {
				if (resp.status === 200) {
					setMuscleGroups(resp.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get muscle groups.",
				});
			});
		getRoutines()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setFilteredData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get routines.",
				});
			});
	}, [refresh]);

	const handleSearch = async () => {
		if (!data.length) return;
		setFilteredData(
			data.filter((item) => {
				let filter = true;
				if (filterOptions.createdBy && filterOptions.createdBy.length)
					filter =
						filter &&
						filterOptions.createdBy.includes(item.createdBy);
				if (filterOptions.mainMuscle && filterOptions.mainMuscle.length)
					filter =
						filter &&
						filterOptions.mainMuscle.includes(
							item.muscleGroup?._id
						);
				if (
					filterOptions.standard &&
					filterOptions.standard !== "All"
				) {
					let condition = true;
					if (filterOptions.standard === "Custom")
						condition = item.trainer !== null;
					else if (filterOptions.standard === "Standard")
						condition = !item.trainer;
					filter = filter && condition;
				}
				if (filterOptions.active) filter = filter && item.active;
				if (filter) return item;
				return false;
			})
		);
	};

	const handleClearFilters = () => {
		setFilterOptions(initialFilters);
		setRefresh(true);
	};

	const handleChange = (element) => (event) => {
		setFilterOptions({ ...filterOptions, [element]: event.target.value });
	};

	const renderFiltersContent = () => {
		return (
			<>
				<FormControl style={{ width: "100%" }}>
					<InputLabel id="demo-simple-select-standard-label">
						Main muscle group
					</InputLabel>
					<Select
						labelId="demo-simple-select-standard-label"
						id="demo-simple-select-standard"
						value={filterOptions.mainMuscle || []}
						multiple
						onChange={handleChange("mainMuscle")}
						MenuProps={MenuProps}
					>
						{muscleGroups?.map((option) => (
							<MenuItem key={option._id} value={option._id}>
								{getNonEmpty(option.fullName)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<br />
				<FormControl style={{ width: "100%" }}>
					<InputLabel id="demo-simple-select-standard-label">
						Standard
					</InputLabel>
					<Select
						labelId="demo-simple-select-standard-label"
						id="demo-simple-select-standard"
						value={filterOptions.standard || ""}
						onChange={handleChange("standard")}
						MenuProps={MenuProps}
					>
						{["All", "Standard", "Custom"]?.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<br />
				<FormControl style={{ width: "100%" }}>
					<InputLabel id="demo-simple-select-standard-label">
						Created by
					</InputLabel>
					<Select
						labelId="demo-simple-select-standard-label"
						id="demo-simple-select-standard"
						value={filterOptions.createdBy || []}
						multiple
						onChange={handleChange("createdBy")}
						MenuProps={MenuProps}
					>
						{users?.map((option) => (
							<MenuItem key={option._id} value={option._id}>
								{option.fullName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<br />
				<FormControlLabel
					control={
						<Switch
							checked={filterOptions.active}
							onChange={() =>
								setFilterOptions({
									...filterOptions,
									active: !filterOptions.active,
								})
							}
							name="checkActive"
						/>
					}
					label={
						filterOptions.active
							? "Only active"
							: "All active/inactive"
					}
				/>
			</>
		);
	};

	return (
		<>
			<Card>
				<CardHeader title="Routines list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() =>
								history.push("/training/edit-routine")
							}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>

					<Table
						data={getData(filteredData, users)}
						columns={columns}
					/>

					<ConfirmDialog
						title={`Are you sure you want to ${
							routineId?.active ? "disable" : "enable"
						} this routine?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusRoutine(
								routineId._id,
								!routineId?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												routineId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Routine ${
												routineId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											routineId?.active
												? "disable"
												: "enable"
										} routine.`,
									});
								});
						}}
					/>
					<PreviewDialog
						title={"Preview image"}
						open={openPreviewDialog}
						setOpen={setOpenPreviewDialog}
						src={
							previewImage
								? `${SERVER_URL}/${previewImage}`
								: null
						}
					/>
					<ConfirmDialog
						title={"Are you sure you want to remove this routine?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteRoutine(routineId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Routine removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not remove routine.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
