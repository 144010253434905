import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import { alertError } from '../../../../utils/logger'
import { getUserStatistics } from '../../../../api/user'
import BarChartContainer from '../../../components/charts/BarChartContainer'

export default function TrainersReportsPage() {
  const [data, setData] = useState([])

  useEffect(() => {
    getUserStatistics('trainer').then(res => {
      if (res.status === 200) {
        setData(res.data)
      }
    }).catch((error) => {
      alertError({ error: error, customMessage: 'Could not get statistics.' });
    });
  }, [])

  return (
    <>
      <Card>
        <CardHeader title='Trainers report'></CardHeader>
        <CardBody>
          <BarChartContainer
            data={data?.users?.filter(item => item._id !== null).map(item => ({ key: item._id?.fullName, value: item.count, id: item._id?._id }))}
            title={'Active users'}
            units="users"
          />
        </CardBody>
        <CardBody>
          <BarChartContainer
            data={data?.usersHistory?.filter(item => item._id !== null).map(item => ({ key: item._id?.fullName, value: item.count, id: item._id?._id }))}
            title={'Users historically'}
            units="users"
          />
        </CardBody>
        <CardBody>
          <BarChartContainer
            data={data?.routines?.filter(item => item._id !== null).map(item => ({ key: item._id?.fullName, value: item.count, id: item._id?._id }))}
            title={'Routines created'}
            units="routines"
          />
        </CardBody>
        <CardBody>
          <BarChartContainer
            data={data?.plans?.filter(item => item._id !== null).map(item => ({ key: item._id?.fullName, value: item.count, id: item._id?._id }))}
            title={'Routine plans created'}
            units="routine plans"
          />
        </CardBody>
      </Card>
    </>
  );
}
