import {
	Button,
	Checkbox,
	createMuiTheme,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
} from "@material-ui/core";
import {
	ArrowDownward,
	ArrowUpward,
	Edit,
	Visibility,
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api/index";
import { getRoutines } from "../../../../api/routine";
import {
	deleteStandardChallenge,
	getStandardChallengeById,
	postStandardChallenge,
	updateStandardChallenge,
} from "../../../../api/standardChallenge";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import TableDialog from "../../../components/dialogs/TableDialog";
import Editor from "../../../components/editor/Editor";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import EditRoutineDialog from "../routines/EditRoutineDialog";
import ViewRoutineDialog from "../routines/ViewRoutineDialog";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyStandardChallenge() {
	return {
		title: "",
		description: "",
		imageURL: "",
		level: "",
		duration: 0,
		calories: 0,
		exercises: [],
		active: true,
	};
}

const LEVELS = ["beginner", "intermediate", "advanced"];

export default function EditStandardChallengesPage() {
	const [standardChallenge, setStandardChallenge] = useState(
		getEmptyStandardChallenge()
	);

	const [routines, setRoutines] = useState(null);
	const [openTableDialog, setOpenTableDialog] = useState(false);

	const [openEditRoutineDialog, setOpenEditRoutineDialog] = useState(null);
	const [openViewRoutineDialog, setOpenViewRoutineDialog] = useState(null);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);

	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

	const standardChallengeId = useParams().id;

	const history = useHistory();
	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getRoutines()
			.then((res) => {
				if (res.status === 200) {
					const routines = res.data.map((item) => ({
						...item,
						standard: !item.trainer,
						muscleGroups: [getNonEmpty(item.muscleGroup?.fullName)]
							.concat(
								item.otherMuscleGroups.map((mg) =>
									getNonEmpty(mg?.fullName)
								)
							)
							.join(", "),
					}));
					setRoutines(routines);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get exercises.",
				});
				history.push("/training/standard-challenges");
			});
		if (!standardChallengeId) {
			disableLoadingData();
			return;
		}
		getStandardChallengeById(standardChallengeId)
			.then((res) => {
				if (res.status === 200) {
					setStandardChallenge(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get challenge.",
				});
				history.push("/training/standard-challenges");
			});
	}, [standardChallengeId, disableLoadingData, history, loggedUser]);

	function saveChallenge() {
		if (!standardChallenge.exercises?.length)
			return alertError({
				error: null,
				customMessage:
					"At least one exercise is required, please check that it is all filled out.",
			});

		if (!standardChallengeId) {
			postStandardChallenge(standardChallenge, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Challenge successfully created.",
						});
						history.push("/training/standard-challenges");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save challenge.",
					});
				});
		} else {
			updateStandardChallenge(
				standardChallengeId,
				standardChallenge,
				selectedImage
			)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/training/standard-challenges");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!standardChallenge[element]) standardChallenge[element] = {};
			let newText = standardChallenge[element];
			newText[lang] = event.target.value;
			setStandardChallenge({ ...standardChallenge, [element]: newText });
		} else
			setStandardChallenge({
				...standardChallenge,
				[element]: event.target.value,
			});
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!standardChallenge[element])
					standardChallenge[element] = {};
				let newText = standardChallenge[element];
				newText[lang] = value;
				setStandardChallenge({
					...standardChallenge,
					[element]: newText,
				});
			} else
				setStandardChallenge({
					...standardChallenge,
					[element]: value,
				});
		}
	};

	const handleMoveExercise = (index, newIndex) => {
		const aux = standardChallenge.exercises[index];
		if (!aux)
			return alertError({
				error: null,
				customMessage:
					"An unexpected error has ocurred trying to move the exercises, please try again.",
			});
		let _exercises = standardChallenge.exercises;
		_exercises.splice(index, 1, _exercises[newIndex]);
		_exercises.splice(newIndex, 1, aux);
		setStandardChallenge({ ...standardChallenge, exercises: _exercises });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Title"
					value={
						(standardChallenge?.title &&
							standardChallenge?.title[lang]) ||
						""
					}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<br />
				<div>Description *</div>
				<Editor
					body={
						(standardChallenge.description &&
							standardChallenge?.description[lang]) ||
						""
					}
					setBody={(new_body) => {
						handleChangeEditor("description", new_body, lang);
					}}
					className="max-height"
					placeholder={"Enter challenge description here..."}
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit challenge"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<div className="row d-flex align-items-center">
							<div className="col-md-4">
								<Autocomplete
									id="level"
									disablePortal
									disableClearable
									options={LEVELS}
									getOptionLabel={(option) => option}
									value={LEVELS?.find(
										(x) => x === standardChallenge?.level
									)}
									onChange={(event, selected) => {
										setStandardChallenge({
											...standardChallenge,
											level: selected,
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											margin="normal"
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
											placeholder="Select a level"
											label="Level"
											required
										/>
									)}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									id={`duration`}
									label={`Duration (min)`}
									value={standardChallenge.duration || ""}
									onChange={handleChange("duration")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									id={`calories`}
									label={`Calories`}
									value={standardChallenge.calories || ""}
									onChange={handleChange("calories")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
						</div>
						<br />
						<br />
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedImage ||
								(standardChallenge.imageURL &&
									standardChallenge.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(standardChallenge.imageURL &&
								standardChallenge.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview image"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={buttonsStyle}
									>
										<VisibilityIcon />
									</Button>
								</Tooltip>
								<Tooltip title="Delete">
									<Button
										size="small"
										style={buttonsStyle}
										onClick={() => {
											setSelectedImage(null);
											setStandardChallenge({
												...standardChallenge,
												imageURL: "",
											});
										}}
									>
										<DeleteIcon />
									</Button>
								</Tooltip>
								<span>
									{selectedImage
										? selectedImage?.name
										: standardChallenge.imageURL &&
										  standardChallenge.imageURL !== ""
										? standardChallenge.imageURL.split(
												/-(.*)/s
										  )[1]
										: ""}
								</span>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${standardChallenge.imageURL}`
									}
								/>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={standardChallenge.active}
									onChange={() =>
										setStandardChallenge({
											...standardChallenge,
											active: !standardChallenge.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
					<CardHeader title="Exercises">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => setOpenTableDialog(true)}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{standardChallenge.exercises?.map((item, index) => {
							const exercise = routines?.find(
								(element) => element._id === item.exercise
							);
							return (
								<>
									<span>
										{exercise?.title}
										{(item.series ||
											item.timeSeries ||
											item.nTimesSeries) && (
											<strong>{` → ${
												item.series
													? "Series repetitions"
													: item?.nTimesSeries
													? "Number of times"
													: "Minutes"
											}: `}</strong>
										)}
										{item.series
											? item.series.join(", ")
											: item.nTimesSeries
											? item.nTimesSeries?.join(", ")
											: item.timeSeries
											? item.timeSeries?.join(", ")
											: ""}
									</span>
									{loggedUser.role.includes("admin") && (
										<Tooltip title={"View"}>
											<Button
												size="small"
												onClick={() =>
													setOpenViewRoutineDialog(
														index
													)
												}
												style={buttonsStyle}
											>
												<Visibility />
											</Button>
										</Tooltip>
									)}
									{loggedUser.role.includes("admin") && (
										<>
											<Tooltip
												title={`Edit ${
													exercise.model === "series"
														? "series repetitions"
														: exercise.model ===
														  "nTimes"
														? "number of times"
														: "minutes"
												}`}
											>
												<Button
													size="small"
													style={buttonsStyle}
													onClick={() => {
														setOpenEditRoutineDialog(
															index
														);
													}}
												>
													<Edit />
												</Button>
											</Tooltip>
											<Tooltip title="Delete">
												<Button
													size="small"
													style={buttonsStyle}
													onClick={() => {
														let _exercises = [
															...standardChallenge.exercises,
														];
														_exercises.splice(
															index,
															1
														);
														setStandardChallenge({
															...standardChallenge,
															exercises: _exercises,
														});
													}}
												>
													<DeleteIcon />
												</Button>
											</Tooltip>
											<Tooltip title="Move up">
												<Button
													size="small"
													style={buttonsStyle}
													disabled={index === 0}
													onClick={() =>
														handleMoveExercise(
															index,
															index - 1
														)
													}
												>
													<ArrowUpward />
												</Button>
											</Tooltip>
											<Tooltip title="Move down">
												<Button
													size="small"
													disabled={
														index >=
														standardChallenge
															.exercises?.length -
															1
													}
													style={buttonsStyle}
													onClick={() =>
														handleMoveExercise(
															index,
															index + 1
														)
													}
												>
													<ArrowDownward />
												</Button>
											</Tooltip>
										</>
									)}
									<br />
									<EditRoutineDialog
										title={exercise?.title}
										open={openEditRoutineDialog === index}
										setOpen={setOpenEditRoutineDialog}
										routine={exercise}
										initialData={{
											series: item.series,
											timeSeries: item.timeSeries,
											nTimesSeries: item.nTimesSeries,
										}}
										onConfirm={(data) => {
											setOpenEditRoutineDialog(null);
											let _exercises = [
												...standardChallenge.exercises,
											];
											_exercises[index] = {
												..._exercises[index],
												series: data.series,
												timeSeries: data.timeSeries,
												nTimesSeries: data.nTimesSeries,
											};
											setStandardChallenge({
												...standardChallenge,
												exercises: _exercises,
											});
										}}
									/>
									<ViewRoutineDialog
										open={openViewRoutineDialog === index}
										setOpen={setOpenViewRoutineDialog}
										routine={exercise}
									/>
								</>
							);
						})}
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() =>
							history.push("/training/standard-challenges")
						}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => {
							saveChallenge();
						}}
						variant="outlined"
						color="primary"
						style={
							standardChallengeId && {
								marginRight: "20px",
							}
						}
					>
						Save challenge
					</Button>
					{standardChallengeId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(2)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete challenge
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>
							<ConfirmDialog
								title={
									"Are you sure you want to delete this challenge?"
								}
								open={openConfirmDialog === 2}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteStandardChallenge(standardChallengeId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Challenge deleted successfully",
												});
												history.push(
													"/training/standard-challenges"
												);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete challenge.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
				<TableDialog
					open={openTableDialog}
					setOpen={setOpenTableDialog}
					routinesTable={true}
					data={
						loggedUser.role.includes("trainer")
							? routines?.filter(
									(item) =>
										item.trainer === loggedUser._id ||
										item.trainer === null
							  )
							: routines
					}
					onSelectRow={(id) => {
						let _exercises = [...standardChallenge.exercises];
						if (_exercises)
							_exercises.push({
								exercise: id,
								series: null,
								timeSeries: null,
								nTimesSeries: null,
							});
						else
							_exercises = [
								{
									exercise: id,
									series: null,
									timeSeries: null,
									nTimesSeries: null,
								},
							];

						setStandardChallenge({
							...standardChallenge,
							exercises: _exercises,
						});
						setOpenTableDialog(null);
					}}
				/>
			</>
		);
}
