import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const postFoodFamily = async (foodFamily, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "foodFamilies");
		foodFamily.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/food-family`, foodFamily);
};

export const getFoodFamilies = () => {
	return authClient().get(`${API}/food-family?getDisabled=true`);
};

export const getFoodFamilyById = (id) => {
	return authClient().get(`${API}/food-family/${id}`);
};

export const updateFoodFamily = async (id, foodFamily, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "foodFamilies");
		foodFamily.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/food-family/${id}`, foodFamily);
};

export const changeStatusFoodFamily = async (id, active) => {
	return authClient().put(`${API}/food-family/change-status/${id}`, {
		active,
	});
};

export const deleteFoodFamily = (id) => {
	return authClient().delete(`${API}/food-family/${id}`);
};
