import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox,
	Tooltip
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteEquipment,
	getEquipmentById,
	postEquipment,
	updateEquipment,
} from '../../../../api/equipment';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import { checkIsEmpty } from '../../../../utils/helpers';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import { SERVER_URL } from '../../../../api/index';
import { Visibility } from '@material-ui/icons';
import { buttonsStyle } from '../../../components/tables/table';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyEquipment() {
	return {
		fullName: {},
		active: true,
		iconURL: ''
	};
}

export default function EditEquipmentsPage() {
	const [equipment, setEquipment] = useState(getEmptyEquipment());
	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const equipmentId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!equipmentId) {
			disableLoadingData();
			return;
		}
		getEquipmentById(equipmentId)
			.then((res) => {
				if (res.status === 200) {
					setEquipment(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get equipment.' });
				history.push('/equipments');
			});
	}, [equipmentId, disableLoadingData, history]);

	function saveEquipment() {
		if (checkIsEmpty(equipment.fullName)) return alertError({ error: null, customMessage: 'The name is required in at least one of the languages.' })
		if (!equipmentId) {
			postEquipment(equipment, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Equipment successfully created.',
						});
						history.push('/equipments');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save equipment.' });
				});
		} else {
			updateEquipment(equipmentId, equipment, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/equipments');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return
		if (lang) {
			if (!equipment[element]) equipment[element] = {}
			let newText = equipment[element]
			newText[lang] = event.target.value
			setEquipment({ ...equipment, [element]: newText })
		} else setEquipment({ ...equipment, [element]: event.target.value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return <>
			<br/>
			<TextField
				id={`fullName`}
				label='Full name'
				value={(equipment.fullName && equipment.fullName[lang]) || ''}
				onChange={handleChange('fullName', lang)}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				variant='outlined'
				required
			/>
		</>
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit equipment'></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent = {renderMultilanguageTabContent}
						/>
						<br />
						<br />
						<label htmlFor={'upload-image'}>
							<input
								style={{ display: 'none' }}
								id={'upload-image'}
								name={'upload-image'}
								type='file'
								accept={'image/*'}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedImage || (equipment.iconURL && equipment.iconURL !== '')
									? 'Change icon'
									: 'Upload icon'}
							</Button>
						</label>
						{(selectedImage || (equipment.iconURL && equipment.iconURL !== '')) && (
							<>
								<Tooltip title={'Preview icon'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewDialog(true)}
										style={{ ...buttonsStyle, marginRight: '15px' }}>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview icon'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${equipment.iconURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: (equipment.iconURL && equipment.iconURL !== '')
										? equipment.iconURL?.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br/>
						<br/>
						<FormControlLabel
							control={<Checkbox checked={equipment.active} onChange={() => setEquipment({ ...equipment, active: !equipment.active })} name="checkActive" />}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/equipments')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveEquipment()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save equipment
					</Button>
					{equipmentId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete equipment
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this equipment?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteEquipment(equipmentId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Equipment deleted successfully',
												});
												history.push('/equipments');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete equipment.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
