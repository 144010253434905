import { Tooltip } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getChatsByUserId, postChat } from "../../../../api/chat";
import { getMessagesByChatId } from "../../../../api/message";
import { getUsers } from "../../../../api/user";
import {
	updateChats,
	updateCurrentChat,
} from "../../../../redux/chatRedux/chatActions";
import ChatBox from "../../../components/chat/ChatBox";
import Conversation from "../../../components/chat/Conversation";
import TableDialog from "../../../components/dialogs/TableDialog";
import SearchBar from "../../../components/searchBar";
import socket from "../../../components/socket/socket";
import "./Chat.css";

const Chat = () => {
	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);
	const chats = useSelector((store) => store.chat?.chats, shallowEqual);
	const onlineUsers = useSelector(
		(store) => store.chat?.onlineUsers,
		shallowEqual
	);
	const [sendMessage, setSendMessage] = useState(null);
	const [openNewChatDialog, setOpenNewChatDialog] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [users, setUsers] = useState([]);
	const [displayedChats, setDisplayedChats] = useState([]);
	const [onlineChats, setOnlineChats] = useState([]);
	const [lastUnreadMessage, setLastUnreadMessage] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		getUsers().then((res) => {
			if (res.status === 200) {
				const data = res.data.filter((item) => item.active);
				const professionals = data.filter(
					(item) =>
						item._id !== loggedUser._id &&
						(item.role.includes("trainer") ||
							item.role.includes("nutritionist"))
				);
				const appUsers = data.filter(
					(item) =>
						item._id !== loggedUser._id &&
						item.role.includes("user")
				);
				const admins = data.filter(
					(item) =>
						item._id !== loggedUser._id &&
						item.role.includes("admin")
				);
				const bots = data
					.filter((item) => item._id !== loggedUser._id && item.isAI)
					.map((item) => ({
						...item,
						role: item.role.concat("bot"),
					}));

				setUsers(
					loggedUser.role.includes("admin")
						? admins
								.concat(bots)
								.concat(professionals)
								.concat(appUsers)
						: admins.concat(appUsers)
				);
			}
		});
	}, [loggedUser]);

	// Send Message to socket server
	useEffect(() => {
		if (sendMessage !== null) {
			for (let i = 0; i < sendMessage?.receiversIds?.length; ++i) {
				let receiverId = sendMessage?.receiversIds[i];

				let sendData = { ...sendMessage, receiverId: receiverId };
				socket.emit("send-message", sendData);
			}
		}
	}, [sendMessage]);

	const handleChangeCurrentChat = async (chat) => {
		if (chat === null) {
			dispatch(updateCurrentChat(null, []));
			return;
		}
		try {
			const { data } = await getMessagesByChatId(chat._id);
			dispatch(updateCurrentChat(chat, data));
			setLastUnreadMessage(null);
		} catch (error) {
			console.log(error);
		}
	};

	const handleUpdateChats = async (chat) => {
		try {
			const { data } = await getChatsByUserId(loggedUser._id);
			dispatch(updateChats(data));
			handleChangeCurrentChat(chat);
		} catch (error) {
			console.log(error);
		}
	};

	const handleAddNewChat = async (userSelected) => {
		const chat = {
			senderId: loggedUser._id,
			receiverId: userSelected._id,
		};
		try {
			const { data } = await postChat(chat);
			handleUpdateChats(data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		let _chats = [...chats];
		if (searchText && searchText !== "") {
			const matchingUsers = users
				.filter((item) =>
					item?.fullName
						?.toLowerCase()
						?.includes(searchText?.toLowerCase())
				)
				?.map((item) => item._id);

			_chats = _chats.filter(
				(item) =>
					item.members.some((u) => matchingUsers.includes(u)) ||
					item?.team?.title
						?.toLowerCase()
						?.includes(searchText?.toLowerCase())
			);
		}
		setDisplayedChats(_chats);
	}, [chats, searchText, users]);

	useEffect(() => {
		if (!chats || !onlineUsers) return;
		let _online = [];
		for (const chat of chats) {
			if (
				onlineUsers.findIndex(
					(item) =>
						chat.members.includes(item.userId) &&
						item.userId !== loggedUser._id
				) >= 0
			)
				_online.push(chat._id);
		}
		setOnlineChats(_online);
	}, [chats, onlineUsers, loggedUser]);

	return (
		<div className="Chat">
			{/* Left Side */}
			<div className="Left-side-chat">
				<Card className="cards-chat-left">
					<CardHeader
						title="Chats"
						className="pointer"
						onClick={() => handleChangeCurrentChat(null)}
					>
						<CardHeaderToolbar>
							<Tooltip title="Add new chat">
								<PersonAddIcon
									className="pointer"
									onClick={() => setOpenNewChatDialog(true)}
								/>
							</Tooltip>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						<div>
							<SearchBar
								searchText={searchText}
								setSearchText={setSearchText}
							/>
							<div className="chat-list">
								{displayedChats?.map((chat) => (
									<div
										onClick={() => {
											handleChangeCurrentChat(chat);
										}}
									>
										<Conversation
											chat={chat}
											online={onlineChats?.includes(
												chat._id
											)}
											currentUserId={loggedUser?._id}
										/>
									</div>
								))}
							</div>
						</div>
					</CardBody>
				</Card>
			</div>

			{/* Right Side */}

			<div className="Right-side-chat">
				<ChatBox
					setSendMessage={setSendMessage}
					lastUnreadMessage={lastUnreadMessage}
					setLastUnreadMessage={setLastUnreadMessage}
				/>
			</div>

			<TableDialog
				open={openNewChatDialog}
				setOpen={setOpenNewChatDialog}
				data={users?.filter(
					(item) =>
						!chats
							?.map((item) => item.members)
							?.flat(1)
							.includes(item._id)
				)}
				usersTable={true}
				onSelectRow={(row) => {
					handleAddNewChat(row);
					setOpenNewChatDialog(false);
				}}
			/>
		</div>
	);
};

export default Chat;
