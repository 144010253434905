import { API, authClient } from '../index';
import { uploadSingleFile } from "../files"

// Get all routine plans
export const getRoutinePlans = () => {
	return authClient().get(`${API}/routine-plan?getDisabled=true`);
};

// Get routine plan by id
export const getRoutinePlanById = (id) => {
	return authClient().get(`${API}/routine-plan/${id}`);
};

// Delete routine plan
export const deleteRoutinePlan = (id) => {
	return authClient().delete(`${API}/routine-plan/${id}`);
};

// Create routine plan 
export const postRoutinePlan = async (routinePlan, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'routinePlans')
		routinePlan.bannerURL = response.data.fileURL
	}
	return authClient().post(`${API}/routine-plan`, routinePlan);
};

// Update routine plan
export const updateRoutinePlan = async (id, routinePlan, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'routinePlans')
		routinePlan.bannerURL = response.data.fileURL
	}
	return authClient().put(`${API}/routine-plan/${id}`, routinePlan);
};

// Change status routine plan
export const changeStatusRoutinePlan= async (id, active) => {
	return authClient().put(`${API}/routine-plan/change-status/${id}`, { active });
};

// Get training statistics
export const getTrainingStatistics = () => {
	return authClient().get(`${API}/routine-plan/statistics`);
};