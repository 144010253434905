import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../../../customStyles/bootstrap_table.css";

const { SearchBar, ClearSearchButton } = Search;

export const buttonsStyle = {
	maxWidth: "30px",
	maxHeight: "30px",
	minWidth: "30px",
	minHeight: "30px",
	marginLeft: "1em",
};

function extractContent(s) {
	if (!s) return "----";
	var span = document.createElement("span");
	span.innerHTML = s;
	return span.textContent || span.innerText;
}

export function substringFormatter(cell) {
	const text = extractContent(cell);
	return text.length > 80 ? text.substring(0, 80) + "..." : text;
}

export function booleanFormatter(cell) {
	return cell ? (
		<CheckBox style={{ color: "#06D6A1" }} />
	) : (
		<CheckBoxOutlineBlank style={{ color: "#06D6A1" }} />
	);
}

export function badgeFormatter(cell) {
	return !cell ? (
		<span className="badge badge-primary">Standard</span>
	) : (
		<span>{cell}</span>
	);
}

const buildDate = (date, withoutTime) => {
	if (Object.prototype.toString.call(date) !== "[object Date]") return;
	let displayDate = `${date.getDate()}/${date.getMonth() +
		1}/${date.getFullYear()}`;
	if (withoutTime !== true)
		displayDate += `  ${date.getHours()}:${String(
			date.getMinutes()
		).padStart(2, "0")}`;
	return displayDate;
};

export function dateFormatter(cell, withoutTime) {
	if (cell === undefined) return "-";
	let date = new Date(cell);
	date = buildDate(date, withoutTime);

	return date;
}

export function toIsoString(date) {
	if (!date) return date;
	const newDate = new Date(date);
	const tzo = -newDate.getTimezoneOffset();

	const pad = (num) => (num < 10 ? "0" : "") + num;

	return (
		newDate.getFullYear() +
		"-" +
		pad(newDate.getMonth() + 1) +
		"-" +
		pad(newDate.getDate()) +
		"T" +
		pad(newDate.getHours() + (tzo % 60)) +
		":" +
		pad(newDate.getMinutes())
	);
}

const customTotal = (from, to, size) => (
	<span
		className="react-bootstrap-table-pagination-total"
		style={{ paddingLeft: "5px" }}
	>
		Showing {from}-{to} of {size} results
	</span>
);

const pagination = paginationFactory({
	page: 1,
	sizePerPage: 10,
	lastPageText: ">>",
	firstPageText: "<<",
	nextPageText: ">",
	prePageText: "<",
	showTotal: true,
	paginationTotalRenderer: customTotal,
	alwaysShowAllBtns: false,
	onPageChange: function(page, sizePerPage) {},
	onSizePerPageChange: function(page, sizePerPage) {},
});

const rowClasses = (row) => (row.active === false ? "inactive-row" : "");

export default function Table({
	data,
	columns,
	rowEvents,
	hideSearchBar,
	...tableProps
}) {
	if (data.length != 0) {
		if (!data[0].trainer)
			columns = columns.filter((c) => c.dataField !== "trainer");
	}
	return (
		<ToolkitProvider
			bootstrap4
			keyField="id"
			data={data}
			columns={columns}
			search
		>
			{(props) => (
				<div>
					{!hideSearchBar && (
						<>
							<SearchBar
								placeholder="Search"
								{...props.searchProps}
							/>
							<ClearSearchButton
								className="ml-3"
								{...props.searchProps}
							/>
							<br />
							<br />
						</>
					)}

					<BootstrapTable
						wrapperClasses="table-responsive"
						classes="table table-head-custom table-vertical-center overflow-hidden"
						bordered={false}
						// remote
						pagination={pagination}
						striped
						rowClasses={rowClasses}
						rowEvents={rowEvents}
						{...tableProps}
						{...props.baseProps}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
}
