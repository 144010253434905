import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

const SelectDialog = (props) => {
	const { title, item, options, open, onClose, onConfirm } = props
	const [selectedOption, setSelectedOption] = useState(null)
	const [error, setError] = useState(null)

	const handleChange = () => (event) => {
		setError(null)
		setSelectedOption(event.target.value);
	};

	return (
		<Dialog
			fullWidth={true}
      		maxWidth="sm"
			open={open}
			onClose={() => onClose()}
			aria-labelledby="select-dialog">
			<DialogTitle id="select-dialog">{title}</DialogTitle>
			<DialogContent>
				<FormControl style={{ width: '100%' }}>
					<InputLabel id="demo-simple-select-standard-label">{item}</InputLabel>
					<Select
						labelId="demo-simple-select-standard-label"
						id="demo-simple-select-standard"
						value={selectedOption || ''}
						onChange={handleChange()}
						MenuProps={MenuProps}
					>
					{options?.map((option) => (
						<MenuItem key={option} value={option}>{option}</MenuItem>
					))}
					</Select>
					<FormHelperText>{'Select ' + item}</FormHelperText>
				</FormControl>
				<br/>
        		<br/>
        		{error && <h5 style={{ color: 'red' }}>{error}</h5>}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (selectedOption) onConfirm(selectedOption)
						else setError('Please select an option!')
					}}
				>
					Ok
				</Button>
				<Button
					variant="outlined"
					color="secondary"
					onClick={() => onClose()}
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default SelectDialog
