import {
	Button,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { getCourseById } from "../../../../api/course";
import {
	deleteThread,
	getThreadById,
	postThread,
	updateThread,
} from "../../../../api/thread";
import { checkIsEmpty, getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import Editor from "../../../components/editor/Editor";
import { useSkeleton } from "../../../hooks/useSkeleton";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";

const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyThread() {
	return {
		title: "",
		text: "",
		topic: null,
		createdBy: null,
		validated: true,
	};
}

export default function EditCourseThreadsPage() {
	const [thread, setThread] = useState(getEmptyThread());
	const [course, setCourse] = useState(null);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const courseId = useParams().id;
	const threadId = useParams().threadId;

	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (threadId)
			getThreadById(threadId)
				.then((res) => {
					if (res.status === 200) setThread(res.data);
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get thread",
					});
					history.push("/sports/courses/" + courseId + "/threads");
				});
		getCourseById(courseId)
			.then((res) => {
				if (res.status === 200) {
					setCourse(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get course.",
				});
				history.push("/sports/courses/" + courseId + "/threads");
			});
	}, [disableLoadingData, history]);

	function saveThread() {
		if (checkIsEmpty(thread?.title)) {
			alertError({ error: null, customMessage: "Title is required" });
			return;
		}

		if (!threadId) {
			postThread({ ...thread, createdBy: user?._id, course: courseId })
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Thread successfully created.",
						});
						history.push(
							"/sports/courses/" + courseId + "/threads"
						);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save thread.",
					});
				});
		} else {
			updateThread(threadId, thread)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Thread successfully created.",
						});
						history.push(
							"/sports/courses/" + courseId + "/threads"
						);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save thread.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		if (event.target.value === " ") return;
		setThread({ ...thread, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value) => {
		if (value === " ") return;
		setThread({ ...thread, [element]: value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader
						title={getNonEmpty(course?.title) + ": edit thread"}
					></CardHeader>
					<CardBody>
						<TextField
							id={`title`}
							label="Title"
							value={thread.title}
							onChange={handleChange("title")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<br />
						<br />
						<div>Text</div>
						<Editor
							body={thread.text || ""}
							setBody={(new_body) =>
								handleChangeEditor("text", new_body)
							}
							className="max-height"
							placeholder={"Enter message text here..."}
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() =>
							history.push(
								"/sports/courses/" + courseId + "/threads"
							)
						}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveThread()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save thread
					</Button>
					{threadId && user?.role?.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete thread
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this thread?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteThread(threadId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Thread deleted successfully",
												});
												history.push(
													"/sports/courses/" +
														courseId +
														"/threads"
												);
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete thread.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
