import {
	Button,
	Checkbox,
	createMuiTheme,
	FormControlLabel,
	MuiThemeProvider,
	Switch,
	TextField,
	Tooltip,
} from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api/index";
import {
	deleteTeam,
	getTeamById,
	postTeam,
	updateTeam,
} from "../../../../api/team";
import { getUsers } from "../../../../api/user";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import TableDialog from "../../../components/dialogs/TableDialog";
import Table, {
	booleanFormatter,
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Autocomplete } from "@material-ui/lab";
import { getNonEmpty } from "utils/helpers";
import { getTeamCategories } from "api/teamCategory";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyTeam() {
	return {
		title: "",
		members: [],
		teamCategories: [],
		logoURL: "",
		public: false,
	};
}

export default function EditTeamsPage() {
	const [team, setTeam] = useState(getEmptyTeam());
	const [users, setUsers] = useState([]);
	const [teamCategories, setTeamCategories] = useState([]);

	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openUserTableDialog, setOpenUserTableDialog] = useState(false);

	const [loading, setLoading] = useState(true);
	const [refresh, setRefresh] = useState(false);

	const teamId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					setUsers(
						res.data.filter(
							(item) => !item.role.includes("admin") && !item.isAI
						)
					);
					setLoading(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get users.",
				});
				history.push("/teams");
			});
		getTeamCategories()
			.then((res) => {
				if (res.status === 200) {
					setTeamCategories(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get team categories.",
				});
				history.push("/teams");
			});
		if (!teamId) {
			disableLoadingData();
			return;
		}
		getTeamById(teamId)
			.then((res) => {
				if (res.status === 200) {
					let team = {
						...res.data,
						teamCategories: res?.data?.teamCategories?.map(
							(x) => x?._id
						),
					};
					setTeam(team);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get team.",
				});
				history.push("/teams");
			});
	}, [teamId, disableLoadingData, history]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	function getMemberData(members) {
		let data = [];

		for (let i = 0; i < members?.length; ++i) {
			const elem = {};
			const member = members[i];

			elem.profilePictureURL =
				member?.user?.imagesURLs && member?.user?.imagesURLs[0];
			elem.name = member?.user?.fullName;
			elem.admin = member?.admin;
			elem.entryDate = member?.entryDate;

			elem.user = member?.user?._id;
			elem.id = member?.user?._id;

			data.push(elem);
		}

		return data;
	}

	function saveTeam() {
		let saveTeam = {
			...team,
			members: team?.members?.map((member) => ({
				...member,
				user: member?.user?._id,
			})),
		};
		if (!teamId) {
			postTeam(saveTeam, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Team successfully created.",
						});
						history.push("/teams");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save team.",
					});
				});
		} else {
			updateTeam(teamId, saveTeam, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/teams");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		if (event.target.value === " ") return;
		setTeam({ ...team, [element]: event.target.value });
	};

	const memberTableColumns = [
		{
			dataField: "profilePictureURL",
			text: "",
			formatter: profilePictureFormatter,
		},
		{ dataField: "name", text: "Full Name", sort: true },
		{
			dataField: "admin",
			text: "Admin",
			sort: true,
			headerAlign: "center",
			align: "center",
			formatter: booleanFormatter,
		},
		{
			dataField: "entryDate",
			text: "Entry Date",
			sort: true,
			headerAlign: "center",
			align: "center",
			formatter: dateFormatter,
		},
		{ dataField: "user", text: "", formatter: memberButtonFormatter },
	];

	function profilePictureFormatter(cell) {
		return cell && cell !== "" ? (
			<Image
				src={SERVER_URL + "/" + cell}
				alt="profile"
				roundedCircle
				style={{ width: "50px", height: "50px" }}
			/>
		) : (
			<div />
		);
	}

	function memberButtonFormatter(cell) {
		const index = team?.members?.findIndex((x) => x.user?._id === cell);
		const member = team?.members[index];

		return (
			<>
				<Tooltip
					title={member?.admin ? "Dismiss as admin" : "Set as admin"}
				>
					<Switch
						color="primary"
						size="small"
						checked={member?.admin}
						onChange={() => {
							let _members = [...team.members];
							_members[index].admin = !member?.admin;

							setTeam({
								...team,
								members: _members,
							});
							setRefresh(true);
						}}
					/>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let _members = [...team.members];
							_members.splice(index, 1);
							setTeam({
								...team,
								members: _members,
							});
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit team"></CardHeader>
					<CardBody>
						<TextField
							id={`title`}
							label="Title"
							value={team.title}
							onChange={handleChange("title")}
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!user?.role.includes("admin")}
							margin="normal"
							variant="outlined"
							required
						/>
						<br />
						<TextField
							id={`code`}
							label="Code"
							value={team.code}
							onChange={handleChange("code")}
							InputLabelProps={{
								shrink: true,
							}}
							disabled={true}
							margin="normal"
							variant="outlined"
						/>
						<Autocomplete
							multiple
							id="categories"
							filterSelectedOptions
							disablePortal
							disableCloseOnSelect
							options={teamCategories}
							getOptionLabel={(option) =>
								getNonEmpty(option.fullName)
							}
							value={teamCategories.filter((x) =>
								team?.teamCategories?.includes(x._id)
							)}
							onChange={(event, selected) => {
								setTeam({
									...team,
									teamCategories: selected.map((x) => x._id),
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									placeholder="Select categories"
									label={"Categories"}
								/>
							)}
						/>
						<br />
						<br />
						{user?.role.includes("admin") && (
							<label htmlFor={"upload-image"} className="ml-0">
								<input
									style={{ display: "none" }}
									id={"upload-image"}
									name={"upload-image"}
									type="file"
									accept={"image/*"}
									onChange={(e) => {
										setSelectedImage(e.target.files[0]);
									}}
								/>
								<Button
									style={{ marginRight: "15px" }}
									color="secondary"
									component="span"
									variant="outlined"
								>
									{selectedImage ||
									(team?.logoURL && team?.logoURL !== "")
										? "Change logo"
										: "Upload logo"}
								</Button>
							</label>
						)}
						{(selectedImage ||
							(team?.logoURL && team?.logoURL !== "")) && (
							<>
								<Tooltip title={"Preview logo"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview logo"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${team?.logoURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: team?.logoURL && team?.logoURL !== ""
										? team.logoURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={team?.public}
									onChange={() =>
										setTeam({
											...team,
											public: !team?.public,
										})
									}
									name="checkActive"
								/>
							}
							label="Public"
						/>
					</CardBody>
					<CardHeader
						title={
							<div>
								Members
								{loading && (
									<span className="ml-3 spinner spinner-black"></span>
								)}
							</div>
						}
					>
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenUserTableDialog(true);
								}}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh && team?.members?.length > 0 && (
							<Table
								columns={memberTableColumns}
								data={getMemberData(team.members, users)}
							/>
						)}
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/teams")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					{user?.role.includes("admin") && (
						<Button
							onClick={() => saveTeam()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save team
						</Button>
					)}
					{teamId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete team
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>
							<ConfirmDialog
								title={
									"Are you sure you want to delete this team?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteTeam(teamId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Team deleted successfully",
												});
												history.push("/teams");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete team.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
				<TableDialog
					open={openUserTableDialog}
					setOpen={setOpenUserTableDialog}
					data={users}
					usersTable={true}
					onSelectRow={(item) => {
						let teamMembers = [...team?.members];
						teamMembers.push({
							user: item,
							admin: false,
						});
						setTeam({ ...team, members: teamMembers });
						setRefresh(true);
						setOpenUserTableDialog(false);
					}}
				/>
			</>
		);
}
