import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api/index";
import {
	deleteSport,
	getSportById,
	postSport,
	updateSport,
} from "../../../../api/sport";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptySport() {
	return {
		fullName: {},
		iconURL: "",
		bannerURL: "",
		active: true,
	};
}

export default function EditSportsPage() {
	const [sport, setSport] = useState(getEmptySport());
	const [selectedIcon, setSelectedIcon] = useState(null);
	const [selectedBanner, setSelectedBanner] = useState(null);

	const [openPreviewIcon, setOpenPreviewIcon] = useState(false);
	const [openPreviewBanner, setOpenPreviewBanner] = useState(false);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const sportId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!sportId) {
			disableLoadingData();
			return;
		}
		getSportById(sportId)
			.then((res) => {
				if (res.status === 200) {
					setSport(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get sport.",
				});
				history.push("/sports");
			});
	}, [sportId, disableLoadingData, history]);

	function saveSport() {
		if (checkIsEmpty(sport.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!sportId) {
			postSport(sport, selectedIcon, selectedBanner)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Sport successfully created.",
						});
						history.push("/sports");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save sport.",
					});
				});
		} else {
			updateSport(sportId, sport, selectedIcon, selectedBanner)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/sports");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!sport[element]) sport[element] = {};
			let newText = sport[element];
			newText[lang] = event.target.value;
			setSport({ ...sport, [element]: newText });
		} else setSport({ ...sport, [element]: event.target.value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full Name"
					value={(sport.fullName && sport.fullName[lang]) || ""}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit sport"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<br />
						<label htmlFor={"upload-image"}>
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedIcon(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedIcon ||
								(sport.iconURL && sport.iconURL !== "")
									? "Change icon"
									: "Upload icon"}
							</Button>
						</label>
						{(selectedIcon ||
							(sport.iconURL && sport.iconURL !== "")) && (
							<>
								<Tooltip title={"Preview icon"}>
									<Button
										size="small"
										onClick={() => setOpenPreviewIcon(true)}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview icon"}
									open={openPreviewIcon}
									setOpen={setOpenPreviewIcon}
									src={
										selectedIcon
											? URL.createObjectURL(selectedIcon)
											: `${SERVER_URL}/${sport.iconURL}`
									}
								/>
								<span>
									{selectedIcon
										? selectedIcon?.name
										: sport.iconURL && sport.iconURL !== ""
										? sport.iconURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<label htmlFor={"upload-banner"}>
							<input
								style={{ display: "none" }}
								id={"upload-banner"}
								name={"upload-banner"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedBanner(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedBanner ||
								(sport.bannerURL && sport.bannerURL !== "")
									? "Change banner"
									: "Upload banner"}
							</Button>
						</label>
						{(selectedBanner ||
							(sport.bannerURL && sport.bannerURL !== "")) && (
							<>
								<Tooltip title={"Preview banner"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewBanner(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview banner"}
									open={openPreviewBanner}
									setOpen={setOpenPreviewBanner}
									src={
										selectedBanner
											? URL.createObjectURL(
													selectedBanner
											  )
											: `${SERVER_URL}/${sport.bannerURL}`
									}
								/>
								<span>
									{selectedBanner
										? selectedBanner?.name
										: sport.bannerURL &&
										  sport.bannerURL !== ""
										? sport.bannerURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={sport.active}
									onChange={() =>
										setSport({
											...sport,
											active: !sport.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/sports")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveSport()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save sport
					</Button>
					{sportId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete sport
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this sport?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteSport(sportId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Sport deleted successfully",
												});
												history.push("/sports");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete sport.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
