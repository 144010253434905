import React from 'react';
import 'hammerjs';
import { 
    Chart, 
    ChartSeries, 
    ChartSeriesItem, 
    ChartTitle,
    ChartSeriesItemTooltip,
    ChartSubtitle,
    ChartCategoryAxis,
    ChartCategoryAxisItem
} from '@progress/kendo-react-charts';
import './charts.css';

const BarChartContainer = ({ data, units, title, units2, twoColumns, noDataMessage }) => {
  const labelContent = (props) => {
    return `${props.dataItem.value} ${units || ''}`;
  };

  const labelContent2 = (props) => {
    return `${props.dataItem.value2} ${units2 || ''}`;
  };

    return <>
      <Chart 
          pannable={{
            lock: 'y'
          }} 
          zoomable={{
            mousewheel: {
              lock: 'y'
            },
            selection: {
              lock: 'y'
            }
          }}
        >
            <ChartTitle text={title}/>
            {(!data || data.length === 0) ? <ChartSubtitle text={noDataMessage || 'No data found'}/> : <ChartSubtitle text=""/>}
            <ChartSeries>
                <ChartSeriesItem 
                  type="column" 
                  data={data} 
                  field="value"
                  labels={{
                    visible: true,
                    content: labelContent
                  }}
                  categoryField="key" 
                >
                  <ChartSeriesItemTooltip />
                </ChartSeriesItem>
                {twoColumns && <ChartSeriesItem 
                  type="column" 
                  data={data} 
                  field="value2"
                  labels={{
                    visible: true,
                    content: labelContent2
                  }}
                  categoryField="key" 
                >
                  <ChartSeriesItemTooltip />
                </ChartSeriesItem>}
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                labels={{
                  rotation: "auto",
                }}
              />
            </ChartCategoryAxis>
      </Chart>
    </>
};

export default BarChartContainer