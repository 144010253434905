import React, { useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import { dateFormatter } from '../../../components/tables/table';
import { useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSkeleton } from '../../../hooks/useSkeleton';

export default function PurchasePage() {
	const location = useLocation();
	const history = useHistory();
	const purchase = location.state;
	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (purchase) disableLoadingData();
	}, [purchase]);

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='PURCHASE'>
						<CardHeaderToolbar>
							<div className='btn btn-primary'>
								<b>{purchase.status}</b>
							</div>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody className='d-flex flex-column p-2'>
						<div className='container'>
							<h5 className='m-3'>
								<b>PURCHASE DATA</b>
							</h5>
							<ul className='list-group'>
								<li className='list-group-item'>
									<b>User: </b> {purchase.user?.fullName}
								</li>
								<li className='list-group-item'>
									<b>Product: </b>
									{purchase.metadata?.purchaseType || purchase.productId}
								</li>
								{purchase.specialistAssigned && (
									<li className='list-group-item'>
										<b>Specialist: </b>
										{purchase.specialistAssigned?.fullName}
									</li>
								)}
								<li className='list-group-item'>
									<b>Purchase date: </b>
									{dateFormatter(purchase.metadata?.purchaseTime) ||
										dateFormatter(purchase.createdAt)}
								</li>
								{purchase.isSubscription && (
									<li className='list-group-item'>
										<span>
											<b>Expiration date: </b>
											{dateFormatter(purchase.metadata?.expirationTime)}
										</span>
									</li>
								)}
								<li className='list-group-item'>
									<b>Price: </b>
									{purchase.metadata?.priceAmount || purchase.price}
								</li>
								<li className='list-group-item'>
									<span>
										<b>Platform: </b>
										{purchase.platform}
									</span>
								</li>
								<li className='list-group-item'>
									<span>
										<b>Transaction ID: </b>
										{purchase.transactionId}
									</span>
								</li>
							</ul>
						</div>
					</CardBody>
					<CardBody className='d-flex flex-column p-2'>
						<div className='container'>
							<h5 className='m-3'>
								<b>STATE CHANGES</b>
							</h5>
							{purchase.notifications.map((notification) => (
								<ul className='list-group m-3'>
									<li className='list-group-item'>
										<span>
											<b>state: </b>
											{notification.notificationMessage ||
												notification.notificationType}
										</span>
									</li>
									{notification.subtype && (
										<li className='list-group-item'>
											<span>
												<b>sub-state: </b>
												{notification.subtype}
											</span>
										</li>
									)}
									<li className='list-group-item'>
										<span>
											<b>date: </b>
											{dateFormatter(
												notification.eventTimeMillis || notification.signedDate
											)}
										</span>
									</li>
								</ul>
							))}
						</div>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/purchases')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
				</div>
			</>
		);
}
