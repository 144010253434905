import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	Tooltip,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { deleteTip, getTipById, postTip, updateTip } from '../../../../api/tip';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import Editor from '../../../components/editor/Editor';
import { shallowEqual, useSelector } from 'react-redux';
import { Visibility } from '@material-ui/icons';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import { SERVER_URL } from '../../../../api/index';
import { buttonsStyle } from '../../../components/tables/table';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import { checkIsEmpty } from '../../../../utils/helpers';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyTip() {
	return {
		title: {},
		description: {},
		summary: {},
		imageURL: '',
		videoURL: '',
		fileURL: '',
		link: '',
		publication_date: (new Date()).toISOString(),
		active: true,
	};
}

export default function EditTipsPage() {
	const [tip, setTip] = useState(getEmptyTip());
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openPreviewFile, setOpenPreviewFile] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const tipId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!tipId) {
			disableLoadingData();
			return;
		}
		getTipById(tipId)
			.then((res) => {
				if (res.status === 200) {
					setTip(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get tip.' });
				history.push('/tips');
			});
	}, [tipId, disableLoadingData, history]);

	function saveTip() {
		if (
			checkIsEmpty(tip.title) ||
			checkIsEmpty(tip.description) ||
			checkIsEmpty(tip.summary) ||
			!tip.publication_date
		)
			return alertError({
				error: null,
				customMessage:
					'The title, description, summary and publication date are required in at least one of the languages.',
			});
		if (tip.link) {
			try {
				if (!Boolean(new URL(tip.link)))
					return alertError({
						error: null,
						customMessage: 'The link is not valid.',
					});
			} catch (error) {
				return alertError({
					error: null,
					customMessage: 'The link is not valid.',
				});
			}
		}

		if (!tipId) {
			postTip(tip, selectedImage, selectedFile)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Tip successfully created.',
						});
						history.push('/tips');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save tip.' });
				});
		} else {
			updateTip(tipId, tip, selectedImage, selectedFile)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/tips');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!tip[element]) tip[element] = {};
			let newText = tip[element];
			newText[lang] = event.target.value;
			setTip({ ...tip, [element]: newText });
		} else setTip({ ...tip, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value === ' ') return;
		if (lang) {
			if (!tip[element]) tip[element] = {};
			let newText = tip[element];
			newText[lang] = value;
			setTip({ ...tip, [element]: newText });
		} else setTip({ ...tip, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label='Title'
					value={(tip.title && tip.title[lang]) || ''}
					onChange={handleChange('title', lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin='normal'
					variant='outlined'
					required
				/>
				<br />
				<Editor
					body={(tip.description && tip.description[lang]) || ''}
					setBody={(new_body) =>
						handleChangeEditor('description', new_body, lang)
					}
					className='max-height'
					placeholder={'Enter tip description here...'}
					label='Description *'
				/>
				<br />
				<Editor
					body={(tip.summary && tip.summary[lang]) || ''}
					setBody={(new_body) => handleChangeEditor('summary', new_body, lang)}
					className='max-height'
					placeholder={'Enter tip summary here...'}
					label='Summary *'
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit tip'></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={renderMultilanguageTabContent}
						/>
						<br/>
						<TextField
							id="publication_date"
							label="Publication date (España):"
							type="date"
							value={tip.publication_date && tip.publication_date?.includes('T') ? tip.publication_date.split('T')[0] : tip.publication_date}
							onChange={handleChange('publication_date')}
							InputLabelProps={{
								shrink: true
							}}
							margin="normal"
							variant="outlined"
						/>
						<br />
						<TextField
							id={`videoURL`}
							label='Youtube Video Id'
							value={tip.videoURL || ''}
							onChange={handleChange('videoURL')}
							InputLabelProps={{
								shrink: true,
							}}
							type='url'
							margin='normal'
							variant='outlined'
						/>
						<br />
						<TextField
							id={`link`}
							label='Link URL'
							value={tip.link || ''}
							onChange={handleChange('link')}
							InputLabelProps={{
								shrink: true,
							}}
							type='url'
							margin='normal'
							variant='outlined'
						/>
						<br/>
						<br/>
						<label htmlFor={'upload-image'}>
							<input
								style={{ display: 'none' }}
								id={'upload-image'}
								name={'upload-image'}
								type='file'
								accept={'image/*'}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedImage || tip.imageURL !== ''
									? 'Change image'
									: 'Upload image'}
							</Button>
						</label>
						{(selectedImage || tip.imageURL !== '') && (
							<>
								<Tooltip title={'Preview image'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewDialog(true)}
										style={{ ...buttonsStyle, marginRight: '15px' }}>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview image'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${tip.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: tip.imageURL !== ''
										? tip.imageURL.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br />
						<br />
						<label htmlFor={'upload-file'}>
							<input
								style={{ display: 'none' }}
								id={'upload-file'}
								name={'upload-file'}
								type='file'
								accept="application/pdf"
								onChange={(e) => {
									setSelectedFile(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedFile || tip.fileURL !== ''
									? 'Change file'
									: 'Upload file'}
							</Button>
						</label>
						{(selectedFile || (tip.fileURL && tip.fileURL !== '')) && (
							<>
								<Tooltip title={'Preview file'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewFile(true)}
										style={{ ...buttonsStyle, marginRight: '15px' }}>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview file'}
									open={openPreviewFile}
									setOpen={setOpenPreviewFile}
									src={
										selectedFile
											? URL.createObjectURL(selectedFile)
											: `${SERVER_URL}/${tip.fileURL}`
									}
								/>
								<span>
									{selectedFile
										? selectedFile?.name
										: tip.fileURL !== ''
										? tip.fileURL.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={tip.active}
									onChange={() => setTip({ ...tip, active: !tip.active })}
									name='checkActive'
								/>
							}
							label='Active'
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/tips')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveTip()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save tip
					</Button>
					{tipId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete tip
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this tip?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteTip(tipId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Tip deleted successfully',
												});
												history.push('/tips');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete tip.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
