import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteNutritionistSpecialization,
	getNutritionistSpecializationById,
	postNutritionistSpecialization,
	updateNutritionistSpecialization,
} from '../../../../api/nutritionistSpecialization';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { shallowEqual, useSelector } from 'react-redux';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import { checkIsEmpty } from '../../../../utils/helpers';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyNutritionistSpecialization() {
	return {
		fullName: {},
		active: true
	};
}

export default function EditNutritionistSpecializationsPage() {
	const [nutritionistSpecialization, setNutritionistSpecialization] = useState(getEmptyNutritionistSpecialization());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const nutritionistSpecializationId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!nutritionistSpecializationId) {
			disableLoadingData();
			return;
		}
		getNutritionistSpecializationById(nutritionistSpecializationId)
			.then((res) => {
				if (res.status === 200) {
					setNutritionistSpecialization(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get nutritionist specialization.' });
				history.push('/nutritionist-specializations');
			});
	}, [nutritionistSpecializationId, disableLoadingData, history]);

	function saveNutritionistSpecialization() {
		if (checkIsEmpty(nutritionistSpecialization.fullName)) return alertError({ error: null, customMessage: 'The name is required in at least one of the languages.' })
		if (!nutritionistSpecializationId) {
			postNutritionistSpecialization(nutritionistSpecialization)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Nutritionist specialization successfully created.',
						});
						history.push('/nutritionist-specializations');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save nutritionist specialization.' });
				});
		} else {
			updateNutritionistSpecialization(nutritionistSpecializationId, nutritionistSpecialization)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/nutritionist-specializations');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return
		if (lang) {
			if (!nutritionistSpecialization[element]) nutritionistSpecialization[element] = {}
			let newText = nutritionistSpecialization[element]
			newText[lang] = event.target.value
			setNutritionistSpecialization({ ...nutritionistSpecialization, [element]: newText })
		} else setNutritionistSpecialization({ ...nutritionistSpecialization, [element]: event.target.value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return <>
			<br/>
			<TextField
				id={`fullName`}
				label='Full name'
				value={(nutritionistSpecialization.fullName && nutritionistSpecialization.fullName[lang]) || ''}
				onChange={handleChange('fullName', lang)}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				variant='outlined'
				required
			/>
		</>
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit nutritionist specialization'></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent = {renderMultilanguageTabContent}
						/>
						<br/>
						<FormControlLabel
							control={<Checkbox checked={nutritionistSpecialization.active} onChange={() => setNutritionistSpecialization({ ...nutritionistSpecialization, active: !nutritionistSpecialization.active })} name="checkActive" />}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/nutritionist-specializations')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveNutritionistSpecialization()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save nutritionist specialization
					</Button>
					{nutritionistSpecializationId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete nutritionist specialization
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this nutritionist specialization?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteNutritionistSpecialization(nutritionistSpecializationId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Nutritionist specialization deleted successfully',
												});
												history.push('/nutritionist-specializations');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete nutritionist specialization.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
