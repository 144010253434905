import { API, authClient } from '../index';

// Get all trainer specializations
export const getTrainerSpecializations = () => {
	return authClient().get(`${API}/trainer-specialization?getDisabled=true`);
};

// Get trainer specialization by id
export const getTrainerSpecializationById = (id) => {
	return authClient().get(`${API}/trainer-specialization/${id}`);
};

// Delete trainer specialization
export const deleteTrainerSpecialization = (id) => {
	return authClient().delete(`${API}/trainer-specialization/${id}`);
};

// Create trainer specialization 
export const postTrainerSpecialization = async (trainerSpecialization) => {
	return authClient().post(`${API}/trainer-specialization`, trainerSpecialization);
};

// Update trainer specialization
export const updateTrainerSpecialization = async (id, trainerSpecialization) => {
	return authClient().put(`${API}/trainer-specialization/${id}`, trainerSpecialization);
};

// Change status trainer specialization
export const changeStatusTrainerSpecialization = async (id, active) => {
	return authClient().put(`${API}/trainer-specialization/change-status/${id}`, { active });
};