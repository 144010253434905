import { API, authClient } from '../index';
import { uploadSingleFile } from "../files"

// Get all tips
export const getTips = () => {
	return authClient().get(`${API}/tip?getDisabled=true`);
};

// Get tip by id
export const getTipById = (id) => {
	return authClient().get(`${API}/tip/${id}`);
};

// Delete tip
export const deleteTip = (id) => {
	return authClient().delete(`${API}/tip/${id}`);
};

// Create tip 
export const postTip = async (tip, image, file) => {
	if (image) {
		const response = await uploadSingleFile(image, 'tips')
		tip.imageURL = response.data.fileURL
	}
	if (file) {
		const response = await uploadSingleFile(file, 'tips');
		tip.fileURL = response.data.fileURL;
	}
	return authClient().post(`${API}/tip`, tip);
};

// Update tip
export const updateTip = async (id, tip, image, file) => {
	if (image) {
		const response = await uploadSingleFile(image, 'tips')
		tip.imageURL = response.data.fileURL
	}
	if (file) {
		const response = await uploadSingleFile(file, 'tips');
		tip.fileURL = response.data.fileURL;
	}
	return authClient().put(`${API}/tip/${id}`, tip);
};

// Change status tip
export const changeStatusTip = async (id, active) => {
	return authClient().put(`${API}/tip/change-status/${id}`, { active });
};