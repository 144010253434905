import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { CardBody } from "../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../api";
import QuestionContent from "../../modules/ContentManager/questions/QuestionContent";
import MultilanguageTabBlock from "../MultilanguageTabBlock";
import PreviewDialog from "./PreviewDialog";

const ViewTestDialog = (props) => {
	const { test, open, setOpen } = props;

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<h5>Title</h5>
				{test?.title[lang] || "---"}
				<br />
				<br />
				{test?.imageURL && test?.imageURL !== "" && (
					<>
						<img
							src={`${SERVER_URL}/${test?.imageURL}`}
							alt={
								test?.imageURL && test?.imageURL !== ""
									? test?.imageURL?.split(/-(.*)/s)[1]
									: ""
							}
							style={{ maxWidth: "200px", cursor: "zoom-in" }}
							onClick={() => setOpenPreviewDialog(true)}
						/>
						<PreviewDialog
							open={openPreviewDialog}
							setOpen={setOpenPreviewDialog}
							src={`${SERVER_URL}/${test?.imageURL}`}
						/>
						<br />
					</>
				)}
				<br />
				<br />
				<h5>Questions</h5>
				<div
					className="my-5"
					style={{
						width: "100%",
						height: "1px",
						backgroundColor: "#EBEDF3",
					}}
				></div>
				<CardBody className="py-0">
					{test?.questions?.map((question, index) => {
						return (
							<>
								<div>
									<strong>QUESTION {index + 1}</strong>
								</div>
								<CardBody>
									<QuestionContent
										question={question}
										language={lang}
									></QuestionContent>
								</CardBody>
							</>
						);
					})}
				</CardBody>
			</>
		);
	};

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{"Test"}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					onClick={() => {
						setOpen(false);
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default ViewTestDialog;
