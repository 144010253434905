import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api/index";
import {
	deleteMultimedia,
	getMultimediaById,
	postMultimedia,
	updateMultimedia,
} from "../../../../api/multimedia";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyMultimedia() {
	return {
		fullName: {},
		bannerURL: "",
		active: true,
	};
}

export default function EditMultimediasPage() {
	const [multimedia, setMultimedia] = useState(getEmptyMultimedia());
	const [selectedBanner, setSelectedBanner] = useState(null);

	const [openPreviewBanner, setOpenPreviewBanner] = useState(false);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const multimediaId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!multimediaId) {
			disableLoadingData();
			return;
		}
		getMultimediaById(multimediaId)
			.then((res) => {
				if (res.status === 200) {
					setMultimedia(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get multimedia.",
				});
				history.push("/multimedias");
			});
	}, [multimediaId, disableLoadingData, history]);

	function saveMultimedia() {
		if (checkIsEmpty(multimedia.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!multimediaId) {
			postMultimedia(multimedia, selectedBanner)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Multimedia successfully created.",
						});
						history.push("/multimedias");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save multimedia.",
					});
				});
		} else {
			updateMultimedia(multimediaId, multimedia, selectedBanner)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/multimedias");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!multimedia[element]) multimedia[element] = {};
			let newText = multimedia[element];
			newText[lang] = event.target.value;
			setMultimedia({ ...multimedia, [element]: newText });
		} else setMultimedia({ ...multimedia, [element]: event.target.value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full Name"
					value={(multimedia.fullName && multimedia.fullName[lang]) || ""}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit multimedia"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<TextField
							id={`fullName`}
							label="Full Name"
							value={(multimedia.fullName) || ""}
							onChange={handleChange("fullName")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<br />
						<br />
						<label htmlFor={"upload-banner"}>
							<input
								style={{ display: "none" }}
								id={"upload-banner"}
								name={"upload-banner"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedBanner(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedBanner ||
								(multimedia.bannerURL && multimedia.bannerURL !== "")
									? "Change resource"
									: "Upload resource"}
							</Button>
						</label>
						{(selectedBanner ||
							(multimedia.bannerURL && multimedia.bannerURL !== "")) && (
							<>
								<Tooltip title={"Preview resource"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewBanner(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview resource"}
									open={openPreviewBanner}
									setOpen={setOpenPreviewBanner}
									src={
										selectedBanner
											? URL.createObjectURL(
													selectedBanner
											  )
											: `${SERVER_URL}/${multimedia.bannerURL}`
									}
								/>
								<span>
									{selectedBanner
										? selectedBanner?.name
										: multimedia.bannerURL &&
										  multimedia.bannerURL !== ""
										? multimedia.bannerURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={multimedia.active}
									onChange={() =>
										setMultimedia({
											...multimedia,
											active: !multimedia.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/multimedias")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveMultimedia()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save multimedia
					</Button>
					{multimediaId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete multimedia
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this multimedia?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteMultimedia(multimediaId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Multimedia deleted successfully",
												});
												history.push("/multimedias");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete multimedia.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
