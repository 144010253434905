import { API, authClient } from "../index";

export const postThread = async (thread) => {
	return authClient().post(`${API}/thread`, thread);
};

export const getThreadByCourse = (courseId) => {
	return authClient().get(
		`${API}/thread/by-course/${courseId}?getDisabled=true`
	);
};

export const updateThread = async (id, thread) => {
	return authClient().put(`${API}/thread/${id}`, thread);
};

export const changeStatusThread = async (id, active) => {
	return authClient().put(`${API}/thread/change-status/${id}`, {
		active,
	});
};

export const getThreadById = (id) => {
	return authClient().get(`${API}/thread/${id}`);
};

export const deleteThread = (id) => {
	return authClient().delete(`${API}/thread/${id}`);
};
