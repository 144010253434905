import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { SERVER_URL } from "../../../api";
import { getUserById } from "../../../api/user";

const Conversation = ({ chat, currentUserId, online }) => {
	const [userData, setUserData] = useState(null);

	const notifications = useSelector(
		(store) => store.chat?.notifications,
		shallowEqual
	);

	useEffect(() => {
		const otherChatMembers = chat?.members.filter(
			(id) => id.toString() !== currentUserId
		);

		const userId = otherChatMembers?.length === 1 && otherChatMembers[0];

		const getUserData = async () => {
			try {
				if (chat?.team) setUserData(chat?.team);
				else if (userId) setUserData((await getUserById(userId)).data);
			} catch (error) {
				console.log(error);
			}
		};

		getUserData();
	}, [currentUserId, chat]);

	return (
		<>
			<div className="follower conversation">
				<div>
					{online && <div className="online-dot-list"></div>}
					{notifications && notifications[chat?._id] && (
						<div className="notification-dot">
							{notifications[chat?._id] > 99
								? "..."
								: notifications[chat?._id]}
						</div>
					)}
					<div className="d-flex align-items-center">
						<img
							src={
								chat?.team && chat?.logoURL
									? SERVER_URL + "/" + chat?.logoURL
									: userData?.imagesURLs &&
									  userData?.imagesURLs[0] &&
									  userData?.imagesURLs[0] !== ""
									? SERVER_URL + "/" + userData?.imagesURLs[0]
									: toAbsoluteUrl("/media/profile.png")
							}
							alt="Profile"
							className="followerImage"
						/>
						<div
							className="name"
							style={{ fontSize: "1rem", color: "#3F4254" }}
						>
							<span>
								{chat?.team
									? userData?.title
									: userData?.fullName}{" "}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Conversation;
