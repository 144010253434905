import { API, authClient } from '../index'

export const login = (email, password) => {
  const body = { email, password }
  return authClient().post(`${API}/user/signin-backoffice`, body)
}

export const register = (body) => {
  return authClient().post(`${API}/user/signup`, body)
}

export const logout = () => {
  return authClient().get(`${API}/user/logout`)
}

export const getCredentials = () => {
  return authClient().get(`${API}/user/credentials`)
}

export const refreshTokens = () => {
  return authClient().get(`${API}/user/refresh?backoffice=true`)
}

export const forgotPassword = (credentials) => {
	return authClient().post(`${API}/user/forgot-password-backoffice`, credentials)
}
  
export const resetPassword = ({ password, id, token }) => {
	const body = { password }
	return authClient().put(`${API}/user/forgot-password/${id}/${token}`, body)
}

export const validateForgotPassword = ({ id, token }) => {
	console.log('validateForgotPassword api call')
	return authClient().get(`${API}/user/forgot-password/${id}/${token}`)
}
