import {
	Button,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	Switch,
	Tooltip,
	Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LanguageIcon from "@material-ui/icons/Language";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getDietTypes } from "../../../../api/dietType";
import {
	changeStatusNutritionalPlan,
	deleteNutritionalPlan,
	getNutritionalPlans,
} from "../../../../api/nutritionalPlan";
import { getUsers } from "../../../../api/user";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import FiltersCard from "../../../components/filters/Filter";
import Table, {
	badgeFormatter,
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

function getData(nutritionalPlans, users) {
	let data = [];
	for (let i = 0; i < nutritionalPlans.length; ++i) {
		const elem = {};
		elem.premium = nutritionalPlans[i].premium;
		elem.title = nutritionalPlans[i].title;
		elem.description = nutritionalPlans[i].description;
		elem.dietTypes = nutritionalPlans[i]?.dietTypes
			?.map((x) => getNonEmpty(x.fullName))
			?.join(", ");

		elem.totalCalories = nutritionalPlans[i].totalCaloriesPlan;
		elem.createdBy = !nutritionalPlans[i].nutritionist
			? null
			: nutritionalPlans[i].nutritionist.fullName || "----";
		elem.createdAt = nutritionalPlans[i].createdAt;
		elem.id = nutritionalPlans[i]._id;
		elem.active = nutritionalPlans[i].active;
		elem.nutritionist = nutritionalPlans[i].nutritionist;
		elem.price = nutritionalPlans[i].price;
		data = data.concat(elem);
	}
	return data;
}

const initialFilters = {
	active: false,
	createdBy: [],
	standard: "All",
	dietTypes: [],
	totalCalories: [0, 0],
	premium: false,
};

export default function NutritionalPlansPage() {
	const [data, setData] = useState([]);
	const [nutritionalPlanId, setNutritionalPlanId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [filteredData, setFilteredData] = useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [maxValueCalories, setMaxValueCalories] = useState(null);
	const [users, setUsers] = useState([]);
	const [dietTypes, setDietTypes] = useState([]);
	const [filterOptions, setFilterOptions] = useState(initialFilters);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		const canEdit =
			user?.role.includes("admin") || elem.nutritionist === user?._id;
		return (
			<>
				<Tooltip title={canEdit ? "Edit" : "View"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>
							history.push("/edit-nutritional-plan/" + cell)
						}
					>
						{canEdit ? <EditIcon /> : <VisibilityIcon />}
					</Button>
				</Tooltip>
				{canEdit && (
					<>
						<Tooltip title="View translations">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() =>
									history.push(
										"/nutritional-plan-translations/" + cell
									)
								}
							>
								<LanguageIcon />
							</Button>
						</Tooltip>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setNutritionalPlanId(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
					</>
				)}
				{user?.role.includes("admin") && (
					<Tooltip title="Delete">
						<Button
							style={buttonsStyle}
							size="small"
							onClick={() => {
								setNutritionalPlanId(cell);
								setOpenConfirmDialog(2);
							}}
						>
							<DeleteIcon />
						</Button>
					</Tooltip>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "premium",
			text: "",
			sort: true,
			formatter: (cell) =>
				cell ? (
					<img
						src={toAbsoluteUrl("/media/premium.png")}
						alt="premium"
						style={{ width: "25px", height: "25px" }}
					/>
				) : null,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "price",
			text: "Price",
			formatter: (cell) => cell? (cell+' €') : "N/A",
			align: "right",
			sort: true
		},
		{
			dataField: "dietTypes",
			text: "Diet Type",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "totalCalories",
			text: "Calories",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "createdBy",
			text: "Created by",
			sort: true,
			headerAlign: "center",
			align: "center",
			formatter: badgeFormatter,
		},
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getUsers()
			.then((resp) => {
				if (resp.status === 200) {
					setUsers(
						resp.data.filter((item) =>
							item.role.includes("nutritionist")
						)
					);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get users.",
				});
			});
		getDietTypes()
			.then((res) => {
				if (res.status === 200) {
					setDietTypes(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get diet types.",
				});
			});
		getNutritionalPlans()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					const max = res.data.reduce(function(prev, current) {
						return prev.totalCaloriesPlan >
							current.totalCaloriesPlan
							? prev
							: current;
					})?.totalCaloriesPlan;
					setFilterOptions({
						...filterOptions,
						totalCalories: [0, max],
					});
					setMaxValueCalories(max);
					setFilteredData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get nutritional plans.",
				});
			});
		// eslint-disable-next-line
	}, [refresh]);

	const handleSearch = async () => {
		if (!data.length) return;
		setFilteredData(
			data.filter((item) => {
				let filter = true;
				if (filterOptions.dietTypes && filterOptions.dietTypes.length)
					filter =
						filter &&
						filterOptions.dietTypes.some((x) =>
							item.dietTypes.map((d) => d._id).includes(x)
						);
				if (filterOptions.createdBy && filterOptions.createdBy.length)
					filter =
						filter &&
						filterOptions.createdBy.includes(item.createdBy);
				if (
					filterOptions.totalCalories &&
					filterOptions.totalCalories.length
				)
					filter =
						filter &&
						filterOptions.totalCalories[0] <=
							item.totalCaloriesPlan &&
						filterOptions.totalCalories[1] >=
							item.totalCaloriesPlan;
				if (
					filterOptions.standard &&
					filterOptions.standard !== "All"
				) {
					let condition = true;
					if (filterOptions.standard === "Custom")
						condition = item.nutritionist !== null;
					else if (filterOptions.standard === "Standard")
						condition = !item.nutritionist;
					filter = filter && condition;
				}
				if (filterOptions.premium) filter = filter && item.premium;
				if (filterOptions.active) filter = filter && item.active;
				if (filter) return item;
				return false;
			})
		);
	};

	const handleClearFilters = () => {
		setFilterOptions(initialFilters);
		setRefresh(true);
	};

	const handleChange = (element) => (event, newValue) => {
		if (element === "totalCalories")
			setFilterOptions({ ...filterOptions, [element]: newValue });
		else
			setFilterOptions({
				...filterOptions,
				[element]: event.target.value,
			});
	};

	const renderFiltersContent = () => {
		return (
			<>
				<Typography id="range-slider" gutterBottom>
					Total calories
				</Typography>
				<Slider
					min={0}
					marks={[
						{ value: 0, label: 0 },
						{ value: maxValueCalories, label: maxValueCalories },
					]}
					max={maxValueCalories}
					value={filterOptions.totalCalories}
					onChange={handleChange("totalCalories")}
					valueLabelDisplay="auto"
					aria-labelledby="range-slider"
				/>
				<br />
				<br />
				<FormControl style={{ width: "100%" }}>
					<InputLabel id="demo-simple-select-standard-label">
						Diet Types
					</InputLabel>
					<Select
						labelId="demo-simple-select-standard-label"
						id="demo-simple-select-standard"
						value={filterOptions.dietTypes || []}
						multiple
						onChange={handleChange("dietTypes")}
						MenuProps={MenuProps}
					>
						{dietTypes?.map((option) => (
							<MenuItem key={option._id} value={option._id}>
								{getNonEmpty(option?.fullName)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<br />
				<FormControl style={{ width: "100%" }}>
					<InputLabel id="demo-simple-select-standard-label">
						Standard
					</InputLabel>
					<Select
						labelId="demo-simple-select-standard-label"
						id="demo-simple-select-standard"
						value={filterOptions.standard || ""}
						onChange={handleChange("standard")}
						MenuProps={MenuProps}
					>
						{["All", "Standard", "Custom"]?.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<br />
				<FormControl style={{ width: "100%" }}>
					<InputLabel id="demo-simple-select-standard-label">
						Created by
					</InputLabel>
					<Select
						labelId="demo-simple-select-standard-label"
						id="demo-simple-select-standard"
						value={filterOptions.createdBy || []}
						multiple
						onChange={handleChange("createdBy")}
						MenuProps={MenuProps}
					>
						{users?.map((option) => (
							<MenuItem key={option._id} value={option._id}>
								{option.fullName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<br />
				<FormControlLabel
					control={
						<Switch
							checked={filterOptions.premium}
							onChange={() =>
								setFilterOptions({
									...filterOptions,
									premium: !filterOptions.premium,
								})
							}
							name="checkPremium"
						/>
					}
					label={
						filterOptions.premium
							? "Only premium"
							: "All premium/free"
					}
				/>
				<br />
				<FormControlLabel
					control={
						<Switch
							checked={filterOptions.active}
							onChange={() =>
								setFilterOptions({
									...filterOptions,
									active: !filterOptions.active,
								})
							}
							name="checkActive"
						/>
					}
					label={
						filterOptions.active
							? "Only active"
							: "All active/inactive"
					}
				/>
			</>
		);
	};

	return (
		<>
			<Card>
				<CardHeader title="Nutritional plans list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() =>
								history.push("/edit-nutritional-plan")
							}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>

					<Table
						data={getData(filteredData, users)}
						columns={columns}
					/>

					<ConfirmDialog
						title={`Are you sure you want to ${
							nutritionalPlanId?.active ? "disable" : "enable"
						} this nutritional plan?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusNutritionalPlan(
								nutritionalPlanId._id,
								!nutritionalPlanId?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												nutritionalPlanId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Nutritional plan ${
												nutritionalPlanId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											nutritionalPlanId?.active
												? "disable"
												: "enable"
										} nutritional plan.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							"Are you sure you want to remove this nutritional plan?"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteNutritionalPlan(nutritionalPlanId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Nutritional plan removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not remove nutritional plan.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
