import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import { Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { getTranslationsByEntityId } from '../../../../api/translation';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError } from '../../../../utils/logger';
import { shallowEqual, useSelector } from 'react-redux'
import RoutineContent from './RoutineContent';
import { getNonEmpty } from '../../../../utils/helpers';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import { getRoutineById } from '../../../../api/routine';

export default function RoutinesTranslationsPage() {
	const [translations, setTranslations] = useState([])
	const [routine, setRoutine] = useState(null)
	const routineId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!routineId) {
			disableLoadingData();
			alertError({ error: null, customMessage: 'Could not get routine translations.' });
			history.push('/training/routines');
			return;
		}

		getTranslationsByEntityId(routineId)
			.then((res) => {
				if (res.status === 200) {
					getRoutineById(routineId)
						.then(response => {
							if (response.status === 200) {
								let translations = {}
								const { title, description, ..._routine } = response.data
								setRoutine({
									..._routine, 
									standard: !_routine.trainer, 
									muscleGroups: ([getNonEmpty(_routine.muscleGroup?.fullName)].concat(_routine.otherMuscleGroups.map(mg => (getNonEmpty(mg?.fullName))))).join(', ')
								})
								translations.es = { title, description }
								for (const translation of res.data) {
									const content = translation.content
									translations[translation.langIsoCode] = content
								}
								setTranslations(translations);
								disableLoadingData();
							}
						})
						.catch((error) => {
							alertError({ error: error, customMessage: 'Could not get routine.' });
							history.push('/training/routines');
						});
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get routine translations.' });
				history.push('/training/routines');
			});
	}, [routineId, disableLoadingData, history]);

	const renderMultilanguageTabContent = (lang, langName) => {
		const canEdit = user?.role.includes('admin') || (routine?.trainer === user?._id && !routine?.standard)
		return <>
			<br/>
			{translations && translations[lang] ? <RoutineContent 
				routine={{...translations[lang], ...routine}} 
				showTitle={true}
			/> : <>
				<p>There is no translation into this language yet.</p>
			</>}
			<br/>
			{canEdit && <Button
				onClick={() => {
					if (lang === 'es') history.push('/training/edit-routine/' + routineId)
					else history.push('/training/translate-routine/' + routineId + '/' + lang)
				}}
				variant='outlined'
				color='primary'>
				{'Edit ' + langName}
			</Button>}
		</>
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='View routine translations'/>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={renderMultilanguageTabContent}
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/training/routines')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
				</div>
			</>
		);
}
