import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const postTeamCategory = async (teamCategory, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "teamCategories");
		teamCategory.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/team-category`, teamCategory);
};

export const getTeamCategories = () => {
	return authClient().get(`${API}/team-category?getDisabled=true`);
};

export const getTeamCategoryById = (id) => {
	return authClient().get(`${API}/team-category/${id}`);
};

export const updateTeamCategory = async (id, teamCategory, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "teamCategories");
		teamCategory.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/team-category/${id}`, teamCategory);
};

export const changeStatusTeamCategory = async (id, active) => {
	return authClient().put(`${API}/team-category/change-status/${id}`, {
		active,
	});
};

export const deleteTeamCategory = (id) => {
	return authClient().delete(`${API}/team-category/${id}`);
};
