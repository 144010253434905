import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import {
	ArrowDownward,
	ArrowUpward,
	Delete,
	Visibility,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api/index";
import { getSports } from "../../../../api/sport";
import { getQuestions } from "../../../../api/question";
import {
	deleteTest,
	getTestById,
	postTest,
	updateTest,
} from "../../../../api/test";
import { checkIsEmpty, getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import TableDialog from "../../../components/dialogs/TableDialog";
import ViewQuestionDialog from "../../../components/dialogs/ViewQuestionDialog";
import Table, {
	buttonsStyle,
	substringFormatter,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyTest() {
	return {
		title: {},
		imageURL: "",
		sport: null,
		questions: [],
		active: true,
	};
}

function getQuestionTableData(questions) {
	let data = [];

	for (const question of questions) {
		const elem = {};

		elem.imageURL = question?.imageURL;
		elem.statement = getNonEmpty(question?.statement);
		elem.sport = getNonEmpty(question?.sport?.fullName);
		elem._id = question?._id;

		data.push(elem);
	}

	return data;
}

export default function EditTestsPage() {
	const [test, setTest] = useState(getEmptyTest());
	const [sports, setSports] = useState([]);

	const [questions, setQuestions] = useState([]);

	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [previewImage, setPreviewImage] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openQuestionTableDialog, setOpenQuestionTableDialog] = useState(
		false
	);
	const [openViewQuestionDialog, setOpenViewQuestionDialog] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState(false);

	const [refresh, setRefresh] = useState(false);

	const testId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getQuestions()
			.then((res) => {
				if (res.status === 200) {
					setQuestions(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get questions.",
				});
				history.push("/sports/tests");
			});
		getSports()
			.then((res) => {
				if (res.status === 200) {
					setSports(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get sports.",
				});
				history.push("/sports/lessons");
			});
		if (!testId) {
			disableLoadingData();
			return;
		}
		getTestById(testId)
			.then((res) => {
				if (res.status === 200) {
					let test = {
						...res.data, sport: res.data.sport?._id
					};
					setTest(test);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get test.",
				});
				history.push("/sports/tests");
			});
	}, [testId, disableLoadingData, history]);

	function saveTest() {
		if (checkIsEmpty(test.title))
			return alertError({
				error: null,
				customMessage:
					"The title is required in at least one of the languages.",
			});
		if (!testId) {
			postTest(test, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Test successfully created.",
						});
						history.push("/sports/tests");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save test.",
					});
				});
		} else {
			updateTest(testId, test, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/sports/tests");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!test[element]) test[element] = {};
			let newText = test[element];
			newText[lang] = event.target.value;
			setTest({ ...test, [element]: newText });
		} else setTest({ ...test, [element]: event.target.value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Title"
					value={(test.title && test.title[lang]) || ""}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
			</>
		);
	};

	function imageFormatter(cell) {
		return cell && cell !== "" ? (
			<img
				src={SERVER_URL + "/" + cell}
				alt="question"
				style={{ width: "50px", height: "50px" }}
				onClick={() => {
					setPreviewImage(cell);
					setOpenPreviewDialog(true);
				}}
			/>
		) : (
			<div />
		);
	}

	const handleMoveQuestion = (index, newIndex) => {
		const aux = test.questions[index];
		if (!aux)
			return alertError({
				error: null,
				customMessage:
					"An unexpected error has ocurred trying to move the questions, please try again.",
			});
		let _questions = test.questions;
		_questions.splice(index, 1, _questions[newIndex]);
		_questions.splice(newIndex, 1, aux);
		setRefresh(true);
		setTest({ ...test, questions: _questions });
	};

	function questionButtonFormatter(cell) {
		const index = test?.questions?.findIndex((x) => x === cell);
		const question = questions?.find((x) => x._id === cell);

		console.log("test", cell);

		return (
			<>
				<Tooltip title="View">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenViewQuestionDialog(true);
							setSelectedQuestion(question);
						}}
					>
						<Visibility />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() => handleMoveQuestion(index, index - 1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={index >= test.questions.length - 1}
						style={buttonsStyle}
						onClick={() => handleMoveQuestion(index, index + 1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let _questions = [...test.questions];
							_questions.splice(index, 1);
							setTest({
								...test,
								questions: _questions,
							});
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	const questionColumns = [
		{
			dataField: "imageURL",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: imageFormatter,
		},
		{
			dataField: "statement",
			text: "Statement",
			sort: true,
			formatter: substringFormatter,
		},

		{ dataField: "_id", text: "", formatter: questionButtonFormatter },
	];

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit test"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<br />
						<Autocomplete
							id="sports"
							disablePortal
							options={sports}
							getOptionLabel={(option) =>
								getNonEmpty(option?.fullName)
							}
							value={sports?.find(
								(x) => x._id === test?.sport
							)}
							onChange={(event, selected) => {
								setTest({
									...test,
									sport: selected?._id || null,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									placeholder="Select a sport"
									label="Sport"
									required
								/>
							)}
						/>
						<br />
						<br />
						<label htmlFor={"upload-image"}>
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedImage ||
								(test.imageURL && test.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(test.imageURL && test.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview image"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${test.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: test.imageURL && test.imageURL !== ""
										? test.imageURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={test.active}
									onChange={() =>
										setTest({
											...test,
											active: !test.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
					<CardHeader title="Questions">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => setOpenQuestionTableDialog(true)}
							>
								Select question
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh && (
							<Table
								columns={questionColumns}
								data={getQuestionTableData(
									test.questions.map((x) => {
										return questions.find(
											(q) => q._id === x
										);
									})
								)}
							></Table>
						)}
						<TableDialog
							open={openQuestionTableDialog}
							setOpen={setOpenQuestionTableDialog}
							questionsTable={true}
							data={getQuestionTableData(questions)}
							onSelectRow={(id) => {
								let questions = test.questions;
								test.questions.push(id);

								setTest({ ...test, questions: questions });

								setRefresh(true);
								setOpenQuestionTableDialog(null);
							}}
						/>
					</CardBody>
					<ViewQuestionDialog
						question={selectedQuestion}
						open={openViewQuestionDialog}
						setOpen={setOpenViewQuestionDialog}
					/>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/sports/tests")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveTest()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save test
					</Button>
					{testId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete test
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this test?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteTest(testId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Test deleted successfully",
												});
												history.push("/sports/tests");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete test.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
