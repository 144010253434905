import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import { Button, AppBar, Tab, Tabs, TextField } from '@material-ui/core';
import Editor from '../../../components/editor/Editor';
import { useHistory, useParams } from 'react-router-dom';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { shallowEqual, useSelector } from 'react-redux'
import { getTranslationsByEntityId, postTranslation, updateTranslation } from '../../../../api/translation'
import { getRoutineById } from '../../../../api/routine';
import { alertError, alertSuccess } from '../../../../utils/logger'

function getEmptyTranslation() {
	return {
		type: 'Routine',
		langIsoCode: null,
		entityId: null,
		content: {
			title: '',
			description: ''
		}
	};
}

export default function TranslateRoutinesPage() {
	const [routine, setRoutine] = useState(null)
	const [translation, setTranslation] = useState(getEmptyTranslation())
	const [translationId, setTranslationId] = useState(null)
	const routineId = useParams().id;
	const currentLanguage = useParams().lang
	const history = useHistory();
	const languages = useSelector((store) => store.authentication?.languages, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!routineId || !currentLanguage) {
			disableLoadingData();
			alertError({ error: null, customMessage: 'Could not get routine translation.' });
			history.push('/training/routines');
			return;
		}
		getTranslationsByEntityId(routineId, currentLanguage)
			.then((res) => {
				if (res.status === 200) {
					getRoutineById(routineId)
						.then(response => {
							if (response.status === 200) {
								const { title, description } = response.data
								setRoutine({ title, description })
								if (res.data?.length) {
									setTranslation(res.data[0]);
									setTranslationId(res.data[0]._id)
								}
								disableLoadingData();
							}
						})
						.catch((error) => {
							alertError({ error: error, customMessage: 'Could not get routine.' });
							history.push('/training/routine-translations/' + routineId)
						});
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get routine translations.' });
				history.push('/training/routine-translations/' + routineId)
			});		
		// eslint-disable-next-line
	}, [routineId, disableLoadingData, history]);

	const handleChange = (element) => (event) => {
		const newContent = translation.content
		newContent[element] = event.target.value
		setTranslation({ ...translation, content: newContent });
	};

	const handleChangeEditor = (element, value) => {
		const newContent = translation.content
		newContent[element] = value
		setTranslation({ ...translation, content: newContent });
	}

	function saveTranslation() {
		if (!translationId) {
			postTranslation(({ ...translation, entityId: routineId, langIsoCode: currentLanguage }))
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Translation successfully created.',
						});
						history.push('/training/routine-translations/' + routineId)
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save translation.' });
				});
		} else {
			updateTranslation(translationId, translation)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/training/routine-translations/' + routineId)
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}
	
	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title={'Translate routine ' + routine?.title}/>
					<CardBody>
						<AppBar position="static" color="default" key="appbar">
							<Tabs
								scrollButtons="auto"
								key="tabs"
							>
								<Tab key={currentLanguage} label={languages.find(item => item.isocode === currentLanguage)?.fullName} />
							</Tabs>
						</AppBar>
						<br/>
						<h5>Title</h5>
          				<p>{routine?.title}</p>
						<TextField
							id={`title`}
							label='Title translation'
							value={translation.content?.title || ''}
							onChange={handleChange('title')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<br/>
						<br/>
						<h5>Description</h5>
        				<div dangerouslySetInnerHTML = {{ __html: routine?.description }}></div>
						<br/>
						<Editor
							body={translation.content?.description || ''}
							setBody={new_body => handleChangeEditor('description', new_body)}
							className='max-height'
							placeholder={'Enter routine description translation here...'}
							label="Description translation"
						/>
						<br/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/training/routine-translations/' + routineId)}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveTranslation()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save translation
					</Button>
				</div>
			</>
		);
}
