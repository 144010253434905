import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import React from "react";
import LessonContent from "../../modules/ContentManager/lessons/LessonContent";
import { getNonEmpty } from "../../../utils/helpers";

const ViewLessonDialog = (props) => {
	const { open, setOpen, lesson } = props;

	return (
		<Dialog
			fullWidth={true}
			maxWidth="xl"
			open={open}
			onClose={() => setOpen(null)}
			aria-labelledby="view-lesson-dialog"
		>
			<DialogTitle id="view-lesson-dialog">
				{getNonEmpty(lesson?.title) || "Lesson"}
			</DialogTitle>
			<DialogContent>
				<LessonContent lesson={lesson} />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="default"
					onClick={() => setOpen(null)}
				>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default ViewLessonDialog;
