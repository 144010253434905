import { authClient, API } from '../index';

// Get all sendings
export const getSendings = () => {
	return authClient().get(`${API}/sending`);
};

// Get sending by id
export const getSendingById = (id) => {
	return authClient().get(`${API}/sending/${id}`);
};

// Create sending
export const postSending = (sending) => {
	return authClient().post(`${API}/sending`, sending);
};

// Create sending
export const searchReceivers = (sending) => {
	return authClient().post(`${API}/sending/pre-sending`, sending);
};
