import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, TextField } from '@material-ui/core'
import TableDialog from '../dialogs/TableDialog'
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { buttonsStyle } from '../tables/table';
import EditIcon from '@material-ui/icons/Edit';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons'
import EditRoutineDialog from '../../modules/ContentManager/routines/EditRoutineDialog'
import ViewRoutineDialog from '../../modules/ContentManager/routines/ViewRoutineDialog';
import { shallowEqual, useSelector } from 'react-redux'
import { alertError } from '../../../utils/logger';

const getEmptySession = () => {
    return {
      title: '',
      startDate: new Date(),
      routines: [],
      status: 'pending'
    }
}

const NewSessionDialog = (props) => {
  const { open, setOpen, data, onConfirm, initialData } = props
	const [session, setSession] = useState(getEmptySession())
  const [openTableDialog, setOpenTableDialog] = useState(false)
	const [openEditRoutineDialog, setOpenEditRoutineDialog] = useState(null)
	const [openViewRoutineDialog, setOpenViewRoutineDialog] = useState(null)
  const user = useSelector((store) => store.authentication?.user, shallowEqual)

	const handleChange = (element) => (event) => {
		setSession({ ...session, [element]: event.target.value });
	};

  useEffect(() => {
    if (initialData && initialData.data) setSession(initialData.data)
  }, [initialData])

	const handleMoveRoutine = (index, newIndex) => {
		const aux = session.routines[index]
		if (!aux) return alertError({ error: null, customMessage: 'An unexpected error has ocurred trying to move the routines, please try again.'})
		let _routines = session.routines
		_routines.splice(index, 1, _routines[newIndex])
		_routines.splice(newIndex, 1, aux)
		setSession({ ...session, routines: _routines })
	}

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="new-session-dialog">
      <DialogTitle id="new-session-dialog">Edit special routine session</DialogTitle>
      <DialogContent>
        <TextField
          id={`title`}
          label='Title'
          value={session.title}
          onChange={handleChange('title')}
          InputLabelProps={{
            shrink: true,
          }}
          margin='normal'
          variant='outlined'
          required
        />
        <br/>
        <br/>
        <h5>Routines</h5>
			  <br/>
        {session.routines.map((item, index) => {
          const routine = data?.find(element => element._id === item.id)
          return <>
            <span>{routine?.title}{(item.series || item.time || item.nTimes) && 
					<strong>{` → ${item.series ? 'Series repetitions' : (item.nTimes ? 'Number of times' : 'Minutes')}: `}</strong>}
					{item.series ? item.series.join(', ') : (item.nTimes ? item.nTimes : item.time)}</span>
            {((user.role.includes('trainer') && (routine.trainer === user._id || !routine.trainer)) || user.role.includes('admin')) && <Tooltip title={'View'}>
              <Button
                size="small"
                onClick={() => setOpenViewRoutineDialog(index)}
                style={buttonsStyle}>
                <VisibilityIcon/>
              </Button>
            </Tooltip>}
            {(user.role.includes('trainer') || user.role.includes('admin')) && <>
              <Tooltip title={`Edit ${routine.model === 'series' ? 'series repetitions' : (routine.model === 'nTimes'  ? 'number of times' : 'minutes')}`}>
                <Button
                  size="small"
                  style={buttonsStyle}
                  onClick={() => {
                    setOpenEditRoutineDialog(index)
                  }}>
                  <EditIcon/>
                </Button>
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  size="small"
                  style={buttonsStyle}
                  onClick={() => {
                    let _routines = [...session.routines]
                    _routines.splice(index, 1)
                    setSession({...session, routines: _routines })
                  }}>
                  <DeleteIcon/>
                </Button>
              </Tooltip>
              <Tooltip title="Move up">
                <Button
                  size="small"
                  style={buttonsStyle}
                  disabled={index === 0}
                  onClick={() => handleMoveRoutine(index, index - 1)}>
                  <ArrowUpward/>
                </Button>
              </Tooltip>
              <Tooltip title="Move down">
                <Button
                  size="small"
                  disabled={index >= session.routines.length - 1}
                  style={buttonsStyle}
                  onClick={() => handleMoveRoutine(index, index + 1)}>
                  <ArrowDownward/>
                </Button>
              </Tooltip>
            </>
            }	
            <br />	
            <EditRoutineDialog
              title={routine?.title}
              open={openEditRoutineDialog === index}
              setOpen={setOpenEditRoutineDialog}
              routine={routine}
              initialData={{ series: item.series, time: item.time, nTimes: data.nTimes }}
              onConfirm = {(data) => {
                setOpenEditRoutineDialog(null)
                let _routines = [...session.routines]
                _routines[index] = { ..._routines[index], series: data.series, time: data.time, nTimes: data.nTimes }
                setSession({...session, routines: _routines})
              }}
            />	
            <ViewRoutineDialog
              open={openViewRoutineDialog === index}
              setOpen={setOpenViewRoutineDialog}
              routine={routine}
            />				
          </>
        })}
        <br />
        {(user.role.includes('trainer') || user.role.includes('admin')) && <Button
          onClick={() => setOpenTableDialog(true)}
          variant='outlined'
          color='primary'>
          Add routine
        </Button>}
        <TableDialog
          open={openTableDialog}
          setOpen={setOpenTableDialog}
          routinesTable={true}
          data={(user.role.includes('trainer') ? data?.filter(item => item.trainer === user._id || item.trainer === null) : data)?.filter(item => item.active)}
          onSelectRow = {(id) => {
            let _routines = [...session.routines]
            if (_routines) _routines.push({ id: id, series: null, time: null, nTimes: null })
            else _routines = [{ id: id, series: null, time: null, nTimes: null }]
            setSession({...session, routines: _routines})
            setOpenTableDialog(null)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (!session.title?.length || !session.routines?.length) return alertError({ error: null, customMessage: 'Please fill all required fields.'})
              setOpen(false)
              onConfirm({ session, index: initialData?.index })
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setOpen(false)
            }}
          >
            Cancel
          </Button>
      </DialogActions>
    </Dialog>
  )
}
export default NewSessionDialog
