import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	Tooltip,
	FormControlLabel,
	Checkbox
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteAdvertisement,
	getAdvertisementById,
	postAdvertisement,
	updateAdvertisement,
} from '../../../../api/advertisement';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { shallowEqual, useSelector } from 'react-redux'
import { Visibility } from '@material-ui/icons'
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import { SERVER_URL } from "../../../../api/index"
import { buttonsStyle } from '../../../components/tables/table'
import LinkIcon from '@material-ui/icons/Link';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptyAdvertisement() {
	return {
		title: '',
		linkURL: '',
		imageURL: '',
		startDate: null,
		endDate: null,
		maxViews: null, 
		maxClicks: null,
		active: true
	};
}

export default function EditAdvertisementsPage() {
	const [advertisement, setAdvertisement] = useState(getEmptyAdvertisement());
	const [selectedImage, setSelectedImage] = useState(null)
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false)
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const advertisementId = useParams().id;
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!advertisementId) {
			disableLoadingData();
			return;
		}
		getAdvertisementById(advertisementId)
			.then((res) => {
				if (res.status === 200) {
					setAdvertisement(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get advertisement.' });
				history.push('/advertisements');
			});
	}, [advertisementId, disableLoadingData, history]);

	function saveAdvertisement() {
		if (!advertisementId) {
			postAdvertisement(advertisement, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Advertisement successfully created.',
						});
						history.push('/advertisements');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save advertisement.' });
				});
		} else {
			updateAdvertisement(advertisementId, advertisement, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/advertisements');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		if (event.target.value === ' ') return
		setAdvertisement({ ...advertisement, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit advertisement'></CardHeader>
					<CardBody>
						<TextField
							id={`title`}
							label='Title'
							value={advertisement.title}
							onChange={handleChange('title')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							required
						/>
						<div className='row mt-5' style={{ margin: "auto" }}>
							<TextField
								id={`linkURL`}
								label='Link URL'
								className='col-10 col-lg-11'
								value={advertisement.linkURL}
								onChange={handleChange('linkURL')}
								InputLabelProps={{
									shrink: true,
								}}
								margin='normal'
								variant='outlined'
								required
							/>
							<Tooltip title="Open link in new tab">
								<Button onClick={() => {
									if (!advertisement.linkURL?.length) return alertError({ error: null, customMessage: 'Please enter a valid link first.' })
									window.open(advertisement.linkURL, '_blank').focus();
								}}>
									<LinkIcon/>
								</Button>
							</Tooltip>
						</div>
						<TextField
							id="startDate"
							label="Start date"
							type="date"
							value={advertisement.startDate && advertisement.startDate.includes('T') ? advertisement.startDate.split('T')[0] : advertisement.startDate}
							onChange={handleChange('startDate')}
							InputLabelProps={{
								shrink: true
							}}
							margin="normal"
							variant="outlined"
						/>
		            	<TextField
							id="endDate"
							label="End date"
							type="date"
							value={advertisement.endDate && advertisement.endDate.includes('T') ? advertisement.endDate.split('T')[0] : advertisement.endDate}
							onChange={handleChange('endDate')}
							InputLabelProps={{
								shrink: true
							}}
							margin="normal"
							variant="outlined"
						/>
						<TextField
							id={`maxViews`}
							label='Max views'
							value={advertisement.maxViews}
							onChange={handleChange('maxViews')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							type="number"
						/>
						<TextField
							id={`maxClicks`}
							label='Max clicks'
							value={advertisement.maxClicks}
							onChange={handleChange('maxClicks')}
							InputLabelProps={{
								shrink: true,
							}}
							margin='normal'
							variant='outlined'
							type="number"
						/>
						<br/>
						<br/>
						<label htmlFor={"upload-image"}>
							<input
								style={{ display: 'none' }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={'image/*'}
								onChange={(e) => {
									setSelectedImage(e.target.files[0])
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color="secondary"
								component="span"
								variant="outlined">
								{(selectedImage || advertisement.imageURL !== '') ? 'Change image' : 'Upload image'}
							</Button>
						</label>
						{(selectedImage || advertisement.imageURL !== '') &&
							<>
								<Tooltip title={'Preview image'}>
									<Button
										size="small"
										onClick={() => setOpenPreviewDialog(true)}
										style={{...buttonsStyle, marginRight: '15px' }}>
										<Visibility/>
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview image'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={selectedImage ? URL.createObjectURL(selectedImage) : `${SERVER_URL}/${advertisement.imageURL}`}
								/>
								<span>
									{selectedImage ? selectedImage?.name : (advertisement.imageURL !== '' ? advertisement.imageURL.split(/-(.*)/s)[1] : '')}
								</span>
							</>
							}
						<br />
						<br />
						<FormControlLabel
							control={<Checkbox checked={advertisement.active} onChange={() => setAdvertisement({ ...advertisement, active: !advertisement.active })} name="checkActive" />}
							label="Active"
						/>
						<br />
						<br />
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/advertisements')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveAdvertisement()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save advertisement
					</Button>
					{advertisementId && user?.role.includes('admin') && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete advertisement
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this advertisement?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteAdvertisement(advertisementId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'Advertisement deleted successfully',
												});
												history.push('/advertisements');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete advertisement.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
