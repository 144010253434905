import { API, authClient } from '../index';

// Get all nutritionist certifications
export const getNutritionistCertifications = () => {
	return authClient().get(`${API}/nutritionist-certification?getDisabled=true`);
};

// Get nutritionist certification by id
export const getNutritionistCertificationById = (id) => {
	return authClient().get(`${API}/nutritionist-certification/${id}`);
};

// Delete nutritionist certification
export const deleteNutritionistCertification = (id) => {
	return authClient().delete(`${API}/nutritionist-certification/${id}`);
};

// Create nutritionist certification 
export const postNutritionistCertification = async (nutritionistCertification) => {
	return authClient().post(`${API}/nutritionist-certification`, nutritionistCertification);
};

// Update nutritionist certification
export const updateNutritionistCertification = async (id, nutritionistCertification) => {
	return authClient().put(`${API}/nutritionist-certification/${id}`, nutritionistCertification);
};

// Change status nutritionist certification
export const changeStatusNutritionistCertification = async (id, active) => {
	return authClient().put(`${API}/nutritionist-certification/change-status/${id}`, { active });
};