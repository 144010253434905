import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle,
	badgeFormatter
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getRoutinePlans, deleteRoutinePlan, changeStatusRoutinePlan } from '../../../../api/routinePlan';
import { Button, Tooltip, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import { getUsers } from '../../../../api/user'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import WarningIcon from '@material-ui/icons/Warning';
import VisibilityIcon from "@material-ui/icons/Visibility"
import { shallowEqual, useSelector } from 'react-redux';
import LanguageIcon from "@material-ui/icons/Language"
import FiltersCard from '../../../components/filters/Filter';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getData(routinePlans) {
	let data = [];
	for (let i = 0; i < routinePlans.length; ++i) {
		const elem = {};
		elem.premium = routinePlans[i].premium;
		elem.title = routinePlans[i].title;
		elem.validation = {validated: routinePlans[i].validated, valmess: routinePlans[i].validationMessage};
		elem.level = routinePlans[i].level;
		elem.description = routinePlans[i].description;
		elem.createdBy = !routinePlans[i].trainer ? null : (routinePlans[i].trainer.fullName || '----')
		elem.createdAt = routinePlans[i].createdAt;
		elem.id = routinePlans[i]._id;
		elem.active = routinePlans[i].active;
		elem.trainer = routinePlans[i].trainer;
		elem.price = routinePlans[i].price;
		data = data.concat(elem);
	}
	return data;
}

const initialFilters = {
	active: false,
	createdBy: [],
	standard: 'All',
	level: 'All',
	premium: false,
	validation: false,
}

export default function RoutinePlansPage() {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [collapsed, setCollapsed] = useState(true)
	const [users, setUsers] = useState([])
	const [routinePlanId, setRoutinePlanId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [filterOptions, setFilterOptions] = useState(initialFilters)
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item._id === cell)
		const canEdit = user?.role.includes('admin') || elem?.trainer === user?._id
		return (
			<>
				<Tooltip title={canEdit ? 'Edit' : 'View'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/training/edit-plan-routine/' + cell)}>
						{canEdit ? <EditIcon /> : <VisibilityIcon/>}
					</Button>
				</Tooltip>
				{canEdit && <>
					<Tooltip title="View translations">
						<Button
							style={buttonsStyle} size="small"
							onClick={() => history.push('/training/plan-routine-translations/' + cell)}>
							<LanguageIcon/>
						</Button>
					</Tooltip>
					<Tooltip title={elem?.active ? "Disable" : "Enable"}>
						<Button
							style={buttonsStyle} size="small"
							onClick={() => {
								setRoutinePlanId(elem)
								setOpenConfirmDialog(1)
							}}>
							{elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon style={{ color: 'red' }}/>}
						</Button>
					</Tooltip>
				</>}
				{user?.role.includes('admin') && <Tooltip title='Delete'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setRoutinePlanId(cell);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>}
			</>
		);
	}

	function validationButton (cell) {
		return cell.validated ? (
			null
		) : (
			<>
				<Tooltip title={`${cell.valmess}`}>
					<Button
						style={buttonsStyle}
						size='small'
					>
						<WarningIcon/>
					</Button>
				</Tooltip>
			</>
		)
	}

	const columns = [
		{ dataField: 'premium', text: '', sort: true, formatter: (cell) => cell ? <img src={toAbsoluteUrl('/media/premium.png')} alt="premium" style={{ width: '25px', height: '25px' }} /> : null },
		{ dataField: 'title', text: 'Title', sort: true },
		{ dataField: 'validation', text: 'Valid', sort: true, sortFunc: (a, b, order, dataField, rowA, rowB) =>{if(order==='asc') return b.validated-a.validated;  return a.validated-b.validated}, formatter: validationButton},
		{
			dataField: "price",
			text: "Price",
			formatter: (cell) => cell? (cell+' €') : "N/A",
			align: "right",
			sort: true
		},
		{ dataField: 'level', text: 'Level', sort: true },
		{ dataField: 'createdBy', text: 'Created by', sort: true, formatter: badgeFormatter },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getUsers().then(resp => {
			if (resp.status === 200) {
				setUsers(resp.data.filter(item => item.role.includes('trainer')))
				setRefresh(false);
			}
		})
		.catch((error) => {
			alertError({ error: error, customMessage: 'Could not get users.' });
		});
		getRoutinePlans()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setFilteredData(res.data)
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get routine plans.' });
			});
	}, [refresh]);

	const handleSearch = async () => {
		if (!data.length) return
		setFilteredData(data.filter(item => {
		  	let filter = true
			if (filterOptions.createdBy && filterOptions.createdBy.length) filter = filter && filterOptions.createdBy.includes(item.createdBy)
			if (filterOptions.level && filterOptions.level !== 'All') filter = filter && filterOptions.level.toLowerCase() === item.level
			if (filterOptions.standard && filterOptions.standard !== 'All') {
				let condition = true
				if (filterOptions.standard === 'Custom') condition = item.trainer !== null
				else if (filterOptions.standard === 'Standard') condition = !item.trainer
				filter = filter && condition
			}
			if (filterOptions.premium) filter = filter && item.premium
			if (filterOptions.active) filter = filter && item.active
			if (filter) return item
			return false
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions(initialFilters)
		setRefresh(true)
	}

	const handleChange = (element) => (event) => {
		setFilterOptions({ ...filterOptions, [element]: event.target.value });
	}; 

	const renderFiltersContent = () => {
		return <>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Level</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.level || ''}
					onChange={handleChange('level')}
					MenuProps={MenuProps}
				>
				{['All', 'Beginner', 'Intermediate', 'Advanced']?.map((option) => (
					<MenuItem key={option} value={option}>{option}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Standard</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.standard || ''}
					onChange={handleChange('standard')}
					MenuProps={MenuProps}
				>
				{['All', 'Standard', 'Custom']?.map((option) => (
					<MenuItem key={option} value={option}>{option}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Created by</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.createdBy || []}
					multiple
					onChange={handleChange('createdBy')}
					MenuProps={MenuProps}
				>
				{users?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{option.fullName}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControlLabel
				control={<Switch checked={filterOptions.premium} onChange={() => setFilterOptions({ ...filterOptions, premium: !filterOptions.premium })} name="checkPremium" />}
				label={filterOptions.premium ? "Only premium" : "All premium/free"}
			/>
			<br/>
			<FormControlLabel
				control={<Switch checked={filterOptions.active} onChange={() => setFilterOptions({ ...filterOptions, active: !filterOptions.active })} name="checkActive" />}
				label={filterOptions.active ? "Only active" : "All active/inactive"}
			/>
		</>
	}


	return (
		<>
			<Card>
				<CardHeader title='Routine plans list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/training/edit-plan-routine')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard 
						filtersContent={renderFiltersContent}
						collapsed={collapsed} 
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>

					<Table data={getData(filteredData)} columns={columns} />

					<ConfirmDialog
						title={`Are you sure you want to ${routinePlanId?.active ? 'disable' : 'enable'} this routine plan?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusRoutinePlan(routinePlanId._id, !routinePlanId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({ title: `${routinePlanId?.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Routine plan ${routinePlanId?.active ? 'disabled' : 'enabled'} successfully` })
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({ error: error, customMessage: `Could not ${routinePlanId?.active ? 'disable' : 'enable'} routine plan.` })
								});
						}}
					/>
					<ConfirmDialog
						title={'Are you sure you want to remove this routine plan?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteRoutinePlan(routinePlanId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Routine plan removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove routine plan.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
