import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import {
	ArrowDownward,
	ArrowUpward,
	Delete,
	Edit,
	ToggleOff,
	ToggleOn,
	Visibility,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteSubscription,
	getSubscriptionById,
	postSubscription,
	updateSubscription,
} from "../../../../api/subscription";
import { checkIsEmpty, getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import EditSubscriptionBenefitDialog from "../../../components/dialogs/EditSubscriptionBenefitDialog";
import EditSubscriptionFaqDialog from "../../../components/dialogs/EditSubscriptionFaqDialog";
import EditSubscriptionRatingDialog from "../../../components/dialogs/EditSubscriptionRatingDialog";
import Editor from "../../../components/editor/Editor";
import Table, {
	booleanFormatter,
	buttonsStyle,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptySubscription() {
	return {
		title: {},
		description: {},
		advantages: {},
		monthlyCost: 0,
		annualDiscount: 0,
		benefitComparison: [],
		faqs: [],
		ratings: [],
		active: true,
	};
}

function getBenefitComparisonData(benefitComparison) {
	let data = [];

	for (let i = 0; i < benefitComparison?.length; ++i) {
		const elem = {};
		const benefit = benefitComparison[i];

		elem.title = getNonEmpty(benefit?.title);
		elem.basic = benefit?.basic;
		elem.pro = benefit?.pro;
		elem.imageURL = benefit?.selectedImage
			? URL.createObjectURL(benefit?.selectedImage)
			: `${SERVER_URL}/${benefit?.imageURL}`;
		elem.active = benefit?.active;
		elem.id = benefit?.id;

		data.push(elem);
	}

	return data;
}

function getFaqData(faqs) {
	let data = [];

	for (let i = 0; i < faqs?.length; ++i) {
		const elem = {};
		const faq = faqs[i];

		elem.title = getNonEmpty(faq?.title);
		elem.active = faq?.active;
		elem.id = faq?.id;

		data.push(elem);
	}

	return data;
}

function getRatingData(ratings) {
	let data = [];

	for (let i = 0; i < ratings?.length; ++i) {
		const elem = {};
		const rating = ratings[i];

		elem.username = getNonEmpty(rating?.username);
		elem.active = rating?.active;
		elem.id = rating?.id;

		data.push(elem);
	}

	return data;
}

export default function EditSubscriptionsPage() {
	const [subscription, setSubscription] = useState(getEmptySubscription());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const [openEditBenefitDialog, setOpenEditBenefitDialog] = useState(false);
	const [openViewBenefitDialog, setOpenViewBenefitDialog] = useState(false);
	const [selectedBenefit, setSelectedBenefit] = useState(null);

	const [openEditFaqDialog, setOpenEditFaqDialog] = useState(false);
	const [openViewFaqDialog, setOpenViewFaqDialog] = useState(false);
	const [selectedFaq, setSelectedFaq] = useState(null);

	const [openEditRatingDialog, setOpenEditRatingDialog] = useState(false);
	const [openViewRatingDialog, setOpenViewRatingDialog] = useState(false);
	const [selectedRating, setSelectedRating] = useState(null);

	const [refresh, setRefresh] = useState(false);

	const subscriptionId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!subscriptionId) {
			disableLoadingData();
			return;
		}
		getSubscriptionById(subscriptionId)
			.then((res) => {
				if (res.status === 200) {
					let subscriptionData = res.data;

					if (subscriptionData?.benefitComparison?.length > 0) {
						for (
							let i = 0;
							i < subscriptionData?.benefitComparison?.length;
							++i
						) {
							subscriptionData.benefitComparison[i].id = i;
						}
					}

					if (subscriptionData?.faqs?.length > 0) {
						for (
							let i = 0;
							i < subscriptionData?.faqs?.length;
							++i
						) {
							subscriptionData.faqs[i].id = i;
						}
					}

					if (subscriptionData?.ratings?.length > 0) {
						for (
							let i = 0;
							i < subscriptionData?.ratings?.length;
							++i
						) {
							subscriptionData.ratings[i].id = i;
						}
					}

					setSubscription(subscriptionData);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get subscription.",
				});
				history.push("/subscriptions");
			});
	}, [subscriptionId, disableLoadingData, history]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	function saveSubscription() {
		let saveSubscription = subscription;

		if (saveSubscription?.benefitComparison?.length > 0) {
			saveSubscription.benefitComparison.forEach((benefitComparison) => {
				delete benefitComparison.id;
			});
		}
		if (saveSubscription?.faqs?.length > 0) {
			saveSubscription.faqs.forEach((faq) => {
				delete faq.id;
			});
		}
		if (saveSubscription?.ratings?.length > 0) {
			saveSubscription.ratings.forEach((rating) => {
				delete rating.id;
			});
		}

		if (
			checkIsEmpty(saveSubscription.title) ||
			checkIsEmpty(saveSubscription.description) ||
			checkIsEmpty(saveSubscription.advantages)
		)
			return alertError({
				error: null,
				customMessage:
					"The title and description are required in at least one of the languages.",
			});
		if (!subscriptionId) {
			postSubscription(saveSubscription)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Subscription successfully created.",
						});
						history.push("/subscriptions");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save subscription.",
					});
				});
		} else {
			updateSubscription(subscriptionId, saveSubscription)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/subscriptions");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!subscription[element]) subscription[element] = {};
			let newText = subscription[element];
			newText[lang] = event.target.value;
			setSubscription({ ...subscription, [element]: newText });
		} else
			setSubscription({ ...subscription, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value === " ") return;
		if (lang) {
			if (!subscription[element]) subscription[element] = {};
			let newText = subscription[element];
			newText[lang] = value;
			setSubscription({ ...subscription, [element]: newText });
		} else setSubscription({ ...subscription, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Title"
					value={
						(subscription.title && subscription.title[lang]) || ""
					}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<Editor
					body={
						(subscription.description &&
							subscription.description[lang]) ||
						""
					}
					setBody={(new_body) =>
						handleChangeEditor("description", new_body, lang)
					}
					className="max-height"
					placeholder={
						"Enter subscription general description here..."
					}
					label="General description *"
				/>
				<br />
				<Editor
					body={
						(subscription.advantages &&
							subscription.advantages[lang]) ||
						""
					}
					setBody={(new_body) =>
						handleChangeEditor("advantages", new_body, lang)
					}
					className="max-height"
					placeholder={
						"Enter subscription advantages description here..."
					}
					label="Advantages description *"
				/>
			</>
		);
	};

	function imageFormatter(absoluteImageURL) {
		return absoluteImageURL && absoluteImageURL !== "" ? (
			<img
				src={absoluteImageURL}
				alt="image"
				style={{ width: "50px", height: "50px" }}
				onClick={() => {}}
			/>
		) : (
			<div />
		);
	}

	const benefitComparisonColumns = [
		{
			dataField: "imageURL",
			text: "",
			formatter: imageFormatter,
		},
		{ dataField: "title", text: "Title" },
		{
			dataField: "basic",
			text: "Basic",
			headerAlign: "center",
			align: "center",
			formatter: booleanFormatter,
		},
		{
			dataField: "pro",
			text: "Pro",
			headerAlign: "center",
			align: "center",
			formatter: booleanFormatter,
		},
		{
			dataField: "id",
			text: "",
			formatter: benefitComparisonButtonFormatter,
		},
	];

	const faqsColumns = [
		{ dataField: "title", text: "Title" },
		{
			dataField: "id",
			text: "",
			formatter: faqButtonFormatter,
		},
	];

	const ratingsColumns = [
		{ dataField: "username", text: "User name" },
		{
			dataField: "id",
			text: "",
			formatter: ratingButtonFormatter,
		},
	];

	const handleMoveBenefitUnit = (element, index, newIndex) => {
		const aux = subscription[element][index];
		if (!aux)
			return alertError({
				error: null,
				customMessage:
					"An unexpected error has ocurred trying to move the elements, please try again.",
			});
		let _elements = subscription[element];
		_elements.splice(index, 1, _elements[newIndex]);
		_elements.splice(newIndex, 1, aux);
		setRefresh(true);
		setSubscription({
			...subscription,
			[element]: _elements,
		});
	};

	function benefitComparisonButtonFormatter(cell) {
		const index = subscription?.benefitComparison?.findIndex(
			(x) => x.id === cell
		);
		const benefitComparison = subscription?.benefitComparison?.find(
			(x) => x.id === cell
		);

		return (
			<>
				<Tooltip title="View">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenViewBenefitDialog(true);
							setSelectedBenefit(benefitComparison);
						}}
					>
						<Visibility />
					</Button>
				</Tooltip>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditBenefitDialog(true);
							setSelectedBenefit(benefitComparison);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() =>
							handleMoveBenefitUnit(
								"benefitComparison",
								index,
								index - 1
							)
						}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							index >= subscription?.benefitComparison?.length - 1
						}
						style={buttonsStyle}
						onClick={() =>
							handleMoveBenefitUnit(
								"benefitComparison",
								index,
								index + 1
							)
						}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip
					title={benefitComparison?.active ? "Disable" : "Enable"}
				>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let benefitComparison = [
								...subscription.benefitComparison,
							];

							benefitComparison[
								index
							].active = !benefitComparison[index].active;

							setRefresh(true);
							setSubscription({
								...subscription,
								benefitComparison: benefitComparison,
							});
						}}
					>
						{benefitComparison?.active ? (
							<ToggleOff />
						) : (
							<ToggleOn style={{ color: "red" }} />
						)}
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let _benefitComparison = [
								...subscription.benefitComparison,
							];
							_benefitComparison.splice(index, 1);
							setSubscription({
								...subscription,
								benefitComparison: _benefitComparison,
							});
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	function faqButtonFormatter(cell) {
		const index = subscription?.faqs?.findIndex((x) => x.id === cell);
		const faq = subscription?.faqs?.find((x) => x.id === cell);

		return (
			<>
				<Tooltip title="View">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenViewFaqDialog(true);
							setSelectedFaq(faq);
						}}
					>
						<Visibility />
					</Button>
				</Tooltip>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditFaqDialog(true);
							setSelectedFaq(faq);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() =>
							handleMoveBenefitUnit("faqs", index, index - 1)
						}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={index >= subscription?.faqs?.length - 1}
						style={buttonsStyle}
						onClick={() =>
							handleMoveBenefitUnit("faqs", index, index + 1)
						}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title={faq?.active ? "Disable" : "Enable"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let faqs = [...subscription.faqs];

							faqs[index].active = !faqs[index].active;

							setRefresh(true);
							setSubscription({
								...subscription,
								faqs: faqs,
							});
						}}
					>
						{faq?.active ? (
							<ToggleOff />
						) : (
							<ToggleOn style={{ color: "red" }} />
						)}
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let _faqs = [...subscription.faqs];
							_faqs.splice(index, 1);
							setSubscription({
								...subscription,
								faqs: _faqs,
							});
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	function ratingButtonFormatter(cell) {
		const index = subscription?.ratings?.findIndex((x) => x.id === cell);
		const rating = subscription?.ratings?.find((x) => x.id === cell);

		return (
			<>
				<Tooltip title="View">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenViewRatingDialog(true);
							setSelectedRating(rating);
						}}
					>
						<Visibility />
					</Button>
				</Tooltip>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenEditRatingDialog(true);
							setSelectedRating(rating);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() =>
							handleMoveBenefitUnit("ratings", index, index - 1)
						}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={index >= subscription?.ratings?.length - 1}
						style={buttonsStyle}
						onClick={() =>
							handleMoveBenefitUnit("ratings", index, index + 1)
						}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title={rating?.active ? "Disable" : "Enable"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let ratings = [...subscription.ratings];

							ratings[index].active = !ratings[index].active;

							setRefresh(true);
							setSubscription({
								...subscription,
								ratings: ratings,
							});
						}}
					>
						{rating?.active ? (
							<ToggleOff />
						) : (
							<ToggleOn style={{ color: "red" }} />
						)}
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let _ratings = [...subscription.ratings];
							_ratings.splice(index, 1);
							setSubscription({
								...subscription,
								ratings: _ratings,
							});
							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit subscription"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<br />
						<TextField
							id={`monthlyCost`}
							label="Monthly cost (€)"
							value={subscription.monthlyCost}
							onChange={handleChange("monthlyCost")}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								inputProps: {
									min: 0,
								},
							}}
							margin="normal"
							variant="outlined"
							type="number"
							required
						/>
						<TextField
							id={`annualDiscount`}
							label="Annual discount (%)"
							value={subscription.annualDiscount}
							onChange={handleChange("annualDiscount")}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								inputProps: {
									min: 0,
								},
							}}
							margin="normal"
							variant="outlined"
							type="number"
							required
						/>
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={subscription.active}
									onChange={() =>
										setSubscription({
											...subscription,
											active: !subscription.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
					<CardHeader title="Benefit Comparison">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenEditBenefitDialog(true);
									setSelectedBenefit(null);
								}}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh &&
							subscription?.benefitComparison?.length > 0 && (
								<Table
									columns={benefitComparisonColumns}
									data={getBenefitComparisonData(
										subscription?.benefitComparison
									)}
								/>
							)}
					</CardBody>
					<CardHeader title="FAQs">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenEditFaqDialog(true);
									setSelectedFaq(null);
								}}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh && subscription?.faqs?.length > 0 && (
							<Table
								columns={faqsColumns}
								data={getFaqData(subscription?.faqs)}
							/>
						)}
					</CardBody>
					<CardHeader title="RATINGs">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenEditRatingDialog(true);
									setSelectedRating(null);
								}}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh && subscription?.ratings?.length > 0 && (
							<Table
								columns={ratingsColumns}
								data={getRatingData(subscription?.ratings)}
							/>
						)}
					</CardBody>
					<EditSubscriptionBenefitDialog
						open={openEditBenefitDialog || openViewBenefitDialog}
						setOpen={
							openViewBenefitDialog
								? setOpenViewBenefitDialog
								: setOpenEditBenefitDialog
						}
						data={selectedBenefit}
						readOnly={openViewBenefitDialog}
						onSave={(benefit) => {
							let benefitComparison = [
								...subscription.benefitComparison,
							];

							const index = benefitComparison.findIndex(
								(x) => x.id === benefit.id
							);

							if (index !== -1) {
								benefitComparison[index] = {
									...benefit,
								};
							} else {
								benefitComparison.push({
									...benefit,
									id: benefitComparison.length,
								});
							}

							setRefresh(true);
							setSubscription({
								...subscription,
								benefitComparison: benefitComparison,
							});
						}}
					/>
					<EditSubscriptionFaqDialog
						open={openEditFaqDialog || openViewFaqDialog}
						setOpen={
							openViewFaqDialog
								? setOpenViewFaqDialog
								: setOpenEditFaqDialog
						}
						data={selectedFaq}
						readOnly={openViewFaqDialog}
						onSave={(faq) => {
							let faqs = [...subscription.faqs];

							const index = faqs.findIndex(
								(x) => x.id === faq.id
							);

							if (index !== -1) {
								faqs[index] = {
									...faq,
								};
							} else {
								faqs.push({
									...faq,
									id: faqs.length,
								});
							}

							setRefresh(true);
							setSubscription({
								...subscription,
								faqs: faqs,
							});
						}}
					/>
					<EditSubscriptionRatingDialog
						open={openEditRatingDialog || openViewRatingDialog}
						setOpen={
							openViewRatingDialog
								? setOpenViewRatingDialog
								: setOpenEditRatingDialog
						}
						data={selectedRating}
						readOnly={openViewRatingDialog}
						onSave={(rating) => {
							let ratings = [...subscription.ratings];

							const index = ratings.findIndex(
								(x) => x.id === rating.id
							);

							if (index !== -1) {
								ratings[index] = {
									...rating,
								};
							} else {
								ratings.push({
									...rating,
									id: ratings.length,
								});
							}

							setRefresh(true);
							setSubscription({
								...subscription,
								ratings: ratings,
							});
						}}
					/>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/subscriptions")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveSubscription()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save subscription
					</Button>
					{subscriptionId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete subscription
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this subscription?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteSubscription(subscriptionId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Subscription deleted successfully",
												});
												history.push("/subscriptions");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete subscription.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
