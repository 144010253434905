import { Button, Tooltip } from "@material-ui/core";
import { Edit, ToggleOff, ToggleOn } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplyIcon from "@material-ui/icons/Reply";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { getCourseById } from "../../../../api/course";
import {
	changeStatusThread,
	deleteThread,
	getThreadByCourse,
} from "../../../../api/thread";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	substringFormatter,
} from "../../../components/tables/table";

function getData(threads, regions) {
	let data = [];
	for (let i = 0; i < threads.length; ++i) {
		const thread = threads[i];
		const elem = {};

		elem.title = thread?.title;
		elem.text = thread?.text;
		elem.createdBy = thread?.createdBy?.fullName;
		elem.replies = thread.replies;
		elem.active = thread?.active;
		elem.createdAt = thread.createdAt;
		elem.id = thread._id;

		data = data.concat(elem);
	}
	return data;
}

export default function CourseThreadsPage() {
	const [data, setData] = useState([]);
	const [course, setCourse] = useState(null);

	const [selectedThread, setSelectedThread] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const [refresh, setRefresh] = useState(false);

	const courseId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	useEffect(() => {
		getCourseById(courseId)
			.then((res) => {
				if (res.status === 200) {
					setCourse(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get course.",
				});
				history.push("/sports/courses");
			});
		getThreadByCourse(courseId)
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get threads.",
				});
				history.push("/sports/courses");
			});
	}, [refresh, courseId]);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);

		return (
			<>
				{user?.role.includes("admin") && (
					<Tooltip title="Edit">
						<Button
							style={buttonsStyle}
							size="small"
							onClick={() =>
								history.push(
									"/sports/courses/" +
										courseId +
										"/edit-thread/" +
										cell
								)
							}
						>
							<Edit />
						</Button>
					</Tooltip>
				)}
				<Tooltip title="Reply">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>
							history.push(
								"/sports/courses/" +
									courseId +
									"/threads/" +
									cell
							)
						}
					>
						<ReplyIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedThread(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOff />
								) : (
									<ToggleOn style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedThread(elem);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "text",
			text: "Text",
			sort: true,
			formatter: substringFormatter,
		},
		{
			dataField: "createdBy",
			text: "Created By",
			sort: true,
			align: "center",
			headerAlign: "center",
		},
		{
			dataField: "replies",
			text: "Replies",
			sort: true,
			align: "center",
			headerAlign: "center",
		},
		{
			dataField: "createdAt",
			text: "Created At",
			formatter: dateFormatter,
			sort: true,
			align: "center",
			headerAlign: "center",
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	return (
		<>
			<Card>
				<CardHeader title={"Forum: " + getNonEmpty(course?.title)}>
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() =>
								history.push(
									"/sports/courses/" +
										courseId +
										"/edit-thread"
								)
							}
						>
							Add new thread
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							selectedThread?.active ? "disable" : "enable"
						} this thread?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusThread(
								selectedThread.id,
								!selectedThread?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedThread?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Thread ${
												selectedThread?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											selectedThread?.active
												? "disable"
												: "enable"
										} thread.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={"Are you sure you want to delete this thread?"}
						children={"This will remove all its replies"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteThread(selectedThread?.id)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Thread removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage:
											"Could not remove thread.",
									})
								);
						}}
					/>
				</CardBody>
			</Card>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<Button
					onClick={() => history.push("/sports/courses")}
					variant="outlined"
					style={{ marginRight: "20px" }}
				>
					Back
				</Button>
			</div>
		</>
	);
}
