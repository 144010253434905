import { authClient, API } from '../index'
import { uploadSingleFile } from "../files"

// Get all giveaways
export const getGiveaways = () => {
  return authClient().get(`${API}/giveaway`)
}

// Get giveaway by id
export const getGiveawayById = (id) => {
  return authClient().get(`${API}/giveaway/${id}`)
}

// Delete giveaway
export const deleteGiveaway = (id) => {
  return authClient().delete(`${API}/giveaway/${id}`)
}

// Create giveaway
export const postGiveaway = async (giveaway, image) => {
  if (image) {
		const response = await uploadSingleFile(image, 'giveaways')
		giveaway.imageURL = response.data.fileURL
	}
  return authClient().post(`${API}/giveaway`, giveaway)
}

// Update giveaway
export const updateGiveaway = async (id, giveaway, image) => {
  if (image) {
		const response = await uploadSingleFile(image, 'giveaways')
		giveaway.imageURL = response.data.fileURL
	}
  return authClient().put(`${API}/giveaway/${id}`, giveaway)
}

// Raffle giveaway
export const raffleGiveaway = async (id) => {
  return authClient().put(`${API}/giveaway/raffle/${id}`)
}