import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getGiveaways, deleteGiveaway } from '../../../../api/giveaway';
import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { getNonEmpty } from '../../../../utils/helpers';
import VisibilityIcon from '@material-ui/icons/Visibility';

function getData(giveaways) {
	let data = [];
	for (let i = 0; i < giveaways.length; ++i) {
		const elem = {};
		elem.title = getNonEmpty(giveaways[i].title);
		elem.status = giveaways[i].status || 'active';
		elem.endDate = giveaways[i].endDate;
		elem.maxParticipants = giveaways[i].maxParticipants || '----';
		elem.minParticipants = giveaways[i].minParticipants || '----';
		elem.createdAt = giveaways[i].createdAt;
		elem.id = giveaways[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function GiveawaysPage() {
	const [data, setData] = useState([]);
	const [giveawayId, setGiveawayId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item.id === cell)
		const isOpen = elem?.status !== 'closed'
		return (
			<>
				<Tooltip title={isOpen ? 'Edit' : 'View'}>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-giveaway/' + cell)}>
						{isOpen ? <EditIcon /> : <VisibilityIcon/>}
					</Button>
				</Tooltip>
				{user?.role.includes('admin') && <>
					<Tooltip title='Delete'>
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setGiveawayId(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
			</>
		);
	}

	function statusFormatter(cell) {
		return <span className={`badge badge-${cell === 'closed' ? 'danger' : 'primary'}`}>
			{cell}
		</span>
	}

	const columns = [
		{ dataField: 'title', text: 'Title', sort: true },
		{ dataField: 'endDate', text: 'End date', sort: true, formatter: dateFormatter },
		{ dataField: 'status', text: 'Status', sort: true, formatter: statusFormatter },
		{ dataField: 'maxParticipants', text: 'Max participants', sort: true },
		{ dataField: 'minParticipants', text: 'Min participants', sort: true },
		{
			dataField: 'createdAt',
			text: 'Created at',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getGiveaways()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get giveaways.' });
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title='Giveaways list'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-giveaway')}>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={'Are you sure you want to remove this giveaway?'}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteGiveaway(giveawayId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Giveaway removed successfully.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'Could not remove giveaway.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
