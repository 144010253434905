import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
} from '../../../../_metronic/_partials/controls';
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
	FormControlLabel,
	Checkbox,
	Tooltip
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
	deleteSpecialDay,
	getSpecialDayById,
	postSpecialDay,
	updateSpecialDay,
} from '../../../../api/specialDay';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { alertError, alertSuccess } from '../../../../utils/logger';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import { checkIsEmpty } from '../../../../utils/helpers';
import PreviewDialog from '../../../components/dialogs/PreviewDialog';
import { SERVER_URL } from '../../../../api/index';
import { Visibility } from '@material-ui/icons';
import { buttonsStyle } from '../../../components/tables/table';
import Editor from '../../../components/editor/Editor'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60',
		},
	},
});

function getEmptySpecialDay() {
	return {
		fullName: {},
		description: {},
		day: '',
		month: '',
		active: true,
		imageURL: ''
	};
}

export default function EditSpecialDaysPage() {
	const [specialDay, setSpecialDay] = useState(getEmptySpecialDay());
	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const specialDayId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!specialDayId) {
			disableLoadingData();
			return;
		}
		getSpecialDayById(specialDayId)
			.then((res) => {
				if (res.status === 200) {
					setSpecialDay(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get specialDay.' });
				history.push('/specialDays');
			});
	}, [specialDayId, disableLoadingData, history]);

	function saveSpecialDay() {
		if (checkIsEmpty(specialDay.fullName)) return alertError({ error: null, customMessage: 'The name is required in at least one of the languages.' })
		if (!specialDayId) {
			postSpecialDay(specialDay, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'SpecialDay successfully created.',
						});
						history.push('/specialDays');
					}
				})
				.catch((error) => {
					alertError({ error: error, customMessage: 'Could not save specialDay.' });
				});
		} else {
			updateSpecialDay(specialDayId, specialDay, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/specialDays');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return
		if (lang) {
			if (!specialDay[element]) specialDay[element] = {}
			let newText = specialDay[element]
			newText[lang] = event.target.value
			setSpecialDay({ ...specialDay, [element]: newText })
		} else setSpecialDay({ ...specialDay, [element]: event.target.value });
	};

	const handleChangeEditor = (element, lang, value) => {
	  if (lang) {
		if (value === ' ') return
		if (!specialDay[element]) specialDay[element] = {}
		let newText = specialDay[element]
		newText[lang] = value
		setSpecialDay({ ...specialDay, [element]: newText })
	  } else setSpecialDay({ ...specialDay, [element]: value })
	}

	const renderMultilanguageTabContent = (lang) => {
		return <>
			<br/>
			<TextField
				id={`fullName`}
				label='Full name'
				value={(specialDay.fullName && specialDay.fullName[lang]) || ''}
				onChange={handleChange('fullName', lang)}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				variant='outlined'
				required
			/>
			<TextField
				id={`shortName`}
				label='Short name'
				value={(specialDay.shortName && specialDay.shortName[lang]) || ''}
				onChange={handleChange('shortName', lang)}
				InputLabelProps={{
					shrink: true,
				}}
				margin='normal'
				variant='outlined'
			/>
			<Editor
			  body={(specialDay.description && specialDay.description[lang]) || ''}
			  setBody={new_body => handleChangeEditor('description', lang, new_body)}
			  className='max-height'
			  lang={lang}
			  placeholder={'Enter text description here...'}
			/>
		</>
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title='Edit specialDay'></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent = {renderMultilanguageTabContent}
						/>
						
						<TextField
							id={`day`}
							label={`day`}
							value={
								(specialDay.day) ||
								""
							}
							style = {{width: 200}}
							onChange={handleChange('day')}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{ readOnly: true }}
							margin="normal"
							variant="outlined"
							type="number"
							InputProps={{
								inputProps: {
									min: 1, max:31
								},
							}}
						/>	&nbsp;					
						<TextField
							id={`month`}
							label={`Month (number)`}
							value={
								(specialDay.month) ||
								""
							}
							style = {{width: 200}}
							onChange={handleChange('month')}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{ readOnly: true }}
							margin="normal"
							variant="outlined"
							type="number"
							InputProps={{
								inputProps: {
									min: 1, max:12
								},
							}}
						/>
						<br />
						<br />
						<label htmlFor={'upload-image'}>
							<input
								style={{ display: 'none' }}
								id={'upload-image'}
								name={'upload-image'}
								type='file'
								accept={'image/*'}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: '15px' }}
								color='secondary'
								component='span'
								variant='outlined'>
								{selectedImage || (specialDay.imageURL && specialDay.imageURL !== '')
									? 'Change image'
									: 'Upload image'}
							</Button>
						</label>
						{(selectedImage || (specialDay.imageURL && specialDay.imageURL !== '')) && (
							<>
								<Tooltip title={'Preview image'}>
									<Button
										size='small'
										onClick={() => setOpenPreviewDialog(true)}
										style={{ ...buttonsStyle, marginRight: '15px' }}>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={'Preview image'}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${specialDay.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: (specialDay.imageURL && specialDay.imageURL !== '')
										? specialDay.imageURL?.split(/-(.*)/s)[1]
										: ''}
								</span>
							</>
						)}
						<br/>
						<br/>
						<FormControlLabel
							control={<Checkbox checked={specialDay.active} onChange={() => setSpecialDay({ ...specialDay, active: !specialDay.active })} name="checkActive" />}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<Button
						onClick={() => history.push('/specialDays')}
						variant='outlined'
						style={{ marginRight: '20px' }}>
						Back
					</Button>
					<Button
						onClick={() => saveSpecialDay()}
						variant='outlined'
						color='primary'
						style={{ marginRight: '20px' }}>
						Save Special Day
					</Button>
					{specialDayId && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant='outlined'
									color='secondary'
									style={{ marginRight: '20px' }}>
									Delete Special Day
								</Button>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginLeft: 'auto',
									}}></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={'Are you sure you want to delete this specialDay?'}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteSpecialDay(specialDayId)
										.then((res) => {
											if (res.status === 204 || res.status === 200) {
												alertSuccess({
													title: 'Deleted!',
													customMessage: 'SpecialDay deleted successfully',
												});
												history.push('/specialDays');
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage: 'Could not delete specialDay.',
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
