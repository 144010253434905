import { authClient, API } from '../index'
import { uploadSingleFile } from "../files"

// Get all events
export const getEvents = () => {
  return authClient().get(`${API}/event`)
}

// Get event by id
export const getEventById = (id) => {
  return authClient().get(`${API}/event/${id}`)
}

// Delete event
export const deleteEvent = (id) => {
  return authClient().delete(`${API}/event/${id}`)
}

// Create event
export const postEvent = async (event, image) => {
  if (image) {
		const response = await uploadSingleFile(image, 'events')
		event.imageURL = response.data.fileURL
	}
  return authClient().post(`${API}/event`, event)
}

// Update event
export const updateEvent = async (id, event, image) => {
  if (image) {
		const response = await uploadSingleFile(image, 'events')
		event.imageURL = response.data.fileURL
	}
  return authClient().put(`${API}/event/${id}`, event)
}