import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
	changeStatusStandardChallenge,
	deleteStandardChallenge,
	getStandardChallenges,
} from "../../../../api/standardChallenge";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	substringFormatter,
} from "../../../components/tables/table";
import { SERVER_URL } from "../../../../api";

function getData(standardChallenges) {
	let data = [];
	for (let i = 0; i < standardChallenges.length; ++i) {
		const elem = {};
		const standardChallenge = standardChallenges[i];

		elem.imageURL = standardChallenge?.imageURL;
		elem.title = getNonEmpty(standardChallenge?.title);
		elem.description = getNonEmpty(standardChallenge?.description);
		elem.level = standardChallenge?.level;
		elem.createdAt = standardChallenge?.createdAt;
		elem.active = standardChallenge?.active;
		elem.id = standardChallenge?._id;

		data = data.concat(elem);
	}
	return data;
}

export default function StandardChallengesPage() {
	const [data, setData] = useState([]);

	const [selectedChallenge, setSelectedChallenge] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);

	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function imageFormatter(cell) {
		return cell && cell !== "" ? (
			<img
				src={SERVER_URL + "/" + cell}
				alt="image"
				style={{ width: "50px", maxHeight: "50px" }}
				onClick={() => {}}
			/>
		) : (
			<div />
		);
	}

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);

		return (
			<>
				<Tooltip title={"Edit"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>
							history.push("/training/edit-challenge/" + cell)
						}
					>
						{<EditIcon />}
					</Button>
				</Tooltip>
				<Tooltip title={elem?.active ? "Disable" : "Enable"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedChallenge(elem);
							setOpenConfirmDialog(1);
						}}
					>
						{elem?.active ? (
							<ToggleOffIcon />
						) : (
							<ToggleOnIcon style={{ color: "red" }} />
						)}
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedChallenge(elem);
							setOpenConfirmDialog(2);
						}}
					>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{
			dataField: "imageURL",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: imageFormatter,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "description",
			text: "Description",
			sort: true,
			formatter: substringFormatter,
		},
		{
			dataField: "level",
			text: "Level",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getStandardChallenges()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get challenges.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Standard challenges list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() =>
								history.push("/training/edit-challenge")
							}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={getData(data)} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							selectedChallenge?.active ? "disable" : "enable"
						} this challenge?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusStandardChallenge(
								selectedChallenge._id,
								!selectedChallenge?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedChallenge?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Challenge ${
												selectedChallenge?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											selectedChallenge?.active
												? "disable"
												: "enable"
										} challenge.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							"Are you sure you want to remove this challenge?"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteStandardChallenge(selectedChallenge?._id)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Challenge removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not remove challenge.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
