import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const getDietTypes = () => {
	return authClient().get(`${API}/diet-type?getDisabled=true`);
};

export const getDietTypeById = (id) => {
	return authClient().get(`${API}/diet-type/${id}`);
};

export const deleteDietType = (id) => {
	return authClient().delete(`${API}/diet-type/${id}`);
};

export const postDietType = async (dietType, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "dietTypes");
		dietType.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/diet-type`, dietType);
};

export const updateDietType = async (id, dietType, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "dietTypes");
		dietType.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/diet-type/${id}`, dietType);
};

export const changeStatusDietType = async (id, active) => {
	return authClient().put(`${API}/diet-type/change-status/${id}`, {
		active,
	});
};
