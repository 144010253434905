import {
	Button,
	createMuiTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	MuiThemeProvider,
	TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { buttonsStyle } from "../../../components/tables/table";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

const EditRoutineDialog = (props) => {
	const { title, open, setOpen, onConfirm, routine, initialData } = props;

	const [data, setData] = useState({
		series: [0],
		timeSeries: [],
		nTimesSeries: [],
	});
	const [error, setError] = useState(null);

	const handleSave = () => {
		setError(null);
		let saveData = data;

		if (!routine) return;
		if (routine.model === "series" && !saveData.series?.length)
			return setError("At least one series is required.");
		if (
			routine.model === "series" &&
			saveData.series.findIndex((item) => isNaN(item) || !item) >= 0
		)
			return setError(
				"Repetitions for all series are required, please check that it is all filled out."
			);

		if (
			routine.model === "time" &&
			((Array.isArray(saveData.timeSeries) &&
				saveData.timeSeries?.length === 0) ||
				(!Array.isArray(saveData.timeSeries) && !saveData.timeSeries))
		)
			return setError("Time is required.");

		if (
			routine.model === "time" &&
			((Array.isArray(saveData.timeSeries) &&
				saveData.timeSeries.findIndex((item) => isNaN(item) || !item) >=
					0) ||
				(!Array.isArray(saveData.timeSeries) &&
					isNaN(saveData.timeSeries)))
		) {
			return setError("Time should be a number.");
		}

		if (
			routine.model === "nTimes" &&
			((Array.isArray(saveData.nTimesSeries) &&
				saveData.nTimesSeries?.length === 0) ||
				(!Array.isArray(saveData.nTimesSeries) &&
					!saveData.nTimesSeries))
		)
			return setError("Number of times is required.");

		if (
			routine.model === "nTimes" &&
			((Array.isArray(saveData.nTimesSeries) &&
				saveData.nTimesSeries.findIndex(
					(item) => isNaN(item) || !item
				) >= 0) ||
				(!Array.isArray(saveData.nTimesSeries) &&
					isNaN(saveData.nTimesSeries)))
		) {
			return setError("Number of times should be a number.");
		}

		setData(saveData);
		onConfirm(saveData);
	};

	useEffect(() => {
		if (!open || !initialData) return;

		let initData = initialData;
		if (!Array.isArray(initData.timeSeries) && initData?.timeSeries)
			initData.timeSeries = [initData.timeSeries];
		if (!Array.isArray(initData.nTimesSeries) && initData?.nTimesSeries)
			initData.nTimesSeries = [initData.nTimesSeries];

		setData(initData);
	}, [initialData, open]);

	const handleChange = (element, index) => (event) => {
		setError(null);
		if (event.target.value === " ") return;
		const value = !isNaN(event.target.value)
			? parseFloat(event.target.value)
			: event.target.value;
		if (index || index === 0) {
			let _items = [...data[element]];
			_items[index] = value;
			setData({ ...data, [element]: _items });
		} else setData({ ...data, [element]: value });
	};

	const addSeries = (element) => {
		setError(null);
		setData({
			...data,
			[element]: data[element] ? data[element].concat(0) : [0],
		});
	};

	const removeSeries = (element, index) => {
		setError(null);
		let _items = [...data[element]];
		_items.splice(index, 1);
		setData({ ...data, [element]: _items });
	};

	if (!routine) setOpen(null);

	return (
		<Dialog
			fullWidth={true}
			maxWidth="sm"
			open={open}
			onClose={() => setOpen(null)}
			aria-labelledby="edit-routine-dialog"
		>
			<DialogTitle id="edit-routine-dialog">{title}</DialogTitle>
			<DialogContent>
				{routine?.model === "series" ? (
					<>
						<br />
						<br />
						<Grid
							container
							direction="column"
							justify="flex-start"
							alignItems="stretch"
							spacing={2}
						>
							{data.series?.map((_, index) => (
								<Grid item key={"series" + index}>
									<Grid
										container
										direction="row"
										justify="space-between"
										alignItems="center"
										spacing={2}
									>
										<Grid item xs={11}>
											<TextField
												id={`series-${index}`}
												label={`Series ${index +
													1} - Repetitions`}
												value={data.series[index] || ""}
												onChange={handleChange(
													"series",
													index
												)}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													inputProps: {
														min: 0,
													},
												}}
												margin="normal"
												variant="outlined"
												type="number"
												required
											/>
										</Grid>
										{data.series?.length && (
											<Grid>
												<Button
													size="small"
													style={buttonsStyle}
													onClick={() => {
														removeSeries(
															"series",
															index
														);
													}}
												>
													<DeleteIcon />
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							))}
						</Grid>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => addSeries("series")}
						>
							Add series
						</Button>
					</>
				) : (
					<>
						{routine?.model === "time" ? (
							<>
								<Grid
									container
									direction="column"
									justify="flex-start"
									alignItems="stretch"
									spacing={2}
								>
									{Array.isArray(data?.timeSeries) &&
										data?.timeSeries?.map((_, index) => (
											<Grid item key={"time" + index}>
												<Grid
													container
													direction="row"
													justify="space-between"
													alignItems="center"
													spacing={2}
												>
													<Grid item xs={11}>
														<TextField
															id={`time-${index}`}
															label={`Series ${index +
																1} - Time (in minutes)`}
															value={
																data
																	?.timeSeries[
																	index
																] || ""
															}
															onChange={handleChange(
																"timeSeries",
																index
															)}
															InputLabelProps={{
																shrink: true,
															}}
															InputProps={{
																inputProps: {
																	min: 0,
																},
															}}
															margin="normal"
															variant="outlined"
															type="number"
															required
														/>
													</Grid>
													{data.timeSeries
														?.length && (
														<Grid>
															<Button
																size="small"
																style={
																	buttonsStyle
																}
																onClick={() => {
																	removeSeries(
																		"timeSeries",
																		index
																	);
																}}
															>
																<DeleteIcon />
															</Button>
														</Grid>
													)}
												</Grid>
											</Grid>
										))}
								</Grid>
								<Button
									variant="outlined"
									color="primary"
									onClick={() => addSeries("timeSeries")}
								>
									Add series
								</Button>
							</>
						) : (
							<>
								<Grid
									container
									direction="column"
									justify="flex-start"
									alignItems="stretch"
									spacing={2}
								>
									{Array.isArray(data?.nTimesSeries) &&
										data?.nTimesSeries?.map((_, index) => (
											<Grid item key={"nTimes" + index}>
												<Grid
													container
													direction="row"
													justify="space-between"
													alignItems="center"
													spacing={2}
												>
													<Grid item xs={11}>
														<TextField
															id={`nTimes-${index}`}
															label={`Series ${index +
																1} - Number of times`}
															value={
																data
																	?.nTimesSeries[
																	index
																] || ""
															}
															onChange={handleChange(
																"nTimesSeries",
																index
															)}
															InputLabelProps={{
																shrink: true,
															}}
															InputProps={{
																inputProps: {
																	min: 0,
																},
															}}
															margin="normal"
															variant="outlined"
															type="number"
															required
														/>
													</Grid>
													{data.nTimesSeries
														?.length && (
														<Grid>
															<Button
																size="small"
																style={
																	buttonsStyle
																}
																onClick={() => {
																	removeSeries(
																		"nTimesSeries",
																		index
																	);
																}}
															>
																<DeleteIcon />
															</Button>
														</Grid>
													)}
												</Grid>
											</Grid>
										))}
								</Grid>
								<Button
									variant="outlined"
									color="primary"
									onClick={() => addSeries("nTimesSeries")}
								>
									Add series
								</Button>
							</>
						)}
					</>
				)}
				<br />
				<br />
				{error && <h5 style={{ color: "red" }}>{error}</h5>}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" onClick={handleSave}>
					Save
				</Button>
				<MuiThemeProvider theme={theme}>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => setOpen(null)}
					>
						Cancel
					</Button>
				</MuiThemeProvider>
			</DialogActions>
		</Dialog>
	);
};
export default EditRoutineDialog;
