import {
	Button,
	Checkbox,
	Chip,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import { deleteFood, getFoods } from "../../../../api/food";
import {
	getFoodPlateById,
	postFoodPlate,
	updateFoodPlate,
} from "../../../../api/foodPlate";
import { getMeals } from "../../../../api/meal";
import { getRecipeTypes } from "../../../../api/recipeType";
import {
	NUTRI_SCORE,
	checkIsEmpty,
	getNonEmpty,
} from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Editor from "../../../components/editor/Editor";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyFoodPlate() {
	return {
		description: {},
		recipe: {},
		recipeTypes: [],
		foods: [],
		meals: [],
		calories: "0",
		preparationMinutes: null,
		nutriScore: null,
		nutrients: {
			fiber: null,
			protein: null,
			carbohydrates: null,
			cholesterol: null,
			saturatedFats: null,
			unsaturatedFats: null,
		},
		imageURL: null,
		active: true,
	};
}

export default function EditFoodPlatesPage() {
	const [recipeTypes, setRecipeTypes] = useState([]);
	const [foods, setFoods] = useState([]);
	const [meals, setMeals] = useState([]);
	const [foodPlate, setFoodPlate] = useState(getEmptyFoodPlate());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const foodPlateId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getMeals()
			.then((res) => {
				if (res.status === 200) {
					setMeals(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get meals.",
				});
			});
		getRecipeTypes()
			.then((res) => {
				if (res.status === 200) {
					setRecipeTypes(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get recipe types.",
				});
			});
		getFoods()
			.then((res) => {
				if (res.status === 200) {
					setFoods(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get foods.",
				});
			});
		if (!foodPlateId) {
			disableLoadingData();
			return;
		}
		getFoodPlateById(foodPlateId)
			.then((res) => {
				if (res.status === 200) {
					let foodPlate = {
						...res.data,
						foods: res?.data?.foods?.map((x) => x._id),
					};
					setFoodPlate(foodPlate);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get food plate.",
				});
				history.push("/food-plates");
			});
	}, [foodPlateId, disableLoadingData, history]);

	function saveFoodPlate() {
		if (checkIsEmpty(foodPlate.description))
			return alertError({
				error: null,
				customMessage:
					"The descripton is required in at least one of the languages.",
			});
		if (foodPlate.recipeTypes.length === 0)
			return alertError({
				error: null,
				customMessage: "At least one recipe type is required.",
			});
		if (foodPlate.foods.length === 0)
			return alertError({
				error: null,
				customMessage: "At least one food is required.",
			});
		if (foodPlate.meals.length === 0)
			return alertError({
				error: null,
				customMessage: "At least one meal is required.",
			});

		if (!foodPlateId) {
			postFoodPlate(foodPlate, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Food plate successfully created.",
						});
						history.push("/food-plates");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save food plate.",
					});
				});
		} else {
			updateFoodPlate(foodPlateId, foodPlate, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/food-plates");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!foodPlate[element]) foodPlate[element] = {};
			let newText = foodPlate[element];
			newText[lang] = event.target.value;
			setFoodPlate({ ...foodPlate, [element]: newText });
		} else
			setFoodPlate({
				...foodPlate,
				[element]: event.target.value,
			});
	};

	const handleChangeNutrients = (element) => (event) => {
		event.persist();
		const { value } = event.target.value;
		if (value === " ") return;
		setFoodPlate((prevPlate) => ({
			...prevPlate,
			nutrients: {
				...prevPlate.nutrients,
				[element]: event?.target?.value,
			},
		}));
	};

	const handleChangeEditor = (element, lang, value) => {
		if (lang) {
			if (value === " ") return;
			if (!foodPlate[element]) foodPlate[element] = {};
			let newText = foodPlate[element];
			newText[lang] = value;
			setFoodPlate({ ...foodPlate, [element]: newText });
		} else setFoodPlate({ ...foodPlate, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`description`}
					label="Title"
					value={
						(foodPlate.description &&
							foodPlate.description[lang]) ||
						""
					}
					onChange={handleChange("description", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<br />
				<br />
				<div>Recipe</div>
				<Editor
					body={(foodPlate.recipe && foodPlate.recipe[lang]) || ""}
					setBody={(new_body) =>
						handleChangeEditor("recipe", lang, new_body)
					}
					className="max-height"
					lang={lang}
					placeholder={"Enter plate recipe here..."}
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit food plate"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<Autocomplete
							multiple
							id="recipe-types"
							filterSelectedOptions
							disablePortal
							disableCloseOnSelect
							options={recipeTypes}
							getOptionLabel={(option) =>
								getNonEmpty(option.fullName)
							}
							value={recipeTypes.filter((x) =>
								foodPlate.recipeTypes.includes(x._id)
							)}
							onChange={(event, selected) => {
								setFoodPlate({
									...foodPlate,
									recipeTypes: selected.map((x) => x._id),
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									required
									placeholder="Select recipe type"
									label={"Recipe type"}
								/>
							)}
						/>
						<Autocomplete
							multiple
							id="foods"
							filterSelectedOptions
							disablePortal
							disableCloseOnSelect
							options={foods}
							getOptionLabel={(option) =>
								getNonEmpty(option.fullName)
							}
							value={foods.filter((x) =>
								foodPlate.foods.includes(x._id)
							)}
							onChange={(event, selected) => {
								setFoodPlate({
									...foodPlate,
									foods: selected.map((x) => x._id),
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									required
									placeholder="Select food"
									label={"Food"}
								/>
							)}
						/>
						<Autocomplete
							multiple
							id="meals"
							filterSelectedOptions
							disablePortal
							disableCloseOnSelect
							options={meals}
							getOptionLabel={(option) =>
								getNonEmpty(option.fullName)
							}
							value={meals.filter((x) =>
								foodPlate.meals.includes(x._id)
							)}
							onChange={(event, selected) => {
								setFoodPlate({
									...foodPlate,
									meals: selected.map((x) => x._id),
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									required
									placeholder="Select meal"
									label={"Meal"}
								/>
							)}
						/>
						<br />
						<div className="mb-2">Diet Types</div>
						{foodPlate?.dietTypes?.length > 0 ? (
							foodPlate?.dietTypes?.map((dietType) => {
								return (
									<Chip
										label={getNonEmpty(dietType.fullName)}
										className="m-2"
									></Chip>
								);
							})
						) : (
							<div>
								Assign foods and save to know the compatibility
								of diets
							</div>
						)}
						<br />
						<br />
						<div className="row">
							<div className="col-md-4">
								<TextField
									id={`calories`}
									label="Calories"
									value={foodPlate?.calories || ""}
									onChange={handleChange("calories")}
									InputLabelProps={{
										shrink: true,
									}}
									required
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									id={`preparationMinutes`}
									label="Minutes of preparation (min)"
									value={foodPlate?.preparationMinutes || ""}
									onChange={handleChange(
										"preparationMinutes"
									)}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
							<div className="col-md-4">
								<Autocomplete
									id="nutri-score"
									disablePortal
									options={NUTRI_SCORE}
									getOptionLabel={(option) => option}
									value={foodPlate?.nutriScore || ""}
									onChange={(event, selected) => {
										setFoodPlate({
											...foodPlate,
											nutriScore: selected,
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											margin="normal"
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
											placeholder="Select score"
											label="Nutri-Score"
										/>
									)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<TextField
									id={`fiber`}
									label="Fiber (g)"
									value={foodPlate?.nutrients?.fiber || ""}
									onChange={handleChangeNutrients("fiber")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									id={`protein`}
									label="Protein (g)"
									value={foodPlate?.nutrients?.protein || ""}
									onChange={handleChangeNutrients("protein")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									id={`protein`}
									label="Carbohydrates (g)"
									value={
										foodPlate?.nutrients?.carbohydrates ||
										""
									}
									onChange={handleChangeNutrients(
										"carbohydrates"
									)}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<TextField
									id={`cholesterol`}
									label="Cholesterol (mg)"
									value={
										foodPlate?.nutrients?.cholesterol || ""
									}
									onChange={handleChangeNutrients(
										"cholesterol"
									)}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									id={`saturatedFats`}
									label="Saturated Fats (g)"
									value={
										foodPlate?.nutrients?.saturatedFats ||
										""
									}
									onChange={handleChangeNutrients(
										"saturatedFats"
									)}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
							<div className="col-md-4">
								<TextField
									id={`unsaturatedFats`}
									label="Unsaturated Fats (g)"
									value={
										foodPlate?.nutrients?.unsaturatedFats ||
										""
									}
									onChange={handleChangeNutrients(
										"unsaturatedFats"
									)}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
									type="number"
									InputProps={{
										inputProps: {
											min: 0,
										},
									}}
								/>
							</div>
						</div>

						<br />
						<br />
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedImage ||
								(foodPlate.imageURL &&
									foodPlate.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(foodPlate.imageURL &&
								foodPlate.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview image"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${foodPlate.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: foodPlate.imageURL &&
										  foodPlate.imageURL !== ""
										? foodPlate.imageURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
								<Tooltip title={"Delete image"}>
									<Button
										size="small"
										onClick={() => {
											setSelectedImage(null);
											setFoodPlate({
												...foodPlate,
												imageURL: null,
											});
										}}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Delete />
									</Button>
								</Tooltip>
							</>
						)}
						<br />
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={foodPlate.active}
									onChange={() =>
										setFoodPlate({
											...foodPlate,
											active: !foodPlate.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/food-plates")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveFoodPlate()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save food plate
					</Button>
					{foodPlateId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete food plate
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this food plate?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteFood(foodPlateId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Food plate deleted successfully",
												});
												history.push("/food-plates");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete food plate.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
