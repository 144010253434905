import { API, authClient } from "../index";

export const getFoods = () => {
	return authClient().get(`${API}/food?getDisabled=true`);
};

export const getFoodById = (id) => {
	return authClient().get(`${API}/food/${id}`);
};

export const deleteFood = (id) => {
	return authClient().delete(`${API}/food/${id}`);
};

export const postFood = async (food) => {
	return authClient().post(`${API}/food`, food);
};

export const updateFood = async (id, food) => {
	return authClient().put(`${API}/food/${id}`, food);
};

export const changeStatusFood = async (id, active) => {
	return authClient().put(`${API}/food/change-status/${id}`, {
		active,
	});
};
