import { API, authClient } from '../index';
import { uploadSingleFile } from '../files';

// Get all tests
export const getTests = () => {
	return authClient().get(`${API}/test?getDisabled=true`);
};

// Get test by id
export const getTestById = (id) => {
	return authClient().get(`${API}/test/${id}`);
};

// Delete test
export const deleteTest = (id) => {
	return authClient().delete(`${API}/test/${id}`);
};

// Create test 
export const postTest = async (test, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'tests')
		test.imageURL = response.data.fileURL
	}
	return authClient().post(`${API}/test`, test);
};

// Update test
export const updateTest = async (id, test, image) => {
	if (image) {
		const response = await uploadSingleFile(image, 'tests')
		test.imageURL = response.data.fileURL
	}
	return authClient().put(`${API}/test/${id}`, test);
};

// Change status test
export const changeStatusTest = async (id, active) => {
	return authClient().put(`${API}/test/change-status/${id}`, { active });
};