import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import '../../../customStyles/preview.css'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60',
    },
  },
});

const SendDialog = (props) => {
  const { title, open, src, onClose, onConfirm, type } = props


  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent className="preview-container">
        {type === 'video' ?
          <video className="preview-video" controls>
            <source src={src}/>
          </video>
          : <embed className="preview-file" style={{ width: src?.includes('.pdf') && '100vh', height: src?.includes('.pdf') && '70vh' }}
            src={src}
            height="100%"
            width="100%"
          />
        }
      </DialogContent>
      <DialogActions>
        <Button
					variant="outlined"
					color="primary"
					onClick={onConfirm}
				>
					SEND
				</Button>
        <MuiThemeProvider theme={theme}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            CANCEL
          </Button>
        </MuiThemeProvider>
      </DialogActions>
    </Dialog>
  )
}
export default SendDialog
