import { authClient, API } from '../index'

// Get all sessions
export const getSessions = () => {
  return authClient().get(`${API}/session`)
}

// Get session by id
export const getSessionById = (id) => {
  return authClient().get(`${API}/session/${id}`)
}

// Delete session
export const deleteSession = (id) => {
  return authClient().delete(`${API}/session/${id}`)
}

// Create session
export const postSession = (session) => {
  return authClient().post(`${API}/session`, session)
}

// Update session
export const updateSession = (id, session) => {
  return authClient().put(`${API}/session/${id}`, session)
}

// Get sessions by user id
export const getSessionsByUserId = (id) => {
  return authClient().get(`${API}/session/user/${id}`)
}

// Get unreviewed sessions by user id
export const countUnreviewedSessionsById = (id) => {
  return authClient().get(`${API}/session/unreviewed/${id}`)
}

// Get sessions by trainer id
export const getSessionsByTrainerId = (id) => {
  return authClient().get(`${API}/session/professional/${id}`)
}

// Add TrainerReview and send feedback
export const updateTrainerReview = (data) => {
  return authClient().put(`${API}/session/save-trainer-review/${data.id}`, data)
}