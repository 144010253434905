import { API, authClient } from "../index";

export const getMeals = () => {
	return authClient().get(`${API}/meal?getDisabled=true`);
};

export const getMealById = (id) => {
	return authClient().get(`${API}/meal/${id}`);
};

export const deleteMeal = (id) => {
	return authClient().delete(`${API}/meal/${id}`);
};

export const postMeal = async (meal) => {
	return authClient().post(`${API}/meal`, meal);
};

export const updateMeal = async (id, meal) => {
	return authClient().put(`${API}/meal/${id}`, meal);
};

export const changeStatusMeal = async (id, active) => {
	return authClient().put(`${API}/meal/change-status/${id}`, {
		active,
	});
};
