import {
	Button,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Tooltip,
} from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import { getSubscriptions } from "../../../../api/subscription";
import { changeStatusUser, deleteUser, getUsers } from "../../../../api/user";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import FiltersCard from "../../../components/filters/Filter";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

function getData(users, trainers, nutritionists) {
	let data = [];
	for (let i = 0; i < users.length; ++i) {
		if (users[i].role.includes("user")) {
			const elem = {};
			elem.name = users[i].fullName;
			elem.email = users[i].email;
			elem.timezone = users[i].timezone;
			elem.imagesURLs = users[i].imagesURLs[0];
			elem.createdAt = users[i].createdAt;
			elem.id = users[i]._id;
			elem.active = users[i].active;
			elem.deactivated = users[i].deactivated ? "Yes" : "No";
			var lat = users[i].geolocation?.latitude;
			if (lat !== undefined) {
				lat = Math.round(lat*1000)/1000;
				var long = Math.round(users[i].geolocation?.longitude*1000)/1000;
				elem.geolocation = lat+', '+long;
			}
			data = data.concat(elem);
		}
	}
	return data;
}

const initialFilters = {
	active: false,
	nutritionist: [],
	trainer: [],
	subscription: [],
};

export default function UsersPage() {
	const [data, setData] = useState([]);
	const [user, setUser] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);
	const [filteredData, setFilteredData] = useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [trainers, setTrainers] = useState([]);
	const [nutritionists, setNutritionists] = useState([]);
	const [subscriptions, setSubscriptions] = useState([]);
	const [filterOptions, setFilterOptions] = useState(initialFilters);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const userLogged = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function imageFormatter(imageURL) {
		return imageURL && imageURL !== "" ? (
			<img
				src={SERVER_URL + "/" + imageURL}
				alt="foodPlate"
				style={{ maxWidth: "50px", maxHeight: "50px" }}
				onClick={() => {
					setPreviewImage(imageURL);
					setOpenPreviewDialog(true);
				}}
			/>
		) : (
			<div />
		);
	}

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);

		return (
			<>
				<Tooltip title="View user info">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/view-user-info/" + cell)}
					>
						<VisibilityIcon />
					</Button>
				</Tooltip>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-user/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title="Payments">
					<Button
						style={buttonsStyle}
						size={"small"}
						onClick={() => history.push("/purchases/" + cell)}
					>
						<AccountBalanceWalletIcon />
					</Button>
				</Tooltip>
				{userLogged?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setUser(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setUser(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "imagesURLs",
			text: "",
			headerAlign: "center",
			align: "center",
			formatter: imageFormatter,
		},
		{ dataField: "name", text: "Full name", sort: true },
		{ dataField: "email", text: "Email", sort: true },
		{ dataField: "geolocation", text: "GEO", sort: true },
		{
			dataField: "timezone",
			text: "TimeZone",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "deactivated",
			text: "Deactivated",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					const data = userLogged.role.includes("admin")
						? res.data
						: res.data.filter(
								(item) =>
									item.trainer === userLogged._id ||
									item.nutritionist === userLogged._id
						  );
					setData(data);
					setFilteredData(data);
					setTrainers(
						res.data.filter((item) => item.role.includes("trainer"))
					);
					setNutritionists(
						res.data.filter((item) =>
							item.role.includes("nutritionist")
						)
					);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get users.",
				});
			});
		getSubscriptions()
			.then((res) => {
				if (res.status === 200) {
					setSubscriptions(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get subscriptions.",
				});
			});
	}, [refresh, userLogged]);

	const handleSearch = async () => {
		if (!data.length) return;
		setFilteredData(
			data.filter((item) => {
				let filter = true;
				if (
					filterOptions.nutritionist &&
					filterOptions.nutritionist.length
				)
					filter =
						filter &&
						filterOptions.nutritionist.includes(item.nutritionist);
				if (filterOptions.trainer && filterOptions.trainer.length)
					filter =
						filter && filterOptions.trainer.includes(item.trainer);
				if (
					filterOptions.subscription &&
					filterOptions.subscription.length
				) {
					const _subscriptions = item.subscriptions.map(
						(s) => s.subscription
					);
					const found = filterOptions.subscription.some((elem) =>
						_subscriptions.includes(elem)
					);
					filter = filter && found;
				}
				if (filterOptions.active) filter = filter && item.active;
				if (filter) return item;
				return false;
			})
		);
	};

	const handleClearFilters = () => {
		setFilterOptions(initialFilters);
		setRefresh(true);
	};

	const handleChange = (element) => (event) => {
		setFilterOptions({ ...filterOptions, [element]: event.target.value });
	};

	const renderFiltersContent = () => {
		return (
			<>
				<FormControl style={{ width: "100%" }}>
					<InputLabel id="demo-simple-select-standard-label">
						Subscription
					</InputLabel>
					<Select
						labelId="demo-simple-select-standard-label"
						id="demo-simple-select-standard"
						value={filterOptions.subscription || []}
						multiple
						onChange={handleChange("subscription")}
						MenuProps={MenuProps}
					>
						{subscriptions?.map((option) => (
							<MenuItem key={option._id} value={option._id}>
								{getNonEmpty(option.title)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<br />
				<br />
				{userLogged.role.includes("admin") && (
					<>
						<FormControl style={{ width: "100%" }}>
							<InputLabel id="demo-simple-select-standard-label">
								Trainer
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={filterOptions.trainer || []}
								multiple
								onChange={handleChange("trainer")}
								MenuProps={MenuProps}
							>
								{trainers?.map((option) => (
									<MenuItem
										key={option._id}
										value={option._id}
									>
										{option.fullName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl style={{ width: "100%" }}>
							<InputLabel id="demo-simple-select-standard-label">
								Nutritionist
							</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								value={filterOptions.nutritionist || []}
								multiple
								onChange={handleChange("nutritionist")}
								MenuProps={MenuProps}
							>
								{nutritionists?.map((option) => (
									<MenuItem
										key={option._id}
										value={option._id}
									>
										{option.fullName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<br />
						<br />
					</>
				)}
				<FormControlLabel
					control={
						<Switch
							checked={filterOptions.active}
							onChange={() =>
								setFilterOptions({
									...filterOptions,
									active: !filterOptions.active,
								})
							}
							name="checkActive"
						/>
					}
					label={
						filterOptions.active
							? "Only active"
							: "All active/inactive"
					}
				/>
			</>
		);
	};

	return (
		<>
			<Card>
				<CardHeader title="Users list"></CardHeader>
				<CardBody>
					<FiltersCard
						filtersContent={renderFiltersContent}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>

					<Table
						data={getData(filteredData, trainers, nutritionists)}
						columns={columns}
					/>

					<ConfirmDialog
						title={`Are you sure you want to ${
							user?.active ? "disable" : "enable"
						} this user?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusUser(user?._id, !user?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												user?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `User ${
												user?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											user?.active ? "disable" : "enable"
										} user.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							"Are you sure you want to unsubscribe this user?"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteUser(user?._id)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"User removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove user.",
									});
								});
						}}
					/>
					<PreviewDialog
						title={"Preview image"}
						open={openPreviewDialog}
						setOpen={setOpenPreviewDialog}
						src={
							previewImage
								? `${SERVER_URL}/${previewImage}`
								: null
						}
					/>
				</CardBody>
			</Card>
		</>
	);
}
