import { UPDATE_ONLINE_USERS, UPDATE_CURRENT_CHAT, UPDATE_CHATS, NEW_MESSAGE, ADD_NOTIFICATIONS, READ_MESSAGES, SET_NOTIFICATIONS } from './chatActions';

const initialState = { onlineUsers: [], chats: [], currentChat: null, currentChatMessages: [], notifications: {} }

export function chat(state = initialState, action) {
	switch (action.type) {
		case UPDATE_ONLINE_USERS:
         return ({ ...state, onlineUsers: action.list });

      case UPDATE_CHATS:
         return ({ ...state, chats: action.chats });

      case UPDATE_CURRENT_CHAT:
         return ({ ...state, currentChat: action.data.chat, currentChatMessages: action.data.messages });

      case NEW_MESSAGE:
         return ({ ...state, currentChatMessages: state.currentChatMessages.concat(action.message) });

      case ADD_NOTIFICATIONS:
         const newNotifications = state.notifications || {}
         return ({ ...state, notifications: {...newNotifications, [action.chat]: newNotifications[action.chat] ? newNotifications[action.chat] + 1 : 1 }});

      case SET_NOTIFICATIONS:
         return ({ ...state, notifications: action.notifications });

      case READ_MESSAGES:
         const _notifications = state.notifications ? {...state.notifications} :  {}
         delete _notifications[action.chat]
         return ({ ...state, notifications: _notifications });

		default:
			return state;
	}
}
