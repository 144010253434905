import { Button, Tooltip } from "@material-ui/core";
import { Forum } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import { getChatByTeam } from "../../../../api/chat";
import { getMessagesByChatId } from "../../../../api/message";
import { deleteTeam, getTeams } from "../../../../api/team";
import { updateCurrentChat } from "../../../../redux/chatRedux/chatActions";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import ConversationDialog from "../../../components/dialogs/ConversationDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Table, {
	booleanFormatter,
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";

function getData(teams) {
	let data = [];
	for (let i = 0; i < teams.length; ++i) {
		const elem = {};
		elem.img = teams[i].logoURL;
		elem.title = teams[i].title;
		elem.code = teams[i].code;
		var lat = teams[i].geolocation?.latitude;
		if (lat !== undefined) {
			lat = Math.round(lat * 1000) / 1000;
			var long =
				Math.round(teams[i].geolocation?.longitude * 1000) / 1000;
			elem.geolocation = lat + ", " + long;
		}
		elem.members = teams[i].members?.length;
		elem.createdAt = teams[i].createdAt;
		elem.public = teams[i].public;
		elem.id = teams[i]._id;
		data = data.concat(elem);
	}
	return data;
}

export default function TeamsPage() {
	const [data, setData] = useState([]);
	const [teamId, setTeamId] = useState(null);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openChatDialog, setOpenChatDialog] = useState(false);
	const [selectedChat, setSelectedChat] = useState(null);

	const [refresh, setRefresh] = useState(false);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);

	const dispatch = useDispatch();

	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function imageFormatter(cell) {
		return cell && cell !== "" ? (
			<img
				src={SERVER_URL + "/" + cell}
				alt="team"
				style={{ width: "50px", height: "50px" }}
				onClick={() => {
					setPreviewImage(cell);
					setOpenPreviewDialog(true);
				}}
			/>
		) : (
			<div />
		);
	}

	function buttonFormatter(cell) {
		const canEdit = user?.role.includes("admin");
		return (
			<>
				<Tooltip title={canEdit ? "Edit" : "View"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-team/" + cell)}
					>
						{canEdit ? <EditIcon /> : <VisibilityIcon />}
					</Button>
				</Tooltip>
				<Tooltip title={"View conversation"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={async () => {
							try {
								const res = await getChatByTeam(cell);
								if (res.status === 200) {
									let chat = {
										...res.data,
										team: data?.find((x) => x.id === cell),
									};
									setSelectedChat(chat);
									try {
										const messagesRes = await getMessagesByChatId(
											chat._id
										);
										dispatch(
											updateCurrentChat(
												chat,
												messagesRes.data
											)
										);
										setOpenChatDialog(true);
									} catch (error) {}
								}
							} catch (error) {}
						}}
					>
						<Forum />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<Tooltip title="Delete">
						<Button
							style={buttonsStyle}
							size="small"
							onClick={() => {
								setTeamId(cell);
								setOpenConfirmDialog(true);
							}}
						>
							<DeleteIcon />
						</Button>
					</Tooltip>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "img",
			text: "",
			sort: false,
			formatter: imageFormatter,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "geolocation",
			text: "GEO",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "members",
			text: "# Members",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "code",
			text: "Code",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "public",
			text: "Public",
			sort: true,
			headerAlign: "center",
			align: "center",
			formatter: booleanFormatter,
		},
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getTeams()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get teams.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Teams list">
					{user?.role.includes("admin") && (
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => history.push("/edit-team")}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					)}
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />

					<PreviewDialog
						title={"Preview image"}
						open={openPreviewDialog}
						setOpen={setOpenPreviewDialog}
						src={
							previewImage
								? `${SERVER_URL}/${previewImage}`
								: null
						}
					/>
					<ConfirmDialog
						title={"Are you sure you want to remove this team?"}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteTeam(teamId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Team removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove team.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
			<ConversationDialog
				title={selectedChat?.team?.title}
				open={openChatDialog}
				setOpen={setOpenChatDialog}
				chat={selectedChat}
			/>
		</>
	);
}
