import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	deleteNotificationText,
	getNotificationTextById,
	postNotificationText,
	updateNotificationText,
} from "../../../../api/notificationText";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

const NOTIFICATIONTYPES = ["DinnerNotification", "LunchNotification", "DinnerNotificationNoEatfityPRO", "FitNotification", "FitInitialNotification"];

function getEmptyNotificationText() {
	return {
		title: {},
		description: {},
		active: true,
	};
}

export default function EditNotificationTextsPage() {
	const [notificationText, setNotificationText] = useState(getEmptyNotificationText());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const notificationTextId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!notificationTextId) {
			disableLoadingData();
			return;
		}
		getNotificationTextById(notificationTextId)
			.then((res) => {
				if (res.status === 200) {
					setNotificationText(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get notification text.",
				});
				history.push("/notification-texts");
			});
	}, [notificationTextId, disableLoadingData, history]);

	function saveNotificationText() {
		if (checkIsEmpty(notificationText.title))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!notificationTextId) {
			postNotificationText(notificationText)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Notification text successfully created.",
						});
						history.push("/notification-texts");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save notification text.",
					});
				});
		} else {
			updateNotificationText(notificationTextId, notificationText)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/notification-texts");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!notificationText[element]) notificationText[element] = {};
			let newText = notificationText[element];
			newText[lang] = event.target.value;
			setNotificationText({ ...notificationText, [element]: newText });
		} else
			setNotificationText({
				...notificationText,
				[element]: event.target.value,
			});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label="Title"
					value={(notificationText.title && notificationText.title[lang]) || ""}
					onChange={handleChange("title", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
				<TextField
					id={`description`}
					label="Description"
					value={
						(notificationText.description && notificationText.description[lang]) ||
						""
					}
					onChange={handleChange("description", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit notification text"></CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<small>[XXX] should be used to indicate where it will be replaced by the user's values</small><br/>
						<small>This title and this description will not necessarily go together</small>
						<br />
						<Autocomplete
							id="notificationType"
							disablePortal
							options={NOTIFICATIONTYPES}
							getOptionLabel={(option) => option}
							value={notificationText?.notificationType}
							onChange={(event, selected) => {
								setNotificationText({
									...notificationText,
									notificationType: selected,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									placeholder="Select type of notification"
									label="Notification type"
									required
								/>
							)}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={notificationText.active}
									onChange={() =>
										setNotificationText({
											...notificationText,
											active: !notificationText.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						onClick={() => history.push("/notification-texts")}
						variant="outlined"
						style={{ marginRight: "20px" }}
					>
						Back
					</Button>
					<Button
						onClick={() => saveNotificationText()}
						variant="outlined"
						color="primary"
						style={{ marginRight: "20px" }}
					>
						Save notification text
					</Button>
					{notificationTextId && user?.role.includes("admin") && (
						<>
							<MuiThemeProvider theme={theme}>
								<Button
									onClick={() => setOpenConfirmDialog(true)}
									variant="outlined"
									color="secondary"
									style={{ marginRight: "20px" }}
								>
									Delete notification text
								</Button>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginLeft: "auto",
									}}
								></div>
							</MuiThemeProvider>

							<ConfirmDialog
								title={
									"Are you sure you want to delete this notification text?"
								}
								open={openConfirmDialog}
								setOpen={setOpenConfirmDialog}
								onConfirm={() => {
									deleteNotificationText(notificationTextId)
										.then((res) => {
											if (
												res.status === 204 ||
												res.status === 200
											) {
												alertSuccess({
													title: "Deleted!",
													customMessage:
														"Notification text deleted successfully",
												});
												history.push("/notification-texts");
											}
										})
										.catch((error) => {
											alertError({
												error: error,
												customMessage:
													"Could not delete notification text.",
											});
										});
								}}
							/>
						</>
					)}
				</div>
			</>
		);
}
