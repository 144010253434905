import { ArrowDownward } from "@material-ui/icons";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import React, { useEffect, useRef, useState } from "react";
import InputEmoji from "react-input-emoji";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
} from "../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../api";
import {
	getMessagesByChatId,
	postMessage,
	setAsRead,
} from "../../../api/message";
import { getUserById } from "../../../api/user";
import {
	addMessage,
	readMessages,
	updateCurrentChat,
} from "../../../redux/chatRedux/chatActions";
import { getFileType } from "../../../utils/helpers";
import { alertError } from "../../../utils/logger";
import PreviewDialog from "../dialogs/PreviewDialog";
import SendDialog from "../dialogs/SendDialog";
import SearchBar from "../searchBar";
import { dateFormatter } from "../tables/table";
import "./ChatBox.css";

const ChatBox = ({
	viewMode = false,
	setSendMessage,
	lastUnreadMessage,
	setLastUnreadMessage,
}) => {
	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const [userData, setUserData] = useState(null);
	const [online, setOnline] = useState(false);
	const [openSendDialog, setOpenSendDialog] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const messages = useSelector(
		(store) => store.chat?.currentChatMessages,
		shallowEqual
	);
	const onlineUsers = useSelector(
		(store) => store.chat?.onlineUsers,
		shallowEqual
	);
	const chat = useSelector((store) => store.chat?.currentChat, shallowEqual);
	const notifications = useSelector(
		(store) => store.chat?.notifications,
		shallowEqual
	);
	const [newMessage, setNewMessage] = useState("");
	const [searchText, setSearchText] = useState("");
	const dispatch = useDispatch();

	const handleChange = (newMessage) => {
		setNewMessage(newMessage);
	};

	// fetching data for header
	useEffect(() => {
		const otherChatMembers = chat?.members.filter(
			(id) => id.toString() !== loggedUser?._id
		);

		const userId = otherChatMembers?.length === 1 && otherChatMembers[0];

		const getUserData = async () => {
			try {
				if (chat?.team) setUserData(chat?.team);
				else if (userId) setUserData((await getUserById(userId)).data);
			} catch (error) {
				console.log(error);
			}
		};

		if (chat !== null) getUserData();
	}, [chat, loggedUser, onlineUsers]);

	useEffect(() => {
		if (!userData || !onlineUsers) return;
		let online = onlineUsers?.some((x) =>
			chat?.members?.some((id) => id === x.userId)
		);
		setOnline(online);
	}, [userData, onlineUsers]);

	// fetch messages
	useEffect(() => {
		const fetchMessages = async () => {
			try {
				let messages = (await getMessagesByChatId(chat._id))?.data;

				messages = messages?.sort(
					(a, b) =>
						new Date(a.createdAt).getTime() -
						new Date(b.createdAt).getTime()
				);

				dispatch(updateCurrentChat(chat, messages));
			} catch (error) {
				console.log(error);
			}
		};

		if (chat !== null) fetchMessages();
	}, [chat, dispatch]);

	// Always scroll to last Message
	useEffect(() => {
		if (scroll.current)
			scroll.current.scrollIntoView({ behavior: "smooth" });
	}, [messages]);

	// Send Message
	const handleSend = async (e) => {
		e.preventDefault();
		const message = {
			senderId: loggedUser?._id,
			text: newMessage,
			chatId: chat._id,
			fileURL: null,
		};
		const receiversIds = chat.members.filter(
			(id) => id.toString() !== loggedUser?._id?.toString()
		);
		// send message to database
		try {
			let { data } = await postMessage(message, selectedImage);
			// send message to socket server
			dispatch(addMessage({ ...data, senderId: { ...loggedUser } }));
			if (selectedImage) {
				setSendMessage({
					...data,
					receiversIds,
					text: "",
					file: data.fileURL,
				});
				setSelectedImage(null);
			} else {
				setNewMessage("");
				setSendMessage({ ...data, receiversIds });
			}
		} catch (err) {
			alertError({
				error: err,
				customMessage: "Could not send message.",
			});
		}
	};

	const formatTime = (date) => {
		const _date = new Date(date);
		return `${_date.getHours()}:${String(_date.getMinutes()).padStart(
			2,
			"0"
		)}`;
	};

	const formatMessage = (text) => {
		if (
			searchText &&
			searchText.length &&
			text.toLowerCase().includes(searchText.toLowerCase())
		) {
			return text.replace(
				new RegExp(`(${searchText})`, "ig"),
				"<mark>$1</mark>"
			);
		}
		return text;
	};

	useEffect(() => {
		if (searchText && searchText.length) {
			let newArray = messages.filter((each) => {
				return each.text
					?.toLowerCase()
					.includes(searchText.toLowerCase());
			});
			if (newArray && newArray.length) {
				const element = document.getElementById(
					newArray[newArray.length - 1]._id
				);
				element.scrollIntoView();
			}
		}
	}, [searchText, messages]);

	useEffect(() => {
		const setAllMessagesRead = async () => {
			try {
				await setAsRead(chat._id, loggedUser?._id);
				dispatch(readMessages(chat._id));
			} catch (error) {
				console.log(error);
			}
		};

		if (chat && notifications && messages?.length && !viewMode) {
			if (notifications[chat._id]) {
				setLastUnreadMessage(messages.length - notifications[chat._id]);
				setAllMessagesRead();
			}
		}
		// eslint-disable-next-line
	}, [notifications, chat, messages]);

	const scroll = useRef();
	const imageRef = useRef();
	return (
		<>
			<Card key={chat} className="cards-chat">
				{chat && (
					<CardHeader className="chat-header">
						<div className="header-chat">
							<div className="d-flex justify-content-center align-items-center">
								<img
									src={
										chat?.team && chat?.team?.logoURL
											? SERVER_URL +
											  "/" +
											  chat?.team?.logoURL
											: userData?.imagesURLs &&
											  userData?.imagesURLs[0] &&
											  userData?.imagesURLs[0] !== ""
											? SERVER_URL +
											  "/" +
											  userData?.imagesURLs[0]
											: toAbsoluteUrl(
													"/media/profile.png"
											  )
									}
									alt="Profile"
									className="followerImage"
								/>
								<div
									className="name"
									style={{
										fontSize: "1.25rem",
										color: "#3F4254",
									}}
								>
									<span>
										{chat?.team
											? chat?.team?.title
											: userData?.fullName}
									</span>
								</div>
							</div>
							<div className="d-flex justify-content-center align-items-center">
								<div
									className="online-dot"
									style={{
										backgroundColor: online
											? "#1BC5BD"
											: "red",
									}}
								></div>
								<span
									style={{
										fontSize: "0.9rem",
										color: "#B5B5C3",
									}}
								>
									{online ? "Active" : "Inactive"}
								</span>
							</div>
						</div>
					</CardHeader>
				)}
				<CardBody>
					{chat && (
						<SearchBar
							searchText={searchText}
							setSearchText={setSearchText}
						/>
					)}
					{chat ? (
						<div className="chat-body">
							{Object.entries(
								messages?.reduce((groups, game) => {
									const date = game.createdAt.split("T")[0];
									if (!groups[date]) {
										groups[date] = [];
									}
									groups[date].push(game);
									return groups;
								}, {})
							)?.map(([group, msgs]) => (
								<>
									<span
										style={{
											textAlign: "center",
											color: "black",
											background: "lightgrey",
											borderRadius: "0.7rem",
											marginTop: "1rem",
											padding: "0.2rem",
											fontWeight: "500",
											marginBottom: "1rem",
										}}
									>
										{dateFormatter(group, true)}
									</span>
									{msgs?.map((message, index) => (
										<>
											{message.senderId?._id?.toString() !==
												loggedUser?._id &&
												lastUnreadMessage === index && (
													<>
														<hr
															style={{
																width: "100%",
																backgroundColor:
																	"black",
																marginBottom:
																	"0px",
															}}
															ref={scroll}
														/>
														<span
															style={{
																textAlign:
																	"center",
																fontWeight:
																	"medium",
															}}
														>
															<ArrowDownward />
															{"New messages"}
															<ArrowDownward />
														</span>
													</>
												)}
											<div
												ref={scroll}
												key={index}
												id={message._id}
												className={
													message?.eventType
														? "d-flex justify-content-center"
														: message.senderId?._id?.toString() ===
														  loggedUser?._id
														? "message own"
														: "message"
												}
											>
												{!message?.eventType &&
													chat?.team &&
													message?.senderId?._id !==
														loggedUser?._id && (
														<span
															style={{
																fontSize:
																	"10px",
															}}
														>
															{
																message
																	?.senderId
																	?.fullName
															}
														</span>
													)}
												{message?.deletedAt ? (
													<span>
														<i
															style={{
																color: "black",
																fontSize:
																	"13px",
															}}
														>
															{"Message deleted at " +
																dateFormatter(
																	message?.deletedAt,
																	false
																)}
														</i>
													</span>
												) : message?.eventType ? (
													<span
														className="p-2 my-2"
														style={{
															fontSize: "12px",
															width:
																"fit-content",
															textAlign: "center",
															color: "black",
															background:
																"lightgrey",
															borderRadius:
																"0.7rem",
															fontWeight: "400",
														}}
													>
														{(message?.senderId
															?.fullName || "") +
															" " +
															message?.eventType}
													</span>
												) : message.fileURL &&
												  message.fileURL !== "" ? (
													<span>
														{getFileType(
															message.fileURL
														) === "image" && (
															<>
																<img
																	src={
																		SERVER_URL +
																		"/" +
																		message.fileURL
																	}
																	alt={
																		message.fileURL.split(
																			/-(.*)/s
																		)[1]
																	}
																	className="chat-message-image"
																	onClick={() =>
																		setOpenPreviewDialog(
																			true
																		)
																	}
																/>
																<PreviewDialog
																	open={
																		openPreviewDialog
																	}
																	setOpen={
																		setOpenPreviewDialog
																	}
																	src={
																		SERVER_URL +
																		"/" +
																		message.fileURL
																	}
																/>
															</>
														)}
														<br />
														<a
															href={
																SERVER_URL +
																"/" +
																message.fileURL
															}
															rel="noopener noreferrer"
															download
															target="_blank"
															style={{
																color: "black",
																textDecoration:
																	"underline",
															}}
														>
															<AttachFileIcon />
															{
																message.fileURL.split(
																	/-(.*)/s
																)[1]
															}
														</a>
													</span>
												) : (
													<span
														dangerouslySetInnerHTML={{
															__html: formatMessage(
																message.text
															),
														}}
													></span>
												)}
												{!message?.eventType && (
													<span
														style={{
															fontSize: "10px",
														}}
													>
														{formatTime(
															message.createdAt
														)}
													</span>
												)}
											</div>
										</>
									))}
								</>
							))}
						</div>
					) : (
						<span className="chatbox-empty-message">
							{
								"To start a conversation tap on an existing chat or create a new one"
							}
						</span>
					)}
				</CardBody>
				{chat && !viewMode && (
					<CardFooter>
						<div className="chat-sender">
							<div onClick={() => imageRef.current.click()}>
								<AttachFileIcon />
							</div>
							<InputEmoji
								shouldReturn={true}
								value={newMessage}
								onChange={handleChange}
							/>
							<button
								type="button"
								className="btn btn-primary"
								onClick={handleSend}
							>
								SEND
							</button>
							<input
								type="file"
								name=""
								id=""
								style={{ display: "none" }}
								ref={imageRef}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
									setOpenSendDialog(true);
								}}
							/>
							<SendDialog
								open={openSendDialog}
								src={
									selectedImage
										? URL.createObjectURL(selectedImage)
										: null
								}
								title={"Sending to " + userData?.fullName}
								type={getFileType(selectedImage?.name)}
								onClose={() => {
									setSelectedImage(null);
									setOpenSendDialog(false);
								}}
								onConfirm={(e) => {
									handleSend(e);
									setOpenSendDialog(false);
								}}
							/>
						</div>
					</CardFooter>
				)}
			</Card>
		</>
	);
};

export default ChatBox;
