import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	Button,
	TextField,
	Select,
	Checkbox,
	InputLabel,
	MenuItem,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputAdornment,
	MuiThemeProvider,
	createMuiTheme,
} from '@material-ui/core';

import { Card, CardBody, CardHeader } from '_metronic/_partials/controls';
import { deleteAd, getAdById, postAd, updateAd } from 'api/ad';
import { useSkeleton } from 'app/hooks/useSkeleton';
import ConfirmDialog from 'app/components/dialogs/ConfirmDialog';
import { alertError, alertSuccess } from 'utils/logger';

// Create theme for delete button (red)
const theme = createMuiTheme({ palette: { secondary: { main: '#F64E60' } } });

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 },
	},
	getContentAnchorEl: () => null,
};

const EnumAdFormats = [
	'banner',
	'interstitial',
	'rewarded-interstitial',
	'rewarded',
	'native-advanced',
	'app-open',
];

const EnumPlatforms = ['android', 'ios'];

const EmptyAd = {
	name: '',
	unitId: '',
	format: '',
	platform: '',
	active: false,
};

export function AdViewPage() {
	const [ad, setAd] = useState(EmptyAd);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	const adId = useParams().id;
	const history = useHistory();

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!adId) {
			disableLoadingData();
			return;
		}
		getAdById(adId)
			.then((res) => {
				if (res.status === 200) {
					setAd(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({ error: error, customMessage: 'Could not get ad.' });
				history.push('/ads');
			});
	}, [adId, disableLoadingData, history]);

	function saveAd() {
		if (!adId)
			postAd(ad)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Ad successfully created.',
						});
						history.push('/ads');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save ad.',
					})
				);
		else
			updateAd(adId, ad)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Changes successfully saved.',
						});
						history.push('/ads');
					}
				})
				.catch((error) =>
					alertError({
						error: error,
						customMessage: 'Could not save changes.',
					})
				);
	}

	const handleChange = (key) => (event) =>
		setAd({ ...ad, [key]: event.target.value });

	if (isLoadingData) return <ContentSkeleton />;
	return (
		<>
			<Card>
				<CardHeader title='Edit Ad' />
				<CardBody>
					<TextField
						id='name'
						label='Name'
						value={ad.name}
						onChange={handleChange('name')}
						InputLabelProps={{ shrink: true }}
						margin='normal'
						variant='outlined'
						required
					/>
					<br />
					<br />
					<TextField
						id='unitId'
						label='Unit Id'
						value={ad.unitId}
						onChange={handleChange('unitId')}
						InputLabelProps={{ shrink: true }}
						margin='normal'
						variant='outlined'
						required
						inputProps={{ maxLength: 10 }}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									{'ca-app-pub-9700734882043173/'}
								</InputAdornment>
							),
						}}
						error={ad.unitId.length !== 10}
						maxLength={4}
					/>
					<br />
					<br />
					<FormControl style={{ width: '100%' }}>
						<InputLabel id='demo-simple-select-standard-label'>
							Format
						</InputLabel>
						<Select
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							value={ad.format}
							onChange={handleChange('format')}
							MenuProps={MenuProps}>
							{EnumAdFormats.map((format) => (
								<MenuItem key={format} value={format}>
									{format.charAt(0).toUpperCase() +
										format.slice(1)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>Select the ad format</FormHelperText>
					</FormControl>
					<br />
					<br />
					<FormControl style={{ width: '100%' }}>
						<InputLabel id='demo-simple-select-standard-label'>
							Platform
						</InputLabel>
						<Select
							labelId='demo-simple-select-standard-label'
							id='demo-simple-select-standard'
							value={ad.platform}
							onChange={handleChange('platform')}
							MenuProps={MenuProps}>
							{EnumPlatforms.map((format) => (
								<MenuItem key={format} value={format}>
									{format.charAt(0).toUpperCase() +
										format.slice(1)}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>Select the ad platform</FormHelperText>
					</FormControl>
					<br />
					<br />
					<FormControlLabel
						control={
							<Checkbox
								checked={ad.active}
								onChange={() => setAd({ ...ad, active: !ad.active })}
								name='checkActive'
							/>
						}
						label='Active'
					/>
					<br />
					<br />
					<div>
						{`This is just an information page, changes made here`}
						<b>{` do not affect the ad's configuration `}</b>
						{`on the AdMob Service. In order to apply any real changes, the ad must be updated through this`}
						<a
							target='_blank'
							rel='noreferrer'
							href={`https://apps.admob.com/v2/apps/5706576419/adunits/${ad.unitId}/edit`}>
							<b>{' LINK'}</b>
						</a>
						.
					</div>
				</CardBody>
			</Card>
			<Button
				onClick={() => history.push('/ads')}
				variant='outlined'
				style={{ marginRight: '20px' }}>
				Back
			</Button>
			<Button
				onClick={saveAd}
				variant='outlined'
				color='primary'
				style={{ marginRight: '20px' }}>
				Save ad
			</Button>
			{adId && (
				<>
					<MuiThemeProvider theme={theme}>
						<Button
							onClick={() => setOpenConfirmDialog(1)}
							variant='outlined'
							color='secondary'>
							Delete ad
						</Button>
					</MuiThemeProvider>
					<ConfirmDialog
						title={'Are you sure you want to delete this ad?'}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() =>
							deleteAd(adId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: 'Deleted!',
											customMessage: 'Ad deleted successfully',
										});
										history.push('/ads');
									}
								})
								.catch((error) =>
									alertError({
										error: error,
										customMessage: 'Could not delete ad.',
									})
								)
						}
					/>
				</>
			)}
		</>
	);
}
