import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { checkIsEmpty } from "../../../utils/helpers";
import { alertError } from "../../../utils/logger";
import MultilanguageTabBlock from "../MultilanguageTabBlock";
import Editor from "../editor/Editor";

function getEmptySubscriptionRating() {
	return {
		username: {},
		description: {},
		stars: 5,
		active: true,
	};
}

const EditSubscriptionRatingsDialog = (props) => {
	const { username, data, open, setOpen, onSave, readOnly } = props;

	const [rating, setRating] = useState(getEmptySubscriptionRating());

	useEffect(() => {
		if (!open || !data) setRating(getEmptySubscriptionRating());
		else setRating(data);
	}, [data, open]);

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!rating[element]) rating[element] = {};
			let newText = rating[element];
			newText[lang] = event.target.value;
			setRating({ ...rating, [element]: newText });
		} else setRating({ ...rating, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value, lang) => {
		if (value?.trim() !== "") {
			if (lang) {
				if (!rating[element]) rating[element] = {};
				let newText = rating[element];
				newText[lang] = value;
				setRating({ ...rating, [element]: newText });
			} else setRating({ ...rating, [element]: value });
		}
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`username`}
					label="Username"
					value={(rating?.username && rating?.username[lang]) || ""}
					onChange={handleChange("username", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="outlined"
					disabled={readOnly}
					required
				/>
				<br />
				<br />
				<div>Description</div>
				<Editor
					disabled={readOnly}
					body={(rating.description && rating?.description[lang]) || ""}
					setBody={(new_body) => {
						handleChangeEditor("description", new_body, lang);
					}}
					className="max-height"
					placeholder={"Enter rating description here..."}
				/>
			</>
		);
	};

	return (
		<Dialog
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{username}</DialogTitle>
			<DialogContent>
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
				<TextField
						id={`stars`}
						label="Stars"
						value={rating?.stars || ""}
						onChange={handleChange("stars")}
						InputLabelProps={{
							shrink: true,
						}}
						required
						margin="normal"
						variant="outlined"
						type="number"
						InputProps={{
							inputProps: {
								min: 0,
								max: 5
							},
						}}
					/>
				<br />
				<br />
				<FormControlLabel
					control={
						<Checkbox
							checked={rating?.active}
							onChange={() =>
								setRating({
									...rating,
									active: !rating?.active,
								})
							}
							name="checkActive"
						/>
					}
					disabled={readOnly}
					label="Active"
				/>
				<br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setRating(getEmptySubscriptionRating());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (checkIsEmpty(rating.username)) {
							alertError({
								error: null,
								customMessage:
									"The username is required in at least one of the languages",
							});
						} else {
							setOpen(false);
							onSave(rating);
							setRating(getEmptySubscriptionRating());
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default EditSubscriptionRatingsDialog;
