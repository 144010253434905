import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const getFoodPlates = () => {
	return authClient().get(`${API}/food-plate?getDisabled=true`);
};

export const getFoodPlateById = (id) => {
	return authClient().get(`${API}/food-plate/${id}`);
};

export const deleteFoodPlate = (id) => {
	return authClient().delete(`${API}/food-plate/${id}`);
};

export const postFoodPlate = async (foodPlate, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "foodPlates");
		foodPlate.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/food-plate`, foodPlate);
};

export const updateFoodPlate = async (id, foodPlate, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "foodPlates");
		foodPlate.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/food-plate/${id}`, foodPlate);
};

export const changeStatusFoodPlate = async (id, active) => {
	return authClient().put(`${API}/food-plate/change-status/${id}`, {
		active,
	});
};
