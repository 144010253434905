import { API, authClient } from '../index';
import { uploadFilesGetLinks, uploadSingleFile } from "../files"

// Get all routines
export const getRoutines = () => {
	return authClient().get(`${API}/routine?getDisabled=true`);
};

// Get routine by id
export const getRoutineById = (id) => {
	return authClient().get(`${API}/routine/${id}`);
};

// Delete routine
export const deleteRoutine = (id) => {
	return authClient().delete(`${API}/routine/${id}`);
};

// Create routine 
export const postRoutine = async (routine, audio, video, mainImage, images) => {
	if (audio) {
		const response = await uploadSingleFile(audio, 'routines')
		routine.audioTipURL = response.data.fileURL
	}
	if (video) {
		const response = await uploadSingleFile(video, 'routines')
		routine.videoURL = response.data.fileURL
	}
	if (mainImage) {
		const response = await uploadSingleFile(mainImage, 'routines')
		routine.imageURL = response.data.fileURL
	}
	if (images) {
		const imagesURLs = await uploadFilesGetLinks(images, 'routines')
		routine.imagesURLs = imagesURLs
	}
	return authClient().post(`${API}/routine`, routine);
};

// Update routine
export const updateRoutine = async (id, routine, audio, video, mainImage, images) => {
	if (audio) {
		const response = await uploadSingleFile(audio, 'routines')
		routine.audioTipURL = response.data.fileURL
	}
	if (video) {
		const response = await uploadSingleFile(video, 'routines')
		routine.videoURL = response.data.fileURL
	}
	if (mainImage) {
		const response = await uploadSingleFile(mainImage, 'routines')
		routine.imageURL = response.data.fileURL
	}
    if (images) {
        for (const index in images) {
            const response = await uploadSingleFile(images[index], 'routines')
            routine.imagesURLs[index] = response.data.fileURL
        }
    }
	return authClient().put(`${API}/routine/${id}`, routine);
};

// Change status routine
export const changeStatusRoutine = async (id, active) => {
	console.log(id, active);
	return authClient().put(`${API}/routine/change-status/${id}`, { active });
};